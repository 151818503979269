import { useEffect } from 'react';
import Moment from 'moment';
import moment from 'moment';
import { get } from 'helpers/apiHelpers';
import { withRouter } from 'react-router';
import axios from 'helpers/gastro';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import tableStyle from 'assets/jss/material-dashboard-pro-react/components/tableStyle';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { combineStyles } from 'helpers/helpers';
import Button from 'components/CustomButtons/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { apiErrorToast } from 'utils/apiError';

const SummaryDay = ({
  variant,
  diets,
  day,
  classes,
  filteredMealTypes,
  t,
  history,
}) => {
  const [state, setState] = useState({
    menuPlanners: [],
    cacheKey: '',
    lockedPdfExport: false,
    lockedXlsxExport: false,
  });
  useEffect(() => {
    getMenuPlanners();
  }, []);

  const getMenuPlanners = async () => {
    const query = {
      pagination: false,
      'date[after]': day,
      'date[before]': day,
      variant: variant['@id'],
      properties: ['id', 'mealType', 'diet'],
      'properties[dish]': 'nameForClient',
      draft: false,
    };
    const cacheKey = JSON.stringify(query);

    if (cacheKey !== state.cacheKey) {
      const response = await get('/menu-summary', query);

      setState(prevState => {
        return {
          ...prevState,
          menuPlanners: response['hydra:member'],
          cacheKey,
        };
      });
    }
  };

  const cellInfo = (diet, mealType, index) => {
    const menuPlanner = state.menuPlanners.find(menu => {
      return (
        menu && menu.diet['@id'] === diet && menu.mealType === mealType['@id']
      );
    });

    if (menuPlanner) {
      return (
        <TableCell colSpan={1} style={{ textAlign: 'center' }} key={index}>
          {menuPlanner.dish.nameForClient}{' '}
          {menuPlanner.dish.rate ? `(${menuPlanner.dish.rateAVG})` : ''}
        </TableCell>
      );
    }

    return (
      <TableCell
        className={classes.warning}
        colSpan={1}
        style={{ textAlign: 'center' }}
        key={index}
      >
        {t('form.menuPlanning.lack')}
      </TableCell>
    );
  };

  const goToEdit = day => {
    history.push(`/admin/menu-planner/${day}/${variant.id}`);
  };

  const exportSingleDate = (date, extension) => {
    let mimeType;
    let lockParam;

    switch (extension) {
      case 'pdf':
        mimeType = 'application/pdf';
        lockParam = 'lockedPdfExport';

        break;
      case 'xlsx':
        mimeType = 'application/vnd.ms-excel';
        lockParam = 'lockedXlsxExport';

        break;
      default:
        break;
    }

    setState(prevState => ({ ...prevState, [lockParam]: true }));
    const params = {
      date: date,
      variant: variant.id,
    };

    axios
      .get('reports/menu-planner', {
        responseType: 'blob',
        params: params,
        headers: { accept: mimeType },
      })
      .then(
        response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Planowanie-menu-${date}_(${moment().format(
              'YYYY-MM-DD_HH.mm'
            )}).` + extension
          );
          document.body.appendChild(link);
          link.click();
          setState(prevState => ({ ...prevState, [lockParam]: false }));
        },
        error => {
          apiErrorToast(t('reports.cannotGenerate'), error);
          setState(prevState => ({ ...prevState, [lockParam]: false }));
        }
      );
  };

  let rows = [];
  rows.push(
    <TableRow className={classes.successRow} key={'_asc'}>
      <TableCell
        padding="dense"
        style={{ textAlign: 'center' }}
        colSpan={filteredMealTypes.length + 1}
      >
        {day} {new Moment(day).format('dddd')}
        <Button
          size={'sm'}
          style={{ marginLeft: '20px' }}
          color="success"
          round
          onClick={() => {
            goToEdit(day);
          }}
        >
          {t('form.menuPlanning.edit')}
        </Button>
        <Button
          size={'sm'}
          style={{ marginLeft: '20px' }}
          color="primary"
          loading
          round
          onClick={() => {
            exportSingleDate(day, 'pdf');
          }}
        >
          {!state.lockedPdfExport && <>{t('form.menuPlanning.saveToPdf')}</>}
          {state.lockedPdfExport && (
            <CircularProgress style={{ width: '20px', height: '20px' }} />
          )}
        </Button>
        <Button
          size={'sm'}
          style={{ marginLeft: '20px' }}
          color="primary"
          loading
          round
          onClick={() => {
            exportSingleDate(day, 'xlsx');
          }}
        >
          {!state.lockedXlsxExport && <>{t('form.menuPlanning.saveToXlsx')}</>}
          {state.lockedXlsxExport && (
            <CircularProgress style={{ width: '20px', height: '20px' }} />
          )}
        </Button>
      </TableCell>
    </TableRow>
  );
  diets.map((diet, index) => {
    rows.push(
      <TableRow key={index}>
        <TableCell colSpan={1}>{diet.name}</TableCell>
        {filteredMealTypes.map((mealType, index) =>
          cellInfo(diet['@id'], mealType, index)
        )}
      </TableRow>
    );
    return null;
  });
  return rows;
};

const combinedStyles = combineStyles(tableStyle, buttonsStyle);
const enhance = compose(
  withRouter,
  withStyles(combinedStyles),
  withTranslation()
);

export default enhance(SummaryDay);
