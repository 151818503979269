import React from 'react';
import { applyMenuTemplate } from 'actions/MenuTemplates';
import { connect } from 'react-redux';
import { Help, Publish } from '@material-ui/icons';
import SweetAlert from 'react-bootstrap-sweetalert';
import withStyles from '@material-ui/core/styles/withStyles';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { withTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

class PublishMenu extends React.Component {
  state = {
    alert: null,
  };

  setLoading = value =>
    this.setState(prevState => ({ ...prevState, loading: value }));

  mapBlockToKey = (el, draft) =>
    `${draft ? 'T' : 'N'}_${el.diet?.['@id'] ?? el.diet}_${
      el.variant?.['@id'] ?? el.variant
    }_${el.mealType?.['@id'] ?? el.mealType}`;

  containsOverrides = () => {
    const map = this.props.menuPlanners
      .filter(el => !el.draft)
      .map(el => this.mapBlockToKey(el, false));
    return (
      this.props.menuPlanners.filter(
        el => el.draft && map.includes(this.mapBlockToKey(el, false))
      ).length > 0
    );
  };

  confirmModal = () => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          title={this.props.t('menuPlanner.action.plan.publish.title')}
          onConfirm={() => {
            if (this.containsOverrides()) {
              this.overrideChangedMenuModal();
            } else {
              this.props.onPublish(false);
              this.hideAlert();
            }
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.danger
          }
          confirmBtnText={this.props.t('common.shared.yes')}
          cancelBtnText={this.props.t('common.shared.no')}
          showCancel
        />
      ),
    });
  };

  overrideChangedMenuModal = () => {
    this.setState({
      alert: (
        <SweetAlert
          title={
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ color: '#c9a032' }}>
                <Help fontSize={'large'} color={'warning'} />
              </Grid>
              <Grid item xs={12}>
                <h3>
                  {this.props.t(
                    'menuPlanner.action.plan.publish.overrideChangedMenu'
                  )}
                </h3>
              </Grid>
              <Grid item xs={12}>
                <h4>
                  {this.props.t(
                    'menuPlanner.action.plan.publish.overrideChangedMenu.description.yes'
                  )}
                  <br />
                  <br />
                  {this.props.t(
                    'menuPlanner.action.plan.publish.overrideChangedMenu.description.no'
                  )}
                </h4>
              </Grid>
            </Grid>
          }
          onConfirm={() => {
            this.props.onPublish(true);
            this.hideAlert();
          }}
          onCancel={() => {
            this.props.onPublish(false);
            this.hideAlert();
          }}
          confirmBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.success
          }
          confirmBtnText={this.props.t('common.shared.yes')}
          cancelBtnText={this.props.t('common.shared.no')}
          showCancel
        />
      ),
    });
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  render() {
    return (
      <>
        <BottomNavigationAction
          label={this.props.t('menuPlanner.action.plan.publish.actionTitle')}
          showLabel={true}
          icon={<Publish style={{ color: '#46ad58' }} />}
          onClick={this.confirmModal}
        />
        {this.state.alert}
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  applyMenuTemplate: (id, date) => dispatch(applyMenuTemplate(id, date)),
});

export default withTranslation()(
  connect(null, mapDispatchToProps)(withStyles(sweetAlertStyle)(PublishMenu))
);
