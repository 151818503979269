import { useState, useEffect } from 'react';

import { AuthServicesModule } from './modules';
// import modulesNames from "../Brands/modules/configurationConst";
import { combineStyles } from '../../helpers/helpers';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  fetchBrandConfigModulesPack,
  updateBrandConfigModulesPack,
} from '../../actions/Brands';
import withStyles from '@material-ui/core/styles/withStyles';
import { modulesNames } from './consts';
import { DialogLoader } from '../../components/DialogLoader';
import { toast } from 'react-toastify';

const LoginOptions = ({
  t,
  classes,
  selectedBrand,
  fetchBrandConfigModulesPack,
  updateBrandConfigModulesPack,
}) => {
  const [modules, setModules] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const modulesConfig = await fetchBrandConfigModulesPack(
        selectedBrand,
        Object.values(modulesNames)
      );

      setModules(modulesConfig.configuration);
      setIsLoading(false);
    })();
  }, []);

  const handleModuleUpdate = data => {
    const type = data['@type'];
    let module = { ...modules[type], ...data };
    setModules(prevState => ({
      ...prevState,
      [type]: module,
    }));
  };

  const handleSubmit = async () => {
    try {
      await updateBrandConfigModulesPack(selectedBrand, modules);
      toast.success(t('success.changesSaved'));
    } catch (error) {
      toast.error(
        error?.message
          ? `${t('errors.modulesCouldNotBeSaved')} ${error.message}`
          : t('errors.modulesCouldNotBeSaved')
      );
    }
  };

  return (
    <>
      <DialogLoader
        loaderState={isLoading}
        text={t('common.shared.isLoading')}
      />

      {!isLoading && modules && (
        <AuthServicesModule
          classes={classes}
          handleModuleUpdate={handleModuleUpdate}
          module={modules[modulesNames.AuthServices]}
          handleSubmit={handleSubmit}
          otherSettingsModule={modules[modulesNames.ConfigClientPanel]}
        />
      )}
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withTranslation(),
  connect(
    ({ Auth: { selectedBrand } }) => ({
      selectedBrand,
    }),
    { fetchBrandConfigModulesPack, updateBrandConfigModulesPack }
  ),
  withStyles(combinedStyles)
);

export default enhance(LoginOptions);
