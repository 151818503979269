import { Component } from 'react';
import { LocalHospital } from '@material-ui/icons';
import withStyles from '@material-ui/core/styles/withStyles';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ModalButton from 'components/CustomButtons/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { Tooltip } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const filterIncorrectDishes = el => !el.isCorrectDishForDiet;
const reduceAdd = (prev, curr) => prev + curr;

class ExcludedDishesUsed extends Component {
  state = {
    errorModal: false,
  };

  closeErrorModal = () =>
    this.setState(prevState => ({ ...prevState, errorModal: false }));

  openErrorModal = () =>
    this.setState(prevState => ({ ...prevState, errorModal: true }));

  countIncorrectBlocks = dishCodes =>
    dishCodes
      .filter(filterIncorrectDishes)
      .map(el => el.excludedFromDiets.length)
      .reduce(reduceAdd, 0);

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.countIncorrectBlocks(prevProps.dishCodes) <
      this.countIncorrectBlocks(this.props.dishCodes)
    ) {
      this.showWrongDishToast();
    }
  }

  componentDidMount() {
    if (this.countIncorrectBlocks(this.props.dishCodes) > 0) {
      this.showWrongDishToast();
    }
  }

  showWrongDishToast = () =>
    toast.warning(
      `${this.props.t(
        'menuPlanner.action.warning.excludedDishesUsed.toast.title'
      )} ${this.props.t(
        'menuPlanner.action.warning.excludedDishesUsed.toast.description'
      )}`
    );

  render() {
    const { dishCodes } = this.props;

    const wrongDishes = dishCodes.filter(filterIncorrectDishes);

    if (wrongDishes.length === 0) {
      return null;
    }

    return (
      <>
        <Dialog
          maxWidth={'lg'}
          open={this.state.errorModal}
          onClose={this.closeErrorModal}
        >
          <DialogContent>
            <ModalButton
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                cursor: 'pointer',
              }}
              color={'transparent'}
              justIcon
              round
              onClick={this.closeErrorModal}
            >
              &times;
            </ModalButton>
          </DialogContent>
          <DialogContent>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {this.props.t(
                        'menuPlanner.action.warning.excludedDishesUsed.fields.dish'
                      )}
                    </TableCell>
                    <TableCell>
                      {this.props.t(
                        'menuPlanner.action.warning.excludedDishesUsed.fields.excludedFromDiet'
                      )}
                    </TableCell>
                    <TableCell>
                      {this.props.t(
                        'menuPlanner.action.warning.excludedDishesUsed.fields.mealType'
                      )}
                    </TableCell>
                    <TableCell>
                      {this.props.t(
                        'menuPlanner.action.warning.excludedDishesUsed.fields.diet'
                      )}
                    </TableCell>
                    <TableCell>
                      {this.props.t(
                        'menuPlanner.action.warning.excludedDishesUsed.fields.variant'
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {wrongDishes.map((el, index) => {
                    return (
                      <TableRow key={index} style={{ background: '#f00' }}>
                        <TableCell style={{ color: '#fff' }} variant={'head'}>
                          {el.dish.nameForClient}
                        </TableCell>
                        <TableCell style={{ color: '#fff' }}>
                          {el.excludedFromDiets.map(diet => (
                            <>
                              {diet.name}
                              <br />
                            </>
                          ))}
                        </TableCell>
                        <TableCell style={{ color: '#fff' }}>
                          {el.mealType.name}
                        </TableCell>
                        <TableCell style={{ color: '#fff' }}>
                          {el.existInDiets.map(diet => (
                            <>
                              {diet.name}
                              <br />
                            </>
                          ))}
                        </TableCell>
                        <TableCell style={{ color: '#fff' }}>
                          {el.existInVariants.map(variant => (
                            <>
                              {variant.name}
                              <br />
                            </>
                          ))}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          </DialogContent>
        </Dialog>
        <Tooltip
          style={{ marginLeft: '10px', cursor: 'help' }}
          title={
            <div>
              <h4>
                {this.props.t(
                  'menuPlanner.action.warning.excludedDishesUsed.tooltip.title'
                )}
              </h4>
            </div>
          }
          placement="bottom"
        >
          <BottomNavigationAction
            label={this.props.t(
              'menuPlanner.action.warning.excludedDishesUsed.tooltip.actionName'
            )}
            showLabel={true}
            icon={<LocalHospital style={{ color: '#f65b52' }} />}
            onClick={this.openErrorModal}
          />
        </Tooltip>
      </>
    );
  }
}

export default withTranslation()(
  withStyles(sweetAlertStyle)(ExcludedDishesUsed)
);
