import { compose } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { combineStyles } from 'helpers/helpers';
import { ROLE_EDIT_SEO } from 'helpers/roles';

import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import CardBody from 'components/Card/CardBody';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';
import DescriptionLetterCounter from './components/DescriptionLetterCounter';
import TitleLetterCounter from './components/TitleLetterCounter';
import CardWrapper from 'components/Card/CardWrapper';

const SEOSettingsCms = ({
  classes,
  roles,
  section,
  state,
  setState,
  moduleConfiguration = true,
}) => {
  const { t } = useTranslation();

  const handleInputChange = event => {
    const newValue = event.target.value;

    if (moduleConfiguration) {
      setState(prevState => ({
        ...prevState,
        configuration: {
          ...prevState.configuration,
          [section]: {
            ...prevState.configuration[section],
            [event.target.name]: newValue,
          },
        },
      }));
    }

    if (!moduleConfiguration) {
      setState(prev => ({
        ...prev,
        [event.target.name]: newValue,
      }));
    }
  };

  const handleToggle = () => {
    if (moduleConfiguration) {
      setState(prevState => ({
        ...prevState,
        configuration: {
          ...prevState.configuration,
          [section]: {
            ...prevState.configuration[section],
            indexing: !prevState.configuration[section].indexing,
          },
        },
      }));
    }

    if (!moduleConfiguration) {
      setState(prev => ({
        ...prev,
        indexing: !prev?.indexing,
      }));
    }
  };

  const getImage = (stateName, data) => {
    if (moduleConfiguration) {
      setState(prevState => ({
        ...prevState,
        configuration: {
          ...prevState.configuration,
          [section]: {
            ...prevState.configuration[section],
            [stateName]: data?.['@id'] || null,
            [`${stateName}Url`]: data?.contentUrl || null,
          },
        },
      }));
    }

    if (!moduleConfiguration) {
      setState(prev => ({
        ...prev,
        [stateName]: data?.['@id'] || null,
        [`${stateName}Url`]: data?.contentUrl || null,
      }));
    }
  };

  const removeImage = stateName => {
    if (moduleConfiguration) {
      setState(prevState => ({
        ...prevState,
        configuration: {
          ...prevState.configuration,
          [section]: {
            ...prevState.configuration[section],
            [stateName]: null,
            [`${stateName}Url`]: null,
          },
        },
      }));
    }
    if (!moduleConfiguration) {
      setState(prev => ({
        ...prev,
        [stateName]: null,
        [`${stateName}Url`]: null,
      }));
    }
  };

  const isEditEnabled = roles ? roles.includes(ROLE_EDIT_SEO) : false;

  return (
    <>
      <CardWrapper title={t(`brandCfg.seoSection.${section}`)}>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <FormTextInput
                label={
                  <TitleLetterCounter
                    wordsCount={
                      moduleConfiguration
                        ? state?.configuration?.[section]?.marketingTitle
                            ?.length
                        : state?.marketingTitle?.length
                    }
                  />
                }
                classes={classes}
                name="marketingTitle"
                value={
                  moduleConfiguration
                    ? state?.configuration?.[section]?.marketingTitle
                    : state?.marketingTitle
                }
                handleChange={handleInputChange}
                inputSize={6}
                disabled={!isEditEnabled}
              />

              <FormTextInput
                label={
                  <DescriptionLetterCounter
                    wordsCount={
                      moduleConfiguration
                        ? state?.configuration?.[section]?.metaDescription
                            ?.length
                        : state?.metaDescription?.length
                    }
                  />
                }
                classes={classes}
                name="metaDescription"
                value={
                  moduleConfiguration
                    ? state?.configuration?.[section]?.metaDescription
                    : state?.metaDescription
                }
                handleChange={handleInputChange}
                multiline
                inputSize={6}
                rowsMax={10}
                disabled={!isEditEnabled}
              />
              <GridContainer>
                <GridItem xs={12} sm={2}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('seo.indexPage')}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        name="indexing"
                        onClick={handleToggle}
                        checked={
                          moduleConfiguration
                            ? state?.configuration?.[section]?.indexing
                            : state?.indexing
                        }
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                        disabled={!isEditEnabled}
                      />
                    }
                    classes={{
                      label: classes.label,
                    }}
                    label={''}
                  />
                </GridItem>
              </GridContainer>
              <FormTextInput
                label={t('seo.openGraphTitle')}
                classes={classes}
                name="openGraphTitle"
                value={
                  moduleConfiguration
                    ? state?.configuration?.[section]?.openGraphTitle
                    : state?.openGraphTitle
                }
                handleChange={handleInputChange}
                inputSize={6}
                disabled={!isEditEnabled}
              />
              <FormTextInput
                label={t('seo.openGraphDesc')}
                classes={classes}
                name="openGraphDescription"
                value={
                  moduleConfiguration
                    ? state?.configuration?.[section]?.openGraphDescription
                    : state?.openGraphDescription
                }
                handleChange={handleInputChange}
                inputSize={6}
                rowsMax={10}
                disabled={!isEditEnabled}
              />
              <GridContainer style={{ marginTop: '20px' }}>
                <GridItem xs={12} sm={2}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('seo.openGraphPhoto')}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={10} style={{ marginLeft: '-15px' }}>
                  <FormImageUpload
                    classes={classes}
                    stateName="image"
                    getImage={getImage}
                    removeImage={removeImage}
                    previewUrl={
                      moduleConfiguration
                        ? state?.configuration?.[section]?.imageUrl
                        : state?.imageUrl
                    }
                    isDisabled={!isEditEnabled}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </CardWrapper>
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => {
  return {
    roles: state.Auth.user.roles,
    selectedBrand: state.Auth.selectedBrand,
  };
};

const enhance = compose(
  connect(mapStateToProps, null),
  withStyles(combinedStyles)
);

export default enhance(SEOSettingsCms);
