import { toast } from 'react-toastify';

export const apiErrorToast = (translationKey = '', error) => {
  if (error?.response?.data?.['hydra:description']) {
    return toast.error(
      `${translationKey} ${error.response.data['hydra:description']}`
    );
  }
  return toast.error(translationKey);
};
