import { useEffect, useState } from 'react';
import { put } from 'helpers/apiHelpers';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import withStyles from '@material-ui/core/styles/withStyles';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import { fetchBrandConfigModulesPack } from 'actions/Brands';
import { combineStyles } from 'helpers/helpers';

import ShopMeals from './ShopMeals';
import DefaultPriceMeals from './DefaultPriceMeals';
import DishDetails from './DishDetails';
import ShareRecipesSettings from './ShareRecipesSettings';
import { get } from 'helpers/apiHelpers';
import OtherConfiguration from './OtherConfiguration';
import ClientPanel from './ClientPanel';
import FormControlStickyButton from 'components/FormControlStickyButton/FormControlStickyButton';
import { toast } from 'react-toastify';

const Shop = ({ classes }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedBrand = useSelector(state => state.Auth.selectedBrand);
  const [shopModule, setShopModule] = useState({});
  const [configClientPanelModule, setConfigClientPanelModule] = useState({});
  const [mealTypeSizes, setMealTypeSizes] = useState([]);

  useEffect(() => {
    dispatch(
      fetchBrandConfigModulesPack(selectedBrand, ['Shop', 'ConfigClientPanel'])
    ).then(response => {
      const {
        '@id': idIri,
        '@type': typeIri,
        ...restShopVariables
      } = response.configuration.Shop;
      const {
        '@id': idCcpIri,
        '@type': typeCcpIri,
        ...restConfigClientPanelVariables
      } = response.configuration.ConfigClientPanel;

      setShopModule(restShopVariables);
      setConfigClientPanelModule(restConfigClientPanelVariables);
    });

    get('/meal-type-sizes', { pagination: false }).then(response => {
      setMealTypeSizes(response?.['hydra:member'] ?? []);
    });
  }, []);

  const handleSubmit = () => {
    put(`/brands/${selectedBrand}/modules`, {
      configuration: {
        ConfigClientPanel: configClientPanelModule,
        Shop: shopModule,
      },
    }).then(() => {
      toast.success(t('success.dataUpdated'));
    });
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <ShopMeals
        classes={classes}
        shopModule={shopModule}
        setShopModule={setShopModule}
      />
      <DefaultPriceMeals classes={classes} mealTypeSizes={mealTypeSizes} />
      <DishDetails
        classes={classes}
        shopModule={shopModule}
        setShopModule={setShopModule}
      />
      <ShareRecipesSettings
        classes={classes}
        shopModule={shopModule}
        setShopModule={setShopModule}
        configClientPanelModule={configClientPanelModule}
        setConfigClientPanelModule={setConfigClientPanelModule}
      />
      <OtherConfiguration
        shopModule={shopModule}
        setShopModule={setShopModule}
      />
      <ClientPanel shopModule={shopModule} setShopModule={setShopModule} />
      <FormControlStickyButton
        classes={classes}
        secondarySubmitText={t('form.save')}
        handleSecondarySubmit={e => handleSubmit(e, false)}
      />
    </div>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(withStyles(combinedStyles));

export default enhance(Shop);
