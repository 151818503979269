import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import RegularButton from 'components/CustomButtons/Button.jsx';

import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import { combineStyles, isGranted } from 'helpers/helpers';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import roles from 'helpers/roles';
import GridItem from 'components/Grid/GridItem';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { compose } from 'redux';

const SmsNotificationForClientPanel = ({
  classes,
  state,
  setState,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const translationKey = {
    bag_delivered: t('notifications.sms.bag_delivered'),
  };
  return (
    <>
      <Card>
        <CardBody>
          <GridItem md={12}>
            <h1>{t('smsNotifications.cardTitle.clientAllowToToggle')}</h1>
          </GridItem>
          <div style={{ overflow: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('notify.whatSms')}</TableCell>
                  <TableCell>{t('notify.allowClientToToggleSms')}</TableCell>
                  {isGranted(roles.ROLE_EDIT_SMS_CONFIGURATION) && (
                    <TableCell>{t('notify.actions')}</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {state.map(sms => {
                  return (
                    <TableRow key={sms.id}>
                      <TableCell>{translationKey[sms.smsKey]}</TableCell>
                      <TableCell>
                        <Checkbox
                          style={{ marginLeft: '-15px' }}
                          checked={sms.allowClientToToggle}
                          onChange={e => {
                            setState(prev => {
                              return {
                                ...prev,
                                clientToggleableSms:
                                  prev.clientToggleableSms.map(item => {
                                    if (item.id === sms.id) {
                                      return {
                                        ...item,
                                        hasChanged: true,
                                        allowClientToToggle: e.target.checked,
                                      };
                                    }
                                    return item;
                                  }),
                              };
                            });
                          }}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <RegularButton
                          disabled={!sms.hasChanged}
                          color={'success'}
                          onClick={() => {
                            handleSubmit(state, sms);
                          }}
                        >
                          {t('common.shared.save', 'Zapisz')}
                        </RegularButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);
const enhance = compose(withStyles(combinedStyles));

export default enhance(SmsNotificationForClientPanel);
