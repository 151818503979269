import { useEffect, useState } from 'react';
import Datetime from 'react-datetime';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import { combineStyles } from 'helpers/helpers';

import { useDispatch, useSelector } from 'react-redux';
import produce from 'immer';
import { useHistory, useParams } from 'react-router-dom';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import FormLabel from '@material-ui/core/FormLabel';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import GridContainer from 'components/Grid/GridContainer';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import { Info } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { fetchDiets } from '../../actions/Diets';
import SelectInput from '../../components/FormSelect/SelectInput';
import { get, put } from 'helpers/apiHelpers';
import { currencyToSymbol } from 'utils/currencies';
import Button from 'components/CustomButtons/Button.jsx';
import makeStyles from '@material-ui/styles/makeStyles';
import { toast } from 'react-toastify';

const INITITAL_FORM_STATE = {
  description: '',
  code: '',
  startDate: '',
  endDate: '',
  value: '',
  amount: '',
  limit: '',
  limitPerUser: '',
  limitPerSubscription: 0,
  onlyAdminCanUseCheck: false,
  disableDietDiscountCheck: false,
  applyToAddonsCheck: false,
  startDateCheck: false,
  endDateCheck: false,
  limitCheck: false,
  limitPerUserCheck: false,
  limitPerSubscriptionCheck: true,
  isPercent: true,
  diets: [],
  selectedDietsCheck: false,
  active: false,
  archived: false,
  minimalDietLength: null,
  minimalOrderValue: null,
};
const useStyles = makeStyles(() => ({
  disabled: {
    background: 'rgba(171,171,171, 0.3)',
  },
}));
const EditForm = ({ classes }) => {
  const [formData, setFormData] = useState(INITITAL_FORM_STATE);
  const history = useHistory();
  const params = useParams();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const diets = useSelector(state => state.Diets.diets);
  const multinational = useSelector(state => state.Brands.brand.multinational);
  const customStyles = useStyles();

  useEffect(() => {
    dispatch(fetchDiets());
    get(`discount-codes/${params.id}`).then(response => {
      setFormData(
        produce(draftState => {
          draftState.code = response.code;
          draftState.description = response.description;
          draftState.diets = response.diets;
          draftState.selectedDietsCheck = response.diets.length > 0;

          const hasValidFrom = response.validFrom !== null;
          draftState.startDate = hasValidFrom
            ? Datetime.moment(response.validFrom)
            : '';
          draftState.startDateCheck = hasValidFrom;

          const hasValidTo = response.validTo !== null;
          draftState.endDate = hasValidTo
            ? Datetime.moment(response.validTo)
            : '';
          draftState.endDateCheck = hasValidTo;

          draftState.isPercent = response.isPercentage;
          draftState.value = response.discount;
          draftState.amount = 1;
          draftState.limit = response.limit;
          draftState.limitCheck = response.limit > 0;
          draftState.limitPerUser = response.limitPerUser;
          draftState.limitPerUserCheck = response.limitPerUser > 0;
          draftState.limitPerSubscription = response.limitPerSubscription;
          draftState.limitPerSubscriptionCheck =
            response.limitPerSubscription > 0;
          draftState.onlyAdminCanUseCheck = response.onlyAdminCanUse;
          draftState.disableDietDiscountCheck = response.disableDietDiscount;
          draftState.applyToAddonsCheck = response.applyToAddons;
          draftState.active = response.active;
          draftState.archived = response.archived;
          draftState.minimalDietLength = response.minimalDietLength;
          draftState.minimalOrderValue = response.minimalOrderValue;
        })
      );
    });
  }, []);

  const handleChange = ({ target: { name, value } }) => {
    setFormData(
      produce(draftState => {
        draftState[name] = value;
      })
    );
  };

  const handleToggle = stateName => {
    setFormData(
      produce(draftState => {
        draftState[stateName] = '';
        draftState[`${stateName}Check`] = !draftState[`${stateName}Check`];
      })
    );
  };

  const handleRadio = stateName => {
    setFormData(
      produce(draftState => {
        draftState[stateName] = !draftState[stateName];
      })
    );
  };

  const yesterday = Datetime.moment().subtract(1, 'day');

  const startDateValidation = current => {
    return current.isAfter(yesterday) && formData.endDate
      ? current.isSameOrBefore(formData.endDate)
      : current.isAfter(yesterday);
  };

  const endDateValidation = picked => {
    if (formData.startDate !== '') {
      const dayBeforeStartDate = Datetime.moment(formData.startDate).subtract(
        1,
        'day'
      );
      return picked.isSameOrAfter(dayBeforeStartDate);
    }
    return picked.isAfter(yesterday);
  };

  const validateDates = () =>
    formData.startDate && formData.endDate
      ? formData.endDate.isAfter(formData.startDate)
      : true;

  const validateCheckedStartDate = () =>
    formData.startDateCheck && !formData.startDate;

  const validateCheckedEndDate = () =>
    formData.endDateCheck && !formData.endDate;

  const validateForm = () => formData.code && formData.value && formData.amount;

  const handleArchive = () => {
    put(`discount-codes/${params.id}`, { archived: !formData.archived }).then(
      data => {
        if (data.archived) {
          history.push('/admin/discount-codes');
        } else {
          setFormData(
            produce(draftState => {
              draftState.archived = data.archived;
            })
          );
        }

        toast.info(
          data?.archived ? t('discountCodes.info9') : t('discountCodes.info10')
        );
      }
    );
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return toast.error(t('form.fillAllRequiredFields'));
    }

    if (!validateDates()) {
      return toast.error(
        `${t('discountCodes.incorrectDatesValid')} ${t('discountCodes.info1')}`
      );
    }

    if (validateCheckedStartDate()) {
      return toast.error(t('discountCodes.info2'));
    }

    if (validateCheckedEndDate()) {
      return toast.error(t('discountCodes.info3'));
    }

    if (formData.amount > 1 && !formData.code.includes('*')) {
      return toast.info(
        `${t('discountCodes.info4')} ${t('discountCodes.info5')} np. ${
          formData.code
        }-*`
      );
    }

    const data = {
      description: formData.description,
      code: formData.code.replace(/  +/g, ' ').trim(),
      validFrom: formData.startDate || null,
      validTo: formData.endDate || null,
      isPercentage: formData.isPercent,
      discount: parseFloat(formData.value),
      quantity: parseInt(formData.amount),
      limit: parseInt(formData.limit),
      limitPerUser: parseInt(formData.limitPerUser),
      limitPerSubscription: parseInt(formData.limitPerSubscription),
      onlyAdminCanUse: formData.onlyAdminCanUseCheck,
      disableDietDiscount: formData.disableDietDiscountCheck,
      applyToAddons: formData.applyToAddonsCheck,
      diets:
        formData.selectedDietsCheck && formData.diets ? formData.diets : [],
      active: formData.active,
    };

    put(`discount-codes/${params.id}`, data).then(
      () => history.push('/admin/discount-codes'),
      error => {
        if (
          error.response.data.violations &&
          error.response.data.violations[0].propertyPath === '[0].code'
        ) {
          toast.error(t('discountCodes.info6'));
        } else {
          toast.error(
            `${t('discountCodes.info7')} ${t('discountCodes.info8')}`
          );
        }
      }
    );
  };

  return (
    <form>
      <h2>{t('discountCodes.discountCodeEdit', 'Edycja kodu rabatowego')}</h2>
      <AdminTable>
        <GridContainer>
          <GridItem sm={6}>
            <GridItem sm={12}>
              <FormLabel className={classes.labelHorizontal}>
                {t('discountCodes.codeContent')} *
                {
                  <div style={{ marginLeft: '10px' }}>
                    <Tooltip
                      title={
                        <div>
                          <h4>{t('discountCodes.tooltip1')}</h4>
                          <h4>{t('discountCodes.tooltip2')}</h4>
                          <h4>{t('discountCodes.tooltip3')}</h4>
                        </div>
                      }
                      placement="right"
                    >
                      <Info fontSize={'small'} />
                    </Tooltip>
                  </div>
                }
              </FormLabel>
              <CustomInput
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  name: 'code',
                  value: formData.code,
                  onChange: ev => handleChange(ev),
                  disabled: true,
                }}
              />
            </GridItem>
            <GridItem sm={12}>
              <FormLabel
                style={{ marginTop: '15px' }}
                className={classes.labelHorizontal}
              >
                {t('discountCodes.desc')}
              </FormLabel>
              <CustomInput
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  name: 'description',
                  value: formData.description,
                  onChange: ev => handleChange(ev),
                }}
              />
            </GridItem>
            <GridItem sm={12}>
              <FormLabel
                style={{ marginTop: '15px', marginRight: '20px' }}
                className={classes.labelHorizontal}
              >
                {t('discountCodes.discountCodeValue')} *
              </FormLabel>
              <div>
                <CustomInput
                  inputProps={{
                    name: 'value',
                    value: formData.value,
                    onChange: ev =>
                      !isNaN(ev.target.value) ? handleChange(ev) : false,
                    disabled: true,
                  }}
                />
                <FormControlLabel
                  style={{ marginLeft: '10px' }}
                  control={
                    <Radio
                      checked={formData.isPercent}
                      onChange={() => handleRadio('isPercent')}
                      icon={
                        <FiberManualRecord className={classes.radioUnchecked} />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                  }}
                  label={t('discountCodes.percentage')}
                  disabled={true}
                />
                <FormControlLabel
                  control={
                    <Radio
                      checked={!formData.isPercent}
                      onChange={() => handleRadio('isPercent')}
                      icon={
                        <FiberManualRecord className={classes.radioUnchecked} />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                  }}
                  label={t('discountCodes.amount')}
                  disabled={true}
                />
              </div>
            </GridItem>
            <GridItem sm={12}>
              <FormLabel
                style={{ marginTop: '15px' }}
                className={classes.labelHorizontal}
              >
                {t('discountCodes.discountCodesQty')} *
              </FormLabel>
              <div>
                <CustomInput
                  inputProps={{
                    name: 'amount',
                    value: formData.amount,
                    onChange: ev =>
                      !isNaN(ev.target.value) ? handleChange(ev) : false,
                    disabled: true,
                  }}
                />
              </div>
            </GridItem>

            <GridItem sm={12}>
              <Checkbox
                style={{ marginLeft: '-14px' }}
                onClick={() =>
                  setFormData(prevState => ({
                    ...prevState,
                    active: !prevState.active,
                  }))
                }
                checked={formData.active}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
              <FormLabel
                style={{ marginBottom: '23px' }}
                className={classes.labelHorizontal}
                onClick={() =>
                  setFormData(prevState => ({
                    ...prevState,
                    active: !prevState.active,
                  }))
                }
              >
                {t('discountCodes.codeIsActive', 'Kod jest aktywny')}
              </FormLabel>
            </GridItem>

            <GridItem sm={12}>
              <Checkbox
                style={{ marginLeft: '-14px' }}
                onClick={() => handleToggle('onlyAdminCanUse')}
                checked={formData.onlyAdminCanUseCheck}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
              <FormLabel
                style={{ marginBottom: '23px' }}
                className={classes.labelHorizontal}
                onClick={() => handleToggle('onlyAdminCanUse')}
              >
                {t('discountCodes.onlyAdminCanUseCode')}
              </FormLabel>
            </GridItem>
            <GridItem sm={12}>
              <Checkbox
                style={{ marginLeft: '-14px' }}
                onClick={() => handleToggle('applyToAddons')}
                checked={formData.applyToAddonsCheck}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
                disabled={true}
              />
              <FormLabel
                style={{ marginBottom: '23px' }}
                className={classes.labelHorizontal}
              >
                {t('discountCodes.applyToAddons')}
              </FormLabel>
            </GridItem>
            <GridItem sm={12}>
              <Checkbox
                style={{ marginLeft: '-14px' }}
                onClick={() => handleToggle('disableDietDiscount')}
                checked={formData.disableDietDiscountCheck}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
              <FormLabel
                style={{ marginBottom: '23px' }}
                className={classes.labelHorizontal}
                onClick={() => handleToggle('disableDietDiscount')}
              >
                {t('discountCodes.disableDietDiscount')}
              </FormLabel>
            </GridItem>
          </GridItem>
          <GridItem sm={6}>
            <GridContainer>
              <GridItem sm={12}>
                <Checkbox
                  style={{ marginLeft: '-14px' }}
                  id={'selectedDietsCheck'}
                  onClick={() => {
                    handleToggle('selectedDiets');
                    setFormData(
                      produce(draftState => {
                        draftState.diets = [];
                      })
                    );
                  }}
                  checked={formData.selectedDietsCheck}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
                <FormLabel
                  htmlFor={'selectedDietsCheck'}
                  className={classes.labelHorizontal}
                >
                  {t('discountCodes.diets2')}:
                  {
                    <div style={{ marginLeft: '10px' }}>
                      <Tooltip
                        title={
                          <div>
                            <h4>{t('discountCodes.tooltip4')}</h4>
                            <h4>{t('discountCodes.tooltip5')}</h4>
                            <h4>
                              {t('discountCodes.tooltip6', {
                                defaultValue: '',
                                replace: {
                                  currencySymbol: currencyToSymbol(
                                    multinational.currency
                                  ),
                                },
                              })}
                            </h4>
                            <h4>
                              {t('discountCodes.tooltip7', {
                                defaultValue: '',
                                replace: {
                                  currencySymbol: currencyToSymbol(
                                    multinational.currency
                                  ),
                                },
                              })}
                            </h4>
                          </div>
                        }
                        placement="right"
                      >
                        <Info fontSize={'small'} />
                      </Tooltip>
                    </div>
                  }
                </FormLabel>
                <SelectInput
                  classes={classes}
                  multiple={true}
                  mapBy="name"
                  trackBy="@id"
                  name="diets"
                  value={formData.diets}
                  options={diets}
                  handleChange={e => {
                    handleChange(e);
                    setFormData(
                      produce(draftState => {
                        draftState.selectedDietsCheck = true;
                      })
                    );
                  }}
                  id="diets"
                  size={12}
                />
              </GridItem>
              <GridItem sm={12} style={{ marginTop: '-5px' }}>
                <Checkbox
                  style={{ marginLeft: '-14px' }}
                  id={'startDateCheck'}
                  onClick={() => handleToggle('startDate')}
                  checked={formData.startDateCheck}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
                <FormLabel
                  htmlFor={'startDateCheck'}
                  className={classes.labelHorizontal}
                >
                  {t('discountCodes.activeFrom')}:
                </FormLabel>
                <Datetime
                  timeFormat={true}
                  isValidDate={startDateValidation}
                  value={
                    formData.startDateCheck === false ? '' : formData.startDate
                  }
                  onFocus={() =>
                    setFormData(
                      produce(draftState => {
                        draftState.startDateCheck = true;
                      })
                    )
                  }
                  onChange={e => {
                    setFormData(
                      produce(draftState => {
                        draftState.startDate = e;
                      })
                    );
                  }}
                  inputProps={{
                    readOnly: true,
                    // disabled: !formData.startDateCheck
                  }}
                />
              </GridItem>
              <GridItem sm={12}>
                <Checkbox
                  style={{ marginLeft: '-14px' }}
                  id={'endDateCheck'}
                  onClick={() => {
                    handleToggle('endDate');
                  }}
                  checked={formData.endDateCheck}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
                <FormLabel
                  htmlFor={'endDateCheck'}
                  className={classes.labelHorizontal}
                >
                  {t('discountCodes.activeTo')}:
                </FormLabel>
                <Datetime
                  timeFormat={true}
                  value={
                    formData.endDateCheck === false ? '' : formData.endDate
                  }
                  isValidDate={endDateValidation}
                  onFocus={() =>
                    setFormData(
                      produce(draftState => {
                        draftState.endDateCheck = true;
                      })
                    )
                  }
                  onChange={e =>
                    setFormData(
                      produce(draftState => {
                        draftState.endDate = e;
                      })
                    )
                  }
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </GridItem>
              <GridItem sm={12}>
                <Checkbox
                  style={{ marginLeft: '-14px' }}
                  id="limit"
                  onClick={() => handleToggle('limit')}
                  checked={formData.limitCheck}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
                <FormLabel
                  style={{ marginBottom: '23px' }}
                  className={classes.labelHorizontal}
                  htmlFor="limit"
                >
                  {t('discountCodes.usageLimit')}
                </FormLabel>
                <div>
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      name: 'limit',
                      value:
                        formData.limitCheck === false ? '' : formData.limit,
                      onFocus: () =>
                        setFormData(
                          produce(draftState => {
                            draftState.limitCheck = true;
                          })
                        ),
                      onChange: ev =>
                        !isNaN(ev.target.value) ? handleChange(ev) : false,
                    }}
                  />
                </div>
              </GridItem>
              <GridItem sm={12}>
                <Checkbox
                  style={{ marginLeft: '-14px' }}
                  id="limitPerUser"
                  disabled={true}
                  checked={formData.limitPerUserCheck}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
                <FormLabel
                  style={{ marginBottom: '23px' }}
                  className={classes.labelHorizontal}
                  htmlFor="limitPerUser"
                >
                  {t('discountCodes.usageLimitPerUser')}
                </FormLabel>
                <div className={customStyles.disabled}>
                  <CustomInput
                    disabled={true}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      name: 'limitPerUser',
                      value: formData.limitPerUser,
                    }}
                  />
                </div>
              </GridItem>
              <GridItem sm={12}>
                <Checkbox
                  style={{ marginLeft: '-14px' }}
                  id="limitPerSubscription"
                  disabled={true}
                  checked={formData.limitPerSubscriptionCheck}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
                <FormLabel
                  style={{ marginBottom: '23px' }}
                  className={classes.labelHorizontal}
                  htmlFor="limitPerSubscription"
                >
                  {t('discountCodes.usageLimitPerSubscription')}
                </FormLabel>
                <div className={customStyles.disabled}>
                  <CustomInput
                    disabled={true}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      name: 'limitPerSubscription',
                      value: formData.limitPerSubscription,
                    }}
                  />
                </div>
              </GridItem>

              <GridItem sm={12}>
                <Checkbox
                  disabled={true}
                  style={{ marginLeft: '-14px' }}
                  checked={formData.minimalOrderValue ? true : false}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
                <FormLabel
                  style={{ marginBottom: '23px' }}
                  className={classes.labelHorizontal}
                >
                  {t('discountCodes.minimalOrderValue')}
                </FormLabel>
                <div className={customStyles.disabled}>
                  <CustomInput
                    disabled={true}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      name: 'minimalOrderValue',
                      value: formData.minimalOrderValue,
                    }}
                  />
                </div>
              </GridItem>
              <GridItem sm={12}>
                <Checkbox
                  disabled={true}
                  style={{ marginLeft: '-14px' }}
                  checked={formData.minimalDietLength ? true : false}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
                <FormLabel
                  style={{ marginBottom: '23px' }}
                  className={classes.labelHorizontal}
                >
                  {t('discountCodes.minimalDietLength')}
                </FormLabel>
                <div className={customStyles.disabled}>
                  <CustomInput
                    disabled={true}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      name: 'minimalDietLength',
                      value: formData.minimalDietLength,
                    }}
                  />
                </div>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem sm={6}></GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem sm={6}>
            <Button
              color={formData.archived ? 'primary' : 'warning'}
              onClick={handleArchive}
            >
              {t(
                formData.archived
                  ? 'discountCodes.archive.restore'
                  : 'discountCodes.archive'
              )}
            </Button>
          </GridItem>

          <GridItem sm={6}>
            <FormControlButtons
              classes={classes}
              discardText={t('discountCodes.cancel')}
              submitText={t('form.save')}
              cancelPath="/admin/discount-codes"
              handleSubmit={handleSubmit}
            />
          </GridItem>
        </GridContainer>
      </AdminTable>
    </form>
  );
};

const combinedStyles = combineStyles(buttonsStyle, extendedFormsStyle);
const enhance = compose(withStyles(combinedStyles));

export default enhance(EditForm);
