import Moment from 'moment';
import Datetime from 'react-datetime';
import Fab from '@material-ui/core/Fab';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import Button from 'components/CustomButtons/Button';
import GridItem from 'components/Grid/GridItem';
import TreeView from './components/TreeView';
import CardBody from 'components/Card/CardBody';
import CustomInput from 'components/CustomInput/CustomInput';
import GridContainer from 'components/Grid/GridContainer';
import FormSelectSingle from 'components/FormSelect/FormSelectSingle';
import FormTextInputNoGrid from 'components/FormTextInput/FormTextInputNoGrid';
import Close from '@material-ui/icons/Close';
import Check from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add';
import SelectInput from 'components/FormSelect/SelectInput';
import { Tooltip, withStyles } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';
import produce from 'immer';
import { combineStyles } from 'helpers/helpers';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import { compose } from 'redux';
import CardWrapper from 'components/Card/CardWrapper';

const Settings = ({
  state,
  setState,
  isEdit,
  handleChange,
  drivers,
  zoneCategories,
  deliveryTypes,
  togglePostCodeModal,
  classes,
}) => {
  const { t } = useTranslation();

  const handleDefaultDeliveryHourChange = newDefaultIndex => {
    let newDeliveryHours = state.deliveryHours.map((deliveryHour, index) => {
      if (index === newDefaultIndex) {
        return { ...deliveryHour, default: true };
      } else if (deliveryHour?.default === true) {
        return { ...deliveryHour, default: false };
      } else {
        return deliveryHour;
      }
    });

    setState({ deliveryHours: newDeliveryHours });
  };

  const handleDeliveryHourToRemove = index => {
    let { deliveryHours } = state;

    deliveryHours.splice(index, 1);

    setState({ deliveryHours });
  };

  const handleDeliveryHourClear = (key, index) => {
    let { deliveryHours } = state;

    deliveryHours[index][key] = null;

    setState({ deliveryHours });
  };

  const handleDeliveryHourChange = (key, newHour, index) => {
    let { deliveryHours } = state;

    deliveryHours[index][key] = newHour ? new Date(newHour) : null;

    setState({ deliveryHours });
  };

  const handleChangeLocation = produceCallback => {
    const newState = produce(state.postCodes, produceCallback);

    setState(prevState => ({ ...prevState, postCodes: newState }));
  };

  const handleZoneCostChange = (key, value, index) => {
    let { zoneCosts } = state;

    zoneCosts[index][key] = value;

    setState({ zoneCosts });
  };

  return (
    <>
      <CardWrapper title={isEdit ? t('zones.editZone') : t('zones.addNewZone')}>
        <CardBody>
          <GridContainer>
            <GridItem sm={6}>
              <h4>{t('zones.basicInfo')}</h4>
              <GridContainer>
                <GridItem sm={6} data-cy="__zone_name">
                  <FormLabel className={classes.labelHorizontal}>
                    {t('zones.zoneName')}*
                  </FormLabel>
                  <CustomInput
                    dataCy="new-zone_name"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      name: 'name',
                      value: state.name,
                      onChange: ev => handleChange(ev),
                    }}
                  />
                </GridItem>
                <GridItem sm={6} data-cy="__short_name">
                  <FormLabel className={classes.labelHorizontal}>
                    {t('zones.shortName')}*
                  </FormLabel>
                  <CustomInput
                    dataCy="new-zone_short-name"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      name: 'shortName',
                      value: state.shortName,
                      onChange: ev => handleChange(ev),
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem sm={6} data-cy="__priority">
                  <FormLabel
                    style={{ marginTop: '4px', marginBottom: '10px' }}
                    className={classes.labelHorizontal}
                  >
                    {t('zones.priority')}*
                  </FormLabel>
                  <CustomInput
                    dataCy="new-zone_priority"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      name: 'priority',
                      type: 'number',
                      value: state.priority,
                      onChange: ev => handleChange(ev),
                    }}
                  />
                </GridItem>
                <GridItem sm={6} data-cy="__assigned_drivers">
                  <SelectInput
                    dataCy="new-zone_assigned_drivers"
                    classes={classes}
                    multiple={true}
                    label={t('zones.assignedDrivers') + '*'}
                    mapBy="name"
                    trackBy="@id"
                    name="drivers"
                    value={state.drivers}
                    options={drivers || []}
                    handleChange={handleChange}
                    size={12}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem sm={6} data-cy="__zone_category">
                  <SelectInput
                    dataCy="new-zone_category"
                    classes={classes}
                    multiple={false}
                    label={t('zones.category')}
                    mapBy="name"
                    trackBy="@id"
                    name="category"
                    value={state.category}
                    options={zoneCategories || []}
                    handleChange={handleChange}
                    size={12}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem sm={6} data-cy="__post-code">
              <h4>{t('zones.postcodesShow')}</h4>
              <GridContainer>
                <GridItem sm={6}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('zones.selectPostcode')}
                  </FormLabel>
                  <span>{'\u2004'}</span>
                  <CustomInput
                    dataCy="new-zone_post-code"
                    classes={classes}
                    customInput={FormTextInputNoGrid}
                    inputProps={{
                      value: state.postCode,
                      onChange: ev => setState({ postCode: ev.target.value }),
                    }}
                    name="postCode"
                  />
                </GridItem>
                <GridItem sm={6}>
                  <Button
                    data-cy="new-zone_show-on-map"
                    href={`https://mapa.targeo.pl/${state.postCode}/kod-pocztowy`}
                    color="info"
                  >
                    {t('zones.showOnMap')}
                  </Button>
                </GridItem>
                {state.zoneCosts.map((zoneCost, index) => {
                  return (
                    <Fragment key={index}>
                      <GridItem sm={4}>
                        <FormLabel className={classes.labelHorizontal}>
                          {t('zoneCosts.price')}
                        </FormLabel>
                        <CustomInput
                          dataCy="new-zone_price"
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            name: 'price',
                            value: state.zoneCosts[index].price,
                            onChange: e =>
                              handleZoneCostChange(
                                'price',
                                e.target.value,
                                index
                              ),
                            type: 'number',
                            style: { marginTop: '9px' },
                          }}
                        />
                      </GridItem>
                      <GridItem sm={4}>
                        <FormLabel className={classes.labelHorizontal}>
                          {t('zoneCosts.vat')}
                        </FormLabel>
                        <CustomInput
                          dataCy="new-zone_vat"
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            name: 'vat',
                            value: state.zoneCosts[index].vat,
                            onChange: e =>
                              handleZoneCostChange(
                                'vat',
                                e.target.value,
                                index
                              ),
                            type: 'number',
                            style: { marginTop: '9px' },
                          }}
                        />
                      </GridItem>
                      <GridItem sm={4}>
                        <FormLabel className={classes.labelHorizontal}>
                          {t('zoneCosts.cost')}
                        </FormLabel>
                        <CustomInput
                          dataCy="new-zone_cost"
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            name: 'cost',
                            value: state.zoneCosts[index].cost,
                            onChange: e =>
                              handleZoneCostChange(
                                'cost',
                                e.target.value,
                                index
                              ),
                            type: 'number',
                            style: { marginTop: '9px' },
                          }}
                        />
                      </GridItem>
                    </Fragment>
                  );
                })}
              </GridContainer>
            </GridItem>
            <GridItem sm={6}>
              <GridContainer>
                <GridItem sm={12}>
                  <h4>{t('zones.additionalInfo')}</h4>
                </GridItem>
                <GridItem sm={12} style={{ marginTop: '2px' }}>
                  <FormSelectSingle
                    dataCy="new-zone_type"
                    classes={classes}
                    label={t('zones.type') + '*'}
                    options={deliveryTypes}
                    value={state.selectedDeliveryType}
                    mapBy="value"
                    trackBy="@id"
                    name="selectedDeliveryType"
                    handleChange={ev => handleChange(ev)}
                    id="selectedDeliveryType"
                  />
                </GridItem>
                <GridItem sm={12}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <FormLabel
                      style={{ marginTop: '20px' }}
                      className={classes.labelHorizontal}
                    >
                      {t('zones.deliveryHours')}
                      <div style={{ marginLeft: '10px' }}>
                        <Tooltip
                          title={
                            <div>
                              <h5
                                dangerouslySetInnerHTML={{
                                  __html: t('zones.deliveryHoursTooltip', {
                                    interpolation: { escapeValue: false },
                                  }),
                                }}
                              />
                            </div>
                          }
                          placement="right"
                        >
                          <Info fontSize={'small'} />
                        </Tooltip>
                      </div>
                    </FormLabel>
                    <Fab
                      data-cy="new-zone_add_zone_hours"
                      color="secondary"
                      variant="extended"
                      className={classes.fab}
                      onClick={() =>
                        setState({
                          deliveryHours: [
                            ...state.deliveryHours,
                            {
                              hourFrom: null,
                              hourTo: null,
                              default:
                                !state.deliveryHours ||
                                state.deliveryHours?.length === 0,
                            },
                          ],
                        })
                      }
                    >
                      <AddIcon />
                      {t('zones.addDeliveryHour')}
                    </Fab>
                  </div>
                </GridItem>
                {state.deliveryHours.map((hour, index) => {
                  return (
                    <Fragment key={index}>
                      <GridItem sm={12}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            margin: '0 -4px',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'baseline',
                              padding: '0 4px',
                            }}
                          >
                            <span style={{ marginRight: '10px' }}>
                              {t('comments.from', 'Od')}
                            </span>
                            <div>
                              <Datetime
                                dateFormat={false}
                                value={
                                  state.deliveryHours[index].hourFrom
                                    ? Moment(
                                        state.deliveryHours[index].hourFrom
                                      ).format('HH:mm')
                                    : ''
                                }
                                onChange={ev =>
                                  handleDeliveryHourChange(
                                    'hourFrom',
                                    ev,
                                    index
                                  )
                                }
                                inputProps={{
                                  placeholder: t('zones.select'),
                                  readOnly: true,
                                  dataCy: 'new-zone_date-from',
                                }}
                                locale="pl"
                              />

                              <Button
                                data-cy="new-zone_clear_date-from"
                                round
                                onClick={() =>
                                  handleDeliveryHourClear('hourFrom', index)
                                }
                                color="danger"
                                className="remove"
                                disabled={
                                  !state.deliveryHours[index].hourTo ||
                                  !state.deliveryHours[index].hourFrom
                                }
                              >
                                {t('zones.clear', '$$Wyczyść')}
                              </Button>
                            </div>
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'baseline',
                              padding: '0 4px',
                            }}
                          >
                            <span style={{ marginRight: '10px' }}>
                              {t('comments.to', 'do')}
                            </span>

                            <div>
                              <Datetime
                                dateFormat={false}
                                value={
                                  state.deliveryHours[index].hourTo
                                    ? Moment(
                                        state.deliveryHours[index].hourTo
                                      ).format('HH:mm')
                                    : ''
                                }
                                onChange={ev =>
                                  handleDeliveryHourChange('hourTo', ev, index)
                                }
                                inputProps={{
                                  placeholder: t('zones.select'),
                                  readOnly: true,
                                  dataCy: 'new-zone_date-to',
                                }}
                                locale="pl"
                              />
                              <Button
                                data-cy="new-zone_clear_date-to"
                                round
                                onClick={() =>
                                  handleDeliveryHourClear('hourTo', index)
                                }
                                color="danger"
                                className="remove"
                                disabled={
                                  !state.deliveryHours[index].hourFrom ||
                                  !state.deliveryHours[index].hourTo
                                }
                              >
                                {t('zones.clear', '$$Wyczyść')}
                              </Button>
                            </div>
                          </div>

                          <Button
                            data-cy="new-zone_date-remove"
                            justIcon
                            round
                            simple
                            onClick={() => handleDeliveryHourToRemove(index)}
                            color="danger"
                            className="remove"
                          >
                            <Close />
                          </Button>
                          <span>{t('common.isDefault', 'Is default: ')}</span>
                          <Checkbox
                            data-cy="new-zone_default-time_checkbox"
                            checked={hour?.default}
                            onClick={() =>
                              handleDefaultDeliveryHourChange(index)
                            }
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        </div>
                      </GridItem>
                    </Fragment>
                  );
                })}
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </CardWrapper>
      <CardWrapper>
        <CardBody>
          <Button
            data-cy="new-zone_add-address"
            color="success"
            onClick={togglePostCodeModal}
          >
            {t('zones.button.addAddress', '+ Dodaj adres')}
          </Button>

          <TreeView
            t={t}
            postCodes={state.postCodes}
            handleChangeLocation={handleChangeLocation}
          />
        </CardBody>
      </CardWrapper>
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);
const enhance = compose(withStyles(combinedStyles));

export default enhance(Settings);
