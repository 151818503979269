import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import withStyles from '@material-ui/core/styles/withStyles';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import AdminTable from 'layouts/AdminTable';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';

import gastro from 'helpers/gastro';
import { post, put } from 'helpers/apiHelpers';
import { combineStyles } from 'helpers/helpers';

import defaultState from './defaultState';
import FormTextInputTranslatable from 'components/FormTextInput/FormTextInputTranslatable';

import { toast } from 'react-toastify';

const Form = ({
  match,
  classes,
  history,
  valueUrl,
  valueKey,
  location,
  translatable = false,
}) => {
  const { t } = useTranslation();
  const [state, setState] = useState({ ...defaultState });

  const isEdit = location.pathname.includes('edit');
  const elementId = match.params.id;

  useEffect(() => {
    if (isEdit) {
      fetchValue();
    }
  }, []);

  const fetchValue = () => {
    gastro
      .get(`${valueUrl}/${elementId}?translations=${translatable}`)
      .then(async response => {
        let value = valueKey ? response.data[valueKey] : response.data.value;
        setState({
          ...state,
          name: value,
        });
      });
  };

  const handleInputChange = event => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const validateForm = () => {
    return state.name;
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return toast.error(t('form.nameFieldCannotBeEmpty'));
    }

    const data = {
      [valueKey ? valueKey : 'value']: state.name,
    };

    const action = isEdit
      ? put(`${valueUrl}/${elementId}`, data)
      : post(valueUrl, data);

    action.then(() => history.push(`/admin/${valueUrl}`));
  };

  const NameComponent =
    true !== translatable ? FormTextInput : FormTextInputTranslatable;

  return (
    <AdminTable title={isEdit ? t('form.editValue') : t('form.addNewValue')}>
      <NameComponent
        label={t('form.field.name') + '*'}
        classes={classes}
        name="name"
        value={state.name}
        handleChange={handleInputChange}
        inputSize={4}
      />
      <FormControlButtons
        classes={classes}
        discardText={t('form.cancel')}
        submitText={t('form.save')}
        cancelPath={`/admin/${valueUrl}`}
        handleSubmit={handleSubmit}
      />
    </AdminTable>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

export default withStyles(combinedStyles)(Form);
