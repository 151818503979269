import { Component } from 'react';
import { connect } from 'react-redux';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { isGranted } from 'helpers/helpers';
import { ROLE_CREATE_MASS_SMS } from 'helpers/roles';
import Moment from 'moment';

import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

class List extends Component {
  canCreateNewSms =
    this.props.brand.smsApiEnabled &&
    this.props.brand.smsApiToken !== '' &&
    this.props.brand.smsApiToken !== null;

  newSms = () => {
    if (this.canCreateNewSms) {
      return this.props.history.push(`${window.location.pathname}/add`);
    } else {
      return toast.error(
        [
          this.props.t('sms.setupSmsModule'),
          !this.props.brand.smsApiEnabled && this.props.t('sms.smsModuleOff'),
        ]
          .filter(Boolean)
          .join(' ')
      );
    }
  };
  render() {
    const { classes } = this.props;

    return (
      <AdminTable title={this.props.t('sms.massSms')}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {isGranted(ROLE_CREATE_MASS_SMS) && (
            <FormControlButtons
              classes={classes}
              handleSubmit={this.newSms}
              submitText={this.props.t('sms.addSms') + ' +'}
            />
          )}
        </div>
        <DataGrid
          actions={true}
          remove={true}
          export={true}
          paginationTop={true}
          paginationBottom={false}
          url="mass-sms"
          reportName={'masssms'}
          columns={columnConfig(this.props.t)}
          minRows={20}
          role="MASS-SMS"
          canRemoveChecker={(canRemove, row) =>
            canRemove && row.status === 'PLANED'
          }
          manipulateQuery={(requestData, query) => {
            if (query.processAt) {
              query['processAt[after]'] = Moment(query.processAt).format(
                'YYYY-MM-DD 00:00:01'
              );
              query['processAt[before]'] = Moment(query.processAt).format(
                'YYYY-MM-DD 23:59:59'
              );
            }
            return query;
          }}
        />
      </AdminTable>
    );
  }
}

const mapStateToProps = state => {
  return {
    brand: state.Brands.brand,
  };
};

const enhance = compose(
  connect(mapStateToProps, null),
  withTranslation(),
  withStyles(buttonsStyle)
);

export default enhance(List);
