import CardBody from '../../components/Card/CardBody';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { combineStyles } from '../../helpers/helpers';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControlButtons from '../../components/FormControlButtons/FormControlButtons';
import { get, put } from 'helpers/apiHelpers';
import { connect } from 'react-redux';
import { DialogLoader } from '../../components/DialogLoader';
import SEOSettingsCms from 'views/SEO/SEOSettingsCms';
import InfoBanner from 'components/CMS/InfoBanner';
import PresentationUrl from 'components/CMS/PresentationUrl';
import CardWrapper from 'components/Card/CardWrapper';
import { toast } from 'react-toastify';

const initialDietsState = {
  enabled: false,
  showNextDaysMenu: false,
  showRates: false,
  showOtherDiets: false,
  showVariants: false,
};

const initialListPageDietsState = {
  enabled: false,
  showBanner: false,
  bannerTitle: '',
  bannerSubTitle: '',
  bannerDesktop: false,
  bannerMobile: false,
};

const DietsCmsPage = ({ classes, t, selectedBrand }) => {
  const [diets, setDiets] = useState(initialDietsState);
  const [listPageDiets, setListPageDiets] = useState(initialListPageDietsState);
  const [enabledStateWithApi, setEnabledStateWithApi] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [module, setModule] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const {
          configuration: { SeoDiet },
        } = await get(`brands/${selectedBrand}/modules?module[]=SeoDiet`);
        const modulesConfig = await get(`brands/${selectedBrand}/modules`);

        const dietsData = {
          enabled: SeoDiet.enabled,
          showNextDaysMenu: SeoDiet.showNextDaysMenu,
          showRates: SeoDiet.showRates,
          showOtherDiets: SeoDiet.showOtherDiets,
          showVariants: SeoDiet.showVariants,
        };

        const listPageDietsData = {
          enabled: SeoDiet.listPageEnabled,
          showBanner: SeoDiet.listPageShowBanner,
          bannerTitle: SeoDiet.listPageBannerTitle,
          bannerSubTitle: SeoDiet.listPageBannerSubTitle,
          bannerDesktop: SeoDiet['listPageBannerDesktop']
            ? SeoDiet['@resources'][SeoDiet['listPageBannerDesktop']]
            : null,
          bannerMobile: SeoDiet['listPageBannerMobile']
            ? SeoDiet['@resources'][SeoDiet['listPageBannerMobile']]
            : null,
        };

        setDiets(dietsData);
        setModule(modulesConfig);
        setListPageDiets(listPageDietsData);
        setEnabledStateWithApi(SeoDiet.listPageEnabled);
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const {
        configuration: { SeoDiet },
      } = await get(`brands/${selectedBrand}/modules?module[]=SeoDiet`);
      setEnabledStateWithApi(SeoDiet.listPageEnabled);
    };
    fetchData();
  }, [isSubmitting]);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    put(`brands/${selectedBrand}/modules`, {
      configuration: {
        SeoCmsDietSide: module.configuration.SeoCmsDietSide,
      },
    })
      .then(() => {
        toast.success(t('status.success.save'));
        setIsSubmitting(false);
      })
      .catch(e => console.log(e));

    try {
      await put(`brands/${selectedBrand}/modules`, {
        module: 'SeoDiet',
        configuration: {
          SeoDiet: {
            ...diets,
            listPageEnabled: listPageDiets.enabled,
            listPageShowBanner: listPageDiets.showBanner,
            listPageBannerTitle: listPageDiets.bannerTitle,
            listPageBannerSubTitle: listPageDiets.bannerSubTitle,
            listPageBannerDesktop: listPageDiets.bannerDesktop?.['@id'],
            listPageBannerMobile: listPageDiets.bannerMobile?.['@id'],
          },
        },
      });
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getImage = (name, data) => {
    if (name === 'infoBannerDesktop') {
      setListPageDiets(prev => ({ ...prev, bannerDesktop: data }));
    }

    if (name === 'infoBannerMobile') {
      setListPageDiets(prev => ({ ...prev, bannerMobile: data }));
    }
  };

  const removeImage = (name, data) => {
    if (name === 'infoBannerDesktop') {
      setListPageDiets(prev => ({ ...prev, bannerDesktop: null }));
    }

    if (name === 'infoBannerMobile') {
      setListPageDiets(prev => ({ ...prev, bannerMobile: null }));
    }
  };

  const handleBannerTextChange = target => {
    if (target?.name === 'bannerHeader') {
      setListPageDiets(prev => ({ ...prev, bannerTitle: target?.value }));
    }

    if (target?.name === 'bannerText') {
      setListPageDiets(prev => ({ ...prev, bannerSubTitle: target?.value }));
    }
  };

  return (
    <>
      <DialogLoader loaderState={isSubmitting} text={t('form.savingChanges')} />
      <CardWrapper title={t('cms.diets.dietPage')}>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => {
                      setListPageDiets(prev => ({
                        ...prev,
                        enabled: !prev.enabled,
                      }));
                    }}
                    checked={listPageDiets.enabled}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={t('cms.diets.showDietsPage')}
              />
            </GridItem>
            {listPageDiets.enabled && (
              <GridItem xs={12}>
                <PresentationUrl
                  subpage="/diets"
                  cmsShowActionButton={enabledStateWithApi}
                />
              </GridItem>
            )}
            <InfoBanner
              getImage={(name, data) => {
                getImage(name, data);
              }}
              bannerText={listPageDiets.bannerSubTitle}
              removeImage={(name, data) => {
                removeImage(name, data);
              }}
              bannerHeader={listPageDiets.bannerTitle}
              handleInputChange={({ target }) => {
                handleBannerTextChange(target);
              }}
              infoBannerCheckbox={listPageDiets.showBanner}
              infoBannerMobileUrl={listPageDiets.bannerMobile?.contentUrl}
              infoBannerDesktopUrl={listPageDiets.bannerDesktop?.contentUrl}
              handleCheckboxChange={() => {
                setListPageDiets(prev => ({
                  ...prev,
                  showBanner: !prev.showBanner,
                }));
              }}
            />
          </GridContainer>
        </CardBody>
      </CardWrapper>

      <CardWrapper title={t('cms.diets.dietSubpage')}>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => {
                      setDiets(prev => ({
                        ...prev,
                        enabled: !prev.enabled,
                      }));
                    }}
                    checked={diets.enabled}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.diets.showDietSubpage')}{' '}
                    <div style={{ marginLeft: '10px' }}>
                      <Tooltip
                        title={
                          <div>
                            <h4>{t('cms.diets.showDietSubpage.tooltip')}</h4>
                          </div>
                        }
                        placement="right"
                      >
                        <Info fontSize={'small'} />
                      </Tooltip>
                    </div>
                  </div>
                }
              />
            </GridItem>

            <GridItem md={12}>
              <h2>{t('cms.diets.visibilityOptionsHeader')}</h2>
            </GridItem>

            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => {
                      setDiets(prev => ({
                        ...prev,
                        showNextDaysMenu: !prev.showNextDaysMenu,
                      }));
                    }}
                    checked={diets.showNextDaysMenu}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.diets.showUpcomingDaysMenu')}
                  </div>
                }
              />
            </GridItem>

            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => {
                      setDiets(prev => ({
                        ...prev,
                        showRates: !prev.showRates,
                      }));
                    }}
                    checked={diets.showRates}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.diets.showRatings')}
                  </div>
                }
              />
            </GridItem>

            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => {
                      setDiets(prev => ({
                        ...prev,
                        showOtherDiets: !prev.showOtherDiets,
                      }));
                    }}
                    checked={diets.showOtherDiets}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.diets.showOtherDiets')}
                  </div>
                }
              />
            </GridItem>

            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => {
                      setDiets(prev => ({
                        ...prev,
                        showVariants: !prev.showVariants,
                      }));
                    }}
                    checked={diets.showVariants}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.diets.showVariants')}
                  </div>
                }
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </CardWrapper>
      {/* SEO */}
      {module?.configuration?.SeoCmsDietSide && (
        <SEOSettingsCms
          state={module}
          setState={setModule}
          section="SeoCmsDietSide"
        />
      )}
      <FormControlButtons
        classes={classes}
        submitText={t('form.save')}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withTranslation(),
  withStyles(combinedStyles),
  connect(({ Auth: { selectedBrand } }) => ({
    selectedBrand,
  }))
);

export default enhance(DietsCmsPage);
