import { Component, Fragment } from 'react';
import Moment from 'moment';
import Datetime from 'react-datetime';
import { compose } from 'redux';
import { get, post } from 'helpers/apiHelpers';
import { withTranslation } from 'react-i18next';

import AdminTable from 'layouts/AdminTable';

import withStyles from '@material-ui/core/styles/withStyles';
import { combineStyles } from 'helpers/helpers';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import Button from 'components/CustomButtons/Button.jsx';
import GridItem from 'components/Grid/GridItem';
import SelectInput from 'components/FormSelect/SelectInput';
import GridContainer from 'components/Grid/GridContainer';
import AppDialogLoader from 'components/DialogLoader/AppDialogLoader';

import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FormTextInput } from 'components';
import SimpleWysiwyg from 'components/FormTextInput/SimpleWysiwyg';
import moment from 'moment';
import FormControlStickyButton from 'components/FormControlStickyButton/FormControlStickyButton';
import { toast } from 'react-toastify';

const initialFilters = {
  marketingTerms: {
    trueValue: true,
    falseValue: true,
  },
  hasActiveDiets: {
    trueValue: true,
    falseValue: true,
  },
};

const filtersTranslations = t => ({
  marketingTerms: {
    trueText: t(
      'massSma.hasConsentFormMarketing',
      'Wyraził zgodę na marketing'
    ),
    falseText: t(
      'massSma.noConsentForMarketing',
      'Nie wyraził zgody na marketing'
    ),
  },
  hasActiveDiets: {
    trueText: t('massSma.hasActiveDiet', 'Ma aktywną dietę'),
    falseText: t('massSma.noActiveDiet', 'Nie ma aktywnej diety'),
  },
});

class Form extends Component {
  state = {
    content: '',
    subject: '',
    processAt: new Date(),
    usersAmount: 0,
    isLoading: false,
    filters: {
      ...initialFilters,
    },
    bagDate: false,
    bagZone: false,
    zones: [],
  };

  componentDidMount() {
    this.getCurrentFiltersUsersAmount(this.state);
    if (this.props.choicesRow) {
      this.setState({
        content: this.props.choicesRow.content,
        subject: this.props.choicesRow.subject,
        processAt: moment(this.props.choicesRow.processAt).format(
          'DD.MM.YYYY HH:mm:ss'
        ),
      });
    }
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  validateForm = () => {
    return (
      this.state.content && this.state.processAt && this.state.subject.trim()
    );
  };

  checkAllFilters = () => {
    const newState = {
      ...this.state,
      filters: {
        ...initialFilters,
      },
      bagDate: false,
      bagZone: false,
    };

    this.setState(newState);
    this.getCurrentFiltersUsersAmount(newState);
  };

  handleBagDateChange = newDate => {
    let newState = { ...this.state };
    if (newDate) {
      newState = { ...newState, bagDate: newDate };
      this.setState(newState);
      return this.getCurrentFiltersUsersAmount(newState);
    }

    if (this.state.bagDate === false) {
      const today = new Moment(new Date());
      newState = { ...newState, bagDate: today.format('DD.MM.YYYY') };
      this.setState(newState);
      return this.getCurrentFiltersUsersAmount(newState);
    }

    newState = { ...newState, bagDate: false };
    this.setState(newState);
    return this.getCurrentFiltersUsersAmount(newState);
  };

  handleBagZoneChange = newZone => {
    let newState = { ...this.state };

    if (newZone) {
      newState = { ...newState, bagZone: newZone };
      this.setState(newState);
      return this.getCurrentFiltersUsersAmount(newState);
    }

    if (this.state.bagZone === false) {
      const firstZone = (this.state.zones ?? [])[0];
      newState = { ...newState, bagZone: firstZone };
      this.setState(newState);
      return this.getCurrentFiltersUsersAmount(newState);
    }

    newState = { ...newState, bagZone: false };
    this.setState(newState);
    return this.getCurrentFiltersUsersAmount(newState);
  };

  handleFilters = (filter, valueModifier) => {
    const newState = {
      ...this.state,
      filters: {
        ...this.state.filters,
        [filter]: {
          ...this.state.filters[filter],
          [valueModifier]: !this.state.filters[filter][valueModifier],
        },
      },
    };
    this.setState(newState);
    this.getCurrentFiltersUsersAmount(newState);
  };

  getActiveFilters = filters => {
    const filterKeys = Object.keys(filters);
    const activeFilters = {};

    filterKeys.forEach(key => {
      if (filters[key].trueValue !== filters[key].falseValue) {
        activeFilters[key] = filters[key].trueValue;
      }
    });

    return activeFilters;
  };

  getCurrentFiltersUsersAmount = async state => {
    this.setState({ isLoading: true });
    let zones = [];
    try {
      const zonesPayload = await get('/zones', {
        pagination: false,
        properties: ['id', 'name'],
      });
      zones = (zonesPayload?.['hydra:member'] ?? []).map(
        ({ id: iri, name }) => ({ value: iri, name })
      );
    } catch (e) {
      console.log(e);
    }

    get('/client-count-query', {
      pagination: true,
      partial: false,
      itemsPerPage: 1,
      properties: ['_xxx'],
      bagDate: this.state.bagDate ? this.state.bagDate : null,
      bagZone: this.state.bagZone ? this.state.bagZone?.value : null,
      type: 'mail',
      ...this.getActiveFilters(state.filters),
    })
      .then(res => {
        this.setState({
          usersAmount: res,
          zones: zones,
        });
      })
      .catch(e => {
        console.log({ e });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  handleSubmit = () => {
    const { t, history } = this.props;
    if (!this.validateForm()) {
      return toast.error(t('email.fillAllFields'));
    }

    const data = {
      processAt: this.state.processAt,
      content: this.state.content,
      subject: this.state.subject,
      filters: {
        ...this.getActiveFilters(this.state.filters),
        bagDate: this.state.bagDate,
        bagZone: this.state.bagZone,
      },
    };

    post('/mass-mails', data).then(
      res => {
        history.push('/admin/mass-mails');
      },
      () => toast.error(t('email.emailNotCreated'))
    );
  };

  render() {
    const { classes, t } = this.props;

    return (
      <Fragment>
        {this.state.isLoading && (
          <AppDialogLoader text={t('common.loader', 'Trwa ładowanie...')} />
        )}
        <form>
          {!this.props.choicesRow && <h2>{t('email.creatingEmail')}</h2>}
          <AdminTable>
            <GridContainer>
              {!this.props.choicesRow && (
                <GridItem md={6}>
                  <GridItem sm={12}>
                    <p>{t('email.emailTo')}</p>
                    <Button onClick={this.checkAllFilters}>
                      {t('email.all')}
                    </Button>
                  </GridItem>
                  {Object.keys(initialFilters).map(filter => {
                    return (
                      <GridItem sm={12} key={filter}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.filters[filter].trueValue}
                              onChange={() =>
                                this.handleFilters(filter, 'trueValue')
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                          }}
                          label={filtersTranslations(t)[filter].trueText}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.filters[filter].falseValue}
                              onChange={() =>
                                this.handleFilters(filter, 'falseValue')
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                          }}
                          label={filtersTranslations(t)[filter].falseText}
                        />
                      </GridItem>
                    );
                  })}
                  <GridItem md={12}>
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.bagDate}
                            onChange={() => this.handleBagDateChange()}
                            icon={<Check className={classes.uncheckedIcon} />}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                        }}
                        label={t(
                          'email.bagDate',
                          'Którzy mają torbę na dzień:'
                        )}
                      />
                      {this.state.bagDate && (
                        <div
                          style={{
                            marginLeft: '35px',
                            width: '100%',
                            maxWidth: '155px',
                          }}
                        >
                          <Datetime
                            timeFormat={false}
                            dateFormat={'YYYY-MM-DD'}
                            closeOnSelect={true}
                            value={this.state.bagDate}
                            onChange={ev =>
                              this.handleBagDateChange(ev.format('DD.MM.YYYY'))
                            }
                            inputProps={{
                              placeholder: t('email.setDate'),
                              readOnly: true,
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </GridItem>
                  <GridItem md={12}>
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.bagZone}
                            onChange={() => this.handleBagZoneChange()}
                            icon={<Check className={classes.uncheckedIcon} />}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                        }}
                        label={t(
                          'email.bagZone',
                          'Którzy mają torbę w strefie:'
                        )}
                      />
                      {this.state.bagZone && (
                        <div
                          style={{
                            marginLeft: '35px',
                            width: '100%',
                            maxWidth: '155px',
                          }}
                        >
                          <SelectInput
                            options={this.state.zones}
                            value={this.state.bagZone}
                            mapBy="name"
                            trackBy="value"
                            classes={classes}
                            handleChange={(e, option) =>
                              this.handleBagZoneChange(option)
                            }
                          />
                        </div>
                      )}
                    </div>
                  </GridItem>
                  <GridItem md={12}>
                    {t('email.recCount')}: <b>{this.state.usersAmount}</b>
                  </GridItem>
                </GridItem>
              )}
              <GridItem md={this.props.choicesRow ? 12 : 6}>
                <GridItem sm={12}>
                  <GridItem md={12}>
                    <p>{t('email.msg')}</p>
                  </GridItem>
                  <GridItem md={12}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('email.deliveryDate')} *
                    </FormLabel>

                    <Datetime
                      timeFormat={true}
                      closeOnSelect={true}
                      open={this.props.choicesRow && false}
                      value={this.state.processAt}
                      onChange={ev => {
                        if (!this.props.choicesRow) {
                          this.setState({
                            processAt: ev.format('DD.MM.YYYY HH:mm'),
                          });
                        }
                      }}
                      isValidDate={day =>
                        day.isAfter(Datetime.moment().subtract(1, 'day'))
                      }
                      inputProps={
                        this.props.choicesRow
                          ? {
                              placeholder: t('email.setDate'),
                              readOnly: true,
                              style: { color: 'RGB(160,160,160)' },
                            }
                          : {
                              placeholder: t('email.setDate'),
                              readOnly: true,
                            }
                      }
                    />
                  </GridItem>
                  <GridItem sm={12}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('email.subject')} *
                    </FormLabel>
                    <FormTextInput
                      inputProps={
                        this.props.choicesRow
                          ? { style: { color: 'RGB(160,160,160)' } }
                          : { style: {} }
                      }
                      disabled={this.props.choicesRow}
                      multiline
                      rowsMax={10}
                      classes={classes}
                      name="subject"
                      value={this.state.subject}
                      handleChange={this.handleChange}
                      inputSize={12}
                      maxLength={255}
                    />
                  </GridItem>
                  <GridItem sm={12}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('email.content')} *
                    </FormLabel>
                  </GridItem>
                  <GridItem md={12}>
                    <SimpleWysiwyg
                      numberRows={15}
                      value={this.state.content}
                      handleChange={e => {
                        this.setState({
                          content: e.target.value,
                        });
                      }}
                    />
                  </GridItem>
                </GridItem>
              </GridItem>
            </GridContainer>
          </AdminTable>
          {!this.props.choicesRow && (
            <FormControlStickyButton
              classes={classes}
              discardText={t('email.cancel')}
              submitText={t('email.addEmail2')}
              cancelPath="/admin/mass-mails"
              handleSubmit={this.handleSubmit}
              submitDisabled={!this.state.usersAmount}
              isFixedToBottom={true}
            />
          )}
        </form>
      </Fragment>
    );
  }
}

const combinedStyles = combineStyles(buttonsStyle, extendedFormsStyle);

const enhance = compose(withStyles(combinedStyles), withTranslation());

export default enhance(Form);
