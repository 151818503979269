import React from 'react';
// @material-ui/core components
import FormLabel from '@material-ui/core/FormLabel';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import CardBody from 'components/Card/CardBody';

import FormSelectMultiple from 'components/FormSelect/FormSelectMultiple';

import SelectInput from 'components/FormSelect/SelectInput';
import { Info } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';

import FormTextInputTranslatable from 'components/FormTextInput/FormTextInputTranslatable';
import CardWrapper from 'components/Card/CardWrapper';

const FormIngredientBasicInformationSection = ({
  classes,
  basicOptions,
  handleChange,
  setDefaultLanguage,
  selectsOptions,
  handleToggle,
  handleWeightBeforeProcessingChange,
  t,
  state,
}) => {
  return (
    <CardWrapper title={t('form.basicInfo')}>
      <CardBody>
        <GridContainer justify="center">
          <GridItem md={10}>
            <GridContainer>
              <GridItem md={4}>
                <FormTextInputTranslatable
                  noGrid
                  classes={classes}
                  label={basicOptions[0].label}
                  name={basicOptions[0].name}
                  type={basicOptions[0].type}
                  value={basicOptions[0].value}
                  handleChange={handleChange}
                  setDefaultLanguage={setDefaultLanguage}
                  maxLength={600}
                  formControlProps={{ fullWidth: true }}
                />
              </GridItem>
              <GridItem md={4}>
                <FormLabel className={classes.labelHorizontal}>
                  {' '}
                  {basicOptions[2].label}{' '}
                </FormLabel>
                <CustomInput
                  id={basicOptions[2].id}
                  formControlProps={{ fullWidth: true }}
                  maxLength={600}
                  inputProps={{
                    id: basicOptions[2].id,
                    type: basicOptions[2].type,
                    name: basicOptions[2].name,
                    onChange: handleChange,
                    value: basicOptions[2].value,
                  }}
                />
              </GridItem>
              <GridItem md={4}>
                <FormLabel className={classes.labelHorizontal}>
                  {' '}
                  {basicOptions[3].label}{' '}
                </FormLabel>
                <CustomInput
                  id={basicOptions[3].id}
                  formControlProps={{ fullWidth: true }}
                  maxLength={600}
                  inputProps={{
                    id: basicOptions[3].id,
                    type: basicOptions[3].type,
                    name: basicOptions[3].name,
                    onChange: handleChange,
                    value: basicOptions[3].value,
                  }}
                />
              </GridItem>
              <GridItem md={6}>
                <FormLabel className={classes.labelHorizontal}>
                  {' '}
                  {selectsOptions[1].label}{' '}
                </FormLabel>
                <div style={{ marginTop: '-10px' }}>
                  <SelectInput
                    noGrid
                    classes={classes}
                    options={selectsOptions[1].options}
                    value={selectsOptions[1].value}
                    trackBy={selectsOptions[1].trackBy}
                    mapBy={selectsOptions[1].mapBy}
                    handleChange={handleChange}
                    name={selectsOptions[1].name}
                    id={selectsOptions[1].id}
                    labelSm={3}
                    selectSm={4}
                  />
                </div>
              </GridItem>
              <GridItem md={6}>
                <FormLabel className={classes.labelHorizontal}>
                  {' '}
                  {basicOptions[1].label}{' '}
                </FormLabel>
                <CustomInput
                  id={basicOptions[1].id}
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    id: basicOptions[1].id,
                    type: basicOptions[1].type,
                    name: basicOptions[1].name,
                    onChange: handleWeightBeforeProcessingChange,
                    value: basicOptions[1].value,
                  }}
                />
              </GridItem>
              <GridItem md={12}>
                <FormSelectMultiple
                  classes={classes}
                  label={selectsOptions[0].label}
                  options={selectsOptions[0].options}
                  value={selectsOptions[0].value}
                  trackBy={selectsOptions[0].trackBy}
                  mapBy={selectsOptions[0].mapBy}
                  handleChange={handleChange}
                  name={selectsOptions[0].name}
                  id={selectsOptions[0].id}
                  labelSm={3}
                  selectSm={4}
                />
              </GridItem>
              <GridItem md={12}>
                <FormSelectMultiple
                  classes={classes}
                  label={selectsOptions[2].label}
                  options={selectsOptions[2].options}
                  value={selectsOptions[2].value}
                  trackBy={selectsOptions[2].trackBy}
                  mapBy={selectsOptions[2].mapBy}
                  handleChange={handleChange}
                  name={selectsOptions[2].name}
                  id={selectsOptions[2].id}
                  labelSm={3}
                  selectSm={4}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem md={10}>
            <GridContainer>
              <GridItem sm={3} style={{ marginTop: '-5px' }}>
                <Checkbox
                  style={{ marginLeft: '-14px' }}
                  id={'homeIngredient'}
                  onClick={() => handleToggle('homeIngredient')}
                  checked={state.homeIngredient}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
                <FormLabel
                  htmlFor={'homeIngredient'}
                  className={classes.labelHorizontal}
                >
                  {t('form.field.homeIngredient')}
                  <Tooltip
                    title={
                      <div>
                        <h4>{t('form.field.nameTooltip.homeIngredient')}</h4>
                      </div>
                    }
                    placement="right"
                  >
                    <Info style={{ marginLeft: '5px' }} fontSize={'small'} />
                  </Tooltip>
                </FormLabel>
              </GridItem>
              <GridItem sm={3} style={{ marginTop: '-5px' }}>
                <Checkbox
                  style={{ marginLeft: '-14px' }}
                  id={'allert'}
                  onClick={() => handleToggle('allert')}
                  checked={state.allert}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
                <FormLabel
                  htmlFor={'allert'}
                  className={classes.labelHorizontal}
                >
                  {t('form.field.allert')}
                </FormLabel>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </CardBody>
    </CardWrapper>
  );
};

export default FormIngredientBasicInformationSection;
