import { Component } from 'react';
import Moment from 'moment';
import moment from 'moment';
import { get } from 'helpers/apiHelpers';
import { withRouter } from 'react-router';
import axios from 'helpers/gastro';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import tableStyle from 'assets/jss/material-dashboard-pro-react/components/tableStyle';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { combineStyles } from 'helpers/helpers';
import Button from 'components/CustomButtons/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { apiErrorToast } from 'utils/apiError';

const getMealTypeNameFromArray = (id, collection) => {
  let element = collection.find(el => {
    return el['@id'] === id;
  });

  if (element) {
    return element.name;
  }

  return '';
};

class SummaryDay extends Component {
  state = {
    menuPlanners: [],
    cacheKey: '',
    lockedPdfExport: false,
    lockedXlsxExport: false,
  };

  componentDidMount() {
    this.getMenuPlanners();
  }

  componentDidUpdate() {
    this.getMenuPlanners();
  }

  getMenuPlanners = async () => {
    const query = {
      pagination: false,
      'date[after]': this.props.day,
      'date[before]': this.props.day,
      variant: this.props.variant['@id'],
      properties: ['id', 'mealType', 'diet'],
      'properties[dish]': 'nameForClient',
    };
    const cacheKey = JSON.stringify(query);

    if (cacheKey !== this.state.cacheKey) {
      const response = await get('/menu-summary', query);

      this.setState(prevState => {
        return {
          ...prevState,
          menuPlanners: response['hydra:member'],
          cacheKey,
        };
      });
    }
  };

  cellInfo = (diet, mealType, index) => {
    let { classes } = this.props;
    const menuPlanner = this.state.menuPlanners.find(menu => {
      return menu && menu.diet['@id'] === diet && menu.mealType === mealType;
    });

    if (menuPlanner) {
      return (
        <TableCell colSpan={1} style={{ textAlign: 'center' }} key={index}>
          {menuPlanner.dish.nameForClient}{' '}
          {menuPlanner.dish.rate ? `(${menuPlanner.dish.rateAVG})` : ''}
        </TableCell>
      );
    }

    return (
      <TableCell
        className={classes.warning}
        colSpan={1}
        style={{ textAlign: 'center' }}
        key={index}
      >
        {this.props.t('form.menuPlanning.lack')}
      </TableCell>
    );
  };

  goToEdit = day => {
    const { history } = this.props;

    history.push(`/admin/menu-planner/${day}/${this.props.variant.id}`);
  };

  exportSingleDate = (date, extension) => {
    let mimeType;
    let lockParam;

    switch (extension) {
      case 'pdf':
        mimeType = 'application/pdf';
        lockParam = 'lockedPdfExport';

        break;
      case 'xlsx':
        mimeType = 'application/vnd.ms-excel';
        lockParam = 'lockedXlsxExport';

        break;
      default:
        break;
    }

    this.setState(prevState => ({ ...prevState, [lockParam]: true }));
    const params = {
      date: date,
      variant: this.props.variant.id,
    };

    axios
      .get('reports/menu-planner', {
        responseType: 'blob',
        params: params,
        headers: { accept: mimeType },
      })
      .then(
        response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Planowanie-menu-${date}_(${moment().format(
              'YYYY-MM-DD_HH.mm'
            )}).` + extension
          );
          document.body.appendChild(link);
          link.click();
          this.setState(prevState => ({ ...prevState, [lockParam]: false }));
        },
        error => {
          const { t } = this.props;
          apiErrorToast(t('reports.cannotGenerate'), error);
          this.setState(prevState => ({ ...prevState, [lockParam]: false }));
        }
      );
  };

  render() {
    let { variant, mealTypes, diets, day, classes } = this.props;
    let rows = [];

    rows.push(
      <TableRow className={classes.successRow} key={'_asc'}>
        <TableCell
          padding="dense"
          style={{ textAlign: 'center' }}
          colSpan={diets.length + 1}
        >
          {day} {new Moment(day).format('dddd')}
          <Button
            size={'sm'}
            style={{ marginLeft: '20px' }}
            color="success"
            round
            onClick={() => {
              this.goToEdit(day);
            }}
          >
            {this.props.t('form.menuPlanning.edit')}
          </Button>
          <Button
            size={'sm'}
            style={{ marginLeft: '20px' }}
            color="primary"
            loading
            round
            onClick={() => {
              this.exportSingleDate(day, 'pdf');
            }}
          >
            {!this.state.lockedPdfExport && (
              <>{this.props.t('form.menuPlanning.saveToPdf')}</>
            )}
            {this.state.lockedPdfExport && (
              <CircularProgress style={{ width: '20px', height: '20px' }} />
            )}
          </Button>
          <Button
            size={'sm'}
            style={{ marginLeft: '20px' }}
            color="primary"
            loading
            round
            onClick={() => {
              this.exportSingleDate(day, 'xlsx');
            }}
          >
            {!this.state.lockedXlsxExport && (
              <>{this.props.t('form.menuPlanning.saveToXlsx')}</>
            )}
            {this.state.lockedXlsxExport && (
              <CircularProgress style={{ width: '20px', height: '20px' }} />
            )}
          </Button>
        </TableCell>
      </TableRow>
    );
    variant.mealTypes.forEach((mealType, index) =>
      rows.push(
        <TableRow key={index}>
          <TableCell colSpan={1}>
            {getMealTypeNameFromArray(mealType, mealTypes)}
          </TableCell>
          {diets.map((diet, index) =>
            this.cellInfo(diet['@id'], mealType, index)
          )}
        </TableRow>
      )
    );
    return rows;
  }
}

const combinedStyles = combineStyles(tableStyle, buttonsStyle);
const enhance = compose(
  withRouter,
  withStyles(combinedStyles),
  withTranslation()
);

export default enhance(SummaryDay);
