import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import produce from 'immer';
import { isGranted } from 'helpers/helpers';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import AdminTable from 'layouts/AdminTable';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button.jsx';
import { Check, Info, Extension as ExtensionIcon } from '@material-ui/icons';
import { Divider, Tooltip } from '@material-ui/core';

import { get, put } from 'helpers/apiHelpers';
import { ROLE_EDIT_MODULE_ADDONS } from 'helpers/roles';
import LabeledCheckbox from 'components/Checkbox/LabeledCheckbox';

import makeStyles from '@material-ui/styles/makeStyles';
import { toast } from 'react-toastify';

const useStyles = makeStyles({
  ...extendedFormsStyle,
  ...buttonsStyle,
  divider: { marginBottom: '10px' },
  tooltip: { color: 'grey', marginBottom: '-4px' },
});

const AddonsSettings = () => {
  const { t } = useTranslation();
  const brandId = useSelector(state => state.Auth.selectedBrand);
  const [formData, setFormData] = useState({
    addToOrder: false,
    extendDiet: false,
    groupByCategory: false,
  });

  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get(`/brands/${brandId}/module/Addons`);
        const { '@id': iriId, '@type': iriType, ...onlyFormData } = response;

        setFormData({
          ...onlyFormData,
        });
      } catch (error) {}
    };

    fetchData();
  }, []);

  const handleCheckboxClick = ({ target: { name } }) => {
    setFormData(
      produce(draft => {
        draft[name] = !draft[name];
      })
    );
  };

  const handleSubmit = async () => {
    await put(`/brands/${brandId}/module`, {
      module: 'Addons',
      configuration: {
        ...formData,
      },
    });
    toast.success(t('status.success.save'));
  };

  return (
    <AdminTable
      title={t('views.addons.addonsSettings.list')}
      iconComponent={<ExtensionIcon />}
    >
      <GridContainer>
        <GridItem md={12}>
          <h3>{t('views.addons.addonsSettings.clientPanel')}</h3>
          <Divider className={classes.divider} />
        </GridItem>
        <GridItem sm={12}>
          <LabeledCheckbox
            name="addToOrder"
            onClick={handleCheckboxClick}
            checked={formData.addToOrder}
            disabled={!isGranted(ROLE_EDIT_MODULE_ADDONS)}
            label={t('views.addons.addonsSettings.addToOrder')}
          />
        </GridItem>
        <GridItem sm={12}>
          <LabeledCheckbox
            name="extendDiet"
            onClick={handleCheckboxClick}
            checked={formData.extendDiet}
            disabled={!isGranted(ROLE_EDIT_MODULE_ADDONS)}
            label={t('views.addons.addonsSettings.extendDiet')}
          />
        </GridItem>
        <GridItem sm={12}>
          <LabeledCheckbox
            name="groupByCategory"
            onClick={handleCheckboxClick}
            checked={formData.groupByCategory}
            checkedIcon={<Check className={classes.checkedIcon} />}
            disabled={!isGranted(ROLE_EDIT_MODULE_ADDONS)}
            label={t('views.addons.addonsSettings.groupByCategory')}
          />
          <Tooltip
            className={classes.tooltip}
            title={
              <h4>
                {t('views.addons.addonsSettings.groupByCategory.tooltip')}
              </h4>
            }
            placement="right"
          >
            <Info fontSize="small" />
          </Tooltip>
        </GridItem>
      </GridContainer>

      {isGranted(ROLE_EDIT_MODULE_ADDONS) && (
        <GridContainer justify="flex-end">
          <GridItem>
            <Button color="success" onClick={handleSubmit}>
              {t('common.shared.save')}
            </Button>
          </GridItem>
        </GridContainer>
      )}
    </AdminTable>
  );
};

export default AddonsSettings;
