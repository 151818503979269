import { compose } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { combineStyles } from 'helpers/helpers';
import { ROLE_EDIT_SEO } from 'helpers/roles';

import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import CardBody from 'components/Card/CardBody';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';
import DescriptionLetterCounter from './components/DescriptionLetterCounter';
import TitleLetterCounter from './components/TitleLetterCounter';
import CardWrapper from 'components/Card/CardWrapper';

const SEOSettings = ({ classes, state, setState, selectedBrand, roles }) => {
  const { t } = useTranslation();

  const handleInputChange = (event, value) => {
    const newValue = event.target.value;
    setState({ ...state, [event.target.name]: value ? value : newValue });
  };

  const getImage = (stateName, data) => {
    setState({
      ...state,
      [stateName]: data?.['@id'] || null,
      [`${stateName}Url`]: data?.contentUrl || null,
    });
  };

  const removeImage = stateName => {
    setState({ ...state, [stateName]: null, [`${stateName}Url`]: null });
  };

  const handleToggle = e => {
    setState({ ...state, [e.target.name]: !state[e.target.name] });
  };

  const {
    '@resources': resources,
    ogImage,
    indexing,
    metaTitle,
    ogTitle,
    metaDescription,
    ogDescription,
  } = state;

  const isEditEnabled = roles ? roles.includes(ROLE_EDIT_SEO) : false;

  return (
    <>
      <CardWrapper title={t('brandCfg.seo')}>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <FormTextInput
                label={<TitleLetterCounter wordsCount={metaTitle?.length} />}
                classes={classes}
                name="metaTitle"
                value={metaTitle}
                handleChange={handleInputChange}
                inputSize={6}
                disabled={!isEditEnabled}
              />
              <FormTextInput
                label={
                  <DescriptionLetterCounter
                    wordsCount={metaDescription?.length}
                  />
                }
                classes={classes}
                name="metaDescription"
                value={metaDescription}
                handleChange={handleInputChange}
                multiline
                inputSize={6}
                rowsMax={10}
                disabled={!isEditEnabled}
              />
              <GridContainer>
                <GridItem xs={12} sm={2}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('seo.indexPage')}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        name="indexing"
                        onClick={handleToggle}
                        checked={indexing}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                        disabled={!isEditEnabled}
                      />
                    }
                    classes={{
                      label: classes.label,
                    }}
                    label={''}
                  />
                </GridItem>
              </GridContainer>
              <FormTextInput
                label={t('seo.openGraphTitle')}
                classes={classes}
                name="ogTitle"
                value={ogTitle}
                handleChange={handleInputChange}
                inputSize={6}
                disabled={!isEditEnabled}
              />
              <FormTextInput
                label={t('seo.openGraphDesc')}
                classes={classes}
                name="ogDescription"
                value={ogDescription}
                handleChange={handleInputChange}
                inputSize={6}
                rowsMax={10}
                disabled={!isEditEnabled}
              />
              <GridContainer style={{ marginTop: '20px' }}>
                <GridItem xs={12} sm={2}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('seo.openGraphPhoto')}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={10} style={{ marginLeft: '-15px' }}>
                  <FormImageUpload
                    classes={classes}
                    stateName="ogImage"
                    getImage={getImage}
                    removeImage={removeImage}
                    previewUrl={resources?.[ogImage]?.contentUrl}
                    isDisabled={!isEditEnabled}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </CardWrapper>
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => {
  return {
    roles: state.Auth.user.roles,
    selectedBrand: state.Auth.selectedBrand,
  };
};

const enhance = compose(
  connect(mapStateToProps, null),
  withStyles(combinedStyles)
);

export default enhance(SEOSettings);
