import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import FormControlButtons from '../../components/FormControlButtons/FormControlButtons';
import React, { useEffect, useState } from 'react';
import { combineStyles } from '../../helpers/helpers';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import { DialogLoader } from '../../components/DialogLoader';
import { get, put } from '../../helpers/apiHelpers';
import { connect } from 'react-redux';
import SEOSettingsCms from 'views/SEO/SEOSettingsCms';
import InfoBanner from 'components/CMS/InfoBanner';
import AddressFinderBanner from 'components/CMS/AddressFinderBanner';
import PresentationUrl from 'components/CMS/PresentationUrl';
import CardWrapper from 'components/Card/CardWrapper';
import { toast } from 'react-toastify';

const initialZonesListPageState = {
  resources: [],
  banners: {
    first: {
      desktop: null,
      enabled: false,
      mobile: null,
      showBanner: false,
      subTitle: null,
      title: null,
    },
    second: {
      desktop: null,
      enabled: false,
      mobile: null,
      showBanner: false,
      subTitle: null,
      title: null,
    },
    third: {
      desktop: null,
      enabled: false,
      mobile: null,
      showBanner: false,
      subTitle: null,
      title: null,
    },
    fourth: {
      desktop: null,
      enabled: false,
      mobile: null,
      showBanner: false,
      subTitle: null,
      title: null,
    },
    fifth: {
      desktop: null,
      enabled: false,
      mobile: null,
      showBanner: false,
      subTitle: null,
      title: null,
    },
    sixth: {
      desktop: null,
      enabled: false,
      mobile: null,
      showBanner: false,
      subTitle: null,
      title: null,
    },
  },

  addressFinder: {
    desktop: null,
    enabled: false,
    mobile: null,
    showBanner: false,
    subTitle: null,
    title: null,
    isColorMobile: false,
    backgroundMobile: '',
    isColorDesktop: false,
    backgroundDesktop: '',
    content: '',
  },

  enabled: false,
  showDeliveryHours: false,
  showPrices: false,
};

const ZonesCmsPage = ({ classes, t, selectedBrand }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [module, setModule] = useState({});
  const [zones, setZones] = useState(initialZonesListPageState);
  const [enabledStateWithApi, setEnabledStateWithApi] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const {
        configuration: { SeoZone },
      } = await get(`brands/${selectedBrand}/modules?module[]=SeoZone`);

      const modulesConfig = await get(`brands/${selectedBrand}/modules`);

      const listPageZonesData = {
        resources: SeoZone['@resources'],
        banners: {
          first: {
            desktop: SeoZone.banners.first.desktop,
            enabled: SeoZone.banners.first.enabled,
            mobile: SeoZone.banners.first.mobile,
            showBanner: SeoZone.banners.first.showBanner,
            subTitle: SeoZone.banners.first.subTitle,
            title: SeoZone.banners.first.title,
          },
          second: {
            desktop: SeoZone.banners.second.desktop,
            enabled: SeoZone.banners.second.enabled,
            mobile: SeoZone.banners.second.mobile,
            showBanner: SeoZone.banners.second.showBanner,
            subTitle: SeoZone.banners.second.subTitle,
            title: SeoZone.banners.second.title,
          },
          third: {
            desktop: SeoZone.banners.third.desktop,
            enabled: SeoZone.banners.third.enabled,
            mobile: SeoZone.banners.third.mobile,
            showBanner: SeoZone.banners.third.showBanner,
            subTitle: SeoZone.banners.third.subTitle,
            title: SeoZone.banners.third.title,
          },
          fourth: {
            desktop: SeoZone.banners.fourth.desktop,
            enabled: SeoZone.banners.fourth.enabled,
            mobile: SeoZone.banners.fourth.mobile,
            showBanner: SeoZone.banners.fourth.showBanner,
            subTitle: SeoZone.banners.fourth.subTitle,
            title: SeoZone.banners.fourth.title,
          },
          fifth: {
            desktop: SeoZone.banners.fifth.desktop,
            enabled: SeoZone.banners.fifth.enabled,
            mobile: SeoZone.banners.fifth.mobile,
            showBanner: SeoZone.banners.fifth.showBanner,
            subTitle: SeoZone.banners.fifth.subTitle,
            title: SeoZone.banners.fifth.title,
          },
          sixth: {
            desktop: SeoZone.banners.sixth.desktop,
            enabled: SeoZone.banners.sixth.enabled,
            mobile: SeoZone.banners.sixth.mobile,
            showBanner: SeoZone.banners.sixth.showBanner,
            subTitle: SeoZone.banners.sixth.subTitle,
            title: SeoZone.banners.sixth.title,
          },
        },
        addressFinder: {
          desktop: SeoZone.addressFinder.desktop,
          enabled: SeoZone.addressFinder.enabled,
          mobile: SeoZone.addressFinder.mobile,
          showBanner: SeoZone.addressFinder.showBanner,
          subTitle: SeoZone.addressFinder.subTitle,
          title: SeoZone.addressFinder.title,
          backgroundMobile: SeoZone.addressFinder.backgroundMobile,
          backgroundDesktop: SeoZone.addressFinder.backgroundDesktop,
          isColorMobile: SeoZone.addressFinder.isColorMobile,
          isColorDesktop: SeoZone.addressFinder.isColorDesktop,
          content: SeoZone.addressFinder.content,
        },

        enabled: SeoZone.enabled,
        showDeliveryHours: SeoZone.showDeliveryHours,
        showPrices: SeoZone.showPrices,
      };
      // const getBanner = type =>
      //   SeoZone[type] ? SeoZone['@resources'][SeoZone[type]] : null;

      setZones(listPageZonesData);
      setModule(modulesConfig);
      setEnabledStateWithApi(SeoZone.enabled);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const {
        configuration: { SeoZone },
      } = await get(`brands/${selectedBrand}/modules?module[]=SeoZone`);
      setEnabledStateWithApi(SeoZone.enabled);
    };
    fetchData();
  }, [isSubmitting]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    put(`brands/${selectedBrand}/modules`, {
      configuration: {
        SeoCmsZonesSubpage: module.configuration.SeoCmsZonesSubpage,
      },
    }).then(() => {
      toast.success(t('status.success.save'));
      setIsSubmitting(false);
    });

    try {
      await put(`brands/${selectedBrand}/modules`, {
        module: 'SeoZone',
        configuration: {
          SeoZone: {
            ...zones,
          },
        },
      });
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChangeMobileBackgroundColor = color => {
    setZones(prev => ({
      ...prev,
      addressFinder: {
        ...prev.addressFinder,
        backgroundMobile: color.hex,
      },
    }));
  };
  const handleChangeDesktopBackgroundColor = color => {
    setZones(prev => ({
      ...prev,
      addressFinder: {
        ...prev.addressFinder,
        backgroundDesktop: color.hex,
      },
    }));
  };
  const getImage = (name, data, banner) => {
    if (banner === 'addressFinder' && name === 'infoBannerDesktop') {
      setZones(prev => ({
        ...prev,
        [banner]: {
          ...prev[banner],
          desktop: data['@id'],
        },
      }));
    }

    if (banner === 'addressFinder' && name === 'infoBannerMobile') {
      setZones(prev => ({
        ...prev,
        [banner]: {
          ...prev[banner],
          mobile: data['@id'],
        },
      }));
    }

    if (name === 'infoBannerDesktop') {
      setZones(prev => ({
        ...prev,
        banners: {
          ...prev.banners,
          [banner]: {
            ...prev.banners[banner],
            desktop: data['@id'],
          },
        },
      }));
    }

    if (name === 'infoBannerMobile') {
      setZones(prev => ({
        ...prev,
        banners: {
          ...prev.banners,
          [banner]: {
            ...prev.banners[banner],
            mobile: data['@id'],
          },
        },
      }));
    }
  };

  const removeImage = (name, banner) => {
    if (banner === 'addressFinder' && name === 'infoBannerDesktop') {
      setZones(prev => ({
        ...prev,
        [banner]: {
          ...prev[banner],
          desktop: null,
        },
      }));
    }

    if (banner === 'addressFinder' && name === 'infoBannerMobile') {
      setZones(prev => ({
        ...prev,
        [banner]: {
          ...prev[banner],
          mobile: null,
        },
      }));
    }

    if (name === 'infoBannerDesktop') {
      setZones(prev => ({
        ...prev,
        banners: {
          ...prev.banners,
          [banner]: {
            ...prev.banners[banner],
            desktop: null,
          },
        },
      }));
    }

    if (name === 'infoBannerMobile') {
      setZones(prev => ({
        ...prev,
        banners: {
          ...prev.banners,
          [banner]: {
            ...prev.banners[banner],
            mobile: null,
          },
        },
      }));
    }
  };

  const handleBannerTextChange = (target, banner) => {
    if (target?.name === 'bannerHeader') {
      setZones(prev => ({
        ...prev,
        banners: {
          ...prev.banners,
          [banner]: {
            ...prev.banners[banner],
            title: target?.value,
          },
        },
      }));
    }

    if (target?.name === 'bannerText') {
      setZones(prev => ({
        ...prev,
        banners: {
          ...prev.banners,
          [banner]: {
            ...prev.banners[banner],
            subTitle: target?.value,
          },
        },
      }));
    }
  };

  const handleBannerCheckboxChange = (e, banner) => {
    if (banner === 'addressFinder') {
      setZones(prev => ({
        ...prev,
        [banner]: {
          ...prev[banner],
          showBanner: !zones[banner].showBanner,
        },
      }));
      return;
    }

    setZones(prev => ({
      ...prev,
      banners: {
        ...prev.banners,
        [banner]: {
          ...prev.banners[banner],
          showBanner: !zones.banners[banner].showBanner,
        },
      },
    }));
  };

  const showImage = (mode, banner) => {
    if (zones && zones.resources) {
      if (banner === 'addressFinder') {
        const image = Object.values(zones?.resources).filter(
          el => el['@id'] === zones?.[banner][mode]
        );

        return image?.[0]?.contentUrl;
      }

      const image = Object.values(zones?.resources).filter(
        el => el['@id'] === zones?.banners?.[banner][mode]
      );
      return image[0]?.contentUrl;
    }
  };

  return (
    <>
      <DialogLoader loaderState={isSubmitting} text={t('form.savingChanges')} />

      <CardWrapper title={t('cms.zones.listPage')}>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => {
                      setZones(prev => ({
                        ...prev,
                        enabled: !prev.enabled,
                      }));
                    }}
                    checked={zones.enabled}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={t('cms.zones.showListPage')}
              />
            </GridItem>
            {zones.enabled && (
              <GridItem xs={12}>
                <PresentationUrl
                  subpage="/zones"
                  cmsShowActionButton={enabledStateWithApi}
                />
              </GridItem>
            )}
          </GridContainer>
        </CardBody>
      </CardWrapper>

      <CardWrapper title={t('cms.zones.banner', 'Banner Informacyjny')}>
        <CardBody>
          <GridContainer>
            <InfoBanner
              showBannerTitle={t('cms.zones.showBaner1', 'Pokaż baner nr 1')}
              getImage={(name, data) => {
                getImage(name, data, 'first');
              }}
              bannerText={zones?.banners?.first?.subTitle}
              removeImage={(name, data) => {
                removeImage(name, 'first', data);
              }}
              bannerHeader={zones?.banners?.first?.title}
              handleInputChange={({ target }) => {
                handleBannerTextChange(target, 'first');
              }}
              infoBannerCheckbox={zones?.banners?.first?.showBanner}
              infoBannerMobileUrl={showImage('mobile', 'first')}
              infoBannerDesktopUrl={showImage('desktop', 'first')}
              handleCheckboxChange={e => {
                handleBannerCheckboxChange(e, 'first');
              }}
            />
          </GridContainer>
        </CardBody>
      </CardWrapper>

      <CardWrapper title={t('cms.zones.banner', 'Banner Informacyjny')}>
        <CardBody>
          <GridContainer>
            <InfoBanner
              showBannerTitle={t('cms.zones.showBaner2', 'Pokaż baner nr 2')}
              getImage={(name, data) => {
                getImage(name, data, 'second');
              }}
              bannerText={zones?.banners?.second?.subTitle}
              removeImage={(name, data) => {
                removeImage(name, 'second', data);
              }}
              bannerHeader={zones?.banners?.second?.title}
              handleInputChange={({ target }) => {
                handleBannerTextChange(target, 'second');
              }}
              infoBannerCheckbox={zones?.banners?.second?.showBanner}
              infoBannerMobileUrl={showImage('mobile', 'second')}
              infoBannerDesktopUrl={showImage('desktop', 'second')}
              handleCheckboxChange={e => {
                handleBannerCheckboxChange(e, 'second');
              }}
            />
          </GridContainer>
        </CardBody>
      </CardWrapper>

      <CardWrapper title={t('cms.zones.banner', 'Banner Informacyjny')}>
        <CardBody>
          <GridContainer>
            <InfoBanner
              showBannerTitle={t('cms.zones.showBaner3', 'Pokaż baner nr 3')}
              getImage={(name, data) => {
                getImage(name, data, 'third');
              }}
              bannerText={zones?.banners?.third?.subTitle}
              removeImage={(name, data) => {
                removeImage(name, 'third', data);
              }}
              bannerHeader={zones?.banners?.third?.title}
              handleInputChange={({ target }) => {
                handleBannerTextChange(target, 'third');
              }}
              infoBannerCheckbox={zones?.banners?.third?.showBanner}
              infoBannerMobileUrl={showImage('mobile', 'third')}
              infoBannerDesktopUrl={showImage('desktop', 'third')}
              handleCheckboxChange={e => {
                handleBannerCheckboxChange(e, 'third');
              }}
            />
          </GridContainer>
        </CardBody>
      </CardWrapper>

      <Card>
        <CardBody>
          <GridContainer>
            <GridItem sm={12}>
              <h3>Wyszukiwarka adresów</h3>
            </GridItem>
            <AddressFinderBanner
              isColorMobile={zones?.addressFinder?.isColorMobile}
              isColorDesktop={zones?.addressFinder?.isColorDesktop}
              setZones={setZones}
              handleChangeMobileBackgroundColor={
                handleChangeMobileBackgroundColor
              }
              handleChangeDesktopBackgroundColor={
                handleChangeDesktopBackgroundColor
              }
              backgroundMobile={zones?.addressFinder?.backgroundMobile}
              backgroundDesktop={zones?.addressFinder?.backgroundDesktop}
              content={zones?.addressFinder?.content}
              showBannerTitle={t(
                'cms.zones.showAddressFinder',
                'Pokazuj sekcję wyszukiwarki'
              )}
              getImage={(name, data) => {
                getImage(name, data, 'addressFinder');
              }}
              removeImage={(name, data) => {
                removeImage(name, 'addressFinder', data);
              }}
              infoBannerCheckbox={zones?.addressFinder?.showBanner}
              infoBannerMobileUrl={showImage('mobile', 'addressFinder')}
              infoBannerDesktopUrl={showImage('desktop', 'addressFinder')}
              handleCheckboxChange={e => {
                handleBannerCheckboxChange(e, 'addressFinder');
              }}
            />
          </GridContainer>
        </CardBody>
      </Card>

      <CardWrapper title={t('cms.zones.banner', 'Banner Informacyjny')}>
        <CardBody>
          <GridContainer>
            <InfoBanner
              showBannerTitle={t('cms.zones.showBaner4', 'Pokaż baner nr 4')}
              getImage={(name, data) => {
                getImage(name, data, 'fourth');
              }}
              bannerText={zones?.banners?.fourth?.subTitle}
              removeImage={(name, data) => {
                removeImage(name, 'fourth', data);
              }}
              bannerHeader={zones?.banners?.fourth?.title}
              handleInputChange={({ target }) => {
                handleBannerTextChange(target, 'fourth');
              }}
              infoBannerCheckbox={zones?.banners?.fourth?.showBanner}
              infoBannerMobileUrl={showImage('mobile', 'fourth')}
              infoBannerDesktopUrl={showImage('desktop', 'fourth')}
              handleCheckboxChange={e => {
                handleBannerCheckboxChange(e, 'fourth');
              }}
            />
          </GridContainer>
        </CardBody>
      </CardWrapper>

      <CardWrapper title={t('cms.zones.banner', 'Banner Informacyjny')}>
        <CardBody>
          <GridContainer>
            <InfoBanner
              showBannerTitle={t('cms.zones.showBaner5', 'Pokaż baner nr 5')}
              getImage={(name, data) => {
                getImage(name, data, 'fifth');
              }}
              bannerText={zones?.banners?.fifth?.subTitle}
              removeImage={(name, data) => {
                removeImage(name, 'fifth', data);
              }}
              bannerHeader={zones?.banners?.fifth?.title}
              handleInputChange={({ target }) => {
                handleBannerTextChange(target, 'fifth');
              }}
              infoBannerCheckbox={zones?.banners?.fifth?.showBanner}
              infoBannerMobileUrl={showImage('mobile', 'fifth')}
              infoBannerDesktopUrl={showImage('desktop', 'fifth')}
              handleCheckboxChange={e => {
                handleBannerCheckboxChange(e, 'fifth');
              }}
            />
          </GridContainer>
        </CardBody>
      </CardWrapper>

      <CardWrapper title={t('cms.zones.banner', 'Banner Informacyjny')}>
        <CardBody>
          <GridContainer>
            <InfoBanner
              showBannerTitle={t('cms.zones.showBaner6', 'Pokaż baner nr 6')}
              getImage={(name, data) => {
                getImage(name, data, 'sixth');
              }}
              bannerText={zones?.banners?.sixth?.subTitle}
              removeImage={(name, data) => {
                removeImage(name, 'sixth', data);
              }}
              bannerHeader={zones?.banners?.sixth?.title}
              handleInputChange={({ target }) => {
                handleBannerTextChange(target, 'sixth');
              }}
              infoBannerCheckbox={zones?.banners?.sixth?.showBanner}
              infoBannerMobileUrl={showImage('mobile', 'sixth')}
              infoBannerDesktopUrl={showImage('desktop', 'sixth')}
              handleCheckboxChange={e => {
                handleBannerCheckboxChange(e, 'sixth');
              }}
            />
          </GridContainer>
        </CardBody>
      </CardWrapper>

      <CardWrapper title={t('cms.zones.zoneSubpage')}>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => {
                      setZones(prev => ({
                        ...prev,
                        showDeliveryHours: !prev.showDeliveryHours,
                      }));
                    }}
                    checked={zones.showDeliveryHours}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.zones.showDeliveryTime')}
                  </div>
                }
              />
            </GridItem>

            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => {
                      setZones(prev => ({
                        ...prev,
                        showPrices: !prev.showPrices,
                      }));
                    }}
                    checked={zones.showPrices}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.zones.showDeliveryPrice')}
                  </div>
                }
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </CardWrapper>

      {module?.configuration?.SeoCmsZonesSubpage && (
        <SEOSettingsCms
          state={module}
          setState={setModule}
          section="SeoCmsZonesSubpage"
        />
      )}
      <FormControlButtons
        classes={classes}
        submitText={t('form.save')}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withTranslation(),
  withStyles(combinedStyles),
  connect(({ Auth: { selectedBrand } }) => ({
    selectedBrand,
  }))
);

export default enhance(ZonesCmsPage);
