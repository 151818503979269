import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import axios from 'helpers/gastro';
import { Dialog, DialogContent } from '@material-ui/core';
import { apiErrorToast } from 'utils/apiError';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PdfDialog = ({ fileUrl, isOpened = false, closeModal = () => {} }) => {
  const { t } = useTranslation();

  const [file, setFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    if (!isEmpty(fileUrl) && isOpened) {
      axios
        .get(fileUrl, {
          responseType: 'blob',
          headers: {
            accept: 'application/pdf',
          },
        })
        .then(
          response => {
            if (response.data) {
              const newFile = window.URL.createObjectURL(
                new Blob([response.data])
              );
              setFile(newFile);
            } else {
              alert(
                'Nie posiadasz uprawnień do wygenerowania tego raportu. Skontaktuj się z administratorem.'
              );
            }
          },
          error => {
            apiErrorToast(t('reports.cannotGenerate'), error);
          }
        );
    }
  }, [fileUrl]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = offset =>
    setPageNumber(prevPageNumber => prevPageNumber + offset);

  const previousPage = () => changePage(-1);
  const nextPage = () => changePage(1);

  const closePreviewModal = () => {
    setPageNumber(1);
    closeModal();
  };

  return (
    <Dialog maxWidth={'xl'} open={isOpened} onClose={closePreviewModal}>
      <DialogContent>
        <Document
          file={file}
          onLoadError={console.error}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>
        <p>
          {`${t('$*pdfDialog.page', '$$Page')} ${pageNumber} ${t(
            '$*pdfDialog.of',
            '$$of'
          )} ${numPages}`}
        </p>
        <button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
          {t('$*pdfDialog.previous', '$$Previous')}
        </button>
        <button
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          {t('$*pdfDialog.next', '$$Next')}
        </button>
      </DialogContent>
    </Dialog>
  );
};

export default PdfDialog;
