import AdminTable from 'layouts/AdminTable';
import { combineStyles } from 'helpers/helpers';
import withStyles from '@material-ui/core/styles/withStyles';

import defaultState from './defaultState';
import { connect } from 'react-redux';
import { fetchMealSize } from 'actions/MealSizes';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';

import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import FormTextInput from 'components/FormTextInput/FormTextInput';

import { post, put } from 'helpers/apiHelpers';

import { withTranslation } from 'react-i18next';
import withRole from 'components/Security/withRole';
import { ROLE_SHOW_SYSTEM_ADVANCED_DISH } from 'helpers/roles';
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';

const Form = ({ match, location, t, classes, history, fetchMealSize }) => {
  const [state, setState] = useState({
    ...defaultState,
  });

  const sizeId = match?.params?.id;
  const isEdit = location?.pathname.includes('edit');

  useEffect(() => {
    if (isEdit) {
      fetchMealSize(sizeId).then(response => {
        setState(prev => ({
          ...prev,
          name: response.name,
          nameForClient: response.nameForClient,
          position: response.position,
          portionDivision: response.portionDivision,
        }));
      });
    }
  }, []);

  const handleInputChange = event => {
    setState(prev => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const validateForm = () => {
    return (
      state.name &&
      state.nameForClient &&
      state.position !== '' &&
      state.portionDivision !== ''
    );
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return toast.error(t('form.fillAllRequiredFields'));
    }
    const data = {
      name: state.name,
      nameForClient: state.nameForClient,
      position: parseInt(state.position),
      portionDivision: parseInt(state.portionDivision),
    };

    const action = isEdit
      ? put(`/sizes/${sizeId}`, data)
      : post('/sizes', data);

    action.then(() => history.push('/admin/sizes'));
  };

  const AdvancedDishFormField = withRole(
    ROLE_SHOW_SYSTEM_ADVANCED_DISH,
    FormTextInput,
    true
  );

  return (
    <AdminTable title={t('form.addNewSize')}>
      <FormTextInput
        label={t('form.field.sizeName') + '*'}
        classes={classes}
        name="name"
        value={state.name}
        handleChange={handleInputChange}
        inputSize={4}
      />
      <FormTextInput
        label={t('form.field.nameForClient') + '*'}
        classes={classes}
        name="nameForClient"
        value={state.nameForClient}
        handleChange={handleInputChange}
        inputSize={4}
      />
      <FormTextInput
        label={t('form.field.order') + '*'}
        classes={classes}
        name="position"
        value={state.position}
        type="number"
        handleChange={handleInputChange}
        inputSize={2}
      />
      <AdvancedDishFormField
        label={t('form.field.portionDivision') + '*'}
        classes={classes}
        name="portionDivision"
        value={state.portionDivision}
        type="number"
        handleChange={handleInputChange}
        inputSize={3}
        required={true}
      />
      <FormControlButtons
        classes={classes}
        discardText={t('form.cancel')}
        submitText={t('form.save')}
        cancelPath="/admin/sizes"
        handleSubmit={handleSubmit}
      />
    </AdminTable>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => {
  return {
    mealSize: state.MealSizes.mealSize,
    loading: state.MealSizes.loading,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchMealSize: id => dispatch(fetchMealSize(id)),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(combinedStyles)(Form))
);
