import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Check } from '@material-ui/icons';
import Checkbox from '@material-ui/core/Checkbox';
import makeStyles from '@material-ui/styles/makeStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';

import includes from 'lodash/includes';
import without from 'lodash/without';

import { toast } from 'react-toastify';
import PaymentMethodManagementSection from './paymentMethodMenagementSection/PaymentMethodManagementSection';
import StandardPaymentTypesSelectItems from './defaultPaymentTypesSelectItems/StandardPaymentTypesSelectItems';
import SubscriptionPaymentTypesSelectItems from './defaultPaymentTypesSelectItems/SubscriptionPaymentTypesSelectItems';

const MODE_PAYMENT_TYPE = {
  standard: 'standard',
  subscription: 'subscription',
  sandbox: 'sandbox',
};

const useStyles = makeStyles({
  ...extendedFormsStyle,
  ...buttonsStyle,
  wrapper: {
    overflowX: 'auto',
  },
  selectCell: {
    minWidth: 280,
  },
  payUTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
});

const PaymentTypeConfig = ({
  modules,
  setModules,
  paymentTypes,
  setPaymentTypes,
}) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const validateChangeStatusPaymentType = (value, type) => {
    const {
      standard: { defaultPaymentType: standardDefaultPaymentType },
      subscription: { defaultPaymentType: subscriptionDefaultPaymentType },
    } = paymentTypes;

    const { paymentTypeStandard, paymentTypeSubscription } = value;

    if (
      (type === MODE_PAYMENT_TYPE.standard &&
        paymentTypeStandard === standardDefaultPaymentType) ||
      (type === MODE_PAYMENT_TYPE.subscription &&
        paymentTypeSubscription === subscriptionDefaultPaymentType)
    ) {
      return toast.error(
        t('errors.paymentMethod.dufaultPaymentMethod.description')
      );
    }
  };

  const validateSetDefaultPaymentTypes = (e, key) => {
    if (!paymentTypes[key].enabledPaymentTypes.includes(e.target.value)) {
      toast.error(
        t(
          'errors.paymentMethod.dufaultPaymentMethod.regular.disabledPaymentType'
        )
      );
    }
  };

  const handleChangePaymentMode = key => {
    setModules(prev => ({
      ...prev,
      ConfigClientPanel: {
        ...prev.ConfigClientPanel,
        [key]: !prev.ConfigClientPanel[key],
      },
    }));
  };

  const handleChangePaymentMethod = (paymentTypes, value, key) => {
    const enabledPaymentTypesArray = paymentTypes[key].enabledPaymentTypes;
    const newPaymentMethod = includes(enabledPaymentTypesArray, value)
      ? without(enabledPaymentTypesArray, value)
      : [...enabledPaymentTypesArray, value];

    setPaymentTypes(prev => ({
      ...prev,
      [key]: {
        ...prev?.[key],
        enabledPaymentTypes: newPaymentMethod,
      },
    }));
  };

  return (
    <div className={classes.wrapper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('paymentType.operator')}</TableCell>
            <TableCell>{t('paymentType.method')}</TableCell>
            <TableCell>
              <Checkbox
                checked={modules.ConfigClientPanel.isOneTimePayPaymentMode}
                onChange={() => {
                  handleChangePaymentMode('isOneTimePayPaymentMode');
                }}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
                disabled={!modules.ConfigClientPanel.isSubscriptionPaymentMode}
              />
              {t('paymentType.oneTimePayment')}
            </TableCell>
            <TableCell>
              <Checkbox
                checked={modules.ConfigClientPanel.isSubscriptionPaymentMode}
                onChange={() => {
                  handleChangePaymentMode('isSubscriptionPaymentMode');
                }}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
                disabled={!modules.ConfigClientPanel.isOneTimePayPaymentMode}
              />
              {t('paymentType.subscription')}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <PaymentMethodManagementSection
            classes={classes}
            modules={modules}
            paymentTypes={paymentTypes}
            setModules={setModules}
            validateChangeStatusPaymentType={validateChangeStatusPaymentType}
            handleChangePaymentMethod={handleChangePaymentMethod}
          />
          <TableRow>
            <TableCell className={classes.selectCell}>
              {t('module.configClientPanel.defaultPaymentMode')}
            </TableCell>
            <TableCell />
            <TableCell className={classes.selectCell}>
              <StandardPaymentTypesSelectItems
                classes={classes}
                paymentTypes={paymentTypes}
                validateSetDefaultPaymentTypes={validateSetDefaultPaymentTypes}
                setPaymentTypes={setPaymentTypes}
              />
            </TableCell>
            <TableCell className={classes.selectCell}>
              <SubscriptionPaymentTypesSelectItems
                classes={classes}
                paymentTypes={paymentTypes}
                validateSetDefaultPaymentTypes={validateSetDefaultPaymentTypes}
                setPaymentTypes={setPaymentTypes}
                modules={modules}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default PaymentTypeConfig;
