import { useState, useEffect } from 'react';
import produce from 'immer';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import ExtensionIcon from '@material-ui/icons/Extension';
import FormLabel from '@material-ui/core/FormLabel';
import makeStyles from '@material-ui/styles/makeStyles';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import AdminTable from 'layouts/AdminTable';
import CustomInput from 'components/CustomInput/CustomInput';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import InfoIconTooltip from 'components/InfoIconTooltip/InfoIconTooltip';

import { get, post, put } from 'helpers/apiHelpers';
import LabeledCheckbox from 'components/Checkbox/LabeledCheckbox';
import { toast } from 'react-toastify';

const useStyles = makeStyles({
  ...extendedFormsStyle,
  ...buttonsStyle,
});

const InvoiceOverrideTooltip = () => {
  const { t } = useTranslation();
  return (
    <InfoIconTooltip
      title={
        <div
          dangerouslySetInnerHTML={{
            __html: t(
              'views.addons.addonsCategories.form.field.invoiceOverride.tooltip',
              { interpolation: { escapeValue: false } }
            ),
          }}
        ></div>
      }
    />
  );
};

const initialFormData = {
  name: '',
  position: 0,
  limitForDiet: 0,
  invoiceOverride: false,
  invoiceOverrideName: null,
  active: true,
  isMarkedAsExcludedCategory: false,
};

const schema = yup.object().shape({
  name: yup.string().required(),
});

const AddonsCategoriesForm = ({ history, location, match }) => {
  const isEdit = location.pathname.includes('edit');
  const addonCategoryId = match.params.id;

  const classes = useStyles();

  const { t } = useTranslation();
  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    const fetchData = async () => {
      if (isEdit) {
        try {
          const response = await get(`/addon-categories/${addonCategoryId}`);
          const {
            '@id': iriId,
            '@type': iriType,
            createdAt,
            updatedAt,
            ...onlyFormData
          } = response;

          setFormData({
            ...onlyFormData,
          });
        } catch (error) {}
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (formData.invoiceOverride) {
      if (!formData.invoiceOverrideName) {
        setFormData(prevData => ({
          ...prevData,
          invoiceOverrideName: prevData.name,
        }));
      }
    } else {
      setFormData(prevData => ({ ...prevData, invoiceOverrideName: null }));
    }
  }, [formData.invoiceOverride]);

  const handleChange = ({ target: { name, value } }) => {
    setFormData(
      produce(draft => {
        draft[name] = value;
      })
    );
  };

  const handleCheckboxClick = ({ target: { name } }) => {
    setFormData(
      produce(draft => {
        draft[name] = !draft[name];
      })
    );
  };

  const handleSubmit = async () => {
    const isValid = schema.isValidSync(formData);
    if (!isValid) {
      return toast.error(t('form.fillAllRequiredFields'));
    }

    try {
      const { position, limitForDiet, ...restFormData } = formData;
      const data = {
        ...restFormData,
        position: parseInt(position),
        limitForDiet: parseInt(limitForDiet),
      };

      isEdit
        ? await put(`/addon-categories/${addonCategoryId}`, data)
        : await post('/addon-categories', data);

      isEdit
        ? history.push('/admin/addon-categories')
        : setFormData(initialFormData);

      !isEdit && toast.success(t('status.success.save'));
    } catch ({ response: { data = {}, status } }) {
      if (status === 500) {
        return toast.error(
          `${t('status.error.serverNotResponding')} ${t(
            'status.error.tryAgainLater'
          )}`
        );
      }

      toast.error(
        `${t('status.error.somethingWentWrong')} ${t(
          'status.error.fillCorrectForm'
        )}`
      );
    }
  };

  return (
    <AdminTable
      title={
        isEdit
          ? t('views.addons.addonsCategories.editCategory')
          : t('views.addons.addonsCategories.newCategory')
      }
      isMarginLeft={true}
      iconComponent={<ExtensionIcon />}
    >
      <GridContainer>
        <GridItem sm={4}>
          <FormLabel className={classes.labelHorizontal}>
            {t('form.field.name.label')} *
          </FormLabel>
          <CustomInput
            formControlProps={{ fullWidth: true }}
            inputProps={{
              name: 'name',
              value: formData.name,
              onChange: handleChange,
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem sm={4}>
          <FormLabel className={classes.labelHorizontal}>
            {t('form.field.priority.label')}
            <InfoIconTooltip title={t('form.field.priority.tooltip')} />
          </FormLabel>
          <CustomInput
            formControlProps={{ fullWidth: true }}
            inputProps={{
              name: 'position',
              value: formData.position,
              onChange: handleChange,
              type: 'number',
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem sm={4}>
          <FormLabel className={classes.labelHorizontal}>
            {t('form.field.limitForDiet.label')}
            <InfoIconTooltip title={t('form.field.limitForDiet.tooltip')} />
          </FormLabel>
          <CustomInput
            formControlProps={{ fullWidth: true }}
            inputProps={{
              name: 'limitForDiet',
              value: formData.limitForDiet,
              onChange: handleChange,
              type: 'number',
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem sm={4}>
          <LabeledCheckbox
            name="active"
            onClick={handleCheckboxClick}
            checked={formData.active}
            label={
              <>
                {t('form.field.active2.label')}
                <InfoIconTooltip
                  title={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t(
                          'views.addons.addonsCategories.form.field.active.tooltip',
                          { interpolation: { escapeValue: false } }
                        ),
                      }}
                    />
                  }
                />
              </>
            }
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem sm={4}>
          <LabeledCheckbox
            name="isMarkedAsExcludedCategory"
            onClick={handleCheckboxClick}
            checked={formData.isMarkedAsExcludedCategory}
            label={t('form.field.isMarkedAsExcludedCategory.label')}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem sm={4}>
          <LabeledCheckbox
            name="invoiceOverride"
            onClick={handleCheckboxClick}
            checked={formData.invoiceOverride}
            label={
              <>
                {formData.invoiceOverride && (
                  <div>
                    <FormLabel className={classes.labelHorizontal}>
                      {t(
                        'views.addons.addonsCategories.form.field.invoiceOverride.label'
                      )}{' '}
                      *
                      <InvoiceOverrideTooltip />
                    </FormLabel>
                    <CustomInput
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        name: 'invoiceOverrideName',
                        value: formData.invoiceOverrideName,
                        onChange: handleChange,
                      }}
                    />
                  </div>
                )}
                {!formData.invoiceOverride && (
                  <>
                    {t(
                      'views.addons.addonsCategories.form.field.invoiceOverride.label'
                    )}
                    <InvoiceOverrideTooltip />
                  </>
                )}
              </>
            }
          />
        </GridItem>
      </GridContainer>
      <FormControlButtons
        discardText={t('form.cancel')}
        submitText={t('form.save')}
        cancelPath="/admin/addon-categories"
        handleSubmit={handleSubmit}
      />
    </AdminTable>
  );
};

export default AddonsCategoriesForm;
