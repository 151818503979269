import { useState, useEffect } from 'react';

import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import Card from 'components/Card/Card';
import React from 'react';
import { combineStyles } from 'helpers/helpers';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { fetchBrand, fetchBrandConfigModulesPack } from 'actions/Brands';
import withStyles from '@material-ui/core/styles/withStyles';
import { put } from 'helpers/apiHelpers';
import { DialogLoader } from 'components/DialogLoader';
import { toast } from 'react-toastify';

const EcoContainers = ({ classes, t, fetchBrand, selectedBrand }) => {
  const [ecoContainer, setEcoContainer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const brandConfig = await fetchBrand(selectedBrand);
      setEcoContainer(brandConfig.ecoContainer);
      setIsLoading(false);
    })();
  }, []);

  const handleSubmit = async () => {
    try {
      await put(`/brands/${selectedBrand}`, { ecoContainer });

      toast.success(t('success.changesSaved'));
    } catch (e) {
      toast.error(t('notify.cannotSave'));
    }
  };

  return (
    <>
      <DialogLoader
        loaderState={isLoading}
        text={t('common.shared.isLoading')}
      />

      {!isLoading && (
        <>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ecoContainer}
                        onChange={() => {
                          setEcoContainer(!ecoContainer);
                        }}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={t('brands.newBrandForm.eco', 'Oferuj Eko pojemniki')}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>

          <FormControlButtons
            classes={classes}
            submitText={t('common.shared.save', 'Zapisz')}
            handleSubmit={handleSubmit}
          />
        </>
      )}
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withTranslation(),
  connect(
    ({ Auth: { selectedBrand } }) => ({
      selectedBrand,
    }),
    { fetchBrand, fetchBrandConfigModulesPack }
  ),
  withStyles(combinedStyles)
);

export default enhance(EcoContainers);
