import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { FormLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import EXTERNAL_URLS from 'constants/externalUrls';
import { FakeUserIcon } from 'components';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import InfoWithTooltip from 'components/InfoWithTooltip/InfoWithTooltip';
import SelectInput from 'components/FormSelect/SelectInput';
import { useTranslatedOrderStatuses } from 'views/Orders/Orders/statusTranslations';

import { orderStyles } from '../../styles';
import { toast } from 'react-toastify';

const getStatusInfoColor = systemValue => {
  switch (systemValue) {
    case 'WAITING_FOR_PAYMENT':
      return 'orange';
    case 'CANCELED':
      return 'red';
    case 'PAID':
    default:
      return 'green';
  }
};

const getLabeledId = id => {
  return `ID #${id}`;
};

const useOrderStyles = makeStyles(orderStyles);
const useStyles = (systemValue => {
  return makeStyles({
    wrapper: {
      marginLeft: '5px',
      marginTop: '15px',
    },
    id: {
      display: 'block',
    },
    status: {
      color: getStatusInfoColor(systemValue),
    },
    infoPills: {
      marginTop: '25px',
    },
    infoIcon: {
      position: 'relative',
      top: '3px',
      marginLeft: '4px',
    },
    checkOnlyActiveInput: {
      marginRight: '10px',
    },
    checkOnlyActiveLabel: { userSelect: 'none' },
  });
})();

const DietDetailsHeader = ({
  classes: propClasses,
  dietInfo,
  client,
  history,
  allDiets,
  orderInfo,
  fetchOnDietChange,
}) => {
  const { t } = useTranslation();

  const orderClasses = useOrderStyles();
  const classes = useStyles(orderInfo.status.systemValue);

  const orderStatuses = useTranslatedOrderStatuses();

  const [onlyActive, setOnlyActive] = useState(false);

  const activeDiet = {
    label: dietInfo.active ? (
      <b>
        {getLabeledId(dietInfo.id)} {t('diets.active', 'Aktywna')}
      </b>
    ) : (
      <span>
        {getLabeledId(dietInfo.id)} {t('diets.inactive', 'Nieaktywna')}
      </span>
    ),
    id: dietInfo.id,
    value: dietInfo['@id'],
  };

  const dietsToShow = onlyActive
    ? allDiets.filter(diet => diet.active)
    : allDiets;

  const allDietsAreInactive = !dietsToShow.some(diet => diet.active);

  const domain = dietInfo?.order?.comingFromDomain ?? client.homeDomain;

  const clientEditUrl = `/admin/clients/edit/${client.id}`;

  return (
    <div className={classes.wrapper}>
      <GridContainer>
        <GridItem md={2}>
          <FormLabel>{t('common.client', 'Klient')}</FormLabel>
          <h1>
            <b>
              <NavLink to={clientEditUrl}>
                {client.firstName} {client.lastName}
              </NavLink>
              <FakeUserIcon email={client.email} domain={domain} />
            </b>
            <div>
              <NavLink to={clientEditUrl}>{getLabeledId(client.id)}</NavLink>
            </div>
          </h1>
        </GridItem>
        <GridItem md={2}>
          <FormLabel>{t('diets.diet', 'Dieta')}</FormLabel>
          <span>
            <h1>
              <b>{dietInfo?.diet?.name}</b>
              <span className={classes.id}>{getLabeledId(dietInfo.id)}</span>
            </h1>
          </span>
        </GridItem>
        <GridItem md={2}>
          <FormLabel>{t('orders.order', 'Zamówienie')}</FormLabel>
          <span>
            <h1>
              <b>
                {dietInfo.isTest && t('common.trial', 'Próbne')}{' '}
                <span className={classes.status}>
                  {orderStatuses[orderInfo.status.systemValue]}
                </span>
              </b>
              <span className={classes.id}>
                {getLabeledId(dietInfo.order.id)}
              </span>
            </h1>
          </span>
        </GridItem>
        <GridItem md={4}>
          <div className={classes.infoPills}>
            {dietInfo.variant && (
              <div className={orderClasses.infoPill}>
                {dietInfo.variant?.name}
              </div>
            )}
            {dietInfo.calorific && (
              <div className={orderClasses.infoPill}>
                {dietInfo.calorific?.name}
              </div>
            )}
            {!!dietInfo.dietLength && (
              <div className={orderClasses.infoPill}>
                {dietInfo.dietLength} {t('brandCfg.days', 'dni')}
              </div>
            )}
          </div>
        </GridItem>
        <GridItem md={2}>
          <FormLabel className={classes.orderedDietLabel}>
            {t('dietDetails.orderedDiet', 'Zamówiona dieta')}
            <InfoWithTooltip
              className={classes.infoIcon}
              link={EXTERNAL_URLS.whyEndedDietIsStillActive}
              title={t('dietDetails.orderedDietInfo')}
              size="s"
            />
          </FormLabel>
          <SelectInput
            classes={propClasses}
            mapBy="label"
            trackBy="value"
            placeholder={t('common.dietPrices.selectDiet', 'Wybierz dietę')}
            label={
              <div>
                <input
                  className={classes.checkOnlyActiveInput}
                  id="onlyActiveCheck"
                  checked={onlyActive}
                  onChange={() => {
                    allDietsAreInactive
                      ? toast.warning(t('dietDetails.noDietsForThisUser'))
                      : setOnlyActive(!onlyActive);
                  }}
                  type="checkbox"
                />
                <label
                  className={classes.checkOnlyActiveLabel}
                  htmlFor="onlyActiveCheck"
                >
                  {t('dietDetails.showOnlyActive', 'Pokaż tylko aktywne')}
                </label>
              </div>
            }
            options={dietsToShow}
            value={activeDiet}
            handleChange={(e, obj) => {
              history.push(`/admin/ecommerce-diets/preview/${obj.id}`);
              fetchOnDietChange(obj.id);
            }}
            size={12}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default DietDetailsHeader;
