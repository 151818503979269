import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ModalButton from '../../../components/CustomButtons/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import buttonsStyle from '../../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import IdDetails from './IdDetails';
import { ROLE_SHOW_ORDER, ROLE_SHOW_BAG } from 'helpers/roles';
import { AddAlertRounded } from '@material-ui/icons';
import ErrorsModal from './ErrorsModal';
import makeStyles from '@material-ui/styles/makeStyles';
import useModalController from '../../../hooks/common/useModalController';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  ...buttonsStyle,
  tableHeaderCell: {
    textAlign: 'center',
    border: '1px solid rgb(224, 224, 224)',
  },
  tableBodyCell: {
    textAlign: 'center',
    border: '1px solid rgb(224, 224, 224)',
  },
  closeButton: {
    position: 'absolute',
    top: '0',
    right: '0',
    cursor: 'pointer',
  },
  dialog: {
    padding: '6px 24px 24px 24px',
  },
  center: {
    textAlign: 'center',
  },
});

const MappingDetails = ({ row }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isOpen, open, close } = useModalController();

  const hasErrors = row.mapping.some(({ errors }) => errors.length > 0);

  return (
    <>
      <Dialog maxWidth={'xl'} open={isOpen} onClose={close}>
        <DialogContent>
          <ModalButton
            className={classes.closeButton}
            color={'transparent'}
            justIcon
            round
            onClick={close}
          >
            ×
          </ModalButton>
        </DialogContent>
        <DialogContent classes={{ root: classes.dialog }}>
          <Paper>
            <Table className={classes.center}>
              <TableHead>
                <TableRow className={classes.center}>
                  <TableCell colSpan={2} className={classes.tableHeaderCell}>
                    {t('massAction.dietElements.diet')}
                  </TableCell>
                  <TableCell colSpan={2} className={classes.tableHeaderCell}>
                    {t('massAction.dietElements.variant')}
                  </TableCell>
                  <TableCell colSpan={2} className={classes.tableHeaderCell}>
                    {t('massAction.dietElements.calorific')}
                  </TableCell>
                  <TableCell colSpan={5} className={classes.tableHeaderCell}>
                    {t('massAction.dietElements.statistics')}
                  </TableCell>
                </TableRow>
                <TableRow className={classes.center}>
                  <TableCell className={classes.tableHeaderCell}>
                    {t('massAction.dietElements.from')}
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    {t('massAction.dietElements.to')}
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    {t('massAction.dietElements.from')}
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    {t('massAction.dietElements.to')}
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    {t('massAction.dietElements.from')}
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    {t('massAction.dietElements.to')}
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    {t('massAction.dietElements.countBags')}
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    {t('massAction.dietElements.countDiets')}
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    {t('massAction.dietElements.countSubscriptions')}
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    {t('massAction.dietElements.countIntents')}
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    {t('common.errors')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {row.mapping.map(
                  ({
                    from,
                    to,
                    alreadyChangedBags,
                    alreadyChangedDiets,
                    alreadyChangedSubscription,
                    alreadyChangedSubscriptionIntents,
                    shouldChangeBags,
                    shouldChangeDiets,
                    shouldChangeSubscription,
                    shouldChangeSubscriptionIntents,
                    errors,
                  }) => (
                    <TableRow>
                      <TableCell className={classes.tableBodyCell}>
                        {from?.diet?.name ? from.diet.name : null}
                      </TableCell>
                      <TableCell className={classes.tableBodyCell}>
                        {to.diet.name}
                      </TableCell>
                      <TableCell className={classes.tableBodyCell}>
                        {from?.variant?.name ? from.variant.name : null}
                      </TableCell>
                      <TableCell className={classes.tableBodyCell}>
                        {to?.variant?.name}
                      </TableCell>
                      <TableCell className={classes.tableBodyCell}>
                        {from?.calorific?.name ? from.calorific.name : null}
                      </TableCell>
                      <TableCell className={classes.tableBodyCell}>
                        {to?.calorific?.name}
                      </TableCell>
                      <TableCell className={classes.tableBodyCell}>
                        {alreadyChangedBags.length}/{shouldChangeBags.length}
                        {shouldChangeBags.length > 0 && (
                          <IdDetails
                            linkRole={ROLE_SHOW_BAG}
                            previewLink={'/admin/ecommerce-bags'}
                            source={shouldChangeBags}
                            finished={alreadyChangedBags}
                          />
                        )}
                      </TableCell>
                      <TableCell className={classes.tableBodyCell}>
                        {alreadyChangedDiets.length}/{shouldChangeDiets.length}
                        {shouldChangeDiets.length > 0 && (
                          <IdDetails
                            linkRole={ROLE_SHOW_ORDER}
                            previewLink={'/admin/ecommerce-diets'}
                            source={shouldChangeDiets}
                            finished={alreadyChangedDiets}
                          />
                        )}
                      </TableCell>
                      <TableCell className={classes.tableBodyCell}>
                        {alreadyChangedSubscription.length}/
                        {shouldChangeSubscription.length}
                        {shouldChangeSubscription.length > 0 && (
                          <IdDetails
                            linkRole={ROLE_SHOW_ORDER}
                            previewLink={'/admin/subscriptions'}
                            previewKey={'clientDiet.id'}
                            source={shouldChangeSubscription}
                            finished={alreadyChangedSubscription}
                          />
                        )}
                      </TableCell>
                      <TableCell className={classes.tableBodyCell}>
                        {alreadyChangedSubscriptionIntents.length}/
                        {shouldChangeSubscriptionIntents.length}
                        {shouldChangeSubscriptionIntents.length > 0 && (
                          <IdDetails
                            source={shouldChangeSubscriptionIntents}
                            finished={alreadyChangedSubscriptionIntents}
                          />
                        )}
                      </TableCell>
                      <TableCell className={classes.tableBodyCell}>
                        {errors.length > 0 ? (
                          <ErrorsModal errors={errors} />
                        ) : (
                          t('common.noErrors')
                        )}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </Paper>
        </DialogContent>
      </Dialog>
      <Button onClick={open}>
        {hasErrors && <AddAlertRounded color="error" />}{' '}
        {t('massAction.dietElements.mapping')}
      </Button>
    </>
  );
};

export default MappingDetails;
