import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import isEmpty from 'lodash/isEmpty';

import EditIcon from '@material-ui/icons/Edit';
import FormLabel from '@material-ui/core/FormLabel';
import NumberFormat from 'react-number-format';
import MaterialButton from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import FormTextInputNoGrid from 'components/FormTextInput/FormTextInputNoGrid';

import { goldColor } from 'assets/jss/material-dashboard-pro-react';
import BoxSection from '../BoxSection';
import { toast } from 'react-toastify';

const useStyles = makeStyles({
  customerInvoiceDataWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  invoiceDataWrapper: {
    paddingTop: '15px',
    fontWeight: '400',
  },
  invoiceDataFormWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 -5px',
  },
  invoiceDataFormInputWrapper: {
    width: '50%',
    padding: '5px',
    display: 'flex',
  },
  invoiceDataFormInputWrapperNarrow: {
    width: '33%',
  },
  saveButton: {
    marginTop: '15px',
    color: goldColor,
    borderColor: goldColor,
  },
  editIcon: {
    width: '15px',
    cursor: 'pointer',
    color: goldColor,
  },
});

const CustomerInvoiceSection = ({
  classes: propClasses,
  customerInvoiceData = {},
  setCustomerInvoiceData = () => {},
} = {}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const [isEditMode, setIsEditMode] = useState(false);
  const [editModeInvoiceData, setEditModeInvoiceData] = useState({
    invoiceType: '',
    invoiceCompany: '',
    invoiceVatNumber: '',
    invoiceAddressCity: '',
    invoiceAddressStreet: '',
    invoiceAddressPostCode: '',
    invoiceAddressBuildNumber: '',
    invoiceAddressPlaceNumber: '',
  });

  useEffect(() => {
    if (!isEmpty(customerInvoiceData)) {
      setEditModeInvoiceData(customerInvoiceData);
      setIsEditMode(false);
    } else {
      setEditModeInvoiceData({
        invoiceType: '',
        invoiceCompany: '',
        invoiceVatNumber: '',
        invoiceAddressCity: '',
        invoiceAddressStreet: '',
        invoiceAddressPostCode: '',
        invoiceAddressBuildNumber: '',
        invoiceAddressPlaceNumber: '',
      });
      setIsEditMode(true);
    }
  }, [customerInvoiceData]);

  const validateInvoiceData = invoiceData => {
    const {
      invoiceCompany,
      invoiceVatNumber,
      invoiceAddressCity,
      invoiceAddressStreet,
      invoiceAddressPostCode,
      invoiceAddressBuildNumber,
    } = invoiceData ?? {};

    return (
      !isEmpty(invoiceCompany) &&
      !isEmpty(invoiceVatNumber) &&
      !isEmpty(invoiceAddressCity) &&
      !isEmpty(invoiceAddressStreet) &&
      !isEmpty(invoiceAddressPostCode) &&
      !isEmpty(invoiceAddressBuildNumber)
    );
  };

  const updateInvoiceData = () => {
    const isInvoiceDataValid = validateInvoiceData(editModeInvoiceData);

    if (!isInvoiceDataValid) {
      return toast.error(
        t('orders.customerInvoiceSection.fillAllRequiredData')
      );
    }

    setCustomerInvoiceData(editModeInvoiceData);
    setIsEditMode(false);
  };

  const invoiceDataFormInputWrapperNarrowClasses = classNames(
    classes.invoiceDataFormInputWrapper,
    classes.invoiceDataFormInputWrapperNarrow
  );

  return (
    <div>
      <BoxSection title={t('orders.invoiceData', '$$Dane do faktury')}>
        {isEditMode ? (
          <div className={classes.invoiceDataFormWrapper}>
            <div className={classes.invoiceDataFormInputWrapper}>
              <FormLabel className={propClasses.labelHorizontal}>
                {t('orders.companyName')}*
              </FormLabel>
              <FormTextInputNoGrid
                data-cy="new-order_billing_company-name"
                classes={propClasses}
                value={editModeInvoiceData?.invoiceCompany}
                handleChange={ev =>
                  setEditModeInvoiceData({
                    ...editModeInvoiceData,
                    invoiceCompany: ev.target.value,
                  })
                }
              />
            </div>
            <div className={classes.invoiceDataFormInputWrapper}>
              <FormLabel className={propClasses.labelHorizontal}>
                {t('orders.taxId')}*
              </FormLabel>
              <FormTextInputNoGrid
                data-cy="new-order_billing_company-number"
                classes={propClasses}
                value={editModeInvoiceData?.invoiceVatNumber}
                handleChange={ev =>
                  setEditModeInvoiceData({
                    ...editModeInvoiceData,
                    invoiceVatNumber: ev.target.value,
                  })
                }
              />
            </div>
            <div className={classes.invoiceDataFormInputWrapper}>
              <FormLabel className={propClasses.labelHorizontal}>
                {t('orders.city')}*
              </FormLabel>
              <FormTextInputNoGrid
                data-cy="new-order_billing_city"
                classes={propClasses}
                value={editModeInvoiceData?.invoiceAddressCity}
                handleChange={ev =>
                  setEditModeInvoiceData({
                    ...editModeInvoiceData,
                    invoiceAddressCity: ev.target.value,
                  })
                }
              />
            </div>
            <div className={classes.invoiceDataFormInputWrapper}>
              <FormLabel className={propClasses.labelHorizontal}>
                {t('orders.street')}*
              </FormLabel>
              <FormTextInputNoGrid
                data-cy="new-order_billing_street"
                classes={propClasses}
                value={editModeInvoiceData?.invoiceAddressStreet}
                handleChange={ev =>
                  setEditModeInvoiceData({
                    ...editModeInvoiceData,
                    invoiceAddressStreet: ev.target.value,
                  })
                }
              />
            </div>
            <div className={invoiceDataFormInputWrapperNarrowClasses}>
              <FormLabel className={propClasses.labelHorizontal}>
                {t('orders.postcode')}*
              </FormLabel>
              <NumberFormat
                data-cy="new-order_billing_postcode"
                classes={propClasses}
                customInput={FormTextInputNoGrid}
                value={editModeInvoiceData?.invoiceAddressPostCode}
                format="##-###"
                onChange={ev =>
                  setEditModeInvoiceData({
                    ...editModeInvoiceData,
                    invoiceAddressPostCode: ev.target.value,
                  })
                }
              />
            </div>
            <div className={invoiceDataFormInputWrapperNarrowClasses}>
              <FormLabel className={propClasses.labelHorizontal}>
                {t('orders.buildingNo')}*
              </FormLabel>
              <FormTextInputNoGrid
                data-cy="new-order_billing_build-number"
                classes={propClasses}
                value={editModeInvoiceData?.invoiceAddressBuildNumber}
                handleChange={ev =>
                  setEditModeInvoiceData({
                    ...editModeInvoiceData,
                    invoiceAddressBuildNumber: ev.target.value,
                  })
                }
              />
            </div>
            <div className={invoiceDataFormInputWrapperNarrowClasses}>
              <FormLabel className={propClasses.labelHorizontal}>
                {t('orders.localNo')}
              </FormLabel>
              <FormTextInputNoGrid
                data-cy="new-order_billing_locale-number"
                classes={propClasses}
                value={editModeInvoiceData?.invoiceAddressPlaceNumber}
                handleChange={ev =>
                  setEditModeInvoiceData({
                    ...editModeInvoiceData,
                    invoiceAddressPlaceNumber: ev.target.value,
                  })
                }
              />
            </div>
            <MaterialButton
              className={classes.saveButton}
              data-cy="new-order_billing_confirm-button"
              variant={'outlined'}
              fullWidth
              onClick={updateInvoiceData}
            >
              {t('orders.save', '$$Zapisz')}
            </MaterialButton>
          </div>
        ) : (
          <div className={classes.customerInvoiceDataWrapper}>
            <div className={classes.customerInvoiceDataWrapper}>
              <p>{`${customerInvoiceData?.invoiceCompany}, ${customerInvoiceData?.invoiceVatNumber}`}</p>
              <p>{`${customerInvoiceData?.invoiceAddressStreet} ${
                customerInvoiceData?.invoiceAddressBuildNumber
              }${
                customerInvoiceData?.invoiceAddressPlaceNumber
                  ? `/${customerInvoiceData?.invoiceAddressPlaceNumber}`
                  : ''
              }, ${customerInvoiceData?.invoiceAddressPostCode} ${
                customerInvoiceData?.invoiceAddressCity
              }`}</p>
            </div>
            <EditIcon
              className={classes.editIcon}
              onClick={() => setIsEditMode(true)}
            />
          </div>
        )}
      </BoxSection>
    </div>
  );
};

export default CustomerInvoiceSection;
