import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { combineStyles } from 'helpers/helpers';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';

import {
  fetchBrandConfigModulesPack,
  updateBrandConfigModulesPack,
} from '../../actions/Brands';

import GridItem from 'components/Grid/GridItem';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import { DialogLoader } from 'components/DialogLoader';
import CardWrapper from 'components/Card/CardWrapper';
import { toast } from 'react-toastify';

const PowerBI = ({
  classes,
  selectedBrand,
  fetchBrandConfigModulesPack,
  updateBrandConfigModulesPack,
}) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [modules, setModules] = useState({});

  useEffect(() => {
    fetchModules();
  }, []);

  const fetchModules = async () => {
    const modulesConfig = await fetchBrandConfigModulesPack(selectedBrand, [
      'PowerBI',
    ]);

    setModules(modulesConfig?.configuration ?? {});
    setIsLoading(false);
  };

  const handleInputChange = e => {
    setModules({
      ...modules,
      PowerBI: {
        ...modules?.PowerBI,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleSubmit = async () => {
    try {
      await updateBrandConfigModulesPack(selectedBrand, modules);

      toast.success(t('success.changesSaved'));
    } catch (error) {
      toast.error(
        error?.message
          ? `${t('errors.modulesCouldNotBeSaved')} ${error.message}`
          : t('errors.modulesCouldNotBeSaved')
      );
    }
  };

  return (
    <>
      <DialogLoader
        loaderState={isLoading}
        text={t('common.shared.isLoading')}
      />
      {!isLoading && (
        <CardWrapper title={'Iframe Code'}>
          <CardBody>
            <GridContainer>
              <GridItem md={6}>
                <FormTextInput
                  multiline
                  classes={classes}
                  name="iframeCode"
                  value={modules?.PowerBI?.iframeCode ?? ''}
                  maxLength={5000}
                  rows={10}
                  handleChange={handleInputChange}
                  inputSize={12}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </CardWrapper>
      )}
      <FormControlButtons
        classes={classes}
        submitText={t('common.shared.save', 'Zapisz')}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  connect(({ Auth: { selectedBrand } }) => ({ selectedBrand }), {
    fetchBrandConfigModulesPack,
    updateBrandConfigModulesPack,
  }),
  withStyles(combinedStyles)
);

export default enhance(PowerBI);
