import React from 'react';
import Button from 'components/CustomButtons/Button';
import ModalButton from 'components/CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { combineStyles } from 'helpers/helpers';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ArchiveIcon from '@material-ui/icons/Archive';
import Archive from '../Archive/Archive';
import AdminTable from '../../layouts/AdminTable';

class DataGridArchive extends React.Component {
  state = {
    menuTemplateName: '',
    archiveOpened: false,
    loading: false,
  };

  setLoading = value =>
    this.setState(prevState => ({ ...prevState, loading: value }));

  closeModal = () =>
    this.setState(prevState => ({
      ...prevState,
      archiveOpened: false,
    }));

  openModal = () =>
    this.setState(prevState => ({ ...prevState, archiveOpened: true }));

  render() {
    if (!this.props.reportName) {
      return null;
    }

    return (
      <>
        <Dialog
          maxWidth={'xl'}
          open={this.state.archiveOpened}
          onClose={this.closeModal}
        >
          <DialogContent>
            <ModalButton
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                cursor: 'pointer',
              }}
              color={'transparent'}
              justIcon
              round
              onClick={this.closeModal}
            >
              &times;
            </ModalButton>
          </DialogContent>
          <DialogContent style={{ width: '100%', minWidth: '990px' }}>
            <AdminTable
              title={this.props.t('reportArchive')}
              iconComponent={<ArchiveIcon />}
            >
              <Archive opened={true} reportName={[this.props.reportName]} />
            </AdminTable>
          </DialogContent>
        </Dialog>
        <div
          style={{
            opacity: this.state.loading ? 0 : 1,
            transition: '0.5s all',
            marginLeft: '4px',
            marginRight: '2px',
          }}
        >
          <Button onClick={this.openModal}>
            <ArchiveIcon />
          </Button>
        </div>
      </>
    );
  }
}

const combinedStyles = combineStyles(buttonsStyle, extendedFormsStyle);

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({});

export default withTranslation()(
  withStyles(combinedStyles)(
    connect(mapStateToProps, mapDispatchToProps)(DataGridArchive)
  )
);
