import { useState } from 'react';
import Accordion from 'components/Accordion/Accordion';
import { Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { orderStyles } from '../../styles';
import { isGranted } from 'helpers/helpers';
import {
  ROLE_SHOW_SMS,
  ROLE_CREATE_SMS,
  ROLE_SHOW_EMAIL,
  ROLE_CREATE_EMAIL,
} from 'helpers/roles';
import SMSModal from './smsModal';
import { useTranslation } from 'react-i18next';
import NewEmailModal from './NewEmailModal';

const DietDetailsClientData = ({ client, classes }) => {
  // sms hooks
  const [SMSModalStatus, setSMSModalStatus] = useState(false);
  const [EmailModalStatus, setEmailModalStatus] = useState(false);
  const { t } = useTranslation();

  return (
    <div style={{ marginLeft: '5px', ...orderStyles.box }}>
      {/*SMS modal*/}
      {SMSModalStatus ? (
        <SMSModal
          setSMSModalStatus={setSMSModalStatus}
          SMSModalStatus={SMSModalStatus}
          client={client}
          classes={classes}
          t={t}
        />
      ) : null}
      {/*SMS Modal end*/}
      {EmailModalStatus ? (
        <NewEmailModal
          setEmailModalStatus={setEmailModalStatus}
          EmailModalStatus={EmailModalStatus}
          client={client}
          classes={classes}
        />
      ) : null}
      {/*Accordion*/}
      <Accordion
        active={0}
        collapses={[
          {
            title: t('common.contactData', 'Dane kontaktowe'),
            content: (
              <div style={{ width: '100%' }}>
                <div>
                  <h5>
                    {t('labels.phneNumberLabel', 'Telefon')}: <br />
                    {client.phone.number}
                  </h5>
                  <Divider style={{ marginBottom: '5px' }} />
                  {(isGranted(ROLE_CREATE_SMS) || isGranted(ROLE_SHOW_SMS)) && (
                    <Button
                      style={{ width: '100%' }}
                      onClick={() => setSMSModalStatus(true)}
                    >
                      {isGranted(ROLE_CREATE_SMS)
                        ? t('sms.send', 'Wyślij SMS')
                        : t('form.showHistory', 'Pokaż historię')}
                    </Button>
                  )}
                </div>
                <div>
                  <h5>
                    {t('clients.email', 'Email')}: <br />
                    {client.email}
                  </h5>
                  <Divider style={{ marginBottom: '5px' }} />
                  {(isGranted(ROLE_CREATE_EMAIL) ||
                    isGranted(ROLE_SHOW_EMAIL)) && (
                    <Button
                      style={{ width: '100%' }}
                      onClick={() => {
                        setEmailModalStatus(true);
                      }}
                    >
                      {isGranted(ROLE_CREATE_EMAIL)
                        ? t('emailModal.sendEmail')
                        : t('form.showHistory')}
                    </Button>
                  )}
                </div>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default DietDetailsClientData;
