import { Component } from 'react';
import ReactTable from 'react-table';
// core components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import TableActions from 'components/DataTable/Actions';
import Danger from 'components/Typography/Danger';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core/index';
import { Help, FormatListBulleted, ListAlt } from '@material-ui/icons';
import { toast } from 'react-toastify';

class IngredientsRecipesTable extends Component {
  renderIngredients = () => {
    return this.props.data.map((item, ingredientIndex) => {
      const isRecipe = item.recipe !== null;
      const currentItem = isRecipe ? item.recipe : item.ingredient;
      const glycemicIndex = currentItem?.macrosAfterProcessing?.glycemicIndex;
      const ingredientNoGlycemicIndex = glycemicIndex === null;
      const { t } = this.props;
      const glycemicIndexValue = () => {
        if (ingredientNoGlycemicIndex) {
          const translateText = isRecipe
            ? t('glycemicIndex.recipeIngredientNoIG')
            : t('glycemicIndex.ingredientNoIG');

          return (
            <Tooltip title={<h4>{translateText}</h4>}>
              <Help
                style={{
                  fontSize: '16px',
                  color: 'grey',
                }}
              />
            </Tooltip>
          );
        }

        return glycemicIndex;
      };

      const getNutritionalValue = (type, unit) => {
        const value = currentItem?.macrosAfterProcessing?.[type];

        if (value !== null) {
          const parsedValue = isRecipe
            ? (
                (parseFloat(value) /
                  parseFloat(currentItem.macrosAfterProcessing.weight)) *
                100
              ).toFixed(2)
            : parseFloat(value).toFixed(2);

          return `${parsedValue} ${unit}`;
        } else {
          return '-';
        }
      };

      if (currentItem?.['@type'] === 'Ingredient' && !currentItem?.unit) {
        toast.error(this.props.t('form.ingredientsDetails.unitHasBeenRemoved'));
      }

      return {
        workName: (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '10px',
            }}
          >
            <TableActions handleRemove={() => this.props.handleRemove(item)} />
            <span style={{ margin: '0 5px 0 -15px', color: '#888888' }}>
              {currentItem?.['@type'] === 'Ingredient' && (
                <FormatListBulleted fontSize={'small'} />
              )}
              {currentItem?.['@type'] === 'Recipe' && <ListAlt />}
            </span>
            <NavLink
              target={'_blank'}
              to={`/admin/${
                currentItem?.['@type'] === 'Ingredient'
                  ? 'ingredients'
                  : 'recipes'
              }/edit/${currentItem?.id}`}
            >
              {currentItem.workName ? currentItem.workName : currentItem.name}
            </NavLink>

            {currentItem?.macrosAfterProcessing?.calories === null && (
              <Tooltip
                title={
                  <h4>
                    {this.props.t(
                      'meals.dishIngredients.recalculationProgress'
                    )}
                  </h4>
                }
              >
                <Help
                  style={{
                    fontSize: '16px',
                    color: 'grey',
                    marginLeft: '4px',
                  }}
                />
              </Tooltip>
            )}
          </div>
        ),
        // 😢
        unit:
          currentItem['@type'] === 'Ingredient' ? (
            currentItem.unit ? (
              currentItem.unit.value ? (
                currentItem.unit.value
              ) : (
                currentItem.unit
              )
            ) : (
              <Danger>
                <b>{this.props.t('form.ingredientsDetails.noUnit')}</b>
              </Danger>
            )
          ) : (
            'Porcja'
          ),
        glycemicIndex: glycemicIndexValue(),
        weight: `${(currentItem.macrosAfterProcessing.weight || 0).toFixed(
          2
        )} g`,
        calories: getNutritionalValue('calories', 'kcal'),
        protein: getNutritionalValue('protein', 'g'),
        fat: getNutritionalValue('fat', 'g'),
        carbohydrates: getNutritionalValue('carbohydrates', 'g'),
        roughage: getNutritionalValue('roughage', 'g'),
      };
    });
  };

  render() {
    const { t } = this.props;
    const columns = [
      {
        Header: t('form.ingredientsDetails.workingName'),
        accessor: 'workName',
        width: 350,
      },
      {
        Header: t('form.ingredientsDetails.unit'),
        accessor: 'unit',
      },
      /* {
        Header: 'WpO',
        accessor: 'weight',
      }, */
      {
        Header: t('glycemicIndex.shortLabel'),
        accessor: 'glycemicIndex',
      },
      {
        Header: t('form.ingredientsDetails.calories'),
        accessor: 'calories',
      },
      {
        Header: t('form.ingredientsDetails.protein'),
        accessor: 'protein',
      },
      {
        Header: t('form.ingredientsDetails.carbohydrates'),
        accessor: 'carbohydrates',
      },
      {
        Header: t('form.ingredientsDetails.fat'),
        accessor: 'fat',
      },
      {
        Header: t('form.ingredientsDetails.fiber'),
        accessor: 'roughage',
      },
    ];

    return (
      <Card>
        <CardBody>
          <ReactTable
            data={this.renderIngredients()}
            resizable={false}
            sortable={false}
            columns={columns}
            minRows={3}
            showPaginationTop={false}
            showPaginationBottom={false}
            className="-striped -highlight"
            noDataText={t('form.field.addIngredientsOrRecipes')}
          />
        </CardBody>
      </Card>
    );
  }
}

export default withTranslation()(IngredientsRecipesTable);
