import React, { useState } from 'react';
import moment from 'moment';

import { orderStyles } from 'views/Orders/styles';

import { Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import Accordion from 'components/Accordion/Accordion';
import Card from 'components/Card/Card';
import NotesModal from './notesModal';
import NotesEditModal from './notesEditModal';
import { useTranslation } from 'react-i18next';

const DietDetailsNotes = ({ notes, client, classes, setNotes }) => {
  const [notesModalStatus, setNotesModalStatus] = useState(false);
  const [editModalStatus, setEditModalStatus] = useState(false);
  const [editModalData, setEditModalData] = useState({});
  const { t } = useTranslation();
  return (
    <div
      style={{
        marginLeft: '5px',
        ...orderStyles.box,
      }}
    >
      <Accordion
        active={0}
        collapses={[
          {
            title: t('common.notes', 'Notatki'),
            content: (
              <div style={{ width: '100%' }}>
                {notesModalStatus && (
                  <NotesModal
                    classes={classes}
                    client={client}
                    setNotes={setNotes}
                    notesModalStatus={notesModalStatus}
                    setNotesModalStatus={setNotesModalStatus}
                    editModalStatus={editModalStatus}
                    setEditModalStatus={setEditModalStatus}
                    setEditModalData={setEditModalData}
                    t={t}
                  />
                )}
                {editModalStatus && (
                  <NotesEditModal
                    classes={classes}
                    client={client}
                    setNotes={setNotes}
                    editModalStatus={editModalStatus}
                    setEditModalStatus={setEditModalStatus}
                    setNotesModalStatus={setNotesModalStatus}
                    editModalData={editModalData}
                    t={t}
                  />
                )}
                <div style={{ maxHeight: '120px', overflowY: 'auto' }}>
                  {notes.length > 0 ? (
                    notes.map(
                      (note, index) =>
                        index < 3 && (
                          <Card
                            key={note.id}
                            style={{ padding: '10px', margin: '5px 0 10px' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <span style={{ fontSize: '10px' }}>
                                {moment(note.updatedAt).format(
                                  'DD.MM.YYYY hh:mm'
                                )}
                              </span>
                            </div>
                            <span style={{ marginTop: '10px' }}>
                              <i>"{note.content}"</i>
                            </span>
                            <span
                              style={{ fontSize: '10px', marginTop: '10px' }}
                            >
                              {note.moderator &&
                                `${note.moderator.firstName} ${note.moderator.lastName}`}
                            </span>
                          </Card>
                        )
                    )
                  ) : (
                    <h5>{t('common.noNotes', 'Brak notatek')}</h5>
                  )}
                </div>
                <Divider style={{ marginBottom: '5px' }} />
                <Button
                  style={{ width: '100%' }}
                  onClick={() => setNotesModalStatus(true)}
                >
                  {t('common.more', 'Więcej')}
                </Button>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default DietDetailsNotes;
