import { useTranslation } from 'react-i18next';

import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import { PayUModule, StripeModule } from '../modules';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import Card from 'components/Card/Card';

import { modulesNames } from '../consts';
import { DialogLoader } from 'components/DialogLoader';

import PayPoModule from '../modules/PayPoModule';
import PayNowModule from '../modules/PayNowModule';
import AdditionalModule from '../modules/AdditionalModule';
import DefaultOrderMode from '../modules/DefaultOrderMode';
import PaymentTypeConfig from './components/PaymentTypeConfig';

const PaymentMethodView = ({
  classes,
  modules,
  setModules,
  isLoading,
  handleModuleUpdate,
  handleSubmit,
  selectDefaultPaymentType,
  setEnabledPaymentTypes,
  paymentTypes,
  setPaymentTypes,
  orderTypes,
  subscriptionPaymentTypes,
  defaultPaymentOptions,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <DialogLoader
        loaderState={isLoading}
        text={t('common.shared.isLoading')}
      />

      {!isLoading && (
        <>
          <Card>
            <CardBody>
              <PaymentTypeConfig
                classes={classes}
                selectDefaultPaymentType={selectDefaultPaymentType}
                setEnabledPaymentTypes={setEnabledPaymentTypes}
                paymentTypes={paymentTypes}
                setPaymentTypes={setPaymentTypes}
                orderTypes={orderTypes}
                subscriptionPaymentTypes={subscriptionPaymentTypes}
                modules={modules}
                setModules={setModules}
              />
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <GridContainer alignItems="center">
                <DefaultOrderMode
                  classes={classes}
                  modules={modules}
                  defaultPaymentOptions={defaultPaymentOptions}
                  setModules={setModules}
                />
              </GridContainer>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <GridContainer>
                <PayUModule
                  classes={classes}
                  handleModuleUpdate={handleModuleUpdate}
                  module={modules[modulesNames.PayU]}
                />
              </GridContainer>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <GridContainer>
                <StripeModule
                  classes={classes}
                  handleModuleUpdate={handleModuleUpdate}
                  module={modules[modulesNames.Stripe]}
                />
              </GridContainer>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <GridContainer>
                <PayPoModule
                  classes={classes}
                  handleModuleUpdate={handleModuleUpdate}
                  module={modules[modulesNames.PayPo]}
                />
              </GridContainer>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <GridContainer>
                <PayNowModule
                  classes={classes}
                  handleModuleUpdate={handleModuleUpdate}
                  module={modules[modulesNames.PayNow]}
                />
              </GridContainer>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <GridContainer>
                <AdditionalModule
                  classes={classes}
                  handleModuleUpdate={handleModuleUpdate}
                  module={modules[modulesNames.AdditionalPayment]}
                />
              </GridContainer>
            </CardBody>
          </Card>

          <FormControlButtons
            classes={classes}
            submitText={t('common.shared.save')}
            handleSubmit={handleSubmit}
          />
        </>
      )}
    </>
  );
};

export default PaymentMethodView;
