import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { combineStyles, isGranted } from '../../helpers/helpers';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import FormSelectSingle from '../../components/FormSelect/FormSelectSingle';
import React, { useState, useEffect } from 'react';
import { put, get, post } from 'helpers/apiHelpers';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { ROLE_IMPERSONATE_USER } from '../../helpers/roles';
import EditIcon from '@material-ui/icons/Edit';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { orderStyles } from '../Orders/styles';
import Close from '@material-ui/icons/Close';
import ModalButton from '../../components/CustomButtons/Button';
import FormControlButtons from '../../components/FormControlButtons/FormControlButtons';
import CustomInput from '../../components/CustomInput/CustomInput';
import { DialogLoader } from '../../components/DialogLoader';
import I18NSection from '../Brands/I18nSection';
import FormLabel from '@material-ui/core/FormLabel';
import SelectInput from '../../components/FormSelect/SelectInput';
import FormImageUpload from '../../components/FormImageUpload/FormImageUpload';
import { fetchLanguages } from 'actions/Auth';
import { setMultinational } from '../../actions/Brands';
import { toast } from 'react-toastify';

const defaultModalData = {
  isoCode: '',
  name: '',
  enabled: false,
  copyFromLanguage: null,
  image: null,
};

const LanguageSettings = ({
  classes,
  t,
  defaultLanguage: DL,
  clientAllowedToChangeLanguage: CATCL,
  adminDefaultLanguage: ADL,
  adminAllowedToChangeLanguage: AATCL,
  supportedRegions: SR,
  currency: C,
  salesMarket,
  selectedBrand,
  fetchLanguages,
  setMultinational,
}) => {
  const [languages, setLanguages] = useState(null);
  const [defaultLanguage, setDefaultLanguage] = useState(DL);
  const [clientAllowedToChangeLanguage, setClientAllowedToChangeLanguage] =
    useState(CATCL);
  const [adminDefaultLanguage, setAdminDefaultLanguage] = useState(ADL);
  const [adminAllowedToChangeLanguage, setAdminAllowedToChangeLanguage] =
    useState(AATCL);
  const [supportedRegions, setSupportedRegions] = useState(SR);
  const [currency, setCurrency] = useState(C);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [modalData, setModalData] = useState(defaultModalData);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [canRemoveLanguages, setCanRemoveLanguages] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await get(`/languages`);
      setLanguages(response['hydra:member']);
      setIsLoading(false);
    })();
  }, []);

  const handleSubmitLanguage = async () => {
    if (modalData.hasOwnProperty('id')) {
      try {
        await put(`/languages/${modalData.id}`, modalData);
        setRefresh(true);
        fetchLanguages();
        toast.success(t('success.changesSaved'));
      } catch (e) {
        toast.error(t('notify.cannotSave'));
      } finally {
        setRefresh(false);
        closeModal();
      }
    } else {
      try {
        await post('/languages', modalData);
        setRefresh(true);
        fetchLanguages();
        toast.success(t('success.changesSaved'));
      } catch (e) {
        toast.error(t('notify.cannotSave'));
      } finally {
        setRefresh(false);
        closeModal();
      }
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const response = await put(`/brands/${selectedBrand}`, {
        multinational: {
          adminAllowedToChangeLanguage,
          adminDefaultLanguage,
          clientAllowedToChangeLanguage,
          defaultLanguage,
          supportedRegions,
          currency,
          salesMarket,
        },
      });

      toast.success(t('success.changesSaved'));

      setMultinational(response.multinational);
    } catch (e) {
      toast.error(t('notify.cannotSave'));
    } finally {
      setIsSubmitting(false);
    }
  };

  const customActions = () => {
    return [
      {
        action: props => {
          setIsEdit(true);
          setIsModalOpened(true);
          setModalData(props.row);
        },
        icon: <EditIcon />,
        disabled: !isGranted(ROLE_IMPERSONATE_USER),
        color: 'warning',
        buttonText: null,
        simple: true,
        round: true,
        justIcon: true,
      },
    ];
  };

  const setImage = (_, data) => {
    setModalData(prevState => ({
      ...prevState,
      image: data?.['@id'] || null,
    }));
    setModalImageUrl(data?.contentUrl || null);
  };

  const removeImage = () => {
    setModalData(prevState => ({
      ...prevState,
      image: null,
    }));
    setModalImageUrl(null);
  };

  const closeModal = () => {
    setIsModalOpened(false);
    setModalData(defaultModalData);
    setModalImageUrl(null);
  };

  return (
    <>
      <DialogLoader loaderState={isLoading} text={t('common.loading')} />
      <DialogLoader loaderState={isSubmitting} text={t('form.savingChanges')} />

      <Dialog
        open={isModalOpened}
        onClose={closeModal}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogContent>
          <ModalButton
            style={orderStyles.closeButton}
            color={'transparent'}
            justIcon
            round
            onClick={closeModal}
          >
            <Close />
          </ModalButton>
          <FormLabel className={classes.labelHorizontal}>
            {t('languageSettings.languageName')}
          </FormLabel>
          <CustomInput
            formControlProps={{ fullWidth: true }}
            inputProps={{
              type: 'text',
              name: 'name',
              value: modalData.name,
              onChange: event =>
                setModalData(prevState => ({
                  ...prevState,
                  name: event.target.value,
                })),
            }}
          />
          {!isEdit && (
            <>
              <FormLabel className={classes.labelHorizontal}>
                {t('languageSettings.languageNameShort')}
              </FormLabel>
              <CustomInput
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  type: 'text',
                  name: 'isoCode',
                  value: modalData.isoCode,
                  onChange: event =>
                    setModalData(prevState => ({
                      ...prevState,
                      isoCode: event.target.value,
                    })),
                }}
              />
              <FormSelectSingle
                classes={classes}
                options={languages}
                mapBy="name"
                trackBy="@id"
                value={modalData.copyFromLanguage}
                handleChange={e => {
                  setModalData(prevState => ({
                    ...prevState,
                    copyFromLanguage: e.target.value,
                  }));
                }}
                label={t('languageSettings.downloadTranslationsFrom')}
              />
            </>
          )}
          <FormImageUpload
            classes={classes}
            stateName="flagImage"
            buttonText={t('brandCfg.selectLogo')}
            getImage={setImage}
            removeImage={removeImage}
            previewUrl={modalImageUrl}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="activateLanguage"
                onClick={event =>
                  setModalData(prevState => ({
                    ...prevState,
                    enabled: event.target.checked,
                  }))
                }
                checked={modalData.enabled}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            label={t('languageSettings.activateLanguage')}
          />
          <FormControlButtons
            classes={classes}
            submitText={t('zoneCategories.save')}
            handleSubmit={handleSubmitLanguage}
          />
        </DialogContent>
      </Dialog>
      <GridContainer style={{ marginTop: '15px' }}>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem md={6}>
                  <h3>{t('common.clientPanel')}</h3>

                  {languages && (
                    <FormSelectSingle
                      classes={classes}
                      options={languages}
                      mapBy="name"
                      trackBy="isoCode"
                      value={defaultLanguage}
                      handleChange={e => {
                        setDefaultLanguage(e.target.value);
                      }}
                      label={t('$*multinational.defaultLanguage')}
                    />
                  )}

                  <FormControlLabel
                    control={
                      <Checkbox
                        name="clientCanChangeLanguage"
                        onClick={() =>
                          setClientAllowedToChangeLanguage(
                            !clientAllowedToChangeLanguage
                          )
                        }
                        checked={clientAllowedToChangeLanguage}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={t('languageSettings.clientAllowedToChangeLanguage')}
                  />
                </GridItem>

                <GridItem md={6}>
                  <h3>{t('common.adminPanel')}</h3>

                  {languages && (
                    <FormSelectSingle
                      classes={classes}
                      options={languages}
                      mapBy="name"
                      trackBy="isoCode"
                      value={adminDefaultLanguage}
                      handleChange={e => {
                        setAdminDefaultLanguage(e.target.value);
                      }}
                      label={t('$*multinational.defaultLanguage')}
                    />
                  )}

                  <FormControlLabel
                    control={
                      <Checkbox
                        name="adminCanChangeLanguage"
                        onClick={() =>
                          setAdminAllowedToChangeLanguage(
                            !adminAllowedToChangeLanguage
                          )
                        }
                        checked={adminAllowedToChangeLanguage}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={t('languageSettings.adminCanChangeLanguage')}
                  />
                </GridItem>

                <GridItem md={6}>
                  <h3>{t('brandCfg.i18n')}</h3>

                  <I18NSection
                    setSupportedRegions={e => setSupportedRegions([e])}
                    supportedRegions={supportedRegions}
                    classes={classes}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem md={6}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('brandCfg.brandCurrency')}
                  </FormLabel>
                  <SelectInput
                    noGrid
                    classes={classes}
                    mapBy="label"
                    trackBy="value"
                    name="multinational.currency"
                    value={currency}
                    options={[
                      {
                        label: t(
                          'languageSettings.brandCurrency.option.pln',
                          '$$Złoty polski - PLN (zł)'
                        ),
                        value: 'PLN',
                      },
                      {
                        label: t(
                          'languageSettings.brandCurrency.option.eur',
                          '$$Euro - EUR (€)'
                        ),
                        value: 'EUR',
                      },
                      {
                        label: t(
                          'languageSettings.brandCurrency.option.gbp',
                          '$$Funt szterling - GBP (£)'
                        ),
                        value: 'GBP',
                      },
                      {
                        label: t(
                          'languageSettings.brandCurrency.option.usd',
                          '$$Dolar amerykański - USD ($)'
                        ),
                        value: 'USD',
                      },
                      {
                        label: t(
                          'languageSettings.brandCurrency.option.czk',
                          '$$Korona czeska - CZK (Kč)'
                        ),
                        value: 'CZK',
                      },
                      {
                        label: t(
                          'languageSettings.brandCurrency.option.nok',
                          'Korona norweska - NOK (kr)'
                        ),
                        value: 'NOK',
                      },
                      {
                        label: t(
                          'languageSettings.brandCurrency.option.chf',
                          'Frank szwajcarski - CHF (chf)'
                        ),
                        value: 'CHF',
                      },
                    ]}
                    disabled={false}
                    handleChange={e => setCurrency(e.target.value)}
                    size={12}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem sm={12}>
                  <FormControlButtons
                    classes={classes}
                    submitText={t('common.shared.save', 'Zapisz')}
                    handleSubmit={handleSubmit}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem md={12}>
                  <FormControlButtons
                    classes={classes}
                    handleSubmit={() => {
                      setIsEdit(false);
                      setIsModalOpened(true);
                    }}
                    submitText={t('languageSettings.addLanguage') + ' +'}
                  />
                  <DataGrid
                    actions={true}
                    paginationTop={false}
                    paginationBottom={false}
                    url="/languages"
                    reportName={'languages'}
                    columns={columnConfig}
                    customActions={customActions}
                    remove={true}
                    disableTotal={true}
                    refresh={refresh}
                    onFetchedData={response => {
                      setCanRemoveLanguages(response.length > 1);
                    }}
                    canRemoveChecker={(_, row) =>
                      canRemoveLanguages && row.isoCode !== ADL
                    }
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withTranslation(),
  connect(
    ({
      Brands: {
        brand: {
          multinational: {
            defaultLanguage,
            clientAllowedToChangeLanguage,
            adminDefaultLanguage,
            adminAllowedToChangeLanguage,
            supportedRegions,
            currency,
            salesMarket,
          },
        },
      },
      Auth: { selectedBrand },
    }) => ({
      defaultLanguage,
      clientAllowedToChangeLanguage,
      adminDefaultLanguage,
      adminAllowedToChangeLanguage,
      supportedRegions,
      currency,
      salesMarket,
      selectedBrand,
    }),
    { fetchLanguages, setMultinational }
  ),
  withStyles(combinedStyles)
);

export default enhance(LanguageSettings);
