import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel, Tooltip } from '@material-ui/core';
import { Check, Info } from '@material-ui/icons';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from '../../../components/CustomButtons/Button';
import { post } from '../../../helpers/apiHelpers';
import { toast } from 'react-toastify';

const UserComModule = ({ classes, handleModuleUpdate, module }) => {
  const { t } = useTranslation();
  const [isSyncing, setIsSyncing] = useState(false);

  const handleClientsSync = async () => {
    setIsSyncing(true);
    try {
      await post('/usercom/client-sync');
      toast.success(
        t('brands.newBrandForm.thulium.syncContacts.successMessage')
      );
      setIsSyncing(false);
    } catch (e) {
      setIsSyncing(false);
    }
  };

  if (!module) {
    return (
      <div>
        {t('brands.newBrandForm.moduleNotLoaded', {
          defaultValue: '{{moduleName}} not loaded.',
          replace: 'UserCom',
        })}
      </div>
    );
  }

  return !module ? (
    <div>
      {t('brands.newBrandForm.moduleNotLoaded', {
        defaultValue: '{{moduleName}} not loaded.',
        replace: { moduleName: __filename.split('.')[0] },
      })}
    </div>
  ) : (
    <>
      <GridContainer>
        <GridItem xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={module.enabled}
                onChange={e =>
                  handleModuleUpdate({
                    ...module,
                    enabled: !module.enabled,
                  })
                }
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            label={t('brands.newBrandForm.useUserComLabel', 'Używaj User.com')}
          />

          <Button disabled={isSyncing} onClick={handleClientsSync}>
            {t('brands.newBrandForm.thulium.syncContacts', 'Synchronizuj')}
          </Button>
          <Tooltip
            title={
              <h4>
                {t(
                  'brands.newBrandForm.thulium.syncContacts.tooltip',
                  '$$Synchronizacja klientów może chwilę zająć'
                )}
              </h4>
            }
            placement="top"
          >
            <Info fontSize={'small'} style={{ color: 'gray' }} />
          </Tooltip>
        </GridItem>
        <GridItem xs={12}>
          <FormTextInput
            label={t('brands.newBrandForm.userCom.apiUrl', 'URL user.com')}
            classes={classes}
            name="userComApiUrl"
            value={module.apiUrl}
            maxLength={64}
            handleChange={e =>
              handleModuleUpdate({
                ...module,
                apiUrl: e.target.value,
              })
            }
            inputSize={12}
          />
          <FormTextInput
            label={t('brands.newBrandForm.userCom.apiKey', 'API klucz')}
            classes={classes}
            name="userComApiKey"
            value={module.apiKey}
            maxLength={64}
            handleChange={e =>
              handleModuleUpdate({
                ...module,
                apiKey: e.target.value,
              })
            }
            inputSize={12}
          />

          <FormTextInput
            label={
              <div style={{ display: 'flex' }}>
                <span>
                  {t(
                    'brands.newBrandForm.userCom.apiToken',
                    'Token zgód marketingowych'
                  )}
                </span>
                <div style={{ marginLeft: '10px' }}>
                  <Tooltip
                    title={
                      <h4>
                        {t(
                          'brands.newBrandForm.userCom.apiToken.tooltip',
                          'Token służący do autoryzacji synchronizacji zgód marketingowych z user.com do Caterings'
                        )}
                      </h4>
                    }
                    placement="right"
                  >
                    <Info
                      fontSize="small"
                      style={{
                        color: 'grey',
                        display: 'block',
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            }
            classes={classes}
            name="userComApiToken"
            value={module.apiToken}
            maxLength={64}
            handleChange={e =>
              handleModuleUpdate({
                ...module,
                apiToken: e.target.value,
              })
            }
            inputSize={12}
          />
        </GridItem>
      </GridContainer>
    </>
  );
};

export default UserComModule;
