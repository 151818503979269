import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel, Tooltip } from '@material-ui/core';
import { Check, Info } from '@material-ui/icons';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from '../../../components/CustomButtons/Button';
import { post } from '../../../helpers/apiHelpers';
import { toast } from 'react-toastify';

const IpressoModule = ({ classes, handleModuleUpdate, module }) => {
  const { t } = useTranslation();
  const [isSyncing, setIsSyncing] = useState(false);

  const handleClientsSync = async () => {
    setIsSyncing(true);
    try {
      await post('/ipresso/client-sync');
      toast.success(
        t('brands.newBrandForm.thulium.syncContacts.successMessage')
      );
      setIsSyncing(false);
    } catch (e) {
      setIsSyncing(false);
    }
  };

  if (!module) {
    return (
      <div>
        {t('brands.newBrandForm.moduleNotLoaded', {
          defaultValue: '{{moduleName}} not loaded.',
          replace: 'Ipresso',
        })}
      </div>
    );
  }

  return !module ? (
    <div>
      {t('brands.newBrandForm.moduleNotLoaded', {
        defaultValue: '{{moduleName}} not loaded.',
        replace: { moduleName: __filename.split('.')[0] },
      })}
    </div>
  ) : (
    <>
      <GridContainer>
        <GridItem xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={module.enabled}
                onChange={e =>
                  handleModuleUpdate({
                    ...module,
                    enabled: !module.enabled,
                  })
                }
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            label={t('brands.newBrandForm.useIpressoLabel', 'Używaj Ipresso')}
          />

          <Button disabled={isSyncing} onClick={handleClientsSync}>
            {t('brands.newBrandForm.thulium.syncContacts', 'Synchronizuj')}
          </Button>
          <Tooltip
            title={
              <h4>
                {t(
                  'brands.newBrandForm.thulium.syncContacts.tooltip',
                  '$$Synchronizacja klientów może chwilę zająć'
                )}
              </h4>
            }
            placement="top"
          >
            <Info fontSize={'small'} style={{ color: 'gray' }} />
          </Tooltip>
        </GridItem>
        <GridItem xs={12}>
          <FormTextInput
            label={t('brands.newBrandForm.ipresso.apiUrl', 'URL iPresso')}
            classes={classes}
            name="ipressoApiUrl"
            value={module.apiUrl}
            maxLength={64}
            handleChange={e =>
              handleModuleUpdate({
                ...module,
                apiUrl: e.target.value,
              })
            }
            inputSize={12}
          />
          <FormTextInput
            label={t('brands.newBrandForm.ipresso.apiCustomerKey', 'API klucz')}
            classes={classes}
            name="ipressoApiCustomerKey"
            value={module.apiCustomerKey}
            maxLength={64}
            handleChange={e =>
              handleModuleUpdate({
                ...module,
                apiCustomerKey: e.target.value,
              })
            }
            inputSize={12}
          />
        </GridItem>
        <GridItem xs={12}>
          <FormTextInput
            label={t('brands.newBrandForm.ipresso.apiLogin', 'API Login')}
            classes={classes}
            name="ipressoApiLogin"
            value={module.apiLogin}
            maxLength={64}
            handleChange={e =>
              handleModuleUpdate({
                ...module,
                apiLogin: e.target.value,
              })
            }
            inputSize={12}
          />
          <FormTextInput
            label={t('brands.newBrandForm.ipresso.apiPassword', 'API Hasło')}
            classes={classes}
            name="ipressoApiPassword"
            value={module.apiPassword}
            maxLength={64}
            handleChange={e =>
              handleModuleUpdate({
                ...module,
                apiPassword: e.target.value,
              })
            }
            inputSize={12}
          />
        </GridItem>
      </GridContainer>
    </>
  );
};

export default IpressoModule;
