import React from 'react';
import FormFiltersContainer from './components/FormFilters.container';

export const Form = ({
  match,
  location,
  fetchDiets,
  fetchPackage,
  history,
  t,
  diets,
  classes,
}) => {
  return (
    <FormFiltersContainer
      match={match}
      location={location}
      fetchDiets={fetchDiets}
      fetchPackage={fetchPackage}
      history={history}
      t={t}
      diets={diets}
      classes={classes}
    />
  );
};

export default Form;
