import React from 'react';
import { combineStyles } from 'helpers/helpers';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import withStyles from '@material-ui/core/styles/withStyles';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {
  AddBoxTwoTone,
  CancelPresentation,
  DeleteForever,
  OpenInNew,
} from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import { put } from 'helpers/apiHelpers';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withTranslation } from 'react-i18next';
import {
  carbohydratesConstant,
  proteinConstant,
  fatConstant,
  roughageConstant,
} from './utils';
import PlannerCardItem from './PlannerCardItem';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { currencyToSymbol } from 'utils/currencies';

const DishInfo = withStyles({
  body1: {
    fontSize: '0.8rem',
  },
})(Typography);

const IconButtonStyled = withStyles({
  root: {
    padding: '12px 0',
  },
})(IconButton);

const DISH_LINK_STYLES = {
  color: '#747474',
  cursor: 'pointer',
  height: 24,
};

class PlannerCard extends React.Component {
  state = {
    code: '',
    editingCode: false,
    isSavingCode: false,
    isRemoving: false,
  };

  componentDidMount() {
    const menuPlanner = this.props.menuPlanners[0] || null;

    if (menuPlanner) {
      this.setState(prevState => ({
        ...prevState,
        code: menuPlanner.reportCode,
      }));
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevMenuPlanner = prevProps.menuPlanners[0] || { reportCode: '' };
    const menuPlanner = this.props.menuPlanners[0] || { reportCode: '' };

    if (prevMenuPlanner.reportCode !== menuPlanner.reportCode) {
      this.setState(prevState => ({
        ...prevState,
        editingCode: false,
        code: menuPlanner.reportCode,
      }));
    }
  }

  toggleEditMode = () => {
    if (this.state.editingCode) {
      const menuPlanner = this.props.menuPlanners[0] || { reportCode: '' };
      this.setState(prevState => ({
        ...prevState,
        editingCode: false,
        code: menuPlanner.reportCode,
      }));
    } else {
      this.setState(prevState => ({ ...prevState, editingCode: true }));
    }
  };

  toggleIsRemoving = () =>
    this.setState(prevState => ({
      ...prevState,
      isRemoving: !this.state.isRemoving,
    }));

  calculateElementPercent = (element, elementConstant, statistics) => {
    return (
      ((100 * elementConstant) / statistics.macrosAfterProcessing?.calories) *
        element || 0
    ).toFixed(2);
  };

  calculateElement = element => {
    return (parseFloat(element ?? 0) || 0).toFixed(2);
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  updatePlanning = () => {
    const menuPlanner = this.props.menuPlanners[0];
    if (menuPlanner.reportCode !== this.state.code) {
      this.setState(prevState => ({ ...prevState, isSavingCode: true }));

      put(menuPlanner['@id'], { reportCode: this.state.code }).then(() => {
        this.setState(prevState => ({
          ...prevState,
          isSavingCode: false,
          editingCode: false,
        }));
        if (typeof this.props.onMenuPlannerUpdated === 'function') {
          this.props.onMenuPlannerUpdated();
        }
      });
    }
  };

  onRemove = menuPlannerId => {
    this.toggleIsRemoving();
    this.props.onRemove(menuPlannerId).then(() => {
      this.toggleIsRemoving();
    });
  };

  render() {
    const { classes, statisticsFor, showDishStats, t, currency } = this.props;

    const draftView = this.props.viewMode === 'DRAFT';
    const menuPlanner = this.props.menuPlanners[0] || null;
    const allowedToWriteCode =
      menuPlanner &&
      ((draftView && menuPlanner.draft) || (!draftView && !menuPlanner.draft));
    const opacity =
      draftView && menuPlanner && !menuPlanner.draft ? '0.5' : '1';
    let statistics = { macrosAfterProcessing: {} };

    if (menuPlanner) {
      if (
        menuPlanner.sizes !== null &&
        typeof menuPlanner.sizes[statisticsFor] !== 'undefined'
      ) {
        statistics = menuPlanner.sizes[statisticsFor];

        if (statistics === null) {
          statistics = { macrosAfterProcessing: {} };
        }
      }
    }
    const { weightAfterWOM, weight } = statistics?.macrosAfterProcessing;
    const weightAfterWOMCalculated = this.calculateElement(weightAfterWOM);
    const weightCalculated = this.calculateElement(weight);

    const carbohydratesCalculated = this.calculateElement(
      statistics.macrosAfterProcessing.carbohydrates
    );

    const carbohydratesPercentage = this.calculateElementPercent(
      statistics.macrosAfterProcessing.carbohydrates,
      carbohydratesConstant,
      statistics
    );

    const carbohydratesCalculatedNet = this.calculateElement(
      Math.max(
        statistics.macrosAfterProcessing.carbohydrates -
          statistics.macrosAfterProcessing.roughage,
        0
      ).toFixed(2)
    );
    const carbohydratesPercentageNet = this.calculateElementPercent(
      Math.max(
        statistics.macrosAfterProcessing.carbohydrates -
          statistics.macrosAfterProcessing.roughage,
        0
      ).toFixed(2),
      carbohydratesConstant,
      statistics
    );
    return (
      <Card
        className={classes.card}
        style={{
          maxWidth: 350,
          margin: '0 auto',
          position: 'relative',
          overflow: 'initial',
        }}
      >
        {menuPlanner ? (
          <>
            <CardContent
              style={{
                display: 'flex',
                padding: 0,
                minWidth: menuPlanner && showDishStats ? '350px' : '250px',
              }}
            >
              <div
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                {showDishStats && (
                  <div
                    style={{
                      maxWidth: '58%',
                      minWidth: '160px',
                      paddingLeft: 4,
                      paddingTop: 4,
                      opacity: opacity,
                      flex: '1 auto',
                    }}
                  >
                    <div>
                      <PlannerCardItem
                        title={t('menuPlanner.plannerCard.new.calories')}
                        value={this.calculateElement(
                          statistics.macrosAfterProcessing.calories
                        )}
                        parameter="kcal"
                      />
                      <PlannerCardItem
                        title={t('menuPlanner.plannerCard.new.protein')}
                        value={
                          <>
                            {this.calculateElement(
                              statistics.macrosAfterProcessing.protein
                            )}
                            g (
                            {this.calculateElementPercent(
                              statistics.macrosAfterProcessing.protein,
                              proteinConstant,
                              statistics
                            )}
                            %)
                          </>
                        }
                      />
                      <PlannerCardItem
                        title={t('menuPlanner.plannerCard.new.carbohydrates')}
                        value={
                          <>
                            {carbohydratesCalculated}g (
                            {carbohydratesPercentage}%)
                          </>
                        }
                      />
                      <PlannerCardItem
                        title={t(
                          'menuPlanner.plannerCard.new.carbohydratesNet'
                        )}
                        value={
                          <>
                            {carbohydratesCalculatedNet}g (
                            {carbohydratesPercentageNet}%)
                          </>
                        }
                      />

                      <PlannerCardItem
                        title={t('menuPlanner.plannerCard.new.roughage')}
                        value={
                          <>
                            {(
                              carbohydratesCalculated -
                              carbohydratesCalculatedNet
                            ).toFixed(2)}
                            g (
                            {this.calculateElementPercent(
                              statistics.macrosAfterProcessing.roughage,
                              roughageConstant,
                              statistics
                            )}
                            )%
                          </>
                        }
                      />
                      <PlannerCardItem
                        title={t('menuPlanner.plannerCard.new.fat')}
                        value={
                          <>
                            {this.calculateElement(
                              statistics.macrosAfterProcessing.fat
                            )}
                            g (
                            {this.calculateElementPercent(
                              statistics.macrosAfterProcessing.fat,
                              fatConstant,
                              statistics
                            )}
                            )%
                          </>
                        }
                      />
                      <PlannerCardItem
                        title={t('menuPlanner.plannerCard.new.ig')}
                        value={this.calculateElement(
                          statistics.macrosAfterProcessing.glycemicIndex
                        )}
                      />
                      <PlannerCardItem
                        title={t('menuPlanner.plannerCard.new.productWeight')}
                        value={
                          <>
                            {weightAfterWOMCalculated}g ({weightCalculated})g
                          </>
                        }
                      />
                      <PlannerCardItem
                        title={t('menuPlanner.plannerCard.new.price')}
                        value={this.calculateElement(statistics.price)}
                        parameter={currencyToSymbol(currency)}
                      />
                      <PlannerCardItem
                        title={t('menuPlanner.plannerCard.new.rate')}
                        value={
                          <>
                            {statistics.rateCount > 0
                              ? (
                                  statistics.rate / statistics.rateCount
                                ).toFixed(2)
                              : '-'}
                          </>
                        }
                      />
                    </div>
                  </div>
                )}
                <div
                  style={{
                    backgroundColor: '#50d3ee',
                    padding: '4px 4px 0 4px',
                    opacity: opacity,
                    flex: '1 auto',
                  }}
                >
                  <DishInfo component="p">
                    {`(${menuPlanner?.dish?.id}) ${menuPlanner.dish.nameForClient}`}
                  </DishInfo>
                  {allowedToWriteCode && (
                    <div
                      style={{
                        position: 'relative',
                        borderTop: '1px solid #dedede',
                      }}
                    >
                      <FormTextInput
                        classes={classes}
                        name={`menu_planning_${menuPlanner.id}`}
                        label={this.props.t('menuPlanner.plannerCard.code')}
                        value={this.state.code}
                        handleChange={ev => {
                          const value = ev.target.value;

                          this.setState(prevState => ({
                            ...prevState,
                            code: value.replace(/\s/g, ''),
                          }));
                        }}
                        onKeyDown={e => {
                          if (e.key === 'Enter' && this.state.code.length > 0) {
                            this.updatePlanning();
                          }
                        }}
                        onBlur={this.updatePlanning}
                      />
                      <div
                        style={{ position: 'absolute', bottom: 10, right: 0 }}
                      >
                        {this.state.isSavingCode && (
                          <>
                            <CircularProgress />
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    width: '30px',
                    maxWidth: '30px',
                    backgroundColor: '#ebebeb',
                    padding: '4px 4px 0 4px',
                    flex: '1 auto',
                  }}
                >
                  {draftView && (
                    <IconButtonStyled
                      disabled={!this.props.canAssign}
                      onClick={this.props.onAssign}
                    >
                      <AddBoxTwoTone />
                    </IconButtonStyled>
                  )}
                  {menuPlanner && (
                    <>
                      {draftView && menuPlanner.draft && (
                        <IconButtonStyled
                          onClick={() => this.onRemove(menuPlanner['@id'])}
                          disabled={this.state.isRemoving}
                        >
                          <DeleteForever />
                        </IconButtonStyled>
                      )}
                      <IconButtonStyled aria-label="Share">
                        <NavLink
                          target="_blank"
                          to={`/admin/meals/edit/${menuPlanner.dish.id}?selectedBrand=${this.props.selectedBrand}`}
                          style={DISH_LINK_STYLES}
                        >
                          <OpenInNew />
                        </NavLink>
                      </IconButtonStyled>
                    </>
                  )}
                </div>
              </div>
            </CardContent>
          </>
        ) : (
          <CardContent style={{ padding: 0, textAlign: 'center' }}>
            {draftView ? (
              <IconButtonStyled
                disabled={!this.props.canAssign}
                onClick={this.props.onAssign}
              >
                <AddBoxTwoTone />
              </IconButtonStyled>
            ) : (
              <IconButtonStyled disabled={true}>
                <CancelPresentation />
              </IconButtonStyled>
            )}
          </CardContent>
        )}
      </Card>
    );
  }
}

const combinedStyles = combineStyles(
  buttonsStyle,
  extendedFormsStyle,
  sweetAlertStyle
);

const mapStateToProps = state => {
  return {
    currency: state.Brands.brand.multinational.currency,
    selectedBrand: state.Auth.selectedBrand,
  };
};

const enhance = compose(
  withTranslation(),
  connect(mapStateToProps),
  withStyles(combinedStyles)
);

export default enhance(PlannerCard);
