import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LockIcon from '@material-ui/icons/Lock';
import axios from 'helpers/gastro';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Tooltip } from '@material-ui/core';
import moment from 'moment';
import reportNames from '../../views/Archive/reportNames';
import DownloadIcon from 'assets/img/DownloadIcon';

import { makeStyles } from '@material-ui/styles';
import { apiErrorToast } from 'utils/apiError';
import classNames from 'classnames';

const useStyles = makeStyles({
  pointer: { cursor: 'pointer' },
  span: { width: '20px', height: '20px', display: 'block' },
});

const DownloadButton = ({ accessGranted, row }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isGenerating, setIsGenerating] = useState(false);

  const getFileName = ({ type, reportName, generatedAt }, backendFileName) => {
    const key = reportNames?.[type]?.[reportName];

    const fullReportName = `${t('reportArchive.type.' + type)}-${t(
      key || reportName
    )}`;
    return `${t('reportArchive')}(${moment(generatedAt).format(
      'YYYY-MM-DD_HH.mm'
    )})-${fullReportName.replaceAll(' ', '_').toLowerCase()}.${backendFileName
      .split('.')
      .pop()}`;
  };

  const download = row => {
    setIsGenerating(true);
    axios
      .get(`/export-archives/${row.id}/download`, { responseType: 'blob' })
      .then(
        response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            getFileName(row, response.headers['x-file-name'])
          );
          document.body.appendChild(link);
          link.click();
          setIsGenerating(false);
        },
        error => {
          apiErrorToast(t('reports.cannotGenerate'), error);
          setIsGenerating(false);
        }
      )
      .finally(() => {
        setIsGenerating(false);
      });
  };

  if (!accessGranted) {
    return (
      <Tooltip
        title={<h4>{t('reportArchive.tooltip.access')}</h4>}
        placement="top"
      >
        <LockIcon size={20} />
      </Tooltip>
    );
  }
  if (row.fileRemoved) {
    return (
      <Tooltip
        title={<h4>{t('reportArchive.tooltip.expired')}</h4>}
        placement="top"
      >
        <span>
          <DownloadIcon styles={{ color: '#D2D2D2' }} />
        </span>
      </Tooltip>
    );
  }
  if (isGenerating) {
    return <CircularProgress size={20} />;
  }

  return (
    <Tooltip
      title={<h4>{t('reportArchive.tooltip.generate')}</h4>}
      placement="top"
    >
      <span
        className={classNames(classes.pointer, classes.span)}
        onClick={() => {
          download(row);
        }}
      >
        <DownloadIcon />
      </span>
    </Tooltip>
  );
};

export default DownloadButton;
