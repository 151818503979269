import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Check from '@material-ui/icons/Check';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import NumberFormat from 'react-number-format';
import FormTextInputNoGrid from 'components/FormTextInput/FormTextInputNoGrid';
import Checkbox from '@material-ui/core/Checkbox';
import { DAY_SELECTOR_MODE } from './DaysSelectorMode';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import makeStyles from '@material-ui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  ...extendedFormsStyle,
  ...buttonsStyle,
  label: {
    display: 'block',
    color: 'rgba(0, 0, 0, 0.46)',
  },
});

const MinAndMaksOrderLengthSectionSettings = ({
  state,
  setState,
  handleInputChange,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <GridItem md={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.customDietLengthEnabled}
              onChange={e => {
                const daysSelectorType = state.customDietLengthEnabled
                  ? DAY_SELECTOR_MODE.predefinedOptions
                  : DAY_SELECTOR_MODE.range;

                setState(prevState => ({
                  ...prevState,
                  customDietLengthEnabled: e.target.checked,
                  daysSelectorType,
                }));
              }}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot,
              }}
            />
          }
          label={t('brandCfg.minimumAndMaximum')}
        />
        <label htmlFor="orderLength" className={classes.label}>
          {t('brandCfg.setupMinMax')}
        </label>
        <GridContainer>
          <GridItem md={4}>
            <FormLabel className={classes.labelHorizontal}>
              {t('brandCfg.minimum')}
            </FormLabel>
            <NumberFormat
              classes={classes}
              customInput={FormTextInputNoGrid}
              format={'###'}
              name={'daysMin'}
              value={state.daysMin}
              onChange={handleInputChange}
            />
          </GridItem>
          <GridItem md={4}>
            <FormLabel className={classes.labelHorizontal}>
              {t('brandCfg.maximum')}
            </FormLabel>
            <NumberFormat
              classes={classes}
              customInput={FormTextInputNoGrid}
              format={'###'}
              name={'daysMax'}
              value={state.daysMax}
              onChange={handleInputChange}
            />
          </GridItem>
          <GridItem md={4}>
            <FormLabel className={classes.labelHorizontal}>
              {t('brandCfg.defValue')}
            </FormLabel>
            <NumberFormat
              classes={classes}
              customInput={FormTextInputNoGrid}
              format={'###'}
              name={'daysDefault'}
              value={state.daysDefault}
              onChange={handleInputChange}
            />
          </GridItem>
        </GridContainer>
      </GridItem>
    </>
  );
};

export default MinAndMaksOrderLengthSectionSettings;
