import { Component } from 'react';
import Datetime from 'react-datetime';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import { combineStyles } from 'helpers/helpers';
import { post } from 'helpers/apiHelpers';
import { connect } from 'react-redux';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import FormLabel from '@material-ui/core/FormLabel';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import GridContainer from 'components/Grid/GridContainer';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import { Info } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { fetchDiets } from '../../actions/Diets';
import SelectInput from '../../components/FormSelect/SelectInput';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { currencyToSymbol } from 'utils/currencies';
import { toast } from 'react-toastify';

class Form extends Component {
  state = {
    description: '',
    code: '',
    startDate: '',
    endDate: '',
    value: '',
    amount: '',
    limit: '',
    limitPerUser: '',
    limitPerSubscription: 0,
    minimalOrderValue: null,
    minimalDietLength: null,
    onlyAdminCanUseCheck: false,
    disableDietDiscountCheck: false,
    applyToAddonsCheck: false,
    startDateCheck: false,
    endDateCheck: false,
    limitCheck: false,
    limitPerUserCheck: false,
    limitPerSubscriptionCheck: true,
    minimalOrderValueCheck: false,
    minimalDietLengthCheck: false,
    isPercent: true,
    diets: [],
    selectedDietsCheck: false,
  };
  yesterday = Datetime.moment().subtract(1, 'day');

  componentDidMount() {
    this.props.fetchDiets();
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleToggle = state => {
    this.setState({
      [state]: '',
      [state + 'Check']: !this.state[state + 'Check'],
    });
  };

  handleRadio = state => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  startDateValidation = current => {
    return current.isAfter(this.yesterday) && this.state.endDate
      ? current.isSameOrBefore(this.state.endDate)
      : current.isAfter(this.yesterday);
  };

  endDateValidation = picked => {
    if (this.state.startDate !== '') {
      const dayBeforeStartDate = Datetime.moment(this.state.startDate).subtract(
        1,
        'day'
      );
      return picked.isSameOrAfter(dayBeforeStartDate);
    }
    return picked.isAfter(this.yesterday);
  };

  validateDates = () => {
    if (this.state.startDate && this.state.endDate) {
      return this.state.endDate.isAfter(this.state.startDate);
    }
    return true;
  };

  validateCheckedStartDate = () =>
    this.state.startDateCheck && !this.state.startDate;

  validateCheckedEndDate = () => this.state.endDateCheck && !this.state.endDate;

  validateForm = () => {
    return this.state.code && this.state.value && this.state.amount;
  };

  handleSubmit = () => {
    if (!this.validateForm()) {
      return toast.error(this.props.t('form.fillAllRequiredFields'));
    }

    if (!this.validateDates()) {
      return toast.error(
        `${this.props.t('discountCodes.incorrectDatesValid')} ${this.props.t(
          'discountCodes.info1'
        )}`
      );
    }

    if (this.validateCheckedStartDate()) {
      return toast.error(this.props.t('discountCodes.info2'));
    }

    if (this.validateCheckedEndDate()) {
      return toast.error(this.props.t('discountCodes.info3'));
    }

    if (this.state.amount > 1 && !this.state.code.includes('*')) {
      return toast.info(
        `${this.props.t('discountCodes.info4')} ${this.props.t(
          'discountCodes.info5'
        )} np.${this.state.code}-*`
      );
    }

    const data = {
      description: this.state.description,
      code: this.state.code.replace(/  +/g, ' ').trim(),
      validFrom: this.state.startDate || null,
      validTo:
        this.state.endDate ||
        // && this.state.endDate.format('YYYY-MM-DD 23:59:59')
        null,
      isPercentage: this.state.isPercent,
      discount: parseFloat(this.state.value),
      quantity: parseInt(this.state.amount),
      limit: parseInt(this.state.limit),
      limitPerUser: parseInt(this.state.limitPerUser),
      limitPerSubscription: parseInt(this.state.limitPerSubscription),
      minimalOrderValue: this.state.minimalOrderValueCheck
        ? parseInt(this.state.minimalOrderValue)
        : null,
      minimalDietLength: this.state.minimalDietLengthCheck
        ? parseInt(this.state.minimalDietLength)
        : null,
      onlyAdminCanUse: this.state.onlyAdminCanUseCheck,
      disableDietDiscount: this.state.disableDietDiscountCheck,
      applyToAddons: this.state.applyToAddonsCheck,
      diets:
        this.state.selectedDietsCheck && this.state.diets
          ? this.state.diets
          : null,
    };

    post('discount-codes', data).then(
      () => this.props.history.push('/admin/discount-codes'),
      error => {
        if (
          error.response.data.violations &&
          error.response.data.violations[0].propertyPath === '[0].code'
        ) {
          toast.error(this.props.t('discountCodes.info6'));
        } else {
          toast.error(
            `${this.props.t('discountCodes.info7')} ${this.props.t(
              'discountCodes.info8'
            )}`
          );
        }
      }
    );
  };

  render() {
    const { classes, multinational } = this.props;
    return (
      <form>
        <h2>{this.props.t('discountCodes.discountCodeCreate')}</h2>
        <AdminTable>
          <GridContainer>
            <GridItem sm={6}>
              <GridItem sm={12}>
                <FormLabel className={classes.labelHorizontal}>
                  {this.props.t('discountCodes.codeContent')} *
                  {
                    <div style={{ marginLeft: '10px' }}>
                      <Tooltip
                        title={
                          <div>
                            <h4>{this.props.t('discountCodes.tooltip1')}</h4>
                            <h4>{this.props.t('discountCodes.tooltip2')}</h4>
                            <h4>{this.props.t('discountCodes.tooltip3')}</h4>
                          </div>
                        }
                        placement="right"
                      >
                        <Info fontSize={'small'} />
                      </Tooltip>
                    </div>
                  }
                </FormLabel>
                <CustomInput
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    name: 'code',
                    value: this.state.code,
                    onChange: ev => this.handleChange(ev),
                  }}
                />
              </GridItem>
              <GridItem sm={12}>
                <FormLabel
                  style={{ marginTop: '15px' }}
                  className={classes.labelHorizontal}
                >
                  {this.props.t('discountCodes.desc')}
                </FormLabel>
                <CustomInput
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    name: 'description',
                    value: this.state.description,
                    onChange: ev => this.handleChange(ev),
                  }}
                />
              </GridItem>
              <GridItem sm={12}>
                <FormLabel
                  style={{ marginTop: '15px', marginRight: '20px' }}
                  className={classes.labelHorizontal}
                >
                  {this.props.t('discountCodes.discountCodeValue')} *
                </FormLabel>
                <div>
                  <CustomInput
                    inputProps={{
                      name: 'value',
                      value: this.state.value,
                      onChange: ev =>
                        !isNaN(ev.target.value) ? this.handleChange(ev) : false,
                    }}
                  />
                  <FormControlLabel
                    style={{ marginLeft: '10px' }}
                    control={
                      <Radio
                        checked={this.state.isPercent}
                        onChange={() => this.handleRadio('isPercent')}
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                    }}
                    label={this.props.t('discountCodes.percentage')}
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={!this.state.isPercent}
                        onChange={() => this.handleRadio('isPercent')}
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                    }}
                    label={this.props.t('discountCodes.amount')}
                  />
                </div>
              </GridItem>
              <GridItem sm={12}>
                <FormLabel
                  style={{ marginTop: '15px' }}
                  className={classes.labelHorizontal}
                >
                  {this.props.t('discountCodes.discountCodesQty')} *
                </FormLabel>
                <div>
                  <CustomInput
                    inputProps={{
                      name: 'amount',
                      value: this.state.amount,
                      onChange: ev =>
                        !isNaN(ev.target.value) ? this.handleChange(ev) : false,
                    }}
                  />
                </div>
              </GridItem>
              <GridItem sm={12}>
                <Checkbox
                  style={{ marginLeft: '-14px' }}
                  onClick={() => this.handleToggle('onlyAdminCanUse')}
                  checked={this.state.onlyAdminCanUseCheck}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
                <FormLabel
                  style={{ marginBottom: '23px' }}
                  className={classes.labelHorizontal}
                  onClick={() => this.handleToggle('onlyAdminCanUse')}
                >
                  {this.props.t('discountCodes.onlyAdminCanUseCode')}
                </FormLabel>
              </GridItem>
              <GridItem sm={12}>
                <Checkbox
                  style={{ marginLeft: '-14px' }}
                  onClick={() => this.handleToggle('applyToAddons')}
                  checked={this.state.applyToAddonsCheck}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
                <FormLabel
                  style={{ marginBottom: '23px' }}
                  className={classes.labelHorizontal}
                  onClick={() => this.handleToggle('applyToAddons')}
                >
                  {this.props.t('discountCodes.applyToAddons')}
                </FormLabel>
              </GridItem>
              <GridItem sm={12}>
                <Checkbox
                  style={{ marginLeft: '-14px' }}
                  onClick={() => this.handleToggle('disableDietDiscount')}
                  checked={this.state.disableDietDiscountCheck}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
                <FormLabel
                  style={{ marginBottom: '23px' }}
                  className={classes.labelHorizontal}
                  onClick={() => this.handleToggle('disableDietDiscount')}
                >
                  {this.props.t('discountCodes.disableDietDiscount')}
                </FormLabel>
              </GridItem>
            </GridItem>
            <GridItem sm={6}>
              <GridContainer>
                <GridItem sm={12}>
                  <Checkbox
                    style={{ marginLeft: '-14px' }}
                    id={'selectedDietsCheck'}
                    onClick={() => {
                      this.handleToggle('selectedDiets');
                      this.setState({
                        diets: [],
                      });
                    }}
                    checked={this.state.selectedDietsCheck}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                  <FormLabel
                    htmlFor={'selectedDietsCheck'}
                    className={classes.labelHorizontal}
                  >
                    {this.props.t('discountCodes.diets2')}:
                    {
                      <div style={{ marginLeft: '10px' }}>
                        <Tooltip
                          title={
                            <div>
                              <h4>{this.props.t('discountCodes.tooltip4')}</h4>
                              <h4>{this.props.t('discountCodes.tooltip5')}</h4>
                              <h4>
                                {this.props.t('discountCodes.tooltip6', {
                                  defaultValue: '',
                                  replace: {
                                    currencySymbol: currencyToSymbol(
                                      multinational.currency
                                    ),
                                  },
                                })}
                              </h4>
                              <h4>
                                {this.props.t('discountCodes.tooltip7', {
                                  defaultValue: '',
                                  replace: {
                                    currencySymbol: currencyToSymbol(
                                      multinational.currency
                                    ),
                                  },
                                })}
                              </h4>
                            </div>
                          }
                          placement="right"
                        >
                          <Info fontSize={'small'} />
                        </Tooltip>
                      </div>
                    }
                  </FormLabel>
                  <SelectInput
                    classes={classes}
                    multiple={true}
                    mapBy="name"
                    trackBy="@id"
                    name="diets"
                    value={this.state.diets}
                    options={this.props.diets}
                    handleChange={event => {
                      this.handleChange(event);
                      this.setState({
                        selectedDietsCheck: true,
                      });
                    }}
                    id="diets"
                    size={12}
                  />
                </GridItem>
                <GridItem sm={12} style={{ marginTop: '-5px' }}>
                  <Checkbox
                    style={{ marginLeft: '-14px' }}
                    id={'startDateCheck'}
                    onClick={() => this.handleToggle('startDate')}
                    checked={this.state.startDateCheck}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                  <FormLabel
                    htmlFor={'startDateCheck'}
                    className={classes.labelHorizontal}
                  >
                    {this.props.t('discountCodes.activeFrom')}:
                  </FormLabel>
                  <Datetime
                    timeFormat={true}
                    isValidDate={this.startDateValidation}
                    value={
                      this.state.startDateCheck === false
                        ? ''
                        : this.state.startDate
                    }
                    onFocus={() => this.setState({ startDateCheck: true })}
                    onChange={ev => {
                      this.setState({ startDate: ev });
                    }}
                    inputProps={{
                      readOnly: true,
                      // disabled: !this.state.startDateCheck
                    }}
                  />
                </GridItem>
                <GridItem sm={12}>
                  <Checkbox
                    style={{ marginLeft: '-14px' }}
                    id={'endDateCheck'}
                    onClick={() => {
                      this.handleToggle('endDate');
                    }}
                    checked={this.state.endDateCheck}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                  <FormLabel
                    htmlFor={'endDateCheck'}
                    className={classes.labelHorizontal}
                  >
                    {this.props.t('discountCodes.activeTo')}:
                  </FormLabel>
                  <Datetime
                    timeFormat={true}
                    value={
                      this.state.endDateCheck === false
                        ? ''
                        : this.state.endDate
                    }
                    isValidDate={this.endDateValidation}
                    onFocus={() => this.setState({ endDateCheck: true })}
                    onChange={ev => this.setState({ endDate: ev })}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </GridItem>
                <GridItem sm={12}>
                  <Checkbox
                    style={{ marginLeft: '-14px' }}
                    onClick={() => this.handleToggle('limit')}
                    checked={this.state.limitCheck}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                  <FormLabel
                    style={{ marginBottom: '23px' }}
                    className={classes.labelHorizontal}
                  >
                    {this.props.t('discountCodes.usageLimit')}
                  </FormLabel>
                  <div>
                    <CustomInput
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        name: 'limit',
                        value:
                          this.state.limitCheck === false
                            ? ''
                            : this.state.limit,
                        onFocus: () => this.setState({ limitCheck: true }),
                        onChange: ev =>
                          !isNaN(ev.target.value)
                            ? this.handleChange(ev)
                            : false,
                      }}
                    />
                  </div>
                </GridItem>
                <GridItem sm={12}>
                  <Checkbox
                    style={{ marginLeft: '-14px' }}
                    onClick={() => this.handleToggle('limitPerUser')}
                    checked={this.state.limitPerUserCheck}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                  <FormLabel
                    style={{ marginBottom: '23px' }}
                    className={classes.labelHorizontal}
                  >
                    {this.props.t('discountCodes.usageLimitPerUser')}
                  </FormLabel>
                  <div>
                    <CustomInput
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        name: 'limitPerUser',
                        value:
                          this.state.limitPerUserCheck === false
                            ? ''
                            : this.state.limitPerUser,
                        onFocus: () =>
                          this.setState({ limitPerUserCheck: true }),
                        onChange: ev =>
                          !isNaN(ev.target.value)
                            ? this.handleChange(ev)
                            : false,
                      }}
                    />
                  </div>
                </GridItem>
                <GridItem sm={12}>
                  <Checkbox
                    style={{ marginLeft: '-14px' }}
                    onClick={() => this.handleToggle('limitPerSubscription')}
                    checked={this.state.limitPerSubscriptionCheck}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                  <FormLabel
                    style={{ marginBottom: '23px' }}
                    className={classes.labelHorizontal}
                  >
                    {this.props.t('discountCodes.usageLimitPerSubscription')}
                  </FormLabel>
                  <div>
                    <CustomInput
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        name: 'limitPerSubscription',
                        value:
                          this.state.limitPerSubscriptionCheck === false
                            ? ''
                            : this.state.limitPerSubscription,
                        onFocus: () =>
                          this.setState({ limitPerSubscriptionCheck: true }),
                        onChange: ev =>
                          !isNaN(ev.target.value)
                            ? this.handleChange(ev)
                            : false,
                      }}
                    />
                  </div>
                </GridItem>
                <GridItem sm={12}>
                  <Checkbox
                    style={{ marginLeft: '-14px' }}
                    onClick={() => this.handleToggle('minimalOrderValue')}
                    checked={this.state.minimalOrderValueCheck}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                  <FormLabel
                    style={{ marginBottom: '23px' }}
                    className={classes.labelHorizontal}
                  >
                    {this.props.t('discountCodes.minimalOrderValue')}
                  </FormLabel>
                  <div>
                    <CustomInput
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        name: 'minimalOrderValue',
                        value:
                          this.state.minimalOrderValueCheck === false
                            ? ''
                            : this.state.minimalOrderValue,
                        onFocus: () =>
                          this.setState({ minimalOrderValueCheck: true }),
                        onChange: ev =>
                          !isNaN(ev.target.value)
                            ? this.handleChange(ev)
                            : false,
                      }}
                    />
                  </div>
                </GridItem>
                <GridItem sm={12}>
                  <Checkbox
                    style={{ marginLeft: '-14px' }}
                    onClick={() => this.handleToggle('minimalDietLength')}
                    checked={this.state.minimalDietLengthCheck}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                  <FormLabel
                    style={{ marginBottom: '23px' }}
                    className={classes.labelHorizontal}
                  >
                    {this.props.t('discountCodes.minimalDietLength')}
                  </FormLabel>
                  <div>
                    <CustomInput
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        name: 'minimalDietLength',
                        value:
                          this.state.minimalDietLengthCheck === false
                            ? ''
                            : this.state.minimalDietLength,
                        onFocus: () =>
                          this.setState({ minimalDietLengthCheck: true }),
                        onChange: ev =>
                          !isNaN(ev.target.value)
                            ? this.handleChange(ev)
                            : false,
                      }}
                    />
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem sm={6}></GridItem>
          </GridContainer>
          <FormControlButtons
            classes={classes}
            discardText={this.props.t('discountCodes.cancel')}
            submitText={this.props.t('discountCodes.generate')}
            cancelPath="/admin/discount-codes"
            handleSubmit={this.handleSubmit}
          />
        </AdminTable>
      </form>
    );
  }
}

const combinedStyles = combineStyles(buttonsStyle, extendedFormsStyle);

const mapStateToProps = state => {
  return {
    diets: state.Diets.diets,
    multinational: state.Brands.brand.multinational,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchDiets: () => dispatch(fetchDiets()),
});

const enhance = compose(
  withStyles(combinedStyles),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
);

export default enhance(Form);
