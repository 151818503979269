import makeStyles from '@material-ui/styles/makeStyles';
import { Button, TableCell } from '@material-ui/core';
import CustomInput from 'components/CustomInput/CustomInput';
import Editor from 'react-simple-code-editor';
import FormLabel from '@material-ui/core/FormLabel';
import Prism from 'prismjs';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import {
  grayColor,
  turquoiseColor,
  whiteColor,
} from 'assets/jss/material-dashboard-pro-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { toast } from 'react-toastify';

const useStyles = makeStyles({
  ...extendedFormsStyle,
  buttonClient: {
    color: whiteColor,
    margin: '10px 0',
    padding: '3px 25px',
    fontSize: '12px',
  },
  buttonOffice: {
    color: whiteColor,
    margin: '10px 0 10px 5px',
    padding: '3px 25px',
    fontSize: '12px',
  },
  backgroundTurquoise: {
    background: turquoiseColor,
  },
  backgroundGray: {
    background: grayColor[20],
  },
  labelContentSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px 0 5px 0',
    flexWrap: 'wrap',
  },
  parentEditor: {
    maxHeight: '70vh',
    overflowX: 'auto',
  },
  editor: {
    fontFamily: '"Fira code", "Fira Mono", monospace',
    fontSize: 12,
  },
});

const TableContentSection = ({
  mailingOption,
  state,
  setState,
  renderAlert,
}) => {
  const classes = useStyles();
  const isClient = mailingOption.contentType === 'client';
  const isOffice = mailingOption.contentType === 'office';

  const { t } = useTranslation();
  return (
    <TableCell>
      <div>
        <span
          onClick={() =>
            mailingOption.managedBySystem &&
            toast.warning(
              `${t('mailNotifications.thisIsASystemNotification')} ${t(
                'mailNotifications.systemNotificationAvailableOnlyForAdministratrs'
              )}`
            )
          }
        >
          {!mailingOption.enabledForOffice &&
          state.mailForAdmin &&
          mailingOption.mailKey !== 'employee_welcome' ? null : (
            <Button
              className={classNames(
                classes.buttonClient,
                isClient ? classes.backgroundTurquoise : classes.backgroundGray
              )}
              onClick={() => {
                const newMailing = [...state.mailing];
                const optionToChange = newMailing.find(
                  newMailingOption => newMailingOption.id === mailingOption.id
                );
                optionToChange.contentType = 'client';

                setState(prev => ({
                  ...prev,
                  mailing: newMailing,
                }));
              }}
            >
              {t('mailNotifications.forClientLabel')}
            </Button>
          )}
        </span>
        <Button
          className={classNames(
            classes.buttonOffice,
            isOffice ? classes.backgroundTurquoise : classes.backgroundGray
          )}
          onClick={() => {
            const newMailing = [...state.mailing];
            const optionToChange = newMailing.find(
              newMailingOption => newMailingOption.id === mailingOption.id
            );
            optionToChange.contentType = 'office';
            setState(prev => ({
              ...prev,
              mailing: newMailing,
            }));
          }}
        >
          {t('mailNotifications.forAdministators')}
        </Button>
      </div>
      {mailingOption.contentType === 'client' ? (
        <>
          <FormLabel
            htmlFor="titleForClient"
            className={classes.labelHorizontal}
          >
            {t('mailNotifications.titleForClient')}
          </FormLabel>
          <CustomInput
            formControlProps={{ fullWidth: true }}
            inputProps={{
              name: 'titleForClient',
              value: mailingOption.titleForClient,
              onChange: ev => {
                const newMailing = [...state.mailing];
                const optionToChange = newMailing.find(
                  newMailingOption => newMailingOption.id === mailingOption.id
                );
                optionToChange.titleForClient = ev.target.value;
                optionToChange.hasChanged = true;

                setState(prev => ({
                  ...prev,
                  mailing: newMailing,
                }));
              },
            }}
          />
          <div className={classes.labelContentSection}>
            <FormLabel className={classes.labelHorizontal}>
              {t('mailNotifications.contentForClient')}
            </FormLabel>
            <div>
              <Button
                onClick={() => {
                  const newMailing = [...state.mailing];
                  const optionToChange = newMailing.find(
                    newMailingOption => newMailingOption.id === mailingOption.id
                  );
                  optionToChange.isHidden = !mailingOption.isHidden;

                  return setState(prev => ({
                    ...prev,
                    mailing: newMailing,
                  }));
                }}
              >
                {mailingOption.isHidden
                  ? t('mailNotifications.showCode')
                  : t('mailNotifications.hideCode')}
              </Button>
              <Button
                onClick={() => {
                  const win = window.open(
                    '',
                    t('mailNotifications.clientPreview'),
                    'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes'
                  );
                  win.document.write(mailingOption.templateForClient);
                }}
              >
                {t('menuPlanner.action.template.use.preview')}
              </Button>
              <Button
                onClick={() => {
                  renderAlert(
                    () => {
                      const newMailing = [...state.mailing];
                      const optionToChange = newMailing.find(
                        newMailingOption =>
                          newMailingOption.id === mailingOption.id
                      );
                      optionToChange.templateForClient =
                        optionToChange.defaultTemplateForClient;
                      optionToChange.hasChanged = true;

                      setState(prev => ({ ...prev, confirmModal: null }));
                      return setState(prev => ({
                        ...prev,
                        mailing: newMailing,
                      }));
                    },
                    () => setState(prev => ({ ...prev, confirmModal: null }))
                  );
                }}
              >
                {t('common.restoreDefaultFem')}
              </Button>
            </div>
          </div>
          {!mailingOption.isHidden && (
            <div className={classes.parentEditor}>
              <Editor
                value={mailingOption.templateForClient}
                onValueChange={code => {
                  const newMailing = [...state.mailing];
                  const optionToChange = newMailing.find(
                    newMailingOption => newMailingOption.id === mailingOption.id
                  );
                  optionToChange.templateForClient = code;
                  optionToChange.hasChanged = true;

                  setState(prev => ({
                    ...prev,
                    mailing: newMailing,
                  }));
                }}
                highlight={code =>
                  Prism.highlight(code, Prism.languages.jsx, 'jsx')
                }
                padding={10}
                className={classes.editor}
              />
            </div>
          )}
        </>
      ) : (
        <>
          <FormLabel
            htmlFor="titleForOffice"
            className={classes.labelHorizontal}
          >
            {t('mailNotifications.titleForAdministration')}
          </FormLabel>
          <CustomInput
            formControlProps={{ fullWidth: true }}
            inputProps={{
              name: 'titleForOffice',
              value: mailingOption.titleForOffice,
              onChange: ev => {
                const newMailing = [...state.mailing];
                const optionToChange = newMailing.find(
                  newMailingOption => newMailingOption.id === mailingOption.id
                );
                optionToChange.titleForOffice = ev.target.value;
                optionToChange.hasChanged = true;

                setState(prev => ({
                  ...prev,
                  mailing: newMailing,
                }));
              },
            }}
          />
          <div className={classes.labelContentSection}>
            <FormLabel className={classes.labelHorizontal}>
              {t('mailNotifications.contentFormAdministration')}
            </FormLabel>
            <div>
              <Button
                onClick={() => {
                  const newMailing = [...state.mailing];
                  const optionToChange = newMailing.find(
                    newMailingOption => newMailingOption.id === mailingOption.id
                  );
                  optionToChange.isHidden = !mailingOption.isHidden;

                  return setState(prev => ({
                    ...prev,
                    mailing: newMailing,
                  }));
                }}
              >
                {mailingOption.isHidden
                  ? t('mailNotifications.showCode')
                  : t('mailNotifications.hideCode')}
              </Button>
              <Button
                onClick={() => {
                  const win = window.open(
                    '',
                    'Podgląd Administracja',
                    'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes'
                  );
                  win.document.write(mailingOption.templateForOffice);
                }}
              >
                {t('menuPlanner.action.template.use.preview')}
              </Button>
              <Button
                onClick={() => {
                  renderAlert(
                    () => {
                      const newMailing = [...state.mailing];
                      const optionToChange = newMailing.find(
                        newMailingOption =>
                          newMailingOption.id === mailingOption.id
                      );
                      optionToChange.templateForOffice =
                        optionToChange.defaultTemplateForOffice;
                      optionToChange.hasChanged = true;

                      setState(prev => ({ ...prev, confirmModal: null }));
                      return setState(prev => ({
                        ...prev,
                        mailing: newMailing,
                      }));
                    },
                    () => setState(prev => ({ ...prev, confirmModal: null }))
                  );
                }}
              >
                {t('common.restoreDefaultFem')}
              </Button>
            </div>
          </div>
          {!mailingOption.isHidden && (
            <div className={classes.parentEditor}>
              <Editor
                value={mailingOption.templateForOffice}
                onValueChange={code => {
                  const newMailing = [...state.mailing];
                  const optionToChange = newMailing.find(
                    newMailingOption => newMailingOption.id === mailingOption.id
                  );
                  optionToChange.templateForOffice = code;
                  optionToChange.hasChanged = true;

                  setState(prev => ({ ...prev, mailing: newMailing }));
                }}
                highlight={code =>
                  Prism.highlight(code, Prism.languages.jsx, 'jsx')
                }
                padding={10}
                className={classes.editor}
              />
            </div>
          )}
        </>
      )}
    </TableCell>
  );
};

export default TableContentSection;
