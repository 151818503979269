import { useState, useEffect } from 'react';
import { combineStyles } from 'helpers/helpers';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  fetchBrand,
  fetchBrandConfigModulesPack,
  updateBrandConfigModulesPack,
} from 'actions/Brands';
import withStyles from '@material-ui/core/styles/withStyles';
import { modulesNames } from '../consts';
import { fetchZones } from 'actions/Zones';
import { put } from 'helpers/apiHelpers';
import DeliveryOptionsView from './DeliveryOptions.view';
import { toast } from 'react-toastify';

const DeliveryOptionsContainer = ({
  classes,
  t,
  zones,
  selectedBrand,
  fetchBrand,
  fetchZones,
  fetchBrandConfigModulesPack,
  updateBrandConfigModulesPack,
}) => {
  const [state, setState] = useState({
    deliveryToDoorEnabled: true,
    pickUpPointEnabled: false,
    pickUpPointInformationLink: '',
  });
  const [modules, setModules] = useState({
    ConfigClientPanel: {
      '@type': modulesNames.ConfigClientPanel,
      defaultPaymentMode: 2,
      enableVariantStep: true,
      isOneTimePayPaymentMode: true,
      isSubscriptionPaymentMode: false,
      oneTimePaymentDefaultGateway: 'TPAY',
      orderPaymentDefaultGateway: 'TPAY',
      referralProgramDescription: '',
      hideDisabledDaysOnClientCalendar: false,
      subscriptionPaymentDefaultGateway: 'STRIPE_CARD',
      validateBagChangesWithSlots: false,
      showCalendarIcons: true,
      useAddressesWithLessFields: false,
      allowClientPayWithMoneboxEverywhere: false,
    },
    Fakturownia: {
      '@type': modulesNames.Fakturownia,
      addonsCustomName: '',
      addonsCustomNameEnabled: false,
      allowInvoicesForClient: false,
      bagModificationName: '',
      bagModificationVat: 8,
      deliveryItemName: '',
      department: null,
      dietItemName: '',
      dietItemNameEnabled: true,
      enabled: false,
      host: '',
      integrationType: 'DISABLED',
      token: '',
    },
    PayU: {
      '@type': modulesNames.PayU,
      clientId: null,
      clientSecret: '',
      enabled: false,
      md5Key: '',
      posId: null,
      sandbox: false,
      googlePayMerchantId: null,
    },
    Stripe: {
      '@type': modulesNames.Stripe,
      cardPayments: false,
      enabled: false,
      linkPayments: false,
      publishableKey: '',
      rulesLink: null,
      secretKey: '',
    },
    MyLead: {
      '@type': modulesNames.MyLead,
      clickId: null,
      enabled: false,
      payoutDecimal: null,
      providerHash: null,
      userId: null,
    },
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const brandConfig = await fetchBrand(selectedBrand);
      const modulesConfig = await fetchBrandConfigModulesPack(
        selectedBrand,
        Object.values(modulesNames)
      );

      await fetchZones();

      setState(prevState => ({
        ...prevState,
        deliveryToDoorEnabled: brandConfig.deliveryToDoorEnabled,
        pickUpPointEnabled: brandConfig.pickUpPointEnabled,
        pickUpPointInformationLink: brandConfig.pickUpPointInformationLink,
      }));
      setModules(modulesConfig.configuration);

      setIsLoading(false);
    })();
  }, []);

  const handleModuleUpdate = data => {
    const type = data['@type'];
    let module = { ...modules[type], ...data };
    setModules({ ...modules, [type]: module });
  };

  const handleSubmit = async () => {
    try {
      await put(`/brands/${selectedBrand}`, state);
      await updateBrandConfigModulesPack(selectedBrand, modules);

      toast.success(t('success.changesSaved'));
    } catch (error) {
      toast.error(
        error?.message
          ? `${t('errors.changesCouldNotBeSaved')}: ${error.message}`
          : t('errors.changesCouldNotBeSaved')
      );
    }
  };
  const props = {
    classes,
    t,
    zones,
    state,
    setState,
    modules,
    setModules,
    isLoading,
    handleModuleUpdate,
    handleSubmit,
  };

  return <DeliveryOptionsView props={props} />;
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withTranslation(),
  connect(
    ({ Auth: { selectedBrand }, Zones: { zones } }) => ({
      selectedBrand,
      zones,
    }),
    {
      fetchBrand,
      fetchBrandConfigModulesPack,
      fetchZones,
      updateBrandConfigModulesPack,
    }
  ),
  withStyles(combinedStyles)
);
export default enhance(DeliveryOptionsContainer);
