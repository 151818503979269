import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MonetizationOn } from '@material-ui/icons';
import withStyles from '@material-ui/core/styles/withStyles';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle';
import { get } from 'helpers/apiHelpers';

import FoodCostGridModal from './FoodCostGridModal';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

const FoodCost = ({ date, isDraftView = false, exportFoodCost = () => {} }) => {
  const { t } = useTranslation();

  const [isFoodCostExceeded, setIsFoodCostExceeded] = useState(false);
  const [isFoodCostModalOpened, setIsFoodCostModalOpened] = useState(false);

  useEffect(() => {
    checkIfFoodCostExceeded();
  }, [isDraftView, date]);

  const checkIfFoodCostExceeded = async () => {
    if (isDraftView) {
      return setIsFoodCostExceeded(false);
    }

    const foodCosts = await fetchFoodCosts();

    const isSomeFoodCostExpanded = Object.values(foodCosts).some(
      foodCost =>
        foodCost.expectedFoodCost !== null &&
        foodCost.foodCost > foodCost.expectedFoodCost
    );

    return setIsFoodCostExceeded(isSomeFoodCostExpanded);
  };

  const fetchFoodCosts = async () => {
    let fetchedFoodCosts = await get('/menu-planner-food-costs', {
      date: date,
      draft: isDraftView,
    });

    fetchedFoodCosts = fetchedFoodCosts['hydra:member']
      .filter(e => e.expectedFoodCost !== null)
      .map(el => ({
        ...el,
        expectedFoodCost: parseFloat(el.expectedFoodCost),
        foodCost: parseFloat(el.foodCost),
        diffCost: parseFloat(el.diffCost),
        dietCost: parseFloat(el.dietCost),
      }))
      .sort((a, b) => {
        const aDiff = a.expectedFoodCost - a.foodCost;
        const bDiff = b.expectedFoodCost - b.foodCost;

        if (aDiff < bDiff) {
          return -1;
        }
        if (aDiff > bDiff) {
          return 1;
        }
        return 0;
      });
    const newFoodCosts = {};

    fetchedFoodCosts.forEach(foodCost => {
      const key = `${foodCost.diet}${foodCost.variant}${foodCost.calorific}`;
      newFoodCosts[key] = foodCost;
    });

    return newFoodCosts;
  };

  return (
    <>
      <FoodCostGridModal
        isOpened={isFoodCostModalOpened}
        onClose={() => setIsFoodCostModalOpened(false)}
        exportFoodCost={exportFoodCost}
        fetchFoodCosts={fetchFoodCosts}
      />
      <BottomNavigationAction
        label={
          isFoodCostExceeded
            ? t('menuPlanner.action.warning.foodCost.tooltip.actionName')
            : t('menuPlanner.action.warning.foodCostSummary.tooltip.actionName')
        }
        showLabel={true}
        icon={<MonetizationOn style={{ color: '#f65b52' }} />}
        onClick={() => setIsFoodCostModalOpened(true)}
      />
    </>
  );
};

export default withStyles(sweetAlertStyle)((FoodCost));
