import { Table, TableBody, TableRow } from '@material-ui/core';
import { getNumberFromString, isGranted } from 'helpers/helpers';
import makeStyles from '@material-ui/styles/makeStyles';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import roles from 'helpers/roles';
import GridItem from 'components/Grid/GridItem';

import TableHeadSection from './components/TableHeadSection';
import TableWhatMailSection from './components/TableWhatMailSection';
import TableClientCheckboxSection from './components/TableClientCheckboxSection';
import TableAdminCheckboxSection from './components/TableAdminCheckboxSection';
import TableContentSection from './components/TableContentSection';
import TableButtonSaveSection from './components/TableButtonSaveSection';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  ...extendedFormsStyle,
  ...buttonsStyle,
  ...sweetAlertStyle,
  table: { overflow: 'auto' },
});

const MailNotificationCardSectionView = ({ props }) => {
  const {
    mailingOptions,
    state,
    setState,
    renderAlert,
    title,
    brand,
    setAllowToChangedElements,
  } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Card>
      <CardBody>
        <GridItem md={12}>
          <h3>{title}</h3>
        </GridItem>
        {state.confirmModal}
        <div className={classes.table}>
          <Table>
            <TableHeadSection t={t} />
            <TableBody>
              {state?.mailing?.map(mailingOption => {
                const selectedBrandDoesNotMatchDefaultFromConfig =
                  state.defaultConfigFromBrand &&
                  brand !==
                    parseInt(
                      getNumberFromString(state.defaultConfigFromBrand['@id'])
                    );
                if (
                  mailingOption.mailKey === 'lost_password_admin' &&
                  selectedBrandDoesNotMatchDefaultFromConfig
                ) {
                  return null;
                }
                return (
                  <TableRow>
                    <TableWhatMailSection
                      mailingOptions={mailingOptions}
                      mailingOption={mailingOption}
                      t={t}
                    />

                    <TableClientCheckboxSection
                      mailingOption={mailingOption}
                      t={t}
                      setAllowToChangedElements={setAllowToChangedElements}
                      state={state}
                      setState={setState}
                      classes={classes}
                    />

                    <TableAdminCheckboxSection
                      mailingOption={mailingOption}
                      t={t}
                      state={state}
                      setState={setState}
                      classes={classes}
                    />

                    <TableContentSection
                      mailingOption={mailingOption}
                      t={t}
                      classes={classes}
                      state={state}
                      setState={setState}
                      renderAlert={renderAlert}
                    />

                    {isGranted(roles.ROLE_EDIT_MAIL_CONFIGURATION) && (
                      <TableButtonSaveSection
                        mailingOption={mailingOption}
                        t={t}
                        state={state}
                        setState={setState}
                      />
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

export default MailNotificationCardSectionView;
