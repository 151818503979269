import { Component, createRef } from 'react';
import { compose } from 'redux';
import { Trans, withTranslation } from 'react-i18next';
import { get, patch, post } from 'helpers/apiHelpers';
import gastro from 'helpers/gastro';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import DataGrid from 'components/DataGrid/DataGrid';

import { default as baseColumnConfig } from './columnConfig';
import { isGranted } from 'helpers/helpers';
import roles, {
  ROLE_CREATE_ORDER,
  ROLE_EXPORT_ORDER,
  ROLE_IMPORT_ORDER,
  ROLE_SHOW_BASKET,
} from 'helpers/roles';
import InfoModal from 'components/InfoModal/InfoModal';

import VerticalAlignBottom from '@material-ui/icons/VerticalAlignBottom';
import BasketDetails from '../../Baskets/Dialogs/BasketDetails';
import MediaUpload from 'components/MediaUpload/MediaUpload';
import Button from 'components/CustomButtons/Button.jsx';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import importOrdersExampleXlsx from './importExample/import-orders-xlsx-example.xlsx';
import importOrdersExampleXlsxEn from './importExample/import-orders-xlsx-example-en.xlsx';
import { NoteAdd } from '@material-ui/icons';
import BaseBrandReport from 'views/Reports/BaseBrandReport';
import getReportFileName from 'helpers/getReportFileName';
import { toast } from 'react-toastify';

class List extends Component {
  grid = createRef();

  state = {
    sources: [],
    isOrderItemsModalOpened: false,
    isExportOrdersModalOpened: false,
    isModalImportOpened: false,
    orderItemsModalContent: '',
    isUseOrderParam: false,
    file: '',
    importButtonClicked: false,
    isImporting: false,
    isGeneratingInvoice: false,
    selectDropdown: false,
  };

  componentDidMount = async () => {
    const sources = await get('/sources');

    this.setState({ sources: sources?.['hydra:member'] });
  };

  toggleImportModal = () => {
    this.setState({
      isModalImportOpened: !this.state.isModalImportOpened,
      importButtonClicked: false,
    });
  };

  toggleExportModal = () => {
    this.setState({
      isExportOrdersModalOpened: !this.state.isExportOrdersModalOpened,
    });
  };

  toggleIsGeneratingInvoice = () => {
    this.setState({
      isGeneratingInvoice: !this.state.isGeneratingInvoice,
    });
  };

  postImporterTask = file => {
    this.setState({ isImporting: true });
    const data = {
      type: 'ORDERS',
      file: file['@id'],
      config: {
        skipHeader: true,
        delimiter: ';',
      },
    };

    post('/importer-tasks', data).then(
      () => {
        this.setState({
          openedModal: false,
          file: null,
          importButtonClicked: false,
        });
        this.setState({ isImporting: false });
        return toast.success(
          `${this.props.t('orders.importAdded')} ${this.props.t(
            'orders.refreshPage'
          )}`
        );
      },
      () => {
        this.setState({ isImporting: false });
        return toast.error(this.props.t('orders.cannotImport'));
      }
    );

    this.toggleImportModal();
  };

  uploadFile = async file => {
    const data = new FormData();
    data.append('file', file);

    const response = await gastro.post('/media', data);

    return response.data;
  };

  importOrders = () => {
    this.setState({ importButtonClicked: true });
    this.uploadFile(this.state.file).then(file => {
      this.postImporterTask(file);
    });
  };

  getExampleImportFile(language) {
    switch (language) {
      case 'en':
        return importOrdersExampleXlsxEn;
      default:
        return importOrdersExampleXlsx;
    }
  }

  newOrder = () => this.props.history.push('/admin/orders/add');

  readQuery = () => {
    if (!this.props.location) {
      return null;
    }
    const query = new URLSearchParams(this.props.location.search);

    return query.get('orderId');
  };

  customActions = row => {
    const { t } = this.props;
    const actions = [];

    if (isGranted(ROLE_SHOW_BASKET)) {
      actions.push({
        action: () => {},
        icon: !!row.basket && (
          <BasketDetails basketId={row?.basket.split('/').pop()} />
        ),
        disabled: row.basket === null,
        color: row.basket ? 'primary' : 'tumblr',
        simple: true,
        round: false,
        style: { width: undefined },
      });
    }

    actions.push({
      action: props => window.open(props.row.invoicePDF, '_blank'),
      icon: !!row.invoicePDF && <VerticalAlignBottom />,
      disabled: row.invoicePDF === null,
      color: row.invoicePDF ? 'primary' : 'tumblr',
      buttonText:
        row.invoicePDF !== null
          ? ['vat', 'advance'].includes(row.invoiceKind)
            ? t('orders.downloadInvoice', 'Pobierz fakturę')
            : t('orders.downloadReceipt', 'Pobierz Paragon')
          : t('orders.noInvoice', 'Brak faktury'),
      simple: true,
      round: false,
      style: { width: undefined },
    });

    if (
      !row.paymentNeedVerification &&
      !row.invoicePDF &&
      row.priceAfterDiscountWithoutMoneyBox > 0 &&
      row.status.systemValue === 'PAID'
    ) {
      actions.push({
        action: props => {
          this.toggleIsGeneratingInvoice();
          patch(`/orders/${row.id}/generateInvoice`).then(() => {
            props?.grid?.current?.fireFetchData?.();
            this.toggleIsGeneratingInvoice();
          });
        },
        icon: <NoteAdd />,
        disabled: this.state.isGeneratingInvoice,
        color: 'primary',
        buttonText: t('orders.generateInvoice', 'Wygeneruj fakturę'),
        simple: true,
        round: false,
        style: { width: undefined },
      });
    }

    return actions;
  };

  render() {
    const {
      classes,
      t,
      columnConfig,
      showAddButton = true,
      customActions,
      customActionsWidth = 250,
      i18n: { language },
    } = this.props;

    const columnsConfigurator = columnConfig || baseColumnConfig;
    const customActionsBuilder = customActions || this.customActions;

    return (
      <AdminTable title={t('orders.orderList')}>
        <GridContainer>
          <GridItem sm={10}>
            {isGranted(ROLE_IMPORT_ORDER) && (
              <Button
                dyta-cy="button_import-orders"
                style={{ margin: '10px', width: '158px' }}
                color={'info'}
                onClick={this.toggleImportModal}
              >
                {t('orders.orderImport')}
              </Button>
            )}
            {isGranted(ROLE_EXPORT_ORDER) && (
              <Button
                style={{ margin: '10px', width: '158px' }}
                color={'info'}
                onClick={this.toggleExportModal}
              >
                {t('orders.orderExport', 'Eksportuj zamówienia')}
              </Button>
            )}
          </GridItem>
          <GridItem sm={2}>
            {showAddButton && isGranted(ROLE_CREATE_ORDER) && (
              <FormControlButtons
                dataCy="button_new-order"
                classes={classes}
                handleSubmit={() => this.newOrder()}
                submitText={t('orders.newOrder') + '+'}
              />
            )}
          </GridItem>
        </GridContainer>
        <DataGrid
          ref={this.grid}
          refreshOnFetch
          actions={true}
          export={true}
          paginationTop={true}
          paginationBottom={false}
          minRows={20}
          url="/orders"
          reportName="order"
          role="ORDER"
          columns={columnsConfigurator(
            this.grid,
            orderItemsModalContent =>
              this.setState({
                orderItemsModalContent,
                isOrderItemsModalOpened: true,
              }),
            this.props.t,
            this.state.sources,
            this.state.selectDropdown,
            setSelectDropdown =>
              this.setState({ selectDropdown: !this.state.selectDropdown })
          )}
          defaultHiddenColumns={[
            'source',
            'paymentNeedVerification',
            'isInvoiceIncluded',
          ]}
          customActions={customActionsBuilder}
          customActionsWidth={customActionsWidth}
          manipulateQuery={(requestData, query) => {
            if (
              query['items.diet.diet.name'] === 'DIET' ||
              query['items.diet.diet.name'] === 'DAY_DISH' ||
              query['items.diet.diet.name'] === 'DAY_ADDON' ||
              query['items.diet.diet.name'] === 'DAY_MODIFICATION' ||
              query['items.diet.diet.name'] === 'BUY_BAG'
            ) {
              query['itemTypes[]'] = query['items.diet.diet.name'];
              query['items.diet.diet.name'] = null;
            }

            if (query['client.phone.number']) {
              let phoneQuery = query['client.phone.number'].replace(
                /[a-zA-Z!@#$%^&* ()_-]/g,
                ''
              );
              query = { ...query, 'client.phone.number': phoneQuery };
            }

            if (this.props.defaultQuery) {
              query = { ...query, ...this.props.defaultQuery };
            }
            if (query['client.firstName']) {
              const splicedName = query['client.firstName'].split(' ');
              let _andX = [
                [
                  {
                    'client.firstName': splicedName[0],
                    'client.lastName': splicedName[0],
                  },
                ],
              ];

              if (splicedName.length > 1) {
                _andX[0][0]['client.lastName'] = splicedName[1];
              }

              const operator = splicedName.length > 1 ? '_andX' : '_orX';

              delete query['client.firstName'];
              query[operator] = _andX;
            }

            if (query.createdAt) {
              const filters = query.createdAt;
              delete query.createdAt;

              if (filters.after) {
                query['createdAt[after]'] = filters.after;
              }
              if (filters.before) {
                query['createdAt[before]'] = filters.before;
              }
            }
            if (query.priceAfterDiscountWithoutMoneyBox) {
              const filters = query.priceAfterDiscountWithoutMoneyBox;
              delete query.priceAfterDiscountWithoutMoneyBox;
              if (filters.valueFrom) {
                query['priceAfterDiscountWithoutMoneyBox[gte]'] =
                  filters.valueFrom;
              }
              if (filters.valueTo) {
                query['priceAfterDiscountWithoutMoneyBox[lte]'] =
                  filters.valueTo;
              }
            }

            const id = this.readQuery();
            query.id = !this.state.isUseOrderParam && id ? id : query.id;
            this.setState(prevState => ({
              ...prevState,
              isUseOrderParam: true,
            }));

            return query;
          }}
        />
        <InfoModal
          modalState={this.state.isOrderItemsModalOpened}
          modalContent={this.state.orderItemsModalContent}
          closeButtonText={t('clients.close', 'Zamknij')}
          handleClose={isOrderItemsModalOpened => {
            this.setState({ isOrderItemsModalOpened });
          }}
        />
        <InfoModal
          modalState={this.state.isModalImportOpened}
          modalContent={
            <div>
              <h1>{t('orders.orderImport.title')}</h1>
              <p style={{ textAlign: 'left' }}>
                <ul>
                  <Trans
                    i18nKey="orders.importerModalInstructions"
                    components={{
                      b: <b />,
                      br: <br />,
                      li: <li />,
                      a: (
                        <a
                          href={this.getExampleImportFile(language)}
                          download="exampleFile.xlsx"
                        >
                          tutaj
                        </a>
                      ),
                    }}
                  />
                </ul>
              </p>
              <MediaUpload
                file={this.state}
                setFile={file => this.setState({ file: file })}
                buttonText={t('clients.selectCSVFile')}
                addButtonProps={{
                  color: 'info',
                  round: false,
                }}
                changeButtonProps={{
                  color: 'info',
                  round: false,
                }}
                removeButtonProps={{
                  color: 'danger',
                  round: false,
                }}
              />
              <div>
                <Button
                  color={this.state.file ? 'success' : 'default'}
                  disabled={
                    !this.state.file ||
                    this.state.isImporting ||
                    this.state.importButtonClicked
                  }
                  onClick={this.importOrders}
                >
                  {t('clients.import')}
                </Button>
                <Button
                  onClick={() => {
                    this.toggleImportModal();
                  }}
                >
                  {t('clients.close')}
                </Button>
              </div>
            </div>
          }
          closeButtonText={t('clients.close')}
          hideDefaultCloseButton
          handleClose={isModalImportOpened => {
            this.setState({ isModalImportOpened });
          }}
        />
        <InfoModal
          modalState={this.state.isExportOrdersModalOpened}
          modalContent={
            <BaseBrandReport
              title={t('orders.orderExport.title', 'Eksport zamówień')}
              excelUrl="reports/caterings-orders"
              fileName={getReportFileName(
                t('orders.orderExport.title', 'Eksport zamówień')
              )}
              role={roles.ROLE_SHOW_REPORT_INCOME_SUMMARY}
              pdfAvailable={false}
              excelAvailable={true}
              archiveReportNames={['CATERINGS_ORDERS']}
              onAfterGenerate={this.toggleExportModal}
            />
          }
          closeButtonText={t('clients.close', 'Zamknij')}
          handleClose={isExportOrdersModalOpened =>
            this.setState({ isExportOrdersModalOpened })
          }
          fullWidth
        />
      </AdminTable>
    );
  }
}

const enhance = compose(withStyles(buttonsStyle), withTranslation());

export default enhance(List);
