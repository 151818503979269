import React, { useEffect, useState } from 'react';
import { combineStyles } from 'helpers/helpers';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import { withTranslation } from 'react-i18next';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import PriceBlock from '../Common/PriceBlock';
import { get } from 'helpers/apiHelpers';

const BasketItemMealType = ({ row, t }) => {
  const [mealType, setMealType] = useState({});
  const id = row.mealType.replace('/meal-types/', '');

  useEffect(() => {
    get(`/meal-types/${id}`).then(el => setMealType(el));
  }, []);

  return (
    <>
      <GridContainer style={{ padding: '8px' }}>
        <GridItem xs={12}>
          <h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>
            {row.quantity}x {t('basket.details.BasketItemDish.title')}
          </h4>
        </GridItem>
        <GridItem xs={12} style={{ padding: '0 16px' }}>
          <p>
            <b>{t('basket.details.BasketItemDish.mealTypes')}</b>:{' '}
            {mealType.name}
          </p>
          <p>
            <b>{t('basket.details.common.day')}</b>: {row.day}{' '}
            {row.clientDiet && (
              <>
                <b>{t('basket.details.common.forDiet')} </b>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`/admin/ecommerce-diets/preview/${row.clientDiet?.id}`}
                >
                  #{row.clientDiet?.id}
                </a>
              </>
            )}
          </p>
          <h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>
            {t('basket.details.common.cost')}
          </h4>
          <PriceBlock price={row.price} />
        </GridItem>
      </GridContainer>
    </>
  );
};

const combinedStyles = combineStyles(buttonsStyle, extendedFormsStyle);

export default withTranslation()(
  withStyles(combinedStyles)(BasketItemMealType)
);
