import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ModalButton from '../../../components/CustomButtons/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import BooleanView from '../../../components/DataGrid/ValueViews/BooleanView';
import Visibility from '@material-ui/icons/Visibility';
import { OpenInNew } from '@material-ui/icons';
import { isGranted } from 'helpers/helpers';
import makeStyles from '@material-ui/styles/makeStyles';
import useModalController from '../../../hooks/common/useModalController';

const useStyles = makeStyles({
  closeButton: {
    position: 'absolute',
    top: '0',
    right: '0',
    cursor: 'pointer',
  },
  idTableCell: {
    textAlign: 'center',
    border: '1px solid rgb(224, 224, 224)',
  },
  statusTableCell: {
    textAlign: 'center',
    border: '1px solid rgb(224, 224, 224)',
    width: '88px',
  },
  finishedTableCell: {
    textAlign: 'center',
    border: '1px solid rgb(224, 224, 224)',
    width: '70px',
  },
  tableWrapper: { maxHeight: '300px', overflowY: 'scroll' },
  table: { textAlign: 'center' },
  dialog: {
    padding: '6px 24px 24px 24px',
  },
});

const IdDetails = ({ previewKey, previewLink, linkRole, source, finished }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isOpen, open, close } = useModalController();

  const buildLink = id => {
    const filter = window.btoa(
      unescape(
        encodeURIComponent(
          JSON.stringify([{ id: previewKey ?? 'id', value: id }])
        )
      )
    );

    return previewLink + '?filter=' + filter;
  };

  const openLink = id => {
    window.open(buildLink(id), '_blank').focus();
  };

  const renderLink = () => {
    return previewLink && isGranted(linkRole);
  };

  return (
    <>
      <Dialog maxWidth="md" open={isOpen} onClose={close}>
        <DialogContent>
          <ModalButton
            className={classes.closeButton}
            color={'transparent'}
            justIcon
            round
            onClick={close}
          >
            ×
          </ModalButton>
        </DialogContent>
        <DialogContent classes={{ root: classes.dialog }}>
          <Paper>
            <Table className={classes.table}>
              <TableHead>
                <TableRow className={classes.table}>
                  <TableCell className={classes.idTableCell}>ID</TableCell>
                  <TableCell className={classes.statusTableCell}>
                    {t('massAction.dietElements.status')}
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
            <div className={classes.tableWrapper}>
              <Table>
                <TableBody>
                  {source.sort().map(id => (
                    <TableRow key={id}>
                      <TableCell className={classes.idTableCell}>
                        {id}{' '}
                        {renderLink() && (
                          <OpenInNew onClick={() => openLink(id)} />
                        )}
                      </TableCell>
                      <TableCell className={classes.finishedTableCell}>
                        <BooleanView value={finished.includes(id)} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </Paper>
        </DialogContent>
      </Dialog>
      <Button onClick={open}>
        <Visibility />
      </Button>
    </>
  );
};

export default IdDetails;
