import { useState, useEffect } from 'react';
import { compose } from 'redux';
import produce from 'immer';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ColorPicker from 'components/BlockColorPicker/BlockColorPicker';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import AdminTable from 'layouts/AdminTable';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button.jsx';
import { Info } from '@material-ui/icons';
import { Divider, Tooltip } from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import { combineStyles, isGranted } from 'helpers/helpers';
import { get, put } from 'helpers/apiHelpers';
import { ROLE_EDIT_MODULE_DISH_PDF_GENERATOR } from 'helpers/roles';

import FormImageUpload from '../../components/FormImageUpload/FormImageUpload';
import FormLabel from '@material-ui/core/FormLabel';
import FormTextInput from '../../components/FormTextInput/FormTextInput';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FontSelector from 'components/FontSelector/FontSelector';
import allFontsLists from 'components/FontSelector/fontsList';
import { toast } from 'react-toastify';

const AddonsSettings = ({ classes }) => {
  const { t } = useTranslation();
  const brandId = useSelector(state => state.Auth.selectedBrand);
  const [images, setImages] = useState({
    leftStamp: null,
    leftStampUrl: null,
    logoAvers: null,
    logoAversUrl: null,
    logoRevers: null,
    logoReversUrl: null,
    rightStamp: null,
    rightStampUrl: null,
  });
  const [formData, setFormData] = useState({
    accentColor: null,
    leftStampDescription: null,
    showNutritionalValues: false,
  });
  const [modulesIsLoaded, setModulesIsLoaded] = useState(false);
  const [formFontsData, setFormFontsData] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const response = await get(
          `/brands/${brandId}/modules?module[]=DishPDFGenerator&module[]=Fonts`
        );
        //const { '@id': iriId, '@type': iriType, ...onlyFormData } = response;
        const {
          accentColor,
          leftStamp,
          rightStamp,
          leftStampDescription,
          logoAvers,
          logoRevers,
          '@resources': resources,
          showNutritionalValues,
        } = response.configuration.DishPDFGenerator;

        setFormData({
          accentColor,
          leftStampDescription,
          showNutritionalValues,
        });
        setFormFontsData(response.configuration.Fonts);
        setImages({
          leftStamp,
          leftStampUrl: resources[leftStamp]?.contentUrl,
          rightStamp,
          rightStampUrl: resources[rightStamp]?.contentUrl,
          logoAvers,
          logoAversUrl: resources[logoAvers]?.contentUrl,
          logoRevers,
          logoReversUrl: resources[logoRevers]?.contentUrl,
        });
      } catch (error) {
      } finally {
        setModulesIsLoaded(true);
      }
    })();
  }, []);

  const handleInputChange = ({ target: { name, value } }) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSaveImage = (stateName, data) => {
    setImages({
      ...images,
      [stateName]: data?.['@id'] || null,
      [`${stateName}Url`]: data?.contentUrl || null,
    });
  };

  const handleRemoveImage = stateName => {
    setImages({
      ...images,
      [stateName]: null,
      [`${stateName}Url`]: null,
    });
  };

  const handleColorChange = ({ hex: accentColor }) =>
    setFormData({ ...formData, accentColor });

  const getFontTypeByName = typeName => {
    const { font, library, libraryParams } = formFontsData[typeName];
    const libraryFonts = allFontsLists[library];
    const defaultFont = libraryFonts?.find(({ value }) => value === font);

    return {
      ...(defaultFont ? { defaultFont } : {}),
      ...(library ? { defaultFontLibrary: library } : {}),
      ...(libraryParams ? { defaultFontLibraryParams: libraryParams } : {}),
      ...(libraryFonts ? { defaultFontsList: libraryFonts } : {}),
    };
  };

  const setFontTypeByName = typeName => fontObject => {
    setFormFontsData(
      produce(formFontsData, draft => {
        draft[typeName] = fontObject;
      })
    );
  };

  const handleSubmit = async () => {
    const { logoAvers, logoRevers, rightStamp, leftStamp } = images;
    await put(`/brands/${brandId}/modules`, {
      module: 'DishPDFGenerator',
      configuration: {
        DishPDFGenerator: {
          ...formData,
          logoAvers,
          logoRevers,
          leftStamp,
          rightStamp,
        },
        Fonts: { ...formFontsData },
      },
    });

    toast.success(t('status.success.save'));
  };

  if (!modulesIsLoaded) return null;

  return (
    <AdminTable title={t('views.addons.addonsSettings.list')}>
      <GridContainer>
        <GridItem md={12}>
          <h3>{t('views.recipeDatabase.meal.mealPdfSettings')}</h3>
          <Divider style={{ marginBottom: '10px' }} />
        </GridItem>
        <GridItem sm={4}>
          <FormImageUpload
            label={t('form.field.logoAvers')}
            classes={classes}
            stateName={'logoAvers'}
            getImage={handleSaveImage}
            removeImage={handleRemoveImage}
            previewUrl={images.logoAversUrl}
          />
        </GridItem>
        <GridItem sm={4}>
          <FormImageUpload
            label={t('form.field.logoRevers')}
            classes={classes}
            stateName={'logoRevers'}
            getImage={handleSaveImage}
            removeImage={handleRemoveImage}
            previewUrl={images.logoReversUrl}
          />
        </GridItem>
        <GridItem sm={4}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div>
              <FormLabel className={classes.labelHorizontal}>
                {t('form.field.accentColor')}
              </FormLabel>
              <ColorPicker
                color={formData.accentColor || ''}
                handleChange={handleColorChange}
              />
            </div>
          </div>
        </GridItem>
        <GridItem sm={4}>
          <Tooltip
            style={{ color: 'grey', marginBottom: '-4px' }}
            title={<h4>{t('form.field.pdfLeftStampTooltip')}</h4>}
            placement="right"
          >
            <Info fontSize={'small'} />
          </Tooltip>
          <FormImageUpload
            label={t('form.field.leftStampPhoto')}
            classes={classes}
            stateName={'leftStamp'}
            getImage={handleSaveImage}
            removeImage={handleRemoveImage}
            previewUrl={images.leftStampUrl}
          />
        </GridItem>

        <GridItem sm={4}>
          <Tooltip
            style={{ color: 'grey', marginBottom: '-4px' }}
            title={<h4>{t('form.field.pdfRightStampTooltip')}</h4>}
            placement="right"
          >
            <Info fontSize={'small'} />
          </Tooltip>
          <FormImageUpload
            label={t('form.field.rightStamp')}
            classes={classes}
            stateName={'rightStamp'}
            getImage={handleSaveImage}
            removeImage={handleRemoveImage}
            previewUrl={images.rightStampUrl}
          />
        </GridItem>
        <GridItem sm={12}>
          <FormTextInput
            noGrid
            label={t('form.field.leftStampDescription')}
            classes={classes}
            name="leftStampDescription"
            value={formData.leftStampDescription || ''}
            handleChange={handleInputChange}
            inputSize={12}
            multiline={true}
            rows={1}
            rowsMax={20}
            maxLength={5000}
          />
        </GridItem>
        <GridItem sm={12}>
          <FormLabel className={classes.labelHorizontal}>
            {t('brandCfg.font.recipePDFTitle')}
          </FormLabel>
          <FontSelector
            onUpdate={setFontTypeByName('recipePDFTitle')}
            {...getFontTypeByName('recipePDFTitle')}
          />

          <FormLabel
            className={classes.labelHorizontal}
            style={{ marginTop: '20px' }}
          >
            {t('brandCfg.font.recipePDFText')}
          </FormLabel>
          <FontSelector
            onUpdate={setFontTypeByName('recipePDFText')}
            {...getFontTypeByName('recipePDFText')}
          />
        </GridItem>
        <GridItem sm={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.showNutritionalValues}
                onChange={() =>
                  setFormData({
                    ...formData,
                    showNutritionalValues: !formData.showNutritionalValues,
                  })
                }
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            label={t(
              'views.recipeDatabase.meal.mealPdfSettings.showNutritionalValues',
              'Pokaż wartości odżywcze'
            )}
          />
        </GridItem>
      </GridContainer>

      {isGranted(ROLE_EDIT_MODULE_DISH_PDF_GENERATOR) && (
        <GridContainer justify="flex-end">
          <GridItem>
            <Button color="success" onClick={handleSubmit}>
              {t('common.shared.save')}
            </Button>
          </GridItem>
        </GridContainer>
      )}
    </AdminTable>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);
const enhance = compose(withStyles(combinedStyles));

export default enhance(AddonsSettings);
