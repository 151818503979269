import { TableCell } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import { toast } from 'react-toastify';

const TableClientCheckboxSection = ({
  mailingOption,
  t,
  setAllowToChangedElements,
  state,
  setState,
  classes,
}) => {
  return (
    <TableCell>
      {!mailingOption.managedBySystem ? (
        <Checkbox
          checked={
            mailingOption.managedBySystem
              ? false
              : mailingOption.enabledForClient
          }
          onChange={e => {
            setAllowToChangedElements();
            if (mailingOption.managedBySystem) {
              return toast.warning(
                `${t('mailNotifications.thisIsASystemNotification')} ${t(
                  'mailNotifications.systemNotificationAvailableOnlyForAdministratrs'
                )}`
              );
            }

            const newMailing = [...state.mailing];

            const optionToChange = newMailing.find(
              newMailingOption => newMailingOption.id === mailingOption.id
            );

            optionToChange.enabledForClient = !mailingOption.enabledForClient;
            optionToChange.hasChanged = true;

            setState({
              mailing: newMailing,
            });
          }}
          checkedIcon={<Check className={classes.checkedIcon} />}
          icon={<Check className={classes.uncheckedIcon} />}
          classes={{
            checked: classes.checked,
            root: classes.checkRoot,
          }}
        />
      ) : (
        '-'
      )}
    </TableCell>
  );
};

export default TableClientCheckboxSection;
