import { Component } from 'react';
import { orderStyles } from '../../styles';

import { isGranted } from 'helpers/helpers';
import { ROLE_CREATE_SMS, ROLE_SHOW_SMS } from 'helpers/roles';
import { post } from 'helpers/apiHelpers';

import ModalButton from 'components/CustomButtons/Button';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import DataGrid from 'components/DataGrid/DataGrid';
import smsColumnConfig from './smsColumnConfig';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Close from '@material-ui/icons/Close';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const MAX_MESSAGE_LENGTH = 670;

class SMSModal extends Component {
  state = {
    data: '',
    content: '',
  };

  componentDidMount() {
    this.setState({ data: this.props.client.phone.number });
  }

  sendSMS = e => {
    const { t } = this.props;
    if (!this.state.data || !this.state.content) {
      return toast.error(t('errors.numberAndContentReuired'));
    }

    e.preventDefault();
    post('/sms', {
      data: this.state.data,
      content: this.state.content,
      client: this.props.client['@id'],
    }).then(newMessage => {
      this.setState({ content: '' });
      this.props.setSMSModalStatus(false);
      toast.success(t('success.smsSent'));
    });
  };

  render() {
    const { classes, setSMSModalStatus, SMSModalStatus, t } = this.props;
    return (
      <Dialog
        maxWidth="lg"
        fullWidth={true}
        open={SMSModalStatus}
        onClose={() => setSMSModalStatus(false)}
      >
        <DialogContent>
          <ModalButton
            style={orderStyles.closeButton}
            color={'transparent'}
            justIcon
            round
            onClick={() => setSMSModalStatus(false)}
          >
            <Close />
          </ModalButton>
          <GridContainer>
            {isGranted(ROLE_CREATE_SMS) && (
              <GridItem md={isGranted(ROLE_SHOW_SMS) ? 3 : 12}>
                <div style={{ marginLeft: '5px', ...orderStyles.box }}>
                  <b>{t('sms.smsToClient', 'SMS do klienta')}</b>
                  <FormTextInput
                    disabled
                    label={t('clients.phone', 'Telefon')}
                    classes={classes}
                    name="phone"
                    value={this.state.data}
                    inputSize={6}
                    maxLength={64}
                  />
                  <span>
                    {t('sms.charsLeftToUse', 'Pozostałe ilość znaków')}:{' '}
                    {MAX_MESSAGE_LENGTH - this.state.content.length}/
                    {MAX_MESSAGE_LENGTH}
                  </span>
                  <FormTextInput
                    multiline
                    rows={8}
                    label={t('common.messageContent', 'Treść wiadomości')}
                    classes={classes}
                    name="phone"
                    value={this.state.content}
                    handleChange={ev =>
                      this.setState({ content: ev.target.value })
                    }
                    inputSize={12}
                    maxLength={MAX_MESSAGE_LENGTH}
                  />
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <ModalButton
                      color={'primary'}
                      onClick={ev => this.sendSMS(ev)}
                    >
                      {t('sms.send', 'Wyślij SMS')}
                    </ModalButton>
                  </div>
                </div>
              </GridItem>
            )}
            {isGranted(ROLE_SHOW_SMS) && (
              <GridItem md={isGranted(ROLE_CREATE_SMS) ? 9 : 12}>
                <div
                  style={{
                    marginLeft: '5px',
                    ...orderStyles.box,
                    paddingBottom: '10px',
                  }}
                >
                  <b>{t('sms.history', 'Historia wysłanych SMS')}</b>
                </div>
                <DataGrid
                  disableExport
                  actions={false}
                  paginationTop={true}
                  paginationBottom={false}
                  url={`${this.props.client['@id']}/sms-messages`}
                  columns={smsColumnConfig}
                  role="SMS"
                />
              </GridItem>
            )}
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withTranslation()(SMSModal);
