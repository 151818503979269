import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import DayPicker, { DateUtils } from 'react-day-picker';

import { put } from 'helpers/apiHelpers';

import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';

import Button from 'components/CustomButtons/Button';

import DeleteBagConfirmModal from './DeleteBagConfirmModal';
import { toast } from 'react-toastify';

const DeleteBagSection = ({
  bagInfo,
  classes,
  dietInfo,
  isDietDay,
  isLoading,
  orderStyles,
  setPickerDate,
  isDisabledDay,
  setIsLoading,
  setCalendarNeedsUpdate,
  isSelectedDaySubscription,
  renderNotAvailableOptionInSubscriptionTooltip,
}) => {
  const { t } = useTranslation();
  const MONTH_NAMES = moment.localeData().months();
  const WEEKDAY_NAMES_LONG = moment.localeData().weekdays();

  const [returnCost, setReturnCost] = useState(false);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [isMultipleDaysChange, setIsMultipleDaysChange] = useState(false);

  useEffect(() => {
    setSelectedDays([new Date(bagInfo?.date)]);
  }, [bagInfo?.date]);

  const handleSubmit = () => {
    put(`/v2/calendar/${dietInfo?.id}/cancel-bags`, {
      bags: [],
      dates: selectedDays.map(date => new moment(date).format('YYYY-MM-DD')),
    }).catch(({ response }) => {
      switch (response?.status) {
        case 402:
          toast.info(t('singleDayDeteils.bagDeletingIsImposible'));
          break;
        case 409:
          setReturnCost(response?.data?.returnCost);
          setIsConfirmModalOpened(true);
          break;
        default:
          break;
      }
    });
  };

  return (
    <>
      <div style={orderStyles.box}>
        <h1>
          <b>{t('singleDayDetails.deleteBag', 'Usuń torbę')}</b>
        </h1>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            checked={isMultipleDaysChange}
            disabled={isSelectedDaySubscription}
            onChange={() => {
              setIsMultipleDaysChange(!isMultipleDaysChange);
            }}
            icon={<Check className={classes.uncheckedIcon} />}
            checkedIcon={<Check className={classes.checkedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
            style={{ padding: '0 14px 0 0' }}
          />
          <FormLabel className={classes.labelHorizontal}>
            {t('singleDayDetails.deleteMultipleBags', 'Usuń wiele toreb')}
            {isSelectedDaySubscription &&
              renderNotAvailableOptionInSubscriptionTooltip(t)}
          </FormLabel>
        </div>
        {isMultipleDaysChange && (
          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <DayPicker
              style={{ margin: 0 }}
              fromMonth={new Date()}
              months={MONTH_NAMES}
              month={new Date(bagInfo?.date)}
              weekdaysLong={WEEKDAY_NAMES_LONG}
              weekdaysShort={moment.localeData().weekdaysMin()}
              firstDayOfWeek={1}
              selectedDays={selectedDays}
              onMonthChange={monthStart =>
                setPickerDate(new moment(monthStart))
              }
              disabledDays={day =>
                moment(day).isBefore(moment()) ||
                !isDietDay(new moment(day).format('YYYY-MM-DD'))
              }
              onDayClick={(day, { selected }) => {
                if (
                  !selected &&
                  selectedDays.length > 0 &&
                  dietInfo.dietLength === selectedDays.length
                ) {
                  return toast.info(
                    `${t('singleDayDetails.allDaysHaveBeenUsedUp')} ${t(
                      'singleDayDetails.unselectSomeDaysToChooseAnother'
                    )}`
                  );
                }

                const targetDayIsDisabled =
                  isDisabledDay(day, true) ||
                  DateUtils.isSameDay(day, new Date(bagInfo?.date)) ||
                  !isDietDay(new moment(day).format('YYYY-MM-DD'));

                if (targetDayIsDisabled) {
                  return;
                }

                let tmpSelectedDays = [...selectedDays];

                if (selected) {
                  const selectedIndex = tmpSelectedDays.findIndex(selectedDay =>
                    DateUtils.isSameDay(selectedDay, day)
                  );
                  tmpSelectedDays.splice(selectedIndex, 1);
                } else {
                  tmpSelectedDays.push(day);
                }

                setSelectedDays(tmpSelectedDays);
              }}
            />
          </div>
        )}
        <div
          style={{
            width: '100%',
            display: 'flex',
            marginTop: '15px',
            justifyContent: 'center',
          }}
        >
          <Button
            color="danger"
            style={{ width: '50%' }}
            onClick={handleSubmit}
          >
            {t('common.shared.delete', 'Usuń')}
          </Button>
        </div>
      </div>
      <DeleteBagConfirmModal
        classes={classes}
        isOpened={isConfirmModalOpened}
        returnCost={returnCost}
        toggleModal={setIsConfirmModalOpened}
        dietInfo={dietInfo}
        bags={[]}
        isLoading={isLoading}
        dates={selectedDays.map(date => new moment(date).format('YYYY-MM-DD'))}
        setIsLoading={setIsLoading}
        setCalendarNeedsUpdate={setCalendarNeedsUpdate}
      />
    </>
  );
};

export default DeleteBagSection;
