import React from 'react';
import {
  CastForEducation,
  ViewList,
  Visibility,
  VisibilityOff,
  Cached,
  Email,
  CheckBox,
  CheckBoxOutlineBlank,
} from '@material-ui/icons';
import withStyles from '@material-ui/core/styles/withStyles';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import CreateMenuTemplate from './CreateMenuTemplate';
import UseMenuTemplate from './UseMenuTemplate';
import FoodCost from './FoodCost';
import PublishMenu from './PublishMenu';
import WrongCodes from './WrongCodes';
import MacrosExceeded from './MacrosExceeded';
import ExcludedDishesUsed from './ExcludedDishesUsed';
import PlannedInactiveElements from './PlannedInactiveElements';
import { get } from 'helpers/apiHelpers';
import { fetchMenuTemplate, fetchMenuTemplates } from 'actions/MenuTemplates';
import { connect } from 'react-redux';
import { combineStyles, isGranted } from 'helpers/helpers';
import RemoveMenuTemplate from './RemoveMenuTemplate';
import SnackbarContent from 'components/Snackbar/SnackbarContent';
import GridItem from 'components/Grid/GridItem';
import { withTranslation } from 'react-i18next';
import menuPlannerUtlis from '../utils';
import { ROLE_SHOW_MENU_SUMMARY } from '../../../../helpers/roles';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import axios from '../../../../helpers/gastro';
import HideMenuForClients from './HideMenuForClients';
import { toast } from 'react-toastify';

const TableCellStyled = withStyles({
  root: {
    padding: '2px',
  },
})(TableCell);

class ActionMenu extends React.PureComponent {
  state = {
    dishCodes: [],
    foodCosts: {},
    inactiveMenuPlaning: [],
    isValidatingCodes: false,
    hasWrongMenuPlanning: false,
    hasInactivePlanning: false,
    hasHigherFoodCostThanExpected: false,
    macrosExceeded: false,
    wrongRows: [],
  };

  componentDidMount() {
    this.filterInactivePlanners();
    this.fetchDishCodes();
    this.computeWrongDietMacros();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.menuPlanners !== this.props.menuPlanners ||
      prevProps.date !== this.props.date
    ) {
      this.filterInactivePlanners();
      this.fetchDishCodes();
      this.computeWrongDietMacros();
    } else {
      if (
        prevProps.viewMode !== this.props.viewMode ||
        prevProps.showPublishedPlanning !== this.props.showPublishedPlanning
      ) {
        this.computeWrongDietMacros();
      }
    }
  }

  filterInactivePlanners = () => {
    const { variants, diets, menuPlanners } = this.props;

    const inactiveVariants = (variants || [])
      .filter(v => !v.active)
      .map(v => v['@id']);
    const inactiveDiets = (diets || [])
      .filter(d => !d.active)
      .map(d => d['@id']);

    const inactivePlanning = (menuPlanners || []).filter(planner => {
      return (
        planner &&
        (inactiveDiets.includes(planner.diet) ||
          inactiveVariants.includes(planner.variant))
      );
    });

    this.setState(prevState => ({
      ...prevState,
      inactiveMenuPlaning: inactivePlanning,
      hasInactivePlanning: inactivePlanning.length > 0,
    }));
  };

  fetchDishCodes = async () => {
    this.setState(prevState => {
      return {
        ...prevState,
        isValidatingCodes: true,
      };
    });

    const dishCodes = await get('/menu-planner-dishes', {
      date: this.props.date,
    });

    this.setState(prevState => {
      return {
        ...prevState,
        isValidatingCodes: false,
        dishCodes: dishCodes['hydra:member'],
        hasWrongMenuPlanning:
          dishCodes['hydra:member'].filter(el => !el.isValidCode).length > 0,
      };
    });
  };

  errorOnExport = () => {
    toast.error(this.props.t('reports.cannotGenerate'));
  };

  exportFoodCost = () => {
    axios
      .get('/menu-planner-food-costs.xlsx', {
        responseType: 'blob',
        params: {
          date: this.props.date,
          draft: this.props.viewMode === 'DRAFT',
        },
      })
      .then(
        response => {
          if (response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              `${this.props.t('common.dietPrices.expectedFoodCostInfo')}_${
                this.props.date
              }_(${moment().format('YYYY-MM-DD_HH.mm')}).xlsx`
            );
            document.body.appendChild(link);
            link.click();
          } else {
            this.errorOnExport();
          }
        },
        error => console.error(error) && this.errorOnExport()
      );
  };

  isSavedAsMenuTemplate = () => this.props.menuTemplate.hasOwnProperty('@id');

  hasWrongDishes = () =>
    this.state.dishCodes.filter(el => !el.isCorrectDishForDiet).length !== 0;

  findViewMenuPlanners = () => {
    return this.props.menuPlanners.filter(menuPlanner => {
      if (this.props.viewMode === 'DRAFT') {
        if (this.props.showPublishedPlanning) {
          return true;
        } else {
          return menuPlanner.draft === true;
        }
      } else {
        return menuPlanner.draft === false;
      }
    });
  };

  findDietMenuPlanners = diet => {
    return this.findViewMenuPlanners().filter(
      menuPlanner => menuPlanner.diet['@id'] === diet
    );
  };

  isValidMacro = (currentValue, expectedValue, margin) => {
    if (null === currentValue || null === expectedValue || null === margin) {
      return true;
    }

    return (
      currentValue <= expectedValue + margin &&
      currentValue >= expectedValue - margin
    );
  };

  computeWrongDietMacros = () => {
    const { variants, diets } = this.props;
    const macrosChecked = {
      protein: 'assumptionOfProtein',
      carbohydrates: 'assumptionOfCarbohydrates',
      fat: 'assumptionOfFats',
    };
    const wrongRows = [];

    diets.forEach(diet => {
      const dietMenuPlanners = this.findDietMenuPlanners(diet['@id']);

      if (dietMenuPlanners.length > 0) {
        variants.forEach(variant => {
          variant.calories.forEach(calory => {
            let row = {
              macros: {},
            };

            Object.keys(macrosChecked).forEach(macro => {
              row.macros[macro] = {
                current: parseFloat(
                  menuPlannerUtlis.getMacroInPercent(
                    macro,
                    menuPlannerUtlis.constant[macro],
                    calory.id,
                    dietMenuPlanners
                  )
                ),
                expected: diet[macrosChecked[macro]].assumption,
                margin: diet[macrosChecked[macro]].margin,
              };
            });

            Object.keys(row.macros).forEach(macro => {
              row.macros[macro].isValid = this.isValidMacro(
                row.macros[macro].current,
                row.macros[macro].expected,
                row.macros[macro].margin
              );
            });

            if (
              row.macros.protein.isValid +
                row.macros.carbohydrates.isValid +
                row.macros.fat.isValid <
              3
            ) {
              row.diet = diet.name;
              row.variant = variant.name;
              row.calory = calory.name;

              wrongRows.push(row);
            }
          });
        });
      }
    });

    this.setState(prevState => ({ ...prevState, wrongRows: wrongRows }));
    this.setState(prevState => ({
      ...prevState,
      macrosExceeded: wrongRows.length > 0,
    }));
  };

  render() {
    const {
      classes,
      variants,
      diets,
      date,
      viewMode,
      onAutomaticPlan,
      onPublish,
      menuPlanners,
      onGoToSummary,
      onFinishAssignMenu,
      onRemovePlanning,
    } = this.props;

    return (
      <Table style={{ backgroundColor: '#fff' }}>
        <TableHead>
          <TableRow style={{ height: 'auto' }}>
            {viewMode === 'DRAFT' && (
              <>
                <TableCellStyled
                  align={'center'}
                  style={{
                    backgroundColor: '#46ad58',
                    color: '#fff',
                    border: '2px solid #46ad58',
                  }}
                >
                  {this.props.t('menuPlanner.action.plan.groupTitle')}
                </TableCellStyled>
              </>
            )}
            <TableCellStyled
              align={'center'}
              style={{
                backgroundColor: '#01a8bc',
                color: '#fff',
                border: '2px solid #01a8bc',
              }}
            >
              {!this.isSavedAsMenuTemplate() && (
                <>{this.props.t('menuPlanner.action.template.groupTitle')}</>
              )}
              {this.isSavedAsMenuTemplate() && (
                <>
                  {this.props.viewMode === 'DRAFT' && (
                    <SnackbarContent
                      style={{
                        margin: '0 auto',
                        padding: '5px',
                        display: 'block',
                      }}
                      color="warning"
                      message={this.props.t(
                        'menuPlanner.action.template.draftNote'
                      )}
                    />
                  )}
                  {this.props.viewMode !== 'DRAFT' && (
                    <>
                      {this.props.t(
                        'menuPlanner.action.template.publishedNote'
                      )}
                      <strong> {this.props.menuTemplate.name}</strong>
                    </>
                  )}
                </>
              )}
            </TableCellStyled>
            <TableCellStyled
              align={'center'}
              style={{
                backgroundColor: '#f65b52',
                color: '#fff',
                border: '2px solid #f65b52',
              }}
            >
              {this.props.t('menuPlanner.action.warning.groupTitle')}
            </TableCellStyled>
            <TableCellStyled
              align={'center'}
              style={{
                backgroundColor: '#123393',
                color: '#fff',
                border: '2px solid #123393',
              }}
            >
              {this.props.t('menuPlanner.action.configure.groupTitle')}
            </TableCellStyled>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {viewMode === 'DRAFT' && (
              <>
                <TableCellStyled style={{ border: '2px solid #46ad58' }}>
                  <BottomNavigation
                    onChange={() => {}}
                    className={classes.root}
                    showLabels={true}
                    style={{ width: '100%' }}
                  >
                    <BottomNavigationAction
                      label={this.props.t(
                        'menuPlanner.action.plan.randomPlanning'
                      )}
                      icon={<CastForEducation style={{ color: '#46ad58' }} />}
                      onClick={onAutomaticPlan}
                    />
                    <PublishMenu
                      onPublish={onPublish}
                      menuPlanners={menuPlanners}
                    />
                    {isGranted(ROLE_SHOW_MENU_SUMMARY) && (
                      <BottomNavigationAction
                        label={this.props.t('menuPlanner.action.plan.summary')}
                        icon={<ViewList style={{ color: '#46ad58' }} />}
                        onClick={onGoToSummary}
                      />
                    )}
                  </BottomNavigation>
                </TableCellStyled>
              </>
            )}
            <TableCellStyled style={{ border: '2px solid #01a8bc' }}>
              {this.props.viewMode === 'DRAFT' && (
                <BottomNavigation
                  onChange={() => {}}
                  className={classes.root}
                  showLabels={true}
                  style={{ width: '100%' }}
                >
                  <UseMenuTemplate
                    date={date}
                    onFinishAssign={onFinishAssignMenu}
                  />
                </BottomNavigation>
              )}
              {this.props.viewMode !== 'DRAFT' && (
                <>
                  {!this.isSavedAsMenuTemplate() && (
                    <BottomNavigation
                      onChange={() => {}}
                      className={classes.root}
                      showLabels={true}
                      style={{ width: '100%' }}
                    >
                      <CreateMenuTemplate date={date} />
                    </BottomNavigation>
                  )}
                  {this.isSavedAsMenuTemplate() && (
                    <GridItem md={12} style={{ textAlign: 'center' }}>
                      <RemoveMenuTemplate
                        loading={this.state.loading}
                        id={this.props.menuTemplate.id}
                      />
                    </GridItem>
                  )}
                </>
              )}
            </TableCellStyled>
            <TableCellStyled style={{ border: '2px solid #f65b52' }}>
              <BottomNavigation
                onChange={() => {}}
                className={classes.root}
                showLabels={true}
                style={{ width: '100%' }}
              >
                {(this.state.hasWrongMenuPlanning ||
                  this.hasWrongDishes() ||
                  this.state.hasHigherFoodCostThanExpected ||
                  this.state.macrosExceeded ||
                  this.state.hasInactivePlanning) && (
                  <>
                    <MacrosExceeded wrongRows={this.state.wrongRows} />
                    <WrongCodes
                      date={date}
                      hasWrongMenuPlanning={this.state.hasWrongMenuPlanning}
                      dishCodes={this.state.dishCodes}
                    />
                    <ExcludedDishesUsed
                      date={date}
                      dishCodes={this.state.dishCodes}
                    />
                    <PlannedInactiveElements
                      diets={diets}
                      variants={variants}
                      hasInactivePlanning={this.state.hasInactivePlanning}
                      inactiveMenuPlaning={this.state.inactiveMenuPlaning}
                      onRemoveMenu={onRemovePlanning}
                    />
                  </>
                )}
                <FoodCost
                  date={this.props.date}
                  isDraftView={viewMode === 'DRAFT'}
                  exportFoodCost={this.exportFoodCost}
                />
              </BottomNavigation>
            </TableCellStyled>
            <TableCellStyled style={{ border: '2px solid #123393' }}>
              <BottomNavigation
                onChange={() => {}}
                className={classes.root}
                showLabels={true}
                style={{ width: '100%' }}
              >
                {this.props.showDishStats ? (
                  <BottomNavigationAction
                    label={this.props.t(
                      'menuPlanner.action.configure.hideStats'
                    )}
                    showLabel={true}
                    icon={<VisibilityOff style={{ color: '#123393' }} />}
                    onClick={this.props.onToggleStats}
                  />
                ) : (
                  <BottomNavigationAction
                    label={this.props.t(
                      'menuPlanner.action.configure.showStats'
                    )}
                    showLabel={true}
                    icon={<Visibility style={{ color: '#123393' }} />}
                    onClick={this.props.onToggleStats}
                  />
                )}
                {this.props.viewMode === 'DRAFT' && (
                  <BottomNavigationAction
                    label={this.props.t(
                      'menuPlanner.action.configure.multipleVariantPlaning'
                    )}
                    showLabel={true}
                    icon={
                      this.props.multipleVariantPlaningEnabled ? (
                        <CheckBox style={{ color: '#123393' }} />
                      ) : (
                        <CheckBoxOutlineBlank style={{ color: '#123393' }} />
                      )
                    }
                    onClick={this.props.onToggleMultipleVariantPlanning}
                  />
                )}
                {this.props.viewMode !== 'DRAFT' && (
                  <HideMenuForClients date={date} />
                )}
                {this.props.viewMode === 'DRAFT' && (
                  <BottomNavigationAction
                    label={this.props.t(
                      'menuPlanner.action.configure.showPublishedPlanning'
                    )}
                    showLabel={true}
                    icon={
                      this.props.showPublishedPlanning ? (
                        <CheckBox style={{ color: '#123393' }} />
                      ) : (
                        <CheckBoxOutlineBlank style={{ color: '#123393' }} />
                      )
                    }
                    onClick={this.props.onToggleShowPublishedPlanning}
                  />
                )}
                <BottomNavigationAction
                  label={this.props.t('$*common.refresh')}
                  showLabel={true}
                  icon={
                    this.props.loading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <Cached style={{ color: '#123393' }} />
                    )
                  }
                  onClick={this.props.onRefresh}
                />
                {this.props.viewMode !== 'DRAFT' && (
                  <BottomNavigationAction
                    label={this.props.t('menuPlanner.action.notifyClients')}
                    showLabel={true}
                    icon={
                      this.props.loading ? (
                        <CircularProgress size={24} />
                      ) : (
                        <Email style={{ color: '#123393' }} />
                      )
                    }
                    onClick={this.props.onNotifyClients}
                  />
                )}
              </BottomNavigation>
            </TableCellStyled>
          </TableRow>
        </TableBody>
      </Table>
    );
  }
}

const combinedStyles = combineStyles(
  buttonsStyle,
  extendedFormsStyle,
  sweetAlertStyle
);

const mapStateToProps = state => {
  return {
    menuTemplate: state.MenuTemplates.menuTemplate,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchMenuTemplates: (force = false) => dispatch(fetchMenuTemplates(force)),
  fetchMenuTemplate: date => dispatch(fetchMenuTemplate(date)),
});

export default withTranslation()(
  withStyles(combinedStyles)(
    connect(mapStateToProps, mapDispatchToProps)(ActionMenu)
  )
);
