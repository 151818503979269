import { Component } from 'react';

import { combineStyles, isGranted } from 'helpers/helpers';
import { get, post, put } from 'helpers/apiHelpers';
import { ROLE_EDIT_MONEY_BOX_CONFIGURATION } from 'helpers/roles';

import withStyles from '@material-ui/core/styles/withStyles';
import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle';
import CardBody from 'components/Card/CardBody';
import GridItem from 'components/Grid/GridItem';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { TableBody } from '@material-ui/core';
import CustomInput from 'components/CustomInput/CustomInput';
import SelectInput from 'components/FormSelect/SelectInput';
import FormLabel from '@material-ui/core/FormLabel';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import GridContainer from 'components/Grid/GridContainer';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import LogView from 'components/History/LogView';

import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import CardWrapper from 'components/Card/CardWrapper';
import { toast } from 'react-toastify';

const DiscountElementTypes = t => [
  {
    name: t('common.shared.percentage', 'Procentowy'),
    value: true,
  },
  {
    name: t('common.shared.amount', 'Kwotowy'),
    value: false,
  },
];

const recommendationModeOptions = (t, currency) => [
  {
    name: t('common.moneyBox.forTheCommand', 'Za polecenie klienta'),
    value: 'RECOMMENDATION_MODE_LINEAR',
  },
  {
    name: t('common.moneyBox.forEachCoin', { currency }),
    value: 'RECOMMENDATION_MODE_PROGRESSIVE',
  },
];

class MoneyBoxConfigurations extends Component {
  state = {
    shortName: '',
    singularNameDenominator: '',
    pluralNameDenominator: '',
    singularNameGenitive: '',
    pluralNameGenitive: '',
    singularNameLocative: '',
    pluralNameLocative: '',
    icon: null,
    iconUrl: null,
    config: {
      forCash: '',
      cheaperDietDifference: '',
      pointsValue: '',
      forPoints: '',
      cashValue: '',
      markPointsValue: '',
      isDiscountPercentage: true,
      discountValue: '',
      pointsForRecommendation: '',
      pointsForRegister: '',
      allowPayWithMoneyboxInSubscriptionMode: false,
      pointsForMarketingTermsWithRegistration: '',
      recommendationMode: 'RECOMMENDATION_MODE_LINEAR',
    },
    violations: { key: '', message: '' },
    isEdit: false,
    isLoading: true,
    isMounting: true,
    modalIsOpen: false,
    moneyBoxReadMoreLink: '',
  };

  async componentDidMount() {
    await get(`/brands/${this.props.brandId}`).then(res => {
      this.setState({
        moneyBoxReadMoreLink: res?.moneyBoxReadMoreLink,
      });
    });

    await get('/money-box-configurations', { pagination: false }).then(res => {
      const foundConfiguration = res['hydra:member'].length > 0;
      this.setState(prevState => ({
        shortName: foundConfiguration
          ? res['hydra:member'][0].names.shortName
          : prevState.shortName,
        singularNameDenominator: foundConfiguration
          ? res['hydra:member'][0].names.singularNameDenominator
          : prevState.singularNameDenominator,
        pluralNameDenominator: foundConfiguration
          ? res['hydra:member'][0].names.pluralNameDenominator
          : prevState.pluralNameDenominator,
        singularNameGenitive: foundConfiguration
          ? res['hydra:member'][0].names.singularNameGenitive
          : prevState.singularNameGenitive,
        pluralNameGenitive: foundConfiguration
          ? res['hydra:member'][0].names.pluralNameGenitive
          : prevState.pluralNameGenitive,
        singularNameLocative: foundConfiguration
          ? res['hydra:member'][0].names.singularNameLocative
          : prevState.singularNameLocative,
        pluralNameLocative: foundConfiguration
          ? res['hydra:member'][0].names.pluralNameLocative
          : prevState.pluralNameLocative,
        icon: foundConfiguration
          ? res['hydra:member'][0].icon?.['@id']
          : prevState.icon,
        iconUrl: foundConfiguration
          ? res['hydra:member'][0].icon?.contentUrl
          : prevState.iconUrl,
        config: foundConfiguration ? res['hydra:member'][0] : prevState.config,
        isEdit: foundConfiguration ? true : prevState.isEdit,
        isLoading: false,
        isMounting: false,
      }));
    });
  }

  getImage = (stateName, data) => {
    this.setState({
      [stateName]: data?.['@id'] || null,
      [`${stateName}Url`]: data?.contentUrl || null,
    });
  };

  removeImage = stateName => {
    this.setState({
      [stateName]: null,
      [`${stateName}Url`]: null,
    });
  };

  handleNameChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleChange = (name, value) => {
    if (isNaN(value)) {
      return false;
    }
    this.setState({
      config: {
        ...this.state.config,
        [name]: value,
      },
    });
  };

  handleModal = () => {
    this.setState({
      modalIsOpen: !this.state.modalIsOpen,
    });
  };

  handleMoneyBoxLinkChange = (value, name) => {
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = () => {
    this.handleModal();
    const { config } = this.state;

    const pointsData = {
      shortName: this.state.shortName,
      singularNameDenominator: this.state.singularNameDenominator,
      pluralNameDenominator: this.state.pluralNameDenominator,
      singularNameGenitive: this.state.singularNameGenitive,
      pluralNameGenitive: this.state.pluralNameGenitive,
      singularNameLocative: this.state.singularNameLocative,
      pluralNameLocative: this.state.pluralNameLocative,
    };

    if (
      Object.values(pointsData).includes('') &&
      Object.values(pointsData).find(value => value !== '')
    ) {
      return toast.error(this.props.t('common.moneyboxconfig.fillAllFields'));
    }

    const data = {
      names: pointsData,
      forCash: parseFloat(config.forCash),

      pointsValue: parseFloat(config.pointsValue),
      forPoints: parseFloat(config.forPoints),
      cashValue: parseFloat(config.cashValue),
      markPointsValue: parseFloat(config.markPointsValue),
      isDiscountPercentage: config.isDiscountPercentage,
      discountValue: parseFloat(config.discountValue),
      pointsForRecommendation: parseFloat(config.pointsForRecommendation),
      pointsForRegister: parseFloat(config.pointsForRegister),
      cheaperDietDifference: parseFloat(config.cheaperDietDifference),
      pointsForMarketingTermsWithRegistration: parseFloat(
        config.pointsForMarketingTermsWithRegistration
      ),
      recommendationMode: config.recommendationMode,
      icon: this.state.icon,
      allowPayWithMoneyboxInSubscriptionMode:
        config.allowPayWithMoneyboxInSubscriptionMode,
    };

    put(`/brands/${this.props.brandId}`, {
      moneyBoxReadMoreLink: this.state.moneyBoxReadMoreLink,
    });

    return this.state.isEdit
      ? put(this.state.config['@id'], data).then(
          () => toast.error(this.props.t('common.shared.savedChanges')),
          () =>
            toast.error(
              this.props.t('common.moneyboxconfig.formFilledIncorrect')
            )
        )
      : post('money-box-configurations', data).then(() =>
          get('/money-box-configurations').then(
            res => {
              toast.success(this.props.t('common.shared.savedChanges'));
              this.setState(prevState => ({
                config:
                  res['hydra:member'].length > 0
                    ? res['hydra:member'][0]
                    : prevState.config,
                isEdit:
                  res['hydra:member'].length > 0 ? true : prevState.isEdit,
                isLoading: false,
              }));
            },
            () =>
              toast.error(
                this.props.t('common.moneyboxconfig.formFilledIncorrect')
              )
          )
        );
  };

  render() {
    const {
      t,
      classes,
      multinational: { currency },
    } = this.props;
    return (
      <form
        style={{
          opacity: this.state.isMounting ? 0 : 1,
          transition: '0.3s all',
        }}
      >
        <CardWrapper title={t('common.moneyboxconfig.points')}>
          <CardBody>
            <GridItem md={12}>
              <GridContainer>
                <GridItem md={6}>
                  <p>{this.props.t('common.moneyboxconfig.pointsInfo')}</p>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem md={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    {this.props.t('common.moneyboxconfig.shortName')}:
                  </FormLabel>
                  <FormTextInput
                    classes={classes}
                    value={this.state.shortName}
                    name="shortName"
                    placeholder={this.props.t(
                      'common.moneyboxconfig.shortNamePlaceholder'
                    )}
                    handleChange={ev =>
                      this.handleNameChange(ev.target.name, ev.target.value)
                    }
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem md={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    {this.props.t('common.moneyboxconfig.fullname1')}:
                  </FormLabel>
                  <FormTextInput
                    classes={classes}
                    value={this.state.singularNameDenominator}
                    name="singularNameDenominator"
                    placeholder={this.props.t(
                      'common.moneyboxconfig.fullname1Placeholder'
                    )}
                    handleChange={ev =>
                      this.handleNameChange(ev.target.name, ev.target.value)
                    }
                  />
                </GridItem>
                <GridItem md={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    {this.props.t('common.moneyboxconfig.fullname2')}:
                  </FormLabel>
                  <FormTextInput
                    classes={classes}
                    value={this.state.pluralNameDenominator}
                    name="pluralNameDenominator"
                    placeholder={this.props.t(
                      'common.moneyboxconfig.fullname2Placeholder'
                    )}
                    handleChange={ev =>
                      this.handleNameChange(ev.target.name, ev.target.value)
                    }
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem md={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    {this.props.t('common.moneyboxconfig.fullname3')}:
                  </FormLabel>
                  <FormTextInput
                    classes={classes}
                    value={this.state.singularNameGenitive}
                    name="singularNameGenitive"
                    placeholder={this.props.t(
                      'common.moneyboxconfig.fullname3Placeholder'
                    )}
                    handleChange={ev =>
                      this.handleNameChange(ev.target.name, ev.target.value)
                    }
                  />
                </GridItem>
                <GridItem md={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    {this.props.t('common.moneyboxconfig.fullname4')}:
                  </FormLabel>
                  <FormTextInput
                    classes={classes}
                    value={this.state.pluralNameGenitive}
                    name="pluralNameGenitive"
                    placeholder={this.props.t(
                      'common.moneyboxconfig.fullname4Placeholder'
                    )}
                    handleChange={ev =>
                      this.handleNameChange(ev.target.name, ev.target.value)
                    }
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem md={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    {this.props.t('common.moneyboxconfig.fullname5')}:
                  </FormLabel>
                  <FormTextInput
                    classes={classes}
                    value={this.state.singularNameLocative}
                    name="singularNameLocative"
                    placeholder={this.props.t(
                      'common.moneyboxconfig.fullname5Placeholder'
                    )}
                    handleChange={ev =>
                      this.handleNameChange(ev.target.name, ev.target.value)
                    }
                  />
                </GridItem>
                <GridItem md={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    {this.props.t('common.moneyboxconfig.fullname6')}:
                  </FormLabel>
                  <FormTextInput
                    classes={classes}
                    value={this.state.pluralNameLocative}
                    name="pluralNameLocative"
                    placeholder={this.props.t(
                      'common.moneyboxconfig.fullname6Placeholder'
                    )}
                    handleChange={ev =>
                      this.handleNameChange(ev.target.name, ev.target.value)
                    }
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem md={3}>
                  <FormImageUpload
                    label={this.props.t('common.moneyboxconfig.icon')}
                    classes={classes}
                    stateName="icon"
                    getImage={this.getImage}
                    removeImage={this.removeImage}
                    previewUrl={this.state.iconUrl}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </CardBody>
        </CardWrapper>
        <CardWrapper title={t('common.moneyboxconfig.config')}>
          <CardBody>
            <GridItem>
              <GridContainer>
                <GridItem md={12}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell width={'30%'}>
                          {this.props.t('common.moneyboxconfig.description')}
                        </TableCell>
                        <TableCell />
                        <TableCell>
                          {this.props.t('common.moneyboxconfig.amountSpent', {
                            currency,
                          })}
                        </TableCell>
                        <TableCell />
                        <TableCell>
                          {this.props.t('common.moneyboxconfig.pointsCount')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          {this.props.t('common.moneyboxconfig.desc1')}
                          <br />
                          <br />
                          {this.props.t('common.shared.etc')}{' '}
                          <i>
                            {this.props.t('common.moneyboxconfig.etc1', {
                              currency,
                            })}
                          </i>
                        </TableCell>
                        <TableCell align={'right'}>
                          <b>{this.props.t('common.moneyboxconfig.spent')}</b>
                        </TableCell>
                        <TableCell>
                          <div style={{ marginTop: '15px' }}>
                            <CustomInput
                              formControlProps={{ fullWidth: true }}
                              error={this.state.config.forCash === ''}
                              helpText={
                                !this.state.isLoading &&
                                this.state.config.forCash === '' ? (
                                  <div style={{ width: '120px' }}>
                                    {this.props.t(
                                      'common.moneyboxconfig.valueCannotBeEmpty'
                                    )}
                                  </div>
                                ) : (
                                  false
                                )
                              }
                              inputProps={{
                                disabled: !isGranted(
                                  ROLE_EDIT_MONEY_BOX_CONFIGURATION
                                ),
                                type: 'text',
                                placeholder: this.props.t(
                                  'common.moneyboxconfig.typeValue'
                                ),
                                name: 'forCash',
                                value: this.state.config.forCash,
                                onChange: event =>
                                  this.handleChange(
                                    event.target.name,
                                    event.target.value
                                  ),
                              }}
                            />
                          </div>
                        </TableCell>
                        <TableCell align={'right'}>
                          <b>
                            {this.props.t(
                              'common.moneyboxconfig.clientGetPoints'
                            )}
                            :
                          </b>
                        </TableCell>
                        <TableCell>
                          <div style={{ marginTop: '15px' }}>
                            <CustomInput
                              formControlProps={{ fullWidth: true }}
                              error={this.state.config.pointsValue === ''}
                              helpText={
                                !this.state.isLoading &&
                                this.state.config.pointsValue === '' ? (
                                  <div style={{ width: '120px' }}>
                                    {this.props.t(
                                      'common.moneyboxconfig.valueCannotBeEmpty'
                                    )}
                                  </div>
                                ) : (
                                  false
                                )
                              }
                              inputProps={{
                                disabled: !isGranted(
                                  ROLE_EDIT_MONEY_BOX_CONFIGURATION
                                ),
                                type: 'text',
                                placeholder: this.props.t(
                                  'common.moneyboxconfig.typeValue'
                                ),
                                name: 'pointsValue',
                                value: this.state.config.pointsValue,
                                onChange: event =>
                                  this.handleChange(
                                    event.target.name,
                                    event.target.value
                                  ),
                              }}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell>
                          {this.props.t('common.moneyboxconfig.pointsCount')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          {this.props.t('common.moneyboxconfig.desc2')}
                          <br />
                          <br />
                          {this.props.t('common.shared.etc')}{' '}
                          <i>
                            {this.props.t('common.moneyboxconfig.etc2', {
                              currency,
                            })}
                          </i>
                        </TableCell>
                        <TableCell align={'right'}>
                          <b>{this.props.t('common.moneyboxconfig.forAny1')}</b>
                        </TableCell>
                        <TableCell align={'left'}>
                          <b>
                            {this.props.t('common.moneyboxconfig.diff', {
                              currency,
                            })}
                          </b>
                        </TableCell>
                        <TableCell align={'right'}>
                          <b>
                            {this.props.t(
                              'common.moneyboxconfig.clientGetPoints'
                            )}
                            :
                          </b>
                        </TableCell>
                        <TableCell>
                          <div style={{ marginTop: '15px' }}>
                            <CustomInput
                              formControlProps={{ fullWidth: true }}
                              error={
                                this.state.config.cheaperDietDifference === ''
                              }
                              helpText={
                                !this.state.isLoading &&
                                this.state.config.cheaperDietDifference ===
                                  '' ? (
                                  <div style={{ width: '120px' }}>
                                    {this.props.t(
                                      'common.moneyboxconfig.valueCannotBeEmpty'
                                    )}
                                  </div>
                                ) : (
                                  false
                                )
                              }
                              inputProps={{
                                disabled: !isGranted(
                                  ROLE_EDIT_MONEY_BOX_CONFIGURATION
                                ),
                                type: 'text',
                                placeholder: this.props.t(
                                  'common.moneyboxconfig.typeValue'
                                ),
                                name: 'cheaperDietDifference',
                                value: this.state.config.cheaperDietDifference,
                                onChange: event =>
                                  this.handleChange(
                                    event.target.name,
                                    event.target.value
                                  ),
                              }}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell />
                        <TableCell>
                          {this.props.t('common.moneyboxconfig.pointsCount')}
                        </TableCell>
                        <TableCell />
                        <TableCell>
                          {this.props.t(
                            'common.moneyboxconfig.discountAmount',
                            {
                              currency,
                            }
                          )}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          {this.props.t('common.moneyboxconfig.desc3')}
                          <br />
                          <br />
                          {this.props.t('common.shared.etc')}{' '}
                          <i>
                            {this.props.t('common.moneyboxconfig.etc3', {
                              currency,
                            })}
                          </i>
                        </TableCell>
                        <TableCell align={'right'}>
                          <b>
                            {this.props.t('common.moneyboxconfig.forAnySpent')}
                          </b>
                        </TableCell>
                        <TableCell>
                          <div style={{ marginTop: '15px' }}>
                            <CustomInput
                              formControlProps={{ fullWidth: true }}
                              error={this.state.config.forPoints === ''}
                              helpText={
                                !this.state.isLoading &&
                                this.state.config.forPoints === '' ? (
                                  <div style={{ width: '120px' }}>
                                    {this.props.t(
                                      'common.moneyboxconfig.valueCannotBeEmpty'
                                    )}
                                  </div>
                                ) : (
                                  false
                                )
                              }
                              inputProps={{
                                disabled: !isGranted(
                                  ROLE_EDIT_MONEY_BOX_CONFIGURATION
                                ),
                                type: 'text',
                                placeholder: this.props.t(
                                  'common.moneyboxconfig.typeValue'
                                ),
                                name: 'forPoints',
                                value: this.state.config.forPoints,
                                onChange: event =>
                                  this.handleChange(
                                    event.target.name,
                                    event.target.value
                                  ),
                              }}
                            />
                          </div>
                        </TableCell>
                        <TableCell align={'right'}>
                          <b>
                            {this.props.t(
                              'common.moneyboxconfig.clientGetDiscount'
                            )}
                            :
                          </b>
                        </TableCell>
                        <TableCell>
                          <div style={{ marginTop: '15px' }}>
                            <CustomInput
                              formControlProps={{ fullWidth: true }}
                              error={this.state.config.cashValue === ''}
                              helpText={
                                !this.state.isLoading &&
                                this.state.config.cashValue === '' ? (
                                  <div style={{ width: '120px' }}>
                                    {this.props.t(
                                      'common.moneyboxconfig.valueCannotBeEmpty'
                                    )}
                                  </div>
                                ) : (
                                  false
                                )
                              }
                              inputProps={{
                                disabled: !isGranted(
                                  ROLE_EDIT_MONEY_BOX_CONFIGURATION
                                ),
                                type: 'text',
                                placeholder: this.props.t(
                                  'common.moneyboxconfig.typeValue'
                                ),
                                name: 'cashValue',
                                value: this.state.config.cashValue,
                                onChange: event =>
                                  this.handleChange(
                                    event.target.name,
                                    event.target.value
                                  ),
                              }}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell>
                          {this.props.t('common.moneyboxconfig.pointsCount')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          {this.props.t('common.moneyboxconfig.desc4')}
                          <br />
                          <br />
                          {this.props.t('common.shared.etc')}{' '}
                          <i>{this.props.t('common.moneyboxconfig.etc4')}</i>
                        </TableCell>
                        <TableCell align={'right'}>
                          <b>{this.props.t('common.moneyboxconfig.forAny2')}</b>
                        </TableCell>
                        <TableCell align={'left'}>
                          <b>{this.props.t('common.moneyboxconfig.oneRate')}</b>
                        </TableCell>
                        <TableCell align={'right'}>
                          <b>
                            {this.props.t(
                              'common.moneyboxconfig.clientGetPoints'
                            )}
                            :
                          </b>
                        </TableCell>
                        <TableCell>
                          <div style={{ marginTop: '15px' }}>
                            <CustomInput
                              formControlProps={{ fullWidth: true }}
                              error={this.state.config.markPointsValue === ''}
                              helpText={
                                !this.state.isLoading &&
                                this.state.config.markPointsValue === '' ? (
                                  <div style={{ width: '120px' }}>
                                    {this.props.t(
                                      'common.moneyboxconfig.valueCannotBeEmpty'
                                    )}
                                  </div>
                                ) : (
                                  false
                                )
                              }
                              inputProps={{
                                disabled: !isGranted(
                                  ROLE_EDIT_MONEY_BOX_CONFIGURATION
                                ),
                                type: 'text',
                                placeholder: this.props.t(
                                  'common.moneyboxconfig.typeValue'
                                ),
                                name: 'markPointsValue',
                                value: this.state.config.markPointsValue,
                                onChange: event =>
                                  this.handleChange(
                                    event.target.name,
                                    event.target.value
                                  ),
                              }}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell>
                          {this.props.t('common.moneyboxconfig.discountValue')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          {this.props.t('common.moneyboxconfig.desc5')}
                          <br />
                          <br />
                          {this.props.t('common.shared.etc')}{' '}
                          <i>
                            {this.props.t('common.moneyboxconfig.etc5', {
                              currency,
                            })}
                          </i>
                        </TableCell>
                        <TableCell align={'right'}>
                          <b>
                            {this.props.t('common.moneyboxconfig.forUseCode')}
                          </b>
                        </TableCell>
                        <TableCell align={'left'}>
                          <b>
                            {this.props.t(
                              'common.moneyboxconfig.clientGetDiscount'
                            )}
                          </b>
                        </TableCell>
                        <TableCell align={'left'}>
                          <div style={{ marginRight: '-15px' }}>
                            <SelectInput
                              classes={this.props.classes}
                              trackBy={'value'}
                              mapBy={'name'}
                              disabled={
                                !isGranted(ROLE_EDIT_MONEY_BOX_CONFIGURATION)
                              }
                              options={DiscountElementTypes(this.props.t)}
                              value={this.state.config.isDiscountPercentage}
                              handleChange={(ev, obj) =>
                                this.setState(prevState => {
                                  return {
                                    config: {
                                      ...prevState.config,
                                      isDiscountPercentage: obj.value,
                                    },
                                  };
                                })
                              }
                            />
                          </div>
                        </TableCell>
                        <TableCell>
                          <div style={{ marginTop: '22px' }}>
                            <CustomInput
                              formControlProps={{ fullWidth: true }}
                              error={this.state.config.discountValue === ''}
                              helpText={
                                !this.state.isLoading &&
                                this.state.config.discountValue === '' ? (
                                  <div style={{ width: '120px' }}>
                                    {this.props.t(
                                      'common.moneyboxconfig.valueCannotBeEmpty'
                                    )}
                                  </div>
                                ) : (
                                  false
                                )
                              }
                              inputProps={{
                                disabled: !isGranted(
                                  ROLE_EDIT_MONEY_BOX_CONFIGURATION
                                ),
                                type: 'text',
                                placeholder: this.props.t(
                                  'common.moneyboxconfig.typeValue'
                                ),
                                name: 'discountValue',
                                value: this.state.config.discountValue,
                                onChange: event =>
                                  this.handleChange(
                                    event.target.name,
                                    event.target.value
                                  ),
                              }}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell>
                          {this.props.t('common.moneyboxconfig.pointsQty')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          {this.props.t('common.moneyboxconfig.desc6')}
                          <br />
                          <br />
                          <SelectInput
                            classes={this.props.classes}
                            trackBy={'value'}
                            mapBy={'name'}
                            label={this.props.t(
                              'prices.moneyBoxConf.modeOfAction',
                              'Sposób działania'
                            )} //
                            disabled={
                              !isGranted(ROLE_EDIT_MONEY_BOX_CONFIGURATION)
                            }
                            options={recommendationModeOptions(
                              this.props.t,
                              currency
                            )}
                            value={this.state.config.recommendationMode}
                            handleChange={(ev, obj) =>
                              this.setState(prevState => {
                                return {
                                  config: {
                                    ...prevState.config,
                                    recommendationMode: obj.value,
                                  },
                                };
                              })
                            }
                          />
                          {this.props.t('common.shared.etc')}
                          {this.state.config.recommendationMode ===
                            'RECOMMENDATION_MODE_LINEAR' && (
                            <i>{this.props.t('common.moneyboxconfig.etc6')}</i>
                          )}
                          {this.state.config.recommendationMode ===
                            'RECOMMENDATION_MODE_PROGRESSIVE' && (
                            <i>
                              {this.props.t('common.moneyboxconfig.etc7', {
                                currency,
                              })}
                            </i>
                          )}
                        </TableCell>
                        <TableCell align={'right'}>
                          {this.state.config.recommendationMode ===
                            'RECOMMENDATION_MODE_LINEAR' && (
                            <b>
                              {this.props.t(
                                'common.moneyboxconfig.forRefNewClient'
                              )}
                            </b>
                          )}
                          {this.state.config.recommendationMode ===
                            'RECOMMENDATION_MODE_PROGRESSIVE' && (
                            <b>
                              {this.props.t('common.moneyBox.forEachCoin', {
                                currency,
                              })}
                            </b>
                          )}
                        </TableCell>
                        <TableCell align={'left'}>
                          {this.state.config.recommendationMode ===
                            'RECOMMENDATION_MODE_LINEAR' && (
                            <b>
                              {this.props.t(
                                'common.moneyboxconfig.witchUseCode'
                              )}
                            </b>
                          )}
                          {this.state.config.recommendationMode ===
                            'RECOMMENDATION_MODE_PROGRESSIVE' && (
                            <b>
                              {this.props.t(
                                'common.moneyboxconfig.spentForNewClient'
                              )}
                            </b>
                          )}
                        </TableCell>
                        <TableCell align={'right'}>
                          <b>
                            {this.props.t('common.moneyboxconfig.refGetPoints')}
                            :
                          </b>
                        </TableCell>
                        <TableCell>
                          <div style={{ marginTop: '22px' }}>
                            <CustomInput
                              formControlProps={{ fullWidth: true }}
                              error={
                                this.state.config.pointsForRecommendation === ''
                              }
                              helpText={
                                !this.state.isLoading &&
                                this.state.config.pointsForRecommendation ===
                                  '' ? (
                                  <div style={{ width: '120px' }}>
                                    {this.props.t(
                                      'common.moneyboxconfig.valueCannotBeEmpty'
                                    )}
                                  </div>
                                ) : (
                                  false
                                )
                              }
                              inputProps={{
                                disabled: !isGranted(
                                  ROLE_EDIT_MONEY_BOX_CONFIGURATION
                                ),
                                type: 'text',
                                placeholder: this.props.t(
                                  'common.moneyboxconfig.typeValue'
                                ),
                                name: 'pointsForRecommendation',
                                value:
                                  this.state.config.pointsForRecommendation,
                                onChange: event =>
                                  this.handleChange(
                                    event.target.name,
                                    event.target.value
                                  ),
                              }}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell>
                          {this.props.t('common.moneyboxconfig.pointsQty')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          {this.props.t('common.moneyboxconfig.desc8')}
                          <br />
                          <br />
                          {this.props.t('common.shared.etc')}{' '}
                          <i>{this.props.t('common.moneyboxconfig.etc8')}</i>
                        </TableCell>
                        <TableCell align={'right'}></TableCell>
                        <TableCell align={'left'}>
                          <b>
                            {this.props.t(
                              'common.moneyboxconfig.forAccountCreate'
                            )}
                          </b>
                        </TableCell>
                        <TableCell align={'right'}>
                          <b>
                            {this.props.t(
                              'common.moneyboxconfig.clientGetPoints'
                            )}
                            :
                          </b>
                        </TableCell>
                        <TableCell>
                          <div style={{ marginTop: '22px' }}>
                            <CustomInput
                              formControlProps={{ fullWidth: true }}
                              error={this.state.config.pointsForRegister === ''}
                              helpText={
                                !this.state.isLoading &&
                                this.state.config.pointsForRegister === '' ? (
                                  <div style={{ width: '120px' }}>
                                    {this.props.t(
                                      'common.moneyboxconfig.valueCannotBeEmpty'
                                    )}
                                  </div>
                                ) : (
                                  false
                                )
                              }
                              inputProps={{
                                disabled: !isGranted(
                                  ROLE_EDIT_MONEY_BOX_CONFIGURATION
                                ),
                                type: 'text',
                                placeholder: this.props.t(
                                  'common.moneyboxconfig.typeValue'
                                ),
                                name: 'pointsForRegister',
                                value: this.state.config.pointsForRegister,
                                onChange: event =>
                                  this.handleChange(
                                    event.target.name,
                                    event.target.value
                                  ),
                              }}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell>
                          {this.props.t('common.moneyboxconfig.pointsQty')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          {this.props.t('common.moneyboxconfig.desc9')}
                          <br />
                          <br />
                          {this.props.t('common.shared.etc')}{' '}
                          <i>{this.props.t('common.moneyboxconfig.etc9')}</i>
                        </TableCell>
                        <TableCell align={'right'}></TableCell>
                        <TableCell align={'left'}>
                          <b>
                            {this.props.t('common.moneyboxconfig.forAccept')}
                          </b>
                        </TableCell>
                        <TableCell align={'right'}>
                          <b>
                            {this.props.t(
                              'common.moneyboxconfig.clientGetPoints'
                            )}
                            :
                          </b>
                        </TableCell>
                        <TableCell>
                          <div style={{ marginTop: '22px' }}>
                            <CustomInput
                              formControlProps={{ fullWidth: true }}
                              error={
                                this.state.config
                                  .pointsForMarketingTermsWithRegistration ===
                                ''
                              }
                              helpText={
                                !this.state.isLoading &&
                                this.state.config
                                  .pointsForMarketingTermsWithRegistration ===
                                  '' ? (
                                  <div style={{ width: '120px' }}>
                                    {this.props.t(
                                      'common.moneyboxconfig.valueCannotBeEmpty'
                                    )}
                                  </div>
                                ) : (
                                  false
                                )
                              }
                              inputProps={{
                                disabled: !isGranted(
                                  ROLE_EDIT_MONEY_BOX_CONFIGURATION
                                ),
                                type: 'text',
                                placeholder: this.props.t(
                                  'common.moneyboxconfig.typeValue'
                                ),
                                name: 'pointsForMarketingTermsWithRegistration',
                                value:
                                  this.state.config
                                    .pointsForMarketingTermsWithRegistration,
                                onChange: event =>
                                  this.handleChange(
                                    event.target.name,
                                    event.target.value
                                  ),
                              }}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </GridItem>
              </GridContainer>
            </GridItem>
          </CardBody>
        </CardWrapper>
        <CardWrapper
          title={t(
            'common.moneyboxconfig.detailSettings',
            'Ustawienia szczegółowe'
          )}
        >
          <CardBody>
            <GridItem md={12}>
              <GridContainer>
                <GridItem md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="allowPayWithMoneyboxInSubscriptionMode"
                        onClick={ev =>
                          this.handleChange(
                            ev.target.name,
                            !this.state.config
                              .allowPayWithMoneyboxInSubscriptionMode
                          )
                        }
                        checked={
                          this.state.config
                            .allowPayWithMoneyboxInSubscriptionMode
                        }
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                    }}
                    label={
                      <>
                        {t(
                          ('common.moneyboxconfig.detailSettings.allowToPayWithPointsForIntent',
                          'Umożliwiaj płatność punktami ze skarbonki z odnowienia subskrypcji')
                        )}
                      </>
                    }
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem md={12}>
              <GridContainer>
                <GridItem>
                  <FormTextInput
                    label={t('brandCfg.moneyboxAddr')}
                    classes={classes}
                    name="moneyBoxReadMoreLink"
                    value={this.state.moneyBoxReadMoreLink}
                    maxLength={255}
                    handleChange={e => {
                      this.handleMoneyBoxLinkChange(
                        e.target.value,
                        e.target.name
                      );
                    }}
                    inputSize={12}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </CardBody>
        </CardWrapper>
        <Modal
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          // open={this.state.modalIsOpen}
          open={this.state.modalIsOpen}
          onClose={this.handleModal}
        >
          <div
            style={{
              background: '#fff',
              width: '25%',
              padding: '30px',
            }}
          >
            <Typography
              style={{ marginBottom: '50px' }}
              variant="h5"
              id="modal-title"
            >
              Czy na pewno chcesz to zrobić?
            </Typography>
            <Typography variant="subtitle1" id="simple-modal-description">
              Wprowadzona zmiana spowoduje, ponowne przeliczenie punktów na
              kontach klientów.
            </Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '40px',
              }}
            >
              <Button
                style={{ paddingInline: '35px' }}
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.handleModal}
              >
                Nie
              </Button>
              <Button
                style={{ background: '#4caf50', paddingInline: '35px' }}
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.handleSubmit}
              >
                Tak
              </Button>
            </div>
          </div>
        </Modal>
        {isGranted(ROLE_EDIT_MONEY_BOX_CONFIGURATION) && (
          <LogView
            classes={classes}
            handleSubmit={() => this.handleModal()}
            submitDisabled={this.state.isLoading}
            submitText={this.props.t('common.shared.save')}
            iri={'/money-box-configurations'}
          />
        )}
        {!isGranted(ROLE_EDIT_MONEY_BOX_CONFIGURATION) && (
          <LogView iri={'/money-box-configurations'} />
        )}
      </form>
    );
  }
}

const combinedStyles = combineStyles(
  extendedFormsStyle,
  validationFormsStyle,
  buttonsStyle
);

const mapStateToProps = state => {
  return {
    multinational: state.Brands.brand.multinational,
    brandId: state.Brands.brand.id,
  };
};

const enhance = compose(
  connect(mapStateToProps),
  withStyles(combinedStyles),
  withTranslation()
);

export default enhance(MoneyBoxConfigurations);
