import { useState } from 'react';
import Button from '@material-ui/core/Button';
import { isGranted } from 'helpers/helpers';
import Moment from 'moment';
import { useTranslation } from 'react-i18next';
import DataGridWithoutArchive from '../DataGrid/DataGridWithoutArchive';
import Filters from './Filters';
import DateFilter from '../Grid/Filter/Date';
import { connect } from 'react-redux';
import { ROLE_SHOW_REPORT_ARCHIVE } from 'helpers/roles';
import DownloadButton from './DownloadButton';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  toggleButton: { display: 'flex', justifyContent: 'center' },
});

const Archive = ({ opened, hideButtonHidden, type, reportName, user }) => {
  const { t } = useTranslation();
  const [isShowArchive, setIsShowArchive] = useState(opened);
  const classes = useStyles();

  const toggleHistory = () => {
    setIsShowArchive(!isShowArchive);
  };

  return (
    <>
      {!opened && (
        <>
          {isShowArchive && !hideButtonHidden && (
            <div className={classes.toggleButton}>
              <Button onClick={toggleHistory}>
                {t('reportArchive.hideArchive')}
              </Button>
            </div>
          )}
          {!isShowArchive && (
            <div className={classes.toggleButton}>
              <Button onClick={toggleHistory}>
                {t('reportArchive.showArchive')}
              </Button>
            </div>
          )}
        </>
      )}
      {(opened || isShowArchive) && (
        <DataGridWithoutArchive
          minRows={10}
          actions={true}
          remove={false}
          export={false}
          paginationTop={true}
          paginationBottom={false}
          url="export-archives"
          manipulateQuery={(requestData, query) => {
            query.type = type;
            query.reportName = reportName;

            return query;
          }}
          columns={[
            {
              title: 'reportArchive.userRaw',
              name: 'userRaw',
              accessor: 'userRaw',
              filterable: true,
              sortable: false,
            },
            {
              title: 'reportArchive.generatedAt',
              name: 'generatedAt',
              accessor: row => Moment(row.generatedAt).format('llll'),
              filterable: true,
              sortable: false,
              Filter: ({ onChange, filter }) => {
                return <DateFilter onChange={onChange} value={filter?.value} />;
              },
            },
            {
              headerStyles: {
                position: 'absolute',
                left: 0,
                right: 0,
                textAlign: 'end',
              },
              title: 'reportArchive.actions',
              name: 'filters',
              accessor: row => <Filters filters={row.filters} />,
              filterable: false,
              sortable: false,
              width: 70,
            },
            {
              title: '',
              name: 'download',
              accessor: row => (
                <DownloadButton
                  row={row}
                  accessGranted={
                    row?.user?.id === parseInt(user.id.split('/').pop()) ||
                    isGranted(ROLE_SHOW_REPORT_ARCHIVE)
                  }
                />
              ),
              filterable: false,
              sortable: false,
              width: 70,
            },
          ]}
        />
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    user: state.Auth.user,
  };
};

export default connect(mapStateToProps)(Archive);
