import { openSweetToast } from '../../actions/App';
import { connect } from 'react-redux';

import InfoIcon from 'assets/img/InfoIcon';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  toast: { display: 'block' },
});

const Filters = ({ filters, openSweetToast }) => {
  const classes = useStyles();

  const showFilter = async () => {
    openSweetToast({
      style: { display: 'block' },
      content: (
        <div className={classes.toastContent}>
          <pre>{JSON.stringify(filters, null, 2)}</pre>
        </div>
      ),
      showCancel: false,
    });
  };
  return (
    <div className={classes.icon} onClick={showFilter}>
      <InfoIcon />
    </div>
  );
};

const mapDispatchToProps = {
  openSweetToast,
};

export default connect(null, mapDispatchToProps)(Filters);
