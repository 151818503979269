import { useState, useEffect } from 'react';

import { FakturowniaModule } from './modules';
import { combineStyles } from 'helpers/helpers';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  fetchBrand,
  fetchBrandConfigModulesPack,
  fetchPaymentTypes,
  updateBrandConfigModulesPack,
} from 'actions/Brands';
import withStyles from '@material-ui/core/styles/withStyles';
import { modulesNames, modulesDefaultState } from './consts';
import { DialogLoader } from 'components/DialogLoader';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';

const Accounting = ({
  classes,
  t,
  fetchBrandConfigModulesPack,
  selectedBrand,
  updateBrandConfigModulesPack,
}) => {
  const [modules, setModules] = useState(modulesDefaultState);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const modulesConfig = await fetchBrandConfigModulesPack(
        selectedBrand,
        Object.values(modulesNames)
      );

      setModules(modulesConfig.configuration);
      setIsLoading(false);
    })();
  }, []);

  const handleModuleUpdate = data => {
    const type = data['@type'];
    let module = { ...modules[type], ...data };
    setModules({ ...modules, [type]: module });
  };

  const handleSubmit = async () => {
    if (module?.dietItemNameEnabled && isEmpty(module?.dietItemName)) {
      return toast.error(t('$*fakturowniaModule.error.noDietItemName'));
    }

    try {
      await updateBrandConfigModulesPack(selectedBrand, modules);
      toast.success(t('success.changesSaved'));
    } catch (error) {
      toast.error(
        error?.message
          ? `${t('errors.modulesCouldNotBeSaved')} ${error.message}`
          : t('errors.modulesCouldNotBeSaved')
      );
    }
  };

  return (
    <>
      <DialogLoader
        loaderState={isLoading}
        text={t('common.shared.isLoading')}
      />

      {!isLoading && (
        <>
          <FakturowniaModule
            classes={classes}
            handleModuleUpdate={handleModuleUpdate}
            module={modules[modulesNames.Fakturownia]}
            handleSubmit={handleSubmit}
          />
          <FormControlButtons
            classes={classes}
            discardText={t('common.shared.cancel', 'Anuluj')}
            submitText={t('common.shared.save', 'Zapisz')}
            handleSubmit={handleSubmit}
          />
        </>
      )}
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withTranslation(),
  connect(
    ({ Auth: { selectedBrand }, Brands: { paymentTypes } }) => ({
      selectedBrand,
      paymentTypes,
    }),
    {
      fetchBrand,
      fetchBrandConfigModulesPack,
      fetchPaymentTypes,
      updateBrandConfigModulesPack,
    }
  ),
  withStyles(combinedStyles)
);

export default enhance(Accounting);
