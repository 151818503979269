import React, { useState } from 'react';
import { isGranted } from 'helpers/helpers';
import { ROLE_SHOW_MONEY_BOX_HISTORY } from 'helpers/roles';

import Accordion from 'components/Accordion/Accordion';
import { Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ModalButton from 'components/CustomButtons/Button';
import Close from '@material-ui/icons/Close';
import Moneybox from 'views/Clients/FormTabs/Moneybox/Moneybox';
import { get } from 'helpers/apiHelpers';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    marginLeft: '5px',
    background: '#f3f3f3',
    padding: '10px 10px 5px 10px',
    marginBottom: '10px',
    borderRadius: '10px',
  },
  dialog: {
    maxHeight: '700px',
  },
  divider: { marginBottom: '5px', marginTop: '15px' },
  button: {
    width: '100%',
  },
  accordionContent: {
    width: '100%',
  },
  modalCloseButton: {
    position: 'absolute',
    top: '0',
    right: '0',
    cursor: 'pointer',
  },
});

const DietDetailsMoneybox = ({ client, fetchUser, setClient }) => {
  const [moneyboxModalStatus, setMoneyboxModalStatus] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();

  const closeModal = () => {
    get(client['@id']).then(client => {
      setClient(client);
      setMoneyboxModalStatus(false);
    });
  };

  return (
    <div className={classes.root}>
      {moneyboxModalStatus && (
        <Dialog
          maxWidth="lg"
          fullWidth={true}
          open={moneyboxModalStatus}
          onClose={closeModal}
        >
          <DialogContent className={classes.dialog}>
            <ModalButton
              className={classes.modalCloseButton}
              color="transparent"
              justIcon={true}
              round={true}
              onClick={closeModal}
            >
              <Close />
            </ModalButton>
            <Moneybox
              userId={client.id}
              userMoneybox={client.moneyBox}
              fetchUser={fetchUser}
              noCancel={true}
            />
          </DialogContent>
        </Dialog>
      )}
      <Accordion
        active={0}
        collapses={[
          {
            title: t('clients.moneybox'),
            content: (
              <div className={classes.accordionContent}>
                <h5>
                  {t('clients.moneybox.accountBalance')}:{' '}
                  <b>
                    {client.moneyBox} {t('clients.moneybox')}
                  </b>
                </h5>
                {isGranted(ROLE_SHOW_MONEY_BOX_HISTORY) && (
                  <div>
                    <Divider className={classes.divider} />
                    <Button
                      className={classes.button}
                      onClick={() => setMoneyboxModalStatus(true)}
                    >
                      {t('common.more')}
                    </Button>
                  </div>
                )}
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default DietDetailsMoneybox;
