import { Component } from 'react';
import { fetchOrderStatuses } from 'actions/OrderStatuses';
import { connect } from 'react-redux';
import { put } from 'helpers/apiHelpers';
import SelectInput from 'components/FormSelect/SelectInput';

import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { translateOrderStatuses } from './statusTranslations';
import { chain } from 'lodash';

import InfoModal from '../../../components/InfoModal/InfoModal';
import Button from '../../../components/CustomButtons/Button';
import { toast } from 'react-toastify';

const updateStatuesesTranslation = (originalStatuses, t) => {
  const _translations = chain(translateOrderStatuses(t))
    .filter(x => x.systemValue)
    .map(({ systemValue, label }) => [systemValue, label])
    .fromPairs()
    .value();
  const _result = originalStatuses.map(item => {
    const clone = Object.assign({}, item);
    clone.value = _translations[clone.systemValue] || clone.value;
    return clone;
  });

  return _result;
};

class StatusPicker extends Component {
  state = {
    status: '',
    options: [],
    showModal: false,
    orderStatuses: [],
  };

  componentDidMount = async () => {
    await this.props.fetchOrderStatuses();
    this.setState({
      status: this.props.value,
      orderStatuses: updateStatuesesTranslation(
        this.props.orderStatuses,
        this.props.t
      ),
    });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.t !== this.props.t ||
      prevProps.orderStatuses !== this.props.orderStatuses
    ) {
      this.setState({
        orderStatuses: updateStatuesesTranslation(
          this.props.orderStatuses,
          this.props.t
        ),
      });
    }
  }

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  changeStatus = obj => {
    if (obj.systemValue === 'CANCELED' && !this.state.showModal) {
      this.toggleModal();
      return;
    }

    this.handleStatusChange(obj);
  };

  handleStatusChange = obj => {
    const { t } = this.props;

    put(this.props.orderId, { status: obj['@id'] })
      .then(({ pointsFromMoneyBox, priceFromMoneyBox }) => {
        this.setState({ status: obj });
        toast.success(t('success.statusUpdated'));
        if (pointsFromMoneyBox > 0) {
          toast.success(
            `${t('success.statusUpdated')} ${t(
              'orders.rememberToReturnPoints',
              { replace: { pointsFromMoneyBox, priceFromMoneyBox } }
            )}`
          );
        }
      })
      .catch(({ response: { data } }) => {
        if (data.hasOwnProperty('violations')) {
          toast.error(data.violations.map(el => el.message).join(' '));
        }
      });

    if (this.state.showModal) {
      this.toggleModal();
    }
  };

  render() {
    const { t } = this.props;
    const selectIsDisabled =
      this.props.disabled || this.state.status.systemValue === 'CANCELED';

    return (
      <>
        <div
          onClick={() =>
            selectIsDisabled &&
            toast.warning(t('errors.anulatedOrderStatusCanNotBeChanged'))
          }
        >
          <SelectInput
            classes={this.props.classes}
            trackBy={'@id'}
            mapBy={'value'}
            disabled={selectIsDisabled}
            options={
              this.state.status.systemValue === 'PAID'
                ? this.state.orderStatuses.filter(
                    status => status.systemValue !== 'WAITING_FOR_PAYMENT'
                  )
                : this.state.orderStatuses
            }
            value={this.state.status}
            handleChange={(ev, obj) => this.changeStatus(obj)}
          />
        </div>

        <InfoModal
          modalState={this.state.showModal}
          handleClose={() => this.toggleModal}
          modalContent={
            <>
              <p>{t('orders.modal.text')}</p>
              <div>
                <Button
                  onClick={() =>
                    this.handleStatusChange(
                      this.state.orderStatuses.find(
                        status => status.systemValue === 'CANCELED'
                      )
                    )
                  }
                >
                  {t('orders.modal.button.yes')}
                </Button>
                <Button onClick={this.toggleModal}>
                  {t('orders.modal.button.no')}
                </Button>
              </div>
            </>
          }
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  orderStatuses: state.OrderStatuses.orderStatuses,
});

const mapDispatchToProps = {
  fetchOrderStatuses,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(extendedFormsStyle),
  withTranslation()
);

export default enhance(StatusPicker);
