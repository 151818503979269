import GridItem from 'components/Grid/GridItem';
import Check from '@material-ui/icons/Check';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TagsInput from 'react-tagsinput';

import Checkbox from '@material-ui/core/Checkbox';
import { DAY_SELECTOR_MODE } from './DaysSelectorMode';
import makeStyles from '@material-ui/styles/makeStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  ...extendedFormsStyle,
  ...buttonsStyle,
  label: {
    display: 'block',
    color: 'rgba(0, 0, 0, 0.46)',
  },
});

const DefinedLengthsSectionSettiongs = ({ state, setState, handleDays }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <GridItem md={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.predefinedDietLengthEnabled}
              onChange={e => {
                const customDietLengthSelectorMode =
                  state.customDietLengthEnabled
                    ? DAY_SELECTOR_MODE.range
                    : DAY_SELECTOR_MODE.predefinedOptions;

                const daysSelectorType = state.predefinedDietLengthEnabled
                  ? DAY_SELECTOR_MODE.range
                  : customDietLengthSelectorMode;

                setState(prevState => ({
                  ...prevState,
                  predefinedDietLengthEnabled: e.target.checked,
                  daysSelectorType: daysSelectorType,
                }));
              }}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot,
              }}
            />
          }
          label={t('brandCfg.defLength')}
        />
        <label htmlFor="orderLength" className={classes.label}>
          {t('brandCfg.addLength')}
        </label>
        <TagsInput
          renderTag={props => {
            let {
              tag,
              key,
              disabled,
              onRemove,
              classNameRemove,
              getTagDisplayValue,
              ...other
            } = props;
            return (
              <span key={key} {...other}>
                {getTagDisplayValue(
                  parseInt(tag) === 1
                    ? tag + t('brandCfg.day')
                    : tag + t('brandCfg.days')
                )}
                {!disabled && (
                  <a
                    href="/"
                    className={classNameRemove}
                    onClick={e => {
                      e.preventDefault();
                      onRemove(key);
                    }}
                  >
                    {''}
                  </a>
                )}
              </span>
            );
          }}
          inputProps={{
            style: {
              width: '45px',
              marginTop: '25px',
              borderBottom: '1px solid rgba(0, 0, 0, 0.26)',
              paddingBottom: '10px',
            },
            id: 'orderLength',
            placeholder: t('brands.newBrandForm.eg', 'np: "7"'),
          }}
          tagProps={{
            className: 'react-tagsinput-tag info',
          }}
          value={state.days}
          onChange={(tags, changed) => {
            const tagsToInts = tags.map(el => parseInt(el));
            const changedToInts = changed.map(el => parseInt(el));
            handleDays(tagsToInts, changedToInts, 'days');
          }}
        />
      </GridItem>
      <GridItem md={6}></GridItem>
    </>
  );
};

export default DefinedLengthsSectionSettiongs;
