import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { put } from 'helpers/apiHelpers';

import SelectInput from 'components/FormSelect/SelectInput';

import withStyles from '@material-ui/core/styles/withStyles';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { toast } from 'react-toastify';

const SourcePicker = ({ classes, orderId, sources = [], initialValue }) => {
  const { t } = useTranslation();

  const [source, setSource] = useState({});

  useEffect(() => {
    const selectedOption = sources.find(
      ({ '@id': iri }) => iri === initialValue
    );

    setSource(selectedOption ?? {});
  }, []);

  const handleSourceChange = obj => {
    put(orderId, { source: obj['@id'] })
      .then(() => {
        if (obj?.['@id'] === null) {
          setSource({});
        }
        setSource(obj);
        toast.success(t('success.sourceUpdated'));
      })
      .catch(({ response: { data } }) => {
        if (data.hasOwnProperty('violations')) {
          toast.error(data.violations.map(el => el.message).join(' '));
        }
      });
  };

  return (
    <div>
      <SelectInput
        classes={classes}
        trackBy={'@id'}
        mapBy={'value'}
        disabled={false}
        options={[
          {
            '@id': null,
            value: t('$*orders.source.noSource', '$$Brak źródła'),
          },
          ...sources,
        ]}
        value={source}
        handleChange={(ev, obj) => handleSourceChange(obj)}
      />
    </div>
  );
};

export default withStyles(extendedFormsStyle)(SourcePicker);
