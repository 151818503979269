import { toast } from 'react-toastify';

export const validateForm = (state, t) => {
  let isValid = true;
  const { variant } = state;

  const validations = [
    {
      condition: variant.name === '',
      message: 'form.variantNameCannotEmpty',
    },
    {
      condition: variant.shortName === '',
      message: 'form.shortVariantNameCannotEmpty',
    },
    {
      condition: variant.mealTypes.length === 0,
      message: 'form.selectMealTypes',
    },
    {
      condition: variant.calories.length < 1,
      message: 'form.addMinOneCalories',
    },
    {
      condition: variant.calories.some(calorie => calorie.name === ''),
      message: 'form.addNamesForAllCalories',
    },
    {
      condition: variant.calories
        .map(calorie => calorie.name)
        .some(
          (name, index) =>
            variant.calories.map(calorie => calorie.name).indexOf(name) !==
            index
        ),
      message: 'form.caloriesNameCannotBeSame',
    },
    {
      condition: variant.calories
        .map(calorie => calorie.sizes.length === variant.mealTypes.length)
        .some(el => el === false),
      message: 'form.addSizesForAllMealTypes',
    },
    {
      condition: !state.variant.internalPosition,
      message: 'form.addInternalPosition',
    },
  ];

  const errors = validations.filter(({ condition, message }) => {
    if (condition) {
      return message;
    }

    return null;
  });

  if (errors.length > 0) {
    toast.error(t(errors[0].message));
    return;
  }

  return isValid;
};
