import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ModalButton from '../../../components/CustomButtons/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import useModalController from '../../../hooks/common/useModalController';

const useStyles = makeStyles({
  tableWrapper: { maxHeight: '70vh', overflowY: 'auto' },
  table: { textAlign: 'center' },
  modalButton: {
    position: 'absolute',
    top: '0',
    right: '0',
    cursor: 'pointer',
  },
  dialog: {
    padding: '6px 24px 24px 24px',
  },
});

const ErrorsModal = ({ errors }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isOpen, open, close } = useModalController();

  return (
    <>
      <Dialog maxWidth="md" open={isOpen} onClose={close}>
        <DialogContent>
          <ModalButton
            className={classes.modalButton}
            color="transparent"
            justIcon
            round
            onClick={close}
          >
            ×
          </ModalButton>
        </DialogContent>
        <DialogContent classes={{ root: classes.dialog }}>
          <Paper>
            <div className={classes.tableWrapper}>
              <Table>
                <TableHead>
                  <TableRow className={classes.table}>
                    <TableCell>ID</TableCell>
                    <TableCell>{t('common.mainMenu.error')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {errors.map(({ resourceType, id, message }) => (
                    <TableRow key={`Error_${resourceType}_${id}`}>
                      <TableCell>
                        {resourceType}#{id}
                      </TableCell>
                      <TableCell>{message}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </Paper>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={open}>
        {t('common.showErrors')}
      </Button>
    </>
  );
};

export default ErrorsModal;
