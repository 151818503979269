const DownloadIcon = ({ title = '', styles = { color: '#9C27B0' } }) => (
  <svg
    style={styles}
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={0}
    stroke="currentColor"
  >
    {title && <title>{title}</title>}
    <path
      d="M19 9H15V3H9V9H5L12 16L19 9ZM5 18V20H19V18H5Z"
      fill="currentColor"
    />
  </svg>
);

export default DownloadIcon;
