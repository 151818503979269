import { Component } from 'react';

import { combineStyles } from 'helpers/helpers';
import { get, post, put, remove } from 'helpers/apiHelpers';

import { isGranted } from 'helpers/helpers';
import { ROLE_EDIT_COST } from 'helpers/roles';

import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle';

import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import FormSelectSingle from 'components/FormSelect/FormSelectSingle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';

import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import EXTERNAL_URLS from 'constants/externalUrls';
import InfoLink from 'components/InfoLink/InfoLink';
import { toast } from 'react-toastify';

const styles = {
  deliveryDiscountInfo: {
    margin: '24px 0 12px',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '400',
    color: '#5a5a5a',
  },
};

class DietDiscounts extends Component {
  state = {
    rows: [],
    isLoading: false,
  };

  componentDidMount() {
    get('/discount-same-addresses').then(discountResponse =>
      this.setState({
        rows: discountResponse['hydra:member'].map(el => ({
          hasChanged: false,
          _minLength: el.minLength,
          _maxLength: el.maxLength,
          _isPercentage: el.isPercentage,
          _discount: el.discount,
          ...el,
        })),
      })
    );
  }

  addRow = () => {
    const newRow = {
      minLength: '',
      maxLength: '',
      isPercentage: '',
      discount: '',
      isLoading: false,
      hasChanged: false,
    };

    this.setState({
      rows: this.state.rows.concat(newRow),
    });
  };

  deleteRow = async index => {
    const rowsAfterDelete = this.state.rows.filter((el, ind) => ind !== index);

    const id = this.state.rows[index].id;

    if (id) {
      remove(`/discount-same-addresses/${id}`);
    }

    this.setState({
      rows: rowsAfterDelete,
    });
  };

  handleChange = (event, index) => {
    const { name, value } = event.target;
    const { rows } = this.state;

    if (!isNaN(value)) {
      let initialVal = `_${name}`;
      rows[index].hasChanged =
        this.state.rows[index][initialVal] !== parseInt(value);

      rows[index][name] = value;

      this.setState({ rows });
    }
  };

  saveRow = index => {
    this.setState({
      isLoading: true,
    });
    const currentRow = this.state.rows[index];

    if (currentRow.discount === '') {
      toast.error(this.props.t('common.shared.fillDiscountField'));
      return this.setState({
        isLoading: false,
      });
    }

    if (
      isNaN(currentRow.discount) ||
      isNaN(currentRow.minLength) ||
      isNaN(currentRow.maxLength)
    ) {
      toast.error(this.props.t('common.shared.fieldsMustBeNumber'));
      return this.setState({
        isLoading: false,
      });
    }

    const postData = {
      minLength: parseInt(currentRow.minLength),
      maxLength: parseInt(currentRow.maxLength),
      isPercentage: currentRow.isPercentage,
      discount: parseFloat(currentRow.discount),
    };

    const action = currentRow.id ? put : post;
    const url = currentRow.id
      ? `/discount-same-addresses/${currentRow.id}`
      : '/discount-same-addresses';

    action(url, postData).then(() => {
      get('/discount-same-addresses').then(discountResponse =>
        this.setState({ rows: discountResponse['hydra:member'] })
      );
      toast.success(this.props.t('common.shared.discountSaved'));
    });
    this.setState({
      isLoading: false,
    });
  };

  render() {
    const { classes } = this.props;
    const { rows } = this.state;

    const DiscountElementTypes = [
      {
        name: this.props.t('common.shared.percentage', 'Procentowy'),
        value: true,
      },
      {
        name: this.props.t('common.shared.amount', 'Kwotowy'),
        value: false,
      },
    ];

    return (
      <GridItem md={12}>
        <p className={classes.deliveryDiscountInfo}>
          {this.props.t('dietDiscountSameAddress')}{' '}
          <InfoLink link={EXTERNAL_URLS.howDeliveryDiscountWorks} />
        </p>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{this.props.t('common.shared.dietQtyFrom')}</TableCell>
              <TableCell>{this.props.t('common.shared.dietQtyTo')}</TableCell>
              <TableCell>
                {this.props.t('common.shared.discountType')}
              </TableCell>
              <TableCell>
                {this.props.t('common.shared.discountForDay')}
              </TableCell>
              {isGranted(ROLE_EDIT_COST) && <TableCell />}
              {isGranted(ROLE_EDIT_COST) && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={index}
                style={{
                  backgroundColor: row.hasChanged ? '#ffffd4' : 'transparent',
                }}
              >
                <TableCell component="th" scope="row">
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      disabled: !isGranted(ROLE_EDIT_COST),
                      type: 'text',
                      placeholder: this.props.t('common.shared.typeValue'),
                      name: 'minLength',
                      value: row.minLength,
                      onChange: event => this.handleChange(event, index),
                    }}
                  />
                </TableCell>
                <TableCell>
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      disabled: !isGranted(ROLE_EDIT_COST),
                      type: 'text',
                      placeholder: this.props.t('common.shared.typeValue'),
                      name: 'maxLength',
                      value: row.maxLength,
                      onChange: event => this.handleChange(event, index),
                    }}
                  />
                </TableCell>
                <TableCell>
                  <FormSelectSingle
                    classes={classes}
                    disabled={!isGranted(ROLE_EDIT_COST)}
                    mapBy="name"
                    name="isPercentage"
                    trackBy="value"
                    value={row.isPercentage}
                    options={DiscountElementTypes}
                    handleChange={event => this.handleChange(event, index)}
                    labelSm={0}
                    selectSm={8}
                  />
                </TableCell>
                <TableCell>
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      disabled: !isGranted(ROLE_EDIT_COST),
                      type: 'text',
                      placeholder: this.props.t('common.shared.typeValue'),
                      name: 'discount',
                      value: row.discount,
                      onChange: event => this.handleChange(event, index),
                    }}
                  />
                </TableCell>
                {isGranted(ROLE_EDIT_COST) && (
                  <TableCell>
                    <FormControlButtons
                      classes={classes}
                      handleSubmit={() => this.saveRow(index)}
                      submitText={this.props.t('common.shared.save')}
                      submitDisabled={this.state.isLoading || !row.hasChanged}
                    />
                  </TableCell>
                )}
                {isGranted(ROLE_EDIT_COST) && (
                  <TableCell>
                    <FormControlButtons
                      classes={classes}
                      submitColor={'danger'}
                      handleSubmit={() => this.deleteRow(index)}
                      submitText={this.props.t('common.shared.delete')}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
            {
              <TableRow>
                <TableCell
                  className={
                    isGranted(ROLE_EDIT_COST)
                      ? 'variant-addRow'
                      : 'variant-noAccess'
                  }
                  colSpan={6}
                  onClick={
                    isGranted(ROLE_EDIT_COST)
                      ? this.addRow
                      : () =>
                          toast.error(
                            this.props.t('common.shared.noPermissions')
                          )
                  }
                >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <span style={{ display: 'block' }}>
                      {rows.length === 0
                        ? this.props.t('common.shared.noDiscounts')
                        : this.props.t('common.shared.addDiscount') + ' +'}
                    </span>
                  </div>
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </GridItem>
    );
  }
}

const combinedStyles = combineStyles(
  extendedFormsStyle,
  validationFormsStyle,
  buttonsStyle,
  styles
);

const enhance = compose(withStyles(combinedStyles), withTranslation());

export default enhance(DietDiscounts);
