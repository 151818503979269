import { useState, useEffect } from 'react';

import { combineStyles } from 'helpers/helpers';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  fetchBrand,
  fetchBrandConfigModulesPack,
  fetchPaymentTypes,
  updateBrandConfigModulesPack,
} from 'actions/Brands';
import withStyles from '@material-ui/core/styles/withStyles';
import { modulesNames } from '../consts';

import { put } from 'helpers/apiHelpers';
import isEmpty from 'lodash/isEmpty';
import OrderFormView from './OrderForm.view';
import { toast } from 'react-toastify';

const OrderFormContainer = ({
  classes,
  t,
  selectedBrand,
  fetchBrand,
  fetchBrandConfigModulesPack,
  paymentTypes,
  updateBrandConfigModulesPack,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [modules, setModules] = useState({
    ConfigClientPanel: {
      omnibusEnabledForDefaultPrices: true,
      showMenuPreviewOnOrderForm: true,
      enableVariantStep: true,
      useAddressesWithLessFields: true,
      showDiscountCodeInputInBasket: true,
      initialBasketId: null,
      calculatorMode: true,
      calculatorLink: true,
      isOneTimePayPaymentMode: true,
      isSubscriptionPaymentMode: true,
      defaultPaymentMode: true,
      showKnowAboutUs: false,
    },
    Seo: {
      ogImage: null,
      metaTitle: '',
      indexing: false,
      metaKeywords: '',
      ogTitle: '',
      metaDescription: 'false',
      ogDescription: '',
    },
    Caching: {
      enabled: false,
      baseInvalidationEnabled: false,
      costsInvalidationEnabled: false,
      cacheLifeTime: '',
    },
    PaymentModesCopy: {
      single: {},
      subscription: {},
    },
  });

  const [hoverState, setHoverState] = useState({
    title: '',
    image: null,
    imageUrl: '',
    active: false,
    description: '',
  });

  const [state, setState] = useState({
    newOrderInfoEnabled: false,
    newOrderInfoBannerDesktop: null,
    newOrderInfoBannerMobile: null,
    newOrderInfoBannerDesktopUrl: '',
    newOrderInfoBannerMobileUrl: '',
    newOrderInfoTitle: null,
    allowVariantAutoScroll: false,
    allowDietScroll: false,
    allowOrderTypeScroll: false,
    allowDietTimeScroll: false,
    allowCalendarScroll: false,
    allowCalorificScroll: false,
    showMenuPreviewOnOrderForm: false,
    showPricePreview: false,
    newOrderInfoDescription: false,
  });

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const brandConfig = await fetchBrand(selectedBrand);
      const modulesConfig = await fetchBrandConfigModulesPack(selectedBrand, [
        modulesNames,
        'ConfigClientPanel',
        'PaymentModesCopy',
        'Caching',
        'Seo',
      ]);

      setState(prevState => ({
        ...prevState,
        sendBanner: false,
        newOrderInfoEnabled: brandConfig.newOrderInfoEnabled,
        newOrderInfoBannerDesktop:
          brandConfig.newOrderInfoBannerDesktop?.['@id'] || null,
        newOrderInfoBannerDesktopUrl:
          brandConfig.newOrderInfoBannerDesktop?.contentUrl || null,
        newOrderInfoBannerMobileUrl:
          brandConfig.newOrderInfoBannerMobile?.contentUrl || null,
        newOrderInfoBannerMobile:
          brandConfig.newOrderInfoBannerMobile?.['@id'] || null,
        newOrderInfoTitle: brandConfig.newOrderInfoTitle,
        allowVariantAutoScroll: brandConfig.allowVariantAutoScroll,
        allowDietScroll: brandConfig.allowDietScroll,
        allowOrderTypeScroll: brandConfig.allowOrderTypeScroll,
        allowDietTimeScroll: brandConfig.allowDietTimeScroll,
        allowCalendarScroll: brandConfig.allowCalendarScroll,
        allowCalorificScroll: brandConfig.allowCalorificScroll,
        showPricePreview: brandConfig.showPricePreview,
        newOrderInfoDescription: brandConfig.newOrderInfoDescription,
      }));
      setModules(modulesConfig.configuration);
      setIsLoading(false);
    })();
  }, []);

  const handleSubmit = () => {
    if (isEmpty(hoverState.title)) {
      return toast.error(t('form.fillAllRequiredFields'));
    }

    const hoverData = {
      title: hoverState.title,
      active: hoverState.active,
      image: hoverState.image,
      imageUrl: hoverState.image?.['@id'] || null,
      description: hoverState.description,
    };

    const data = {
      newOrderInfoBannerDesktop: state.newOrderInfoBannerDesktop,
      newOrderInfoBannerMobile: state.newOrderInfoBannerMobile,
      newOrderInfoBannerDesktopUrl: state.newOrderInfoBannerDesktopUrl,
      newOrderInfoBannerMobileUrl: state.newOrderInfoBannerMobileUrl,
      newOrderInfoTitle: state.newOrderInfoTitle,
      newOrderInfoEnabled: state.newOrderInfoEnabled,
      allowVariantAutoScroll: state.allowVariantAutoScroll,
      allowDietScroll: state.allowDietScroll,
      allowOrderTypeScroll: state.allowOrderTypeScroll,
      allowDietTimeScroll: state.allowDietTimeScroll,
      allowCalendarScroll: state.allowCalendarScroll,
      allowCalorificScroll: state.allowCalorificScroll,
      showMenuPreviewOnOrderForm: state.showMenuPreviewOnOrderForm,
      showPricePreview: state.showPricePreview,
      newOrderInfoDescription: state.newOrderInfoDescription,
    };

    try {
      Promise.all([
        put(`/brands/${selectedBrand}/hover`, {
          hover: hoverData,
        }),
        put(`/brands/${selectedBrand}`, data),
        updateBrandConfigModulesPack(selectedBrand, modules),
      ]).then(response => {
        setState(prevState => ({
          ...prevState,
          sendBanner: false,
        }));

        toast.success(t('success.changesSaved'));
      });
    } catch (error) {
      toast.error(
        error?.message
          ? `${t('errors.changesCouldNotBeSaved')}: ${error.message}`
          : t('errors.changesCouldNotBeSaved')
      );
    }
  };

  const handleInputChange = e => {
    setState(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const getImage = (stateName, data) => {
    setState(prevState => ({
      ...prevState,
      sendBanner: true,
      [stateName]: data?.['@id'] || null,
      [`${stateName}Url`]: data?.contentUrl || null,
    }));
  };

  const removeImage = stateName => {
    setState(prevState => ({
      ...prevState,
      sendBanner: false,
      [stateName]: null,
      [`${stateName}Url`]: null,
      ...(stateName === 'backgroundImage'
        ? { useImageAsBackground: false }
        : {}),
    }));
  };

  const handleToggle = variableName => {
    setState(prevState => ({
      ...prevState,
      [variableName]: !prevState[variableName],
    }));
  };

  const props = {
    isLoading: isLoading,
    modules: modules,
    setModules: setModules,
    hoverState: hoverState,
    setHoverState: setHoverState,
    state: state,
    setState: setState,
    handleSubmit: handleSubmit,
    handleInputChange: handleInputChange,
    getImage: getImage,
    removeImage: removeImage,
    handleToggle: handleToggle,
    classes: classes,
    paymentTypes: paymentTypes,
    t: t,
  };

  return <OrderFormView props={props} />;
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withTranslation(),
  connect(
    ({ Auth: { selectedBrand }, Brands: { paymentTypes } }) => ({
      selectedBrand,
      paymentTypes,
    }),
    {
      fetchBrand,
      fetchBrandConfigModulesPack,
      fetchPaymentTypes,
      updateBrandConfigModulesPack,
    }
  ),
  withStyles(combinedStyles)
);

export default enhance(OrderFormContainer);
