import { FormLabel, withStyles } from '@material-ui/core';
import { FileCopyOutlined, Launch } from '@material-ui/icons';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import { Tooltip } from '@material-ui/core';
import { FormTextInput } from 'components';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { combineStyles, copyToClipboard, isValidUrl } from 'helpers/helpers';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { fetchLanguages } from 'actions/Auth';
import { Info } from '@material-ui/icons';
import { toast } from 'react-toastify';
const PresentationUrl = ({
  classes,
  subpage,
  mainDomain,
  editInput,
  value = '',
  handleChange,
  cmsShowActionButton = true,
  size,
}) => {
  const { t } = useTranslation();

  let url = '';
  if (isValidUrl(mainDomain)) {
    url = new URL(mainDomain);
  } else {
    url = new URL(`https://${mainDomain}`);
  }
  const extractedDomain = url.hostname;
  const urlInputValue = `https://${extractedDomain}${subpage}`;

  const showRedirectAndCopyButton = () => {
    if (window.location.pathname.includes('/addon')) {
      return window.location.pathname.includes('/edit');
    }
    return !window.location.pathname.includes('/add');
  };

  return (
    <>
      <FormLabel className={classes.labelHorizontal}>
        <h1>{t('presentationUrl.urlAddress')}</h1>
      </FormLabel>
      <Tooltip
        title={
          <div>
            <h4>{t('presentationUrl.slugDescriptionTooltip')}</h4>
          </div>
        }
        placement="top"
      >
        <Info
          style={{ color: 'RGB(128,128,128)', margin: '5px 0 0 5px' }}
          fontSize={'small'}
        />
      </Tooltip>
      <GridContainer>
        <GridItem sm={size ? size : 6}>
          <div style={{ display: 'flex' }}>
            <FormTextInput
              style={{
                background: 'RGB(238,238,238)',
                color: 'RGB(153,153,153)!important',
              }}
              name="urlDefaultAddress"
              value={urlInputValue}
              disabled={true}
              classes={classes}
              inputSize={12}
              maxLength={64}
            />
            {editInput && (
              <FormTextInput
                style={{
                  color: 'RGB(153,153,153)!important',
                }}
                name="urlSlug"
                value={value}
                placeholder={t('presentationUrl.slugPlaceholder')}
                handleChange={e => handleChange(e)}
                classes={classes}
                inputSize={12}
                maxLength={64}
              />
            )}
            {showRedirectAndCopyButton() && cmsShowActionButton ? (
              <>
                <Tooltip
                  title={
                    <div>
                      <h4>{t('presentationUrl.transitionToSite')}</h4>
                    </div>
                  }
                  placement="top"
                >
                  <a
                    href={value ? `${urlInputValue}${value}` : urlInputValue}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Launch
                      style={{
                        color: 'RGB(161,50,180)',
                        margin: '5px 0 0 10px',
                        cursor: 'pointer',
                      }}
                    />
                  </a>
                </Tooltip>
                <Tooltip
                  title={
                    <div>
                      <h4>{t('presentationUrl.copyLink')}</h4>
                    </div>
                  }
                  placement="top"
                >
                  <FileCopyOutlined
                    style={{
                      color: 'RGB(44,186,204)',
                      margin: '5px 0 0 10px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      return copyToClipboard(
                        value ? `${urlInputValue}${value}` : urlInputValue,
                        () => {
                          toast.success(
                            t('presentationUrl.copyClipboard.success')
                          );
                        }
                      );
                    }}
                  />
                </Tooltip>
              </>
            ) : null}
          </div>
        </GridItem>
      </GridContainer>
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle);
const enhance = compose(
  withStyles(combinedStyles),
  connect(
    ({
      Brands: {
        brand: { mainDomain },
      },
    }) => ({
      mainDomain,
    }),
    { fetchLanguages }
  )
);
export default enhance(PresentationUrl);
