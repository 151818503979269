import { TableCell } from '@material-ui/core';
import { Check, Info } from '@material-ui/icons';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/styles/makeStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';

const useStyles = makeStyles({
  ...extendedFormsStyle,
  ...buttonsStyle,
  tooltipWrapper: {
    margin: '0 0 0 10px',
  },
  align: {
    display: 'flex',
    alignItems: 'center',
  },
});

const MODE_PAYMENT_TYPE = {
  standard: 'standard',
  subscription: 'subscription',
  sandbox: 'sandbox',
};

const PaymentRow = ({
  value,
  modules,
  paymentTypes,
  setModules,
  validateChangeStatusPaymentType,
  handleChangePaymentMethod,
}) => {
  const classes = useStyles();

  return (
    <>
      <TableCell>
        <div className={classes.align}>
          {value.method}
          {value.tooltip && (
            <div className={classes.tooltipWrapper}>
              <Tooltip title={<h4>{value.tooltip}</h4>} placement="right">
                <Info fontSize="small" />
              </Tooltip>
            </div>
          )}
        </div>
      </TableCell>
      <TableCell>
        {value?.paymentTypeStandard && (
          <Checkbox
            checked={
              value.option === MODE_PAYMENT_TYPE.sandbox
                ? modules[value.title].sandbox
                : paymentTypes?.standard?.enabledPaymentTypes?.includes(
                    value.paymentTypeStandard
                  )
            }
            onChange={() => {
              if (value.option === MODE_PAYMENT_TYPE.sandbox) {
                setModules(prev => ({
                  ...prev,
                  [value.title]: {
                    ...prev[value.title],
                    sandbox: !prev[value.title].sandbox,
                  },
                }));
                return null;
              }

              if (
                !validateChangeStatusPaymentType(
                  value,
                  MODE_PAYMENT_TYPE.standard
                )
              ) {
                handleChangePaymentMethod(
                  paymentTypes,
                  value.paymentTypeStandard,
                  MODE_PAYMENT_TYPE.standard
                );
              }
            }}
            disabled={!modules.ConfigClientPanel.isOneTimePayPaymentMode}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
        )}
      </TableCell>
      <TableCell>
        {value?.paymentTypeSubscription && (
          <Checkbox
            checked={paymentTypes?.subscription?.enabledPaymentTypes?.includes(
              value.paymentTypeSubscription
            )}
            onChange={() => {
              if (
                !validateChangeStatusPaymentType(
                  value,
                  MODE_PAYMENT_TYPE.subscription
                )
              ) {
                handleChangePaymentMethod(
                  paymentTypes,
                  value.paymentTypeSubscription,
                  MODE_PAYMENT_TYPE.subscription
                );
              }
            }}
            disabled={!modules.ConfigClientPanel.isSubscriptionPaymentMode}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
        )}
      </TableCell>
      <TableCell style={{ background: `${value.designation}` }} />
    </>
  );
};

export default PaymentRow;
