import { Component } from 'react';
import { put } from 'helpers/apiHelpers';

import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

import InfoModal from '../../../components/InfoModal/InfoModal';
import Button from '../../../components/CustomButtons/Button';
import BooleanView from '../../../components/DataGrid/ValueViews/BooleanView';
import { toast } from 'react-toastify';

class VerifyButton extends Component {
  state = {
    showModal: false,
    verified: false,
  };

  componentDidMount() {
    this.setState({
      verified: this.props.verified,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.verified !== this.props.verified) {
      this.setState({
        verified: this.props.verified,
      });
    }
  }

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  changeStatus = () => {
    this.toggleModal();
  };

  handleStatusChange = () => {
    const { t } = this.props;

    put(this.props.orderId, { verified: !this.state.verified })
      .then(() => {
        this.setState({
          verified: !this.state.verified,
        });
        toast.success(t('success.statusUpdated'));
      })
      .catch(error => {
        if (error.hasOwnProperty('response')) {
          const {
            response: { data },
          } = error;
          if (data.hasOwnProperty('violations')) {
            toast.error(data.violations.map(el => el.message).join(' '));
          }
        }
      });

    if (this.state.showModal) {
      this.toggleModal();
    }
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <div onClick={() => this.changeStatus()}>
          <BooleanView value={this.state.verified} />
        </div>

        <InfoModal
          modalState={this.state.showModal}
          handleClose={() => this.toggleModal}
          modalContent={
            <>
              <p>{t('orders.modalVerify.text')}</p>
              <div>
                <Button onClick={() => this.handleStatusChange()}>
                  {t('orders.modal.button.yes')}
                </Button>
                <Button onClick={this.toggleModal}>
                  {t('orders.modal.button.no')}
                </Button>
              </div>
            </>
          }
        />
      </>
    );
  }
}

const enhance = compose(withStyles(extendedFormsStyle), withTranslation());

export default enhance(VerifyButton);
