import RegularButton from 'components/CustomButtons/Button.jsx';
import { put } from 'helpers/apiHelpers';
import { TableCell } from '@material-ui/core';
import { toast } from 'react-toastify';

const TableButtonSaveSection = ({ mailingOption, t, state, setState }) => {
  return (
    <TableCell>
      <RegularButton
        disabled={!mailingOption.hasChanged}
        color={'success'}
        onClick={() => {
          put(mailingOption['@id'], mailingOption)
            .then(
              () => {
                const newMailing = [...state.mailing];

                let optionToChange = newMailing.find(
                  newMailingOption => newMailingOption.id === mailingOption.id
                );
                optionToChange.hasChanged = false;
                setState({
                  mailing: newMailing,
                });
                toast.success(t('notify.changesSaved'));
              },
              () =>
                toast.error(
                  t('notify.cannotSave', 'Nie udało się zapisać zmian')
                )
            )
            .finally(() => window.location.reload());
        }}
      >
        {t('common.shared.save', 'Zapisz')}
      </RegularButton>
    </TableCell>
  );
};

export default TableButtonSaveSection;
