import { useState, useEffect } from 'react';

import { combineStyles } from '../../../helpers/helpers';
import extendedFormsStyle from '../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  fetchBrand,
  fetchBrandConfigModulesPack,
  fetchPaymentTypes,
  updateBrandConfigModulesPack,
} from '../../../actions/Brands';
import withStyles from '@material-ui/core/styles/withStyles';
import { modulesNames } from '../consts';
import { put } from '../../../helpers/apiHelpers';
import produce from 'immer';
import set from 'lodash/set';
import ClienPanelDesignView from './ClientPanelDesing.view';
import styled from 'styled-components';
import { toast } from 'react-toastify';

const uploadedContentBaseURL = `${process.env.REACT_APP_API_ENDPOINT}/uploaded-media/`;

const Wrapper = styled.div`
  display: flex;
  gap: 40px;

  > div {
    max-width: 225px;
  }

  > div:last-child {
    button {
      margin-top: 20px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ClientPanelDesignContainer = ({
  classes,
  t,
  selectedBrand,
  fetchBrand,
  fetchBrandConfigModulesPack,
  updateBrandConfigModulesPack,
}) => {
  const [state, setState] = useState({
    newOrderInfoDescription: '',
    mainColor: '#000000',
    secondaryColor: '#B58A78',
    backgroundColor: '#FAFBFC',
    backgroundImage: null,
    backgroundImageUrl: null,
    useImageAsBackground: false,
    logoMobile: null,
    logoDesktop: null,
    logoMail: null,
    logoMobileUrl: null,
    logoDesktopUrl: null,
    logoMailUrl: null,
    favicon: null,
    faviconUrl: null,
    customCSS: '',
    customScripts: '',
    headTags: '',
    bodyTags: '',
    footerLink: {
      up: [],
      down: [],
    },
    logoLink: '',
    logoLinkOpenNewCard: false,
  });

  const [modules, setModules] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isColor, setIsColor] = useState(true);

  // TODO: match variable names with ones from BE

  const handleDeleteLink = (index, section) => {
    setDisabledButton(true);
    setState(
      produce(draftState => {
        draftState.footerLink[section].splice(index, 1);
      })
    );
  };

  useEffect(() => {
    (async () => {
      const brandConfig = await fetchBrand(selectedBrand);
      const modulesConfig = await fetchBrandConfigModulesPack(
        selectedBrand,
        Object.values(modulesNames)
      );

      setState(prevState => ({
        ...prevState,
        mainColor:
          brandConfig.mainColor === null ? '#000000' : brandConfig.mainColor,
        secondaryColor:
          brandConfig.secondaryColor === null
            ? '#B58A78'
            : brandConfig.secondaryColor,
        backgroundColor: brandConfig.backgroundColor
          ? brandConfig.backgroundColor
          : state.backgroundColor,
        backgroundImage: brandConfig.backgroundImage?.['@id'] || null,
        backgroundImageUrl: brandConfig.backgroundImage?.contentUrl || null,
        useImageAsBackground: brandConfig.useImageAsBackground,
        logoMobile: brandConfig.logoMobile?.['@id'] || null,
        logoDesktop: brandConfig.logoDesktop?.['@id'] || null,
        logoMail: brandConfig.logoMail?.['@id'] || null,
        logoMobileUrl: brandConfig.logoMobile?.contentUrl || null,
        logoDesktopUrl: brandConfig.logoDesktop?.contentUrl || null,
        logoMailUrl: brandConfig.logoMail?.contentUrl || null,
        favicon: brandConfig.favicon?.['@id'] || null,
        faviconUrl: brandConfig.favicon?.contentUrl || null,
        customCSS: brandConfig.customCSS,
        customScripts: brandConfig.customScripts,
        headTags: brandConfig.headTags,
        bodyTags: brandConfig.bodyTags,
        footerLink: brandConfig.footerLink,
        logoLink: brandConfig.logoLink ?? '',
        logoLinkOpenNewCard: brandConfig.logoLinkOpenNewCard ?? false,
      }));

      if (Object.keys(brandConfig.footerLink ?? {}).length === 0) {
        setState(prevState => ({
          ...prevState,
          footerLink: { up: [], down: [] },
        }));
      }
      setModules(modulesConfig.configuration);
      setIsLoading(false);
    })();
  }, []);

  const [disabledButton, setDisabledButton] = useState(false);

  const getImage = (stateName, data) => {
    setState(prevState => ({
      ...prevState,
      [stateName]: data?.['@id'] || null,
      [`${stateName}Url`]: data?.contentUrl || null,
    }));
  };

  const removeIcon = (section, index) => {
    setState(prevState => ({
      ...prevState,
      footerLink: {
        ...prevState.footerLink,
        [section]: prevState.footerLink[section].map((line, ind) => {
          if (ind === index) {
            return {
              ...line,
              icon: '',
            };
          }
          return line;
        }),
      },
    }));
  };

  const removeImage = stateName => {
    setState(prevState => ({
      ...prevState,
      [stateName]: null,
      [`${stateName}Url`]: null,
      ...(stateName === 'backgroundImage'
        ? { useImageAsBackground: false }
        : {}),
    }));
  };

  const handleInputChange = ({ target: { name, value } }) => {
    setDisabledButton(true);
    setState(
      produce(draftState => {
        set(draftState, name, value);
      })
    );
  };

  const onClickUp = (index, section) => {
    const relation = index - 1;
    const array = state.footerLink[section];
    const temp = array[relation];
    if (relation === -1) {
      return;
    }
    array[relation] = array[index];
    array[index] = temp;
    setState(prevState => ({
      ...prevState,
      footerLink: {
        ...prevState.footerLink,
        [section]: array,
      },
    }));
  };

  const updateModule = (_, payload, section, index) => {
    setDisabledButton(true);
    setState(prevState => ({
      ...prevState,
      footerLink: {
        ...prevState.footerLink,
        [section]: prevState.footerLink[section].map((link, ind) => {
          if (ind === index) {
            return {
              ...link,
              icon: uploadedContentBaseURL + payload.contentUrl,
            };
          }
          return link;
        }),
      },
    }));
  };

  const onClickDown = (index, section) => {
    const relation = index + 1;
    const array = state.footerLink[section];
    const temp = array[relation];
    if (relation === array.length) {
      return;
    }

    array[relation] = array[index];
    array[index] = temp;
    setState(prevState => ({
      ...prevState,
      footerLink: {
        ...prevState.footerLink,
        [section]: array,
      },
    }));
  };

  const handleColorPicker = (color, stateName) => {
    setState(prevState => ({ ...prevState, [stateName]: color.hex }));
  };

  const handleModuleUpdate = data => {
    const type = data['@type'];
    let module = { ...modules[type], ...data };
    setModules({ ...modules, [type]: module });
  };
  const handleSubmit = async () => {
    const data = {
      newOrderInfoDescription: state.newOrderInfoDescription,
      mainColor: state.mainColor,
      secondaryColor: state.secondaryColor,
      backgroundColor: state.backgroundColor,
      backgroundImage: state.backgroundImage,
      backgroundImageUrl: state.backgroundImageUrl,
      useImageAsBackground: state.useImageAsBackground,
      logoMobile: state.logoMobile,
      logoDesktop: state.logoDesktop,
      logoMail: state.logoMail,
      favicon: state.favicon,
      customCSS: state.customCSS,
      customScripts: state.customScripts,
      headTags: state.headTags,
      bodyTags: state.bodyTags,
      footerLink: state.footerLink,
      logoLink: state.logoLink,
      logoLinkOpenNewCard: state.logoLinkOpenNewCard,
    };

    try {
      const response = await put(`/brands/${selectedBrand}`, data);
      await updateBrandConfigModulesPack(response.id, modules);

      toast.success(t('success.changesSaved'));
    } catch (error) {
      toast.error(
        error?.message
          ? `${t('errors.modulesCouldNotBeSaved')} ${error.message}`
          : t('errors.modulesCouldNotBeSaved')
      );
    }

    if (disabledButton) {
      window.location.reload();
    }
  };

  const props = {
    state: state,
    setState: setState,
    modules: modules,
    setModules: setModules,
    isLoading: isLoading,
    isColor: isColor,
    setIsColor: setIsColor,
    handleDeleteLink: handleDeleteLink,
    disabledButton: disabledButton,
    setDisabledButton: setDisabledButton,
    Wrapper: Wrapper,
    getImage: getImage,
    removeIcon: removeIcon,
    removeImage: removeImage,
    handleInputChange: handleInputChange,
    onClickUp: onClickUp,
    updateModule: updateModule,
    onClickDown: onClickDown,
    handleColorPicker: handleColorPicker,
    handleModuleUpdate: handleModuleUpdate,
    handleSubmit: handleSubmit,
    classes: classes,
    t: t,
    selectedBrand: selectedBrand,
    fetchBrand: fetchBrand,
    fetchBrandConfigModulesPack: fetchBrandConfigModulesPack,
    updateBrandConfigModulesPack: updateBrandConfigModulesPack,
  };

  return <ClienPanelDesignView props={props} />;
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withTranslation(),
  connect(
    ({ Auth: { selectedBrand } }) => ({
      selectedBrand,
    }),
    {
      fetchBrand,
      fetchBrandConfigModulesPack,
      fetchPaymentTypes,
      updateBrandConfigModulesPack,
    }
  ),
  withStyles(combinedStyles)
);

export default enhance(ClientPanelDesignContainer);
