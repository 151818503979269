import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/styles/makeStyles';

import MailNotificationCardSectionView from './MailNotificationCardSection.view';
import { connect } from 'react-redux';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';

import SweetAlert from 'react-bootstrap-sweetalert';
import { compose } from 'redux';

const useStyles = makeStyles({
  ...extendedFormsStyle,
  ...buttonsStyle,
  ...sweetAlertStyle,
});

const MailNotificationCardSectionContainer = ({
  mailingOptions,
  mailForAdmin,
  title,
  mailing,
  defaultConfigFromBrand,
  brand,
  setAllowToChangedElements,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [state, setState] = useState({
    mailForAdmin: mailForAdmin,
    mailing: mailing,
    confirmModal: null,
    defaultConfigFromBrand: defaultConfigFromBrand,
  });

  const renderAlert = (onConfirm, onCancel) => {
    const confirmModal = (
      <SweetAlert
        warning
        title={t('notify.resetContent')}
        onConfirm={() => onConfirm()}
        onCancel={() => onCancel()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        confirmBtnText={t('notify.yes')}
        cancelBtnText={t('notify.no')}
        showCancel
      />
    );

    setState(prev => ({ ...prev, confirmModal }));
  };

  const props = {
    mailingOptions,
    state,
    setState,
    renderAlert,
    title,
    brand,
    setAllowToChangedElements,
  };

  return <MailNotificationCardSectionView props={props} />;
};

const mapStateToProps = state => {
  return {
    brand: state.Auth.selectedBrand,
  };
};

const enhance = compose(connect(mapStateToProps, null));

export default enhance(MailNotificationCardSectionContainer);
