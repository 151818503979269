import { useState, useEffect } from 'react';
import { isGranted } from 'helpers/helpers';
import columnConfig from './columnConfig';
import { get, post } from 'helpers/apiHelpers';
import { ROLE_CREATE_MONEY_BOX_HISTORY } from 'helpers/roles';
import DataGrid from 'components/DataGrid/DataGrid';

import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import FormLabel from '@material-ui/core/FormLabel';

import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import GridContainer from 'components/Grid/GridContainer';
import CustomInput from 'components/CustomInput/CustomInput';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import { Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/styles/makeStyles';
import { toast } from 'react-toastify';

const useStyles = makeStyles({
  ...extendedFormsStyle,
  ...buttonsStyle,
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  divider: {
    marginBottom: '10px',
  },
});

const Moneybox = ({ fetchUser, userMoneybox, noCancel, userId }) => {
  const [openedModal, setOpenedModal] = useState(false);
  const [moneybox, setMoneybox] = useState([]);
  const [value, setValue] = useState('');
  const [event, setEvent] = useState('');

  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    get(`clients/${userId}/money-box-histories`, {
      pagination: false,
      'order[id]': 'DESC',
    }).then(response => {
      setMoneybox(response['hydra:member']);
    });
  }, [userId]);

  const closeModal = () => {
    setOpenedModal(false);
  };

  const newPoints = () => {
    setOpenedModal(true);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    if (name === 'value') setValue(value);
    if (name === 'event') setEvent(value);
  };

  const validateForm = () => {
    return value;
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return toast.error(this.props.t('clients.fillAllFields'));
    }
    const data = {
      value: parseFloat(value),
      event: event,
      client: `/clients/${userId}`,
    };
    post('/money-box-histories', data).then(
      () => {
        setValue('');
        setEvent('');
        get(`clients/${userId}/money-box-histories`, {
          pagination: false,
          'order[id]': 'DESC',
        }).then(response => {
          setMoneybox(response['hydra:member']);
        });
        fetchUser(userId);
        closeModal();
      },
      error => {
        if (error.response.status === 500) {
          return toast.error(
            `${this.props.t('clients.smthWentWrong')} ${this.props.t(
              'clients.checkForm'
            )}`
          );
        }
        toast.error(error.response.data.violations[0].message);
      }
    );
  };

  return (
    <>
      <div className={classes.header}>
        <h4>
          {t('client.moneyBox.pointsAmount')}: <b>{userMoneybox ?? ''}</b>
        </h4>
        {isGranted(ROLE_CREATE_MONEY_BOX_HISTORY) && (
          <FormControlButtons
            classes={classes}
            cancelPath={noCancel ? '' : '/admin/clients'}
            discardText={t('clients.backToList')}
            handleSubmit={newPoints}
            submitText={t('clients.modify')}
          />
        )}
      </div>
      <Divider className={classes.divider} />
      <DataGrid
        refresh={moneybox[0]?.stateAfter ?? 0}
        refreshOnFetch={true}
        actions={false}
        url={`clients/${userId}/money-box-histories`}
        role="ORDER"
        paginationTop={true}
        paginationBottom={true}
        columns={columnConfig}
      />
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={openedModal}
        onClose={closeModal}
      >
        <DialogContent>
          <GridContainer>
            <GridItem xs={6}>
              <FormLabel className={classes.labelHorizontal}>
                {t('clients.pointsCount')}*
              </FormLabel>
              <CustomInput
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  name: 'value',
                  value: value,
                  type: 'number',
                  onChange: handleChange,
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <FormLabel className={classes.labelHorizontal}>
                {t('clients.event')}*
              </FormLabel>
              <CustomInput
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  multiline: true,
                  rowsMax: 3,
                  name: 'event',
                  value: event,
                  onChange: handleChange,
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem>
              <Button className={classes.marginRight} onClick={closeModal}>
                {t('clients.cancel')}
              </Button>
              <Button
                color="success"
                className={classes.marginLeft}
                onClick={handleSubmit}
              >
                {t('clients.add')}
              </Button>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Moneybox;
