import { useEffect, useState } from 'react';

import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { useTranslation } from 'react-i18next';
import ReactTable from 'react-table';
import Input from '@material-ui/core/Input';
import { put } from 'helpers/apiHelpers';
import produce from 'immer';
import { useSelector } from 'react-redux';
import { currencyToSymbol } from 'utils/currencies';
import { isGranted } from 'helpers/helpers';
import roles from 'helpers/roles';
import CardWrapper from 'components/Card/CardWrapper';
import { toast } from 'react-toastify';

const DefaultPriceMeals = ({ classes, mealTypeSizes = [] }) => {
  const { t } = useTranslation();
  const { currency } = useSelector(state => state.Brands.brand.multinational);

  const [stateMealTypeSizes, setStateMealTypeSizes] = useState([]);
  useEffect(() => {
    setStateMealTypeSizes(mealTypeSizes);
  }, [mealTypeSizes]);

  const updateSizePrice = (iri, defaultCostForShop) => {
    put(iri, { defaultCostForShop: parseFloat(defaultCostForShop) }).then(
      () => {
        toast.success(t('success.dataUpdated'));
      }
    );
  };

  const getTableData = () => {
    const tableData = stateMealTypeSizes.map(item => {
      return {
        mealTypeName: item?.mealType?.name || '',
        calories: item?.calories ? `${item.calories} kcal` : '',
        defaultCostForShop: (
          <Input
            inputProps={{
              style: {
                fontSize: 14,
                paddingLeft: 0,
                paddingTop: 5,
                paddingBottom: 5,
              },
              disabled: !isGranted(roles.ROLE_EDIT_MODULE_SHOP),
              type: 'number',
              value: item.defaultCostForShop ?? '',
              onChange: e => {
                setStateMealTypeSizes(
                  produce(draft => {
                    const currentMealTypeSize = draft.find(
                      mealTypeSize => mealTypeSize['@id'] === item['@id']
                    );

                    currentMealTypeSize.defaultCostForShop = e.target.value;
                  })
                );
              },
              onBlur: e => {
                updateSizePrice(item['@id'], e.target.value);
              },
            }}
          />
        ),
      };
    });

    return tableData;
  };

  const tableColumns = [
    {
      accessor: 'mealTypeName',
      Header: t('$*shop.defaultPriceMeals.mealType', '$$Typ posiłku'),
    },
    {
      accessor: 'calories',
      Header: t('$*shop.defaultPriceMeals.size', '$$Wielkość'),
    },
    {
      accessor: 'defaultCostForShop',
      Header: t('$*shop.defaultPriceMeals.defaultPrice', {
        defaultValue: '$$Cena domyślna [ {{currencySymbol}} ]',
        replace: { currencySymbol: currencyToSymbol(currency) },
      }),
    },
  ];

  return (
    <CardWrapper
      title={t(
        '$*shop.defaultPriceMeals.defaultMealsPrices',
        '$$Domyślne ceny posiłków'
      )}
    >
      <CardBody>
        <GridContainer>
          <GridItem xs={6}>
            <ReactTable
              data={getTableData()}
              resizable={false}
              sortable={false}
              columns={tableColumns}
              minRows={5}
              showPaginationTop={true}
              showPaginationBottom={true}
              className="-striped -highlight"
              previousText={t('dataGrid.prevPage')}
              nextText={t('dataGrid.nextPage')}
              loadingText={t('dataGrid.loading')}
              noDataText={t('dataGrid.notFound')}
              pageText={t('dataGrid.page')}
              ofText={t('dataGrid.pageOf')}
              rowsText={t('dataGrid.results')}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </CardWrapper>
  );
};

export default DefaultPriceMeals;
