import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import makeStyles from '@material-ui/styles/makeStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';

import { sortByKey } from 'helpers/helpers';

import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import FormTextInput from 'components/FormTextInput/FormTextInput';

const useStyles = makeStyles({
  ...extendedFormsStyle,
  ...buttonsStyle,
});

const SuperVariantBetaPricesTable = props => {
  const { t } = useTranslation();
  const { state, setState, multinational } = props;

  const classes = useStyles();

  const calories = state.variant.calories || [];
  const mealTypes = state.variant.mealTypes || [];

  const handleInputChange = ({ event, mealType, calorific }) => {
    const findCalorific = state.variant.calories.find(
      el => el.key === calorific.key
    );

    const findSuperVariant = findCalorific.superVariantMealType.find(
      superVariant => superVariant.mealType === mealType['@id']
    );

    const newValue = calories.map(calories => {
      if (calories.key === findCalorific.key) {
        return {
          ...calories,
          superVariantMealType: calories.superVariantMealType.map(
            superVariant => {
              if (
                superVariant.id === findSuperVariant.id &&
                superVariant.mealType === mealType['@id']
              ) {
                return {
                  ...superVariant,
                  resignPrice: parseFloat(event.target.value),
                };
              }
              return superVariant;
            }
          ),
        };
      }
      return calories;
    });

    setState(prevValue => {
      return {
        ...prevValue,
        variant: {
          ...prevValue.variant,
          calories: newValue,
        },
      };
    });
  };

  useEffect(() => {
    const rawMealTypes = mealTypes.map(mealType => mealType?.['@id']);

    const notCorrectCalories = calories.filter(
      el => mealTypes.length !== el.superVariantMealType.length
    );
    if (notCorrectCalories.length === 0) {
      return;
    }

    const initialNewSuperVariant = calories.map(prevCalorific => {
      if (mealTypes.length > prevCalorific.superVariantMealType.length) {
        const mealTypeToAdd = mealTypes
          .map(mealType => {
            if (
              !prevCalorific.superVariantMealType.find(
                el => el.mealType === (mealType?.['@id'] || mealType)
              )
            ) {
              return {
                mealType: mealType['@id'],
                resignPrice: 0,
              };
            }
            return null;
          })
          .filter(el => el !== null);

        return {
          ...prevCalorific,
          superVariantMealType: [
            ...prevCalorific.superVariantMealType,
            ...mealTypeToAdd,
          ],
        };
      } else {
        return {
          ...prevCalorific,
          superVariantMealType: prevCalorific.superVariantMealType.filter(
            superVariantMealType => {
              return rawMealTypes.includes(superVariantMealType.mealType);
            }
          ),
        };
      }
    });

    setState(prevValue => {
      return {
        ...prevValue,
        variant: {
          ...prevValue.variant,
          calories: initialNewSuperVariant,
        },
      };
    });
  }, [mealTypes]);

  const pickMealType = (mealType, calorific) => {
    return calorific.superVariantMealType.find(
      el =>
        (el.mealType?.['@id'] ?? el.mealType) ===
        (mealType?.['@id'] ?? mealType)
    );
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell></TableCell>

          {mealTypes.map(el => (
            <TableCell key={`head-${el['@id']}`}>{el.name}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {sortByKey(calories, 'position').map((calorific, index) => (
          <TableRow key={`ii-${index}`}>
            <TableCell rowSpan={1}>
              {calorific.name}{' '}
              {calorific.workName &&
                calorific.name !== calorific.workName &&
                `(${calorific.workName})`}
            </TableCell>
            <TableCell>
              {`${t('form.field.variant.superVariant.beta.value')} [${
                multinational.currency
              }]`}
            </TableCell>
            {mealTypes.map((el, mIndex) => (
              <TableCell key={`super-variant-beta-price-${index}-${mIndex}`}>
                <FormTextInput
                  classes={classes}
                  name="minPrice"
                  type="number"
                  value={pickMealType(el, calorific)?.resignPrice || 0}
                  handleChange={ev => {
                    handleInputChange({ event: ev, mealType: el, calorific });
                  }}
                  inputSize={12}
                  maxLength={6}
                  inputProps={{ min: 0 }}
                />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default SuperVariantBetaPricesTable;
