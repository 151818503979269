import React from 'react';
import { EuroSymbol } from '@material-ui/icons';
import withStyles from '@material-ui/core/styles/withStyles';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ModalButton from 'components/CustomButtons/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { Tooltip } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

class WrongCodes extends React.Component {
  state = {
    codesErrorModal: false,
  };

  closeCodesErrorModal = () =>
    this.setState(prevState => ({ ...prevState, codesErrorModal: false }));

  openCodesErrorModal = () =>
    this.setState(prevState => ({ ...prevState, codesErrorModal: true }));

  render() {
    const { hasWrongMenuPlanning, dishCodes } = this.props;

    if (!hasWrongMenuPlanning) {
      return null;
    }

    return (
      <>
        <Dialog
          maxWidth={'lg'}
          open={this.state.codesErrorModal}
          onClose={this.closeCodesErrorModal}
        >
          <DialogContent>
            <ModalButton
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                cursor: 'pointer',
              }}
              color={'transparent'}
              justIcon
              round
              onClick={this.closeCodesErrorModal}
            >
              &times;
            </ModalButton>
          </DialogContent>
          <DialogContent>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {this.props.t(
                        'menuPlanner.action.warning.wrongCodes.fields.dish'
                      )}
                    </TableCell>
                    <TableCell>
                      {this.props.t(
                        'menuPlanner.action.warning.wrongCodes.fields.mealType'
                      )}
                    </TableCell>
                    <TableCell>
                      {this.props.t(
                        'menuPlanner.action.warning.wrongCodes.fields.codes'
                      )}
                    </TableCell>
                    <TableCell>
                      {this.props.t(
                        'menuPlanner.action.warning.wrongCodes.fields.diet'
                      )}
                    </TableCell>
                    <TableCell>
                      {this.props.t(
                        'menuPlanner.action.warning.wrongCodes.fields.variant'
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dishCodes
                    .filter(el => !el.isValidCode)
                    .map((el, index) => {
                      return (
                        <TableRow key={index} style={{ background: '#f00' }}>
                          <TableCell style={{ color: '#fff' }} variant={'head'}>
                            {el.dish.nameForClient}
                          </TableCell>
                          <TableCell style={{ color: '#fff' }}>
                            {el.mealType.name}
                          </TableCell>
                          <TableCell
                            style={{ color: '#fff' }}
                            dangerouslySetInnerHTML={{
                              __html: el.existInCodes.join('<br/>'),
                            }}
                          />
                          <TableCell
                            style={{ color: '#fff' }}
                            dangerouslySetInnerHTML={{
                              __html: el.existInDiets
                                .map(el => el?.name)
                                .join('<br/>'),
                            }}
                          />
                          <TableCell
                            style={{ color: '#fff' }}
                            dangerouslySetInnerHTML={{
                              __html: el.existInVariants
                                .map(el => el?.name)
                                .join('<br/>'),
                            }}
                          />
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Paper>
          </DialogContent>
        </Dialog>
        <Tooltip
          style={{ marginLeft: '10px', cursor: 'help' }}
          title={
            <div>
              <h4>
                {this.props.t(
                  'menuPlanner.action.warning.wrongCodes.tooltip.title'
                )}
                <br />
                <br />
                {this.props.t(
                  'menuPlanner.action.warning.wrongCodes.tooltip.titleDesc'
                )}
                <br />
                <br />
                <b>
                  {this.props.t(
                    'menuPlanner.action.warning.wrongCodes.tooltip.critic'
                  )}
                </b>
                <br />
                <br />
                <u>
                  {this.props.t(
                    'menuPlanner.action.warning.wrongCodes.tooltip.example'
                  )}
                </u>
                <br />
                {this.props.t(
                  'menuPlanner.action.warning.wrongCodes.tooltip.exampleDesc'
                )}
              </h4>
            </div>
          }
          placement="bottom"
        >
          <BottomNavigationAction
            label={this.props.t(
              'menuPlanner.action.warning.wrongCodes.tooltip.actionName'
            )}
            showLabel={true}
            icon={<EuroSymbol style={{ color: '#f65b52' }} />}
            onClick={this.openCodesErrorModal}
          />
        </Tooltip>
      </>
    );
  }
}

export default withTranslation()(withStyles(sweetAlertStyle)(WrongCodes));
