import { useEffect, useState } from 'react';
import CopyingBrandSettingsView from './CopyingBrandSettings.view';

import { connect } from 'react-redux';
import { getOtherBrand, postOtherBrand } from './AxiosFuncion';
import { get, put } from 'helpers/apiHelpers';
import {
  transformBrandData,
  transformModulesData,
} from './TransformDataToRequest';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const CopyingBrandSettingsContainer = ({ sourceBrandInformation }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loginData, setLoginData] = useState({
    apiAddress: '',
    email: '',
    password: '',
  });
  const [tokenFromLoginCheck, setTokenFromLoginCheck] = useState('');
  //brand data - list of brands and other information
  const [brandList, setBrandList] = useState([]);
  //selected brand - completely information with brand
  const [selectedBrandToCopy, setSelectedBrandToCopy] = useState({});

  const idSourceBrandInformation = sourceBrandInformation.id;

  const [sourceBrandModules, setSourceBrandModules] = useState({});

  useEffect(() => {
    get(`brands/${idSourceBrandInformation}/modules`).then(res => {
      setSourceBrandModules(res.configuration);
    });
  }, [idSourceBrandInformation]);

  const handleChange = e => {
    setLoginData(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleShowModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleLogin = () => {
    const postData = {
      url: `${loginData.apiAddress}/login_check`,
      username: loginData.email,
      password: loginData.password,
    };

    postOtherBrand(postData).then(response => {
      setTokenFromLoginCheck(response.data.token);

      const getData = {
        url: `${loginData.apiAddress}/brands`,
        token: response.data.token,
        brand: 1,
      };
      getOtherBrand(getData).then(brandData => {
        setBrandList(brandData.data['hydra:member']);
      });
    });
  };

  const handleCopySettings = () => {
    const requestData = {
      url: `${loginData.apiAddress}/brands/${selectedBrandToCopy.id}/modules`,
      token: tokenFromLoginCheck,
      brand: selectedBrandToCopy.id,
    };
    const requestModulesData = transformBrandData(selectedBrandToCopy);
    put(`brands/${idSourceBrandInformation}`, requestModulesData)
      .then(() => {
        toast.success(t('brandCfg.copyingBrandSettings.brand.toast.success'));

        getOtherBrand(requestData).then(modulesData => {
          const requestModulesData = transformModulesData(
            modulesData.data.configuration,
            sourceBrandModules
          );
          put(`brands/${idSourceBrandInformation}/modules`, requestModulesData)
            .then(() => {
              setTimeout(() => {
                toast.success(
                  t('brandCfg.copyingBrandSettings.module.toast.success')
                );
              }, 3000);
            })
            .catch(err => {
              toast.error(err);
            });
        });
      })
      .catch(err => {
        toast.error(err);
      });
  };

  return (
    <CopyingBrandSettingsView
      loginData={loginData}
      setLoginData={setLoginData}
      handleChange={handleChange}
      handleLogin={handleLogin}
      brandList={brandList}
      sourceBrandInformation={sourceBrandInformation}
      selectedBrandToCopy={selectedBrandToCopy}
      setSelectedBrandToCopy={setSelectedBrandToCopy}
      handleCopySettings={handleCopySettings}
      isModalOpen={isModalOpen}
      handleShowModal={handleShowModal}
    />
  );
};

const mapStateToProps = state => {
  return {
    sourceBrandInformation: state.Brands.brand,
  };
};

const enhance = compose(connect(mapStateToProps));

export default enhance(CopyingBrandSettingsContainer);
