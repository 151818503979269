import { useEffect, useState } from 'react';
import { isGranted, postCodeValidator } from 'helpers/helpers';
import defaultState from './defaultState';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';

import FormLabel from '@material-ui/core/FormLabel';

import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import SelectInput from 'components/FormSelect/SelectInput';

import { get, put } from 'helpers/apiHelpers';
import { ROLE_CREATE_BRAND } from '../../helpers/roles';
import DataGrid from '../../components/DataGrid/DataGrid';
import columnConfig from '../Brands/columnConfig';
import { Info } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core/index';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import { difference, isEmpty } from 'lodash';
import { ReactPhoneNumberAdapter } from 'components/ReactPhoneNumberAdapter/ReactPhoneNumberAdapter';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { saveCompany } from 'actions/Company';
import FakturowniaWarehouseModule from 'views/BrandConfiguration/modules/FakturowniaWarehouseModule';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import CardWrapper from 'components/Card/CardWrapper';
import makeStyles from '@material-ui/styles/makeStyles';
import { toast } from 'react-toastify';

const useStyles = makeStyles({
  ...extendedFormsStyle,
  ...buttonsStyle,
  container: {
    marginTop: '15px',
  },
  flex: { display: 'flex' },
  icon: { marginLeft: '4px', fontSize: '16px' },
  grid: { marginLeft: '-15px' },
});

const MyCompany = ({ user, history, brands: propBrands, saveCompany }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [state, setState] = useState({
    ...defaultState,
  });

  const [brandsAfterChanges, setBrandsAfterChanges] = useState([]);
  const [featuredBrands, setFeaturedBrands] = useState([]);
  const [modules, setModules] = useState({});
  const [brands, setBrands] = useState([]);
  const [newValuesFalse, setNewValuesFalse] = useState([]);
  const [newValuesTrue, setNewValuesTrue] = useState([]);

  useEffect(() => {
    const index = user.company.lastIndexOf('/') + 1;
    const companyId = user.company.slice(index, user.company.length);

    get(user.company).then(response => {
      const dishIngredientsDecimalPlaces =
        response.dietarySetup.dishIngredientsDecimalPlaces;
      setState(prev => ({
        ...prev,
        name: response.name,
        shortName: response.shortName,
        mainDomain: response.mainDomain,
        email: response.email,
        phone: response.phone,
        street: response.street,
        city: response.city,
        showFooterCaterings: response.showFooterCaterings,
        postCode: response.postCode,
        vatNumber: response.vatNumber,
        regon: response.regon,
        hotJarVersion: response.hotJar.version,
        hotJarId: response.hotJar.id,
        allowHotJar: response.hotJar.enabled,
        allowZohoSalesIQ: response.zohoSalesIQ.enabled,
        zohoSalesIQWidgetCode: response.zohoSalesIQ.widgetCode,
        defaultConfigFromBrand: response.defaultConfigFromBrand,
        dishIngredientsDecimalPlaces:
          dishIngredientsDecimalPlaces === 0
            ? t('common.lack')
            : dishIngredientsDecimalPlaces ?? 2,
        loadAllVariantsInitially:
          !!response.dietarySetup.loadAllVariantsInitially,
        alteredDietWorkName: response.dietarySetup.alteredDietWorkName,
        maxDishesToProducePerDay: response.maxDishesToProducePerDay,
        maxDishesToProducePerDayInBrand:
          response.maxDishesToProducePerDayInBrand ?? {},
        maxDishesToProducePerDayEnabled:
          response.maxDishesToProducePerDayEnabled,
      }));
    });

    get(`/company/${companyId}/modules`).then(res => {
      setModules(res.configuration);
    });

    get('/brands').then(response => {
      const activeBrands = [];

      response['hydra:member'].map(el => {
        if (el.highlightOnReports) {
          return activeBrands.push(el['@id']);
        }
        return null;
      });

      setBrands(response['hydra:member']);
      setFeaturedBrands(activeBrands);
      setBrandsAfterChanges(activeBrands);
      setNewValuesFalse([]);
      setNewValuesTrue([]);
    });
  }, []);

  const companyBrandsSettings = () => history.push(`/admin/company/brands/add`);

  const handleInputChange = ({ target: { name, value } }) => {
    setState(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleMaxDishesToProducePerDayInBrandChange = (event, brandId) => {
    setState(prev => ({
      ...prev,
      maxDishesToProducePerDayInBrand: {
        ...prev.maxDishesToProducePerDayInBrand,
        [brandId]: isNaN(parseInt(event.target.value))
          ? null
          : parseInt(event.target.value),
      },
    }));
  };

  const handlePhoneChange = (number, countryCode) => {
    setState(prev => ({
      ...prev,
      phone: {
        ...prev.phone,
        number,
        countryCode,
      },
    }));
  };

  const validateEmail = () => {
    return state.email.includes('@');
  };

  const validateForm = () => {
    return state.name && state.maxDishesToProducePerDay >= 0;
  };

  const handleModuleUpdate = data => {
    const type = data['@type'];
    setModules(prev => ({
      ...prev,
      [type]: data,
    }));
  };

  const handleBrands = e => {
    const value = e.target.value;
    setBrandsAfterChanges(value);

    const newValuesFalse = difference(featuredBrands, value);
    const newValuesTrue = difference(value, featuredBrands);

    if (!isEmpty(newValuesTrue)) {
      setNewValuesTrue(newValuesTrue);
    }

    if (!isEmpty(newValuesFalse)) {
      setNewValuesTrue(newValuesFalse);
    }
    return e;
  };

  const handleHighlightBrands = async () => {
    if (!isEmpty(newValuesTrue)) {
      await Promise.all(
        newValuesTrue.map(el => put(el, { highlightOnReports: true }))
      );
    }

    if (!isEmpty(newValuesFalse)) {
      await Promise.all(
        newValuesFalse.map(el => put(el, { highlightOnReports: false }))
      );
    }
  };

  const handleSubmit = () => {
    const index = user.company.lastIndexOf('/') + 1;
    const companyId = user.company.slice(index, user.company.length);

    if (!validateEmail()) {
      toast.error(t('myCompany.toast.incorrectEmail'));
      return;
    }

    if (!validateForm()) {
      toast.error(t('myCompany.toast.complete'));
      return;
    }
    const data = {
      name: state.name,
      shortName: state.shortName,
      mainDomain: state.mainDomain,
      email: state.email.toLowerCase(),
      phone: state.phone,
      street: state.street,
      city: state.city,
      postCode: state.postCode,
      vatNumber: state.vatNumber,
      regon: state.regon,
      defaultConfigFromBrand: state.defaultConfigFromBrand,
      showFooterCaterings: state.showFooterCaterings,
      maxDishesToProducePerDay: parseInt(state.maxDishesToProducePerDay),
      maxDishesToProducePerDayEnabled: state.maxDishesToProducePerDayEnabled,
      maxDishesToProducePerDayInBrand: state.maxDishesToProducePerDayInBrand,
      hotJar: {
        version: state.hotJarVersion,
        id: state.hotJarId,
        enabled: state.allowHotJar,
      },
      zohoSalesIQ: {
        enabled: state.allowZohoSalesIQ,
        widgetCode: state.zohoSalesIQWidgetCode,
      },
      dietarySetup: {
        dishIngredientsDecimalPlaces:
          state.dishIngredientsDecimalPlaces === t('common.lack')
            ? 0
            : state.dishIngredientsDecimalPlaces,
        loadAllVariantsInitially: state.loadAllVariantsInitially,
        alteredDietWorkName: state.alteredDietWorkName,
      },
    };

    Promise.all([
      saveCompany(user.company, data),
      handleHighlightBrands(),
      put(`company/${companyId}/modules`, {
        configuration: {
          FakturowniaWarehouse: modules['FakturowniaWarehouse'],
        },
      }),
    ]).then(
      () => toast.success(t('myCompany.toast.updated')),
      error => {
        if (error.response.data.violations[0].propertyPath === 'email') {
          toast.error(`Email: ${error.response.data.violations[0].message}`);
        } else {
          toast.error(t('errors.checkForm'));
        }
      }
    );
  };

  return (
    <GridContainer className={classes.container}>
      <GridItem xs={12}>
        <CardWrapper title={t('myCompany.companyInfo')}>
          <CardBody>
            <GridContainer>
              <GridItem md={6}>
                <FormTextInput
                  label={t('myCompany.fullname') + '*'}
                  classes={classes}
                  name="name"
                  value={state.name}
                  handleChange={handleInputChange}
                  inputSize={12}
                />
                <FormTextInput
                  label={t('myCompany.shortName')}
                  classes={classes}
                  name="shortName"
                  value={state.shortName}
                  handleChange={handleInputChange}
                  inputSize={12}
                />
                <FormTextInput
                  label={t('myCompany.mainDomain')}
                  classes={classes}
                  name="mainDomain"
                  value={state.mainDomain}
                  handleChange={handleInputChange}
                  inputSize={12}
                  maxLength={64}
                />
                <FormTextInput
                  label={t('myCompany.email')}
                  classes={classes}
                  name="email"
                  value={state.email}
                  handleChange={handleInputChange}
                  inputSize={12}
                />
                <ReactPhoneNumberAdapter
                  label={t('myCompany.phone')}
                  name="phone"
                  value={state.phone}
                  onChange={handlePhoneChange}
                />
                <FormLabel className={classes.labelHorizontal}>
                  <div className={classes.flex}>
                    <span>{t('myCompany.defBrand')}</span>
                    <Tooltip
                      title={<h4>{t('myCompany.defBrandTooltip')}</h4>}
                      placement="right"
                    >
                      <Info className={classes.icon} />
                    </Tooltip>
                  </div>
                </FormLabel>
                <SelectInput
                  noGrid
                  classes={classes}
                  mapBy="name"
                  trackBy="@id"
                  value={state.defaultConfigFromBrand}
                  options={propBrands}
                  handleChange={(ev, obj) => {
                    setState(prev => ({
                      ...prev,
                      defaultConfigFromBrand: obj['@id'],
                    }));
                  }}
                  size={12}
                />
              </GridItem>
              <GridItem md={6}>
                <FormTextInput
                  label={t('myCompany.street')}
                  classes={classes}
                  name="street"
                  value={state.street}
                  handleChange={handleInputChange}
                  inputSize={12}
                />
                <FormTextInput
                  label={t('myCompany.city')}
                  classes={classes}
                  name="city"
                  value={state.city}
                  handleChange={handleInputChange}
                  inputSize={12}
                />
                <FormTextInput
                  label={t('myCompany.postcode')}
                  classes={classes}
                  error={!postCodeValidator(state.postCode)}
                  success={postCodeValidator(state.postCode)}
                  value={state.postCode}
                  onChange={handleInputChange}
                  name="postCode"
                  inputSize={12}
                />
                <NumberFormat
                  label={t('myCompany.taxId')}
                  classes={classes}
                  customInput={FormTextInput}
                  value={state.vatNumber}
                  format="##########"
                  onChange={handleInputChange}
                  name="vatNumber"
                  inputSize={12}
                />
                <NumberFormat
                  label={t('myCompany.regon')}
                  classes={classes}
                  customInput={FormTextInput}
                  value={state.regon}
                  format="##########"
                  onChange={handleInputChange}
                  name="regon"
                  inputSize={12}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.allowHotJar}
                      onChange={e => {
                        setState(prev => ({
                          ...prev,
                          allowHotJar: !state.allowHotJar,
                        }));
                      }}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  label={t('myCompany.useHojtar')}
                />
                <FormTextInput
                  label="Hotjar Id"
                  classes={classes}
                  name="hotJarId"
                  value={state.hotJarId}
                  maxLength={64}
                  handleChange={handleInputChange}
                  inputSize={6}
                />
                <FormTextInput
                  label="Hotjar version"
                  classes={classes}
                  name="hotJarVersion"
                  value={state.hotJarVersion}
                  maxLength={64}
                  handleChange={handleInputChange}
                  inputSize={6}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.allowZohoSalesIQ}
                      onChange={e => {
                        setState(prev => ({
                          ...prev,
                          allowZohoSalesIQ: !state.allowZohoSalesIQ,
                        }));
                      }}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  label={t('myCompany.useZohoSalesIQ')}
                />
                <FormTextInput
                  label="Zoho SalesIQ widgetCode"
                  classes={classes}
                  name="zohoSalesIQWidgetCode"
                  value={state.zohoSalesIQWidgetCode}
                  maxLength={500}
                  handleChange={handleInputChange}
                  inputSize={9}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </CardWrapper>
      </GridItem>
      <GridItem xs={12}>
        <CardWrapper title={t('myCompany.brands')}>
          <GridItem md={12}>
            {isGranted(ROLE_CREATE_BRAND) && (
              <FormControlButtons
                classes={classes}
                handleSubmit={companyBrandsSettings}
                submitText={t('myCompany.addBrand') + '+'}
              />
            )}
            <DataGrid
              actions
              remove
              export
              paginationTop
              paginationBottom={false}
              url="/brands"
              reportName="brand"
              columns={columnConfig}
              role="BRAND"
            />
          </GridItem>
        </CardWrapper>
      </GridItem>
      <GridItem xs={12}>
        <CardWrapper title={t('myCompany.dietConfiguration')}>
          <CardBody>
            <GridContainer>
              <GridItem md={3}>
                <FormLabel className={classes.labelHorizontal}>
                  {t('myCompany.dishIngredientsDecimalPlaces')}
                </FormLabel>
                <SelectInput
                  noGrid
                  multiple={false}
                  classes={classes}
                  mapBy="value"
                  trackBy="value"
                  value={state.dishIngredientsDecimalPlaces}
                  options={Array.from(Array(10), (x, index) => ({
                    label: index,
                    value: index === 0 ? t('common.lack') : index,
                  }))}
                  handleChange={(e, { value }) => {
                    setState(prev => ({
                      ...prev,
                      dishIngredientsDecimalPlaces:
                        value === 0 ? t('common.lack') : value,
                    }));
                  }}
                  size={12}
                />
              </GridItem>
              <GridItem md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.loadAllVariantsInitially}
                      onChange={e => {
                        setState(prev => ({
                          ...prev,
                          loadAllVariantsInitially:
                            !state.loadAllVariantsInitially,
                        }));
                      }}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  label={t('myCompany.menuPlanner.selectAllVariantsInitialy')}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem md={3}>
                <FormTextInput
                  label={t('myCompany.alteredDietWorkName') + '*'}
                  classes={classes}
                  name="alteredDietWorkName"
                  value={state.alteredDietWorkName}
                  handleChange={handleInputChange}
                  inputSize={12}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </CardWrapper>
      </GridItem>
      <GridItem xs={12}>
        <CardWrapper title={t('myCompany.highlitghtBrandOnReport')}>
          <CardBody>
            <GridContainer>
              <GridItem md={3}>
                <FormLabel className={classes.labelHorizontal}>
                  {t('myCompany.dishCardReport')}
                </FormLabel>
                <SelectInput
                  noGrid
                  multiple
                  classes={classes}
                  mapBy="name"
                  trackBy="@id"
                  value={brandsAfterChanges}
                  options={brands}
                  const
                  handleChange={handleBrands}
                  size={12}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </CardWrapper>
      </GridItem>
      <GridItem xs={12}>
        <CardWrapper
          title={
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.maxDishesToProducePerDayEnabled}
                  onChange={e => {
                    setState(prev => ({
                      ...prev,
                      maxDishesToProducePerDayEnabled:
                        !state.maxDishesToProducePerDayEnabled,
                    }));
                  }}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              label={
                <>
                  {t('myCompany.maxDishesToProducePerDayEnabled')}
                  <Tooltip
                    title={
                      <h4>{t('myCompany.maxDishesToProducePerDay.tooltip')}</h4>
                    }
                    placement="right"
                  >
                    <Info className={classes.icon} />
                  </Tooltip>
                </>
              }
            />
          }
        >
          {state.maxDishesToProducePerDayEnabled && (
            <CardBody>
              <GridContainer>
                <GridItem md={6}>
                  <FormTextInput
                    label={t('myCompany.maxDishesToProducePerDay') + '*'}
                    classes={classes}
                    name="maxDishesToProducePerDay"
                    type="number"
                    value={state.maxDishesToProducePerDay}
                    noAutoComplete
                    handleChange={handleInputChange}
                  />
                </GridItem>
                <GridItem md={6}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('employees.brand')}</TableCell>
                        <TableCell>
                          {t('myCompany.maxDishesToProducePerDayInBrand')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {propBrands.map(brand => (
                        <TableRow key={brand['@id']}>
                          <TableCell>{brand.name}</TableCell>
                          <TableCell>
                            <FormTextInput
                              classes={classes}
                              name={`maxDishesToProducePerDayInBrand[${brand.id}]`}
                              type="number"
                              value={
                                state.maxDishesToProducePerDayInBrand[
                                  brand.id
                                ] ?? ''
                              }
                              noAutoComplete
                              handleChange={ev =>
                                handleMaxDishesToProducePerDayInBrandChange(
                                  ev,
                                  brand.id
                                )
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </GridItem>
              </GridContainer>
            </CardBody>
          )}
        </CardWrapper>
      </GridItem>
      <GridItem xs={12}>
        <CardWrapper>
          <CardBody>
            <GridItem md={6} className={classes.grid}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.showFooterCaterings}
                    onChange={e => {
                      setState(prev => ({
                        ...prev,
                        showFooterCaterings: !state.showFooterCaterings,
                      }));
                    }}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={t('myCompany.menuPlanner.showCateringsFooter')}
              />
            </GridItem>
          </CardBody>
        </CardWrapper>
      </GridItem>
      <GridItem xs={12}>
        <FakturowniaWarehouseModule
          classes={classes}
          handleModuleUpdate={handleModuleUpdate}
          module={modules['FakturowniaWarehouse']}
        />
      </GridItem>
      <GridItem xs={12}>
        <FormControlButtons
          classes={classes}
          submitText={t('common.shared.save')}
          handleSubmit={handleSubmit}
        />
      </GridItem>
    </GridContainer>
  );
};

const mapStateToProps = state => {
  return {
    user: state.Auth.user,
    brands: state.Auth.user.brands,
  };
};

const enhance = compose(connect(mapStateToProps, { saveCompany }));

export default enhance(MyCompany);
