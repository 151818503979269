import React from 'react';
import { useTranslation } from 'react-i18next';

import { combineStyles } from 'helpers/helpers';

import Dialog from '@material-ui/core/Dialog';
import withStyles from '@material-ui/core/styles/withStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Button from 'components/CustomButtons/Button';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import notificationsStyle from 'assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx';
import Calendar from '../CalendarSection/Calendar';
import { useState } from 'react';
import moment from 'moment';
import { useEffect } from 'react';
import { compose } from 'recompose';
import { isDateInArray, isTheSameDays } from 'helpers/dateHelpers';

const getDaysInMonth = month => {
  const days = [];
  const currentYear = new Date().getFullYear();
  const date = new Date(currentYear, month - 1, 1);

  const verifiedMonths = month === 13 ? 0 : month - 1;

  while (date.getMonth() === verifiedMonths) {
    days.push(date.toISOString().split('T')[0] + ' 00:00:00');
    date.setDate(date.getDate() + 1);
  }
  return days;
};

const AdditionalMealTypeModalCalendar = ({
  classes,
  isOpened = () => {},
  onClose = () => {},
  label,
  diet,
  updateDiet,
  value,
  selectedDietAdditionalCalendar,
  setSelectedDietAdditionalCalendar,
  editAdditionalMealTypes,
}) => {
  const { t } = useTranslation();
  const { dietLength, selectedDays } = diet ?? {};
  const [sundayInclude, setSundayInclude] = useState(false);
  const [saturdayInclude, setSaturdayInclude] = useState(false);

  const [month, setMonth] = useState(moment().month());
  const [secondMonth, setSecondMonth] = useState(moment().month() + 1);

  const [disabledDays, setDisabledDays] = useState([]);
  const [daysOfMonth, setDaysOfMonth] = useState([]);

  useEffect(() => {
    setSundayInclude(true);
    setSaturdayInclude(true);
    setDaysOfMonth([
      ...getDaysInMonth(month + 1),
      ...getDaysInMonth(secondMonth + 1),
      ...getDaysInMonth(secondMonth + 2),
    ]);
  }, []);

  useEffect(() => {
    const selectedDaysArray = selectedDays?.map(
      day => moment(day).format('YYYY-MM-DD') + ' 00:00:00'
    );

    const disabledDays = daysOfMonth.filter(
      day => !selectedDaysArray.includes(day)
    );

    setDisabledDays(disabledDays);
    setSelectedDietAdditionalCalendar(diet);
  }, [isOpened]);

  const selectDietDay = day => {
    const isDayInSelectedDays = isDateInArray(
      day,
      selectedDietAdditionalCalendar.selectedDays
    );

    if (
      !isDayInSelectedDays &&
      dietLength - selectedDietAdditionalCalendar.selectedDays.length === 0
    ) {
      return;
    }

    const daysToChange = isDayInSelectedDays
      ? selectedDietAdditionalCalendar.selectedDays.filter(
          el => !isTheSameDays(el, day)
        )
      : selectedDietAdditionalCalendar.selectedDays.concat(day);

    const daysToChangeBlockedDisabledDays = daysToChange.filter(
      el => !disabledDays.includes(el.format('YYYY-MM-DD') + ' 00:00:00')
    );
    daysToChangeBlockedDisabledDays.map(el => {
      return { el, amount: 1 };
    });

    return setSelectedDietAdditionalCalendar({
      ...selectedDietAdditionalCalendar,
      selectedDays: daysToChangeBlockedDisabledDays,
    });
  };

  return (
    <Dialog maxWidth={false} open={isOpened} onClose={() => onClose(false)}>
      <DialogTitle className={classes.modalHeader}>
        {t('orders.additionalMealTypes.modal.title', 'Dodatkowa porcja:') +
          ` ${label}`}
      </DialogTitle>
      <DialogContent
        className={classes.modalBody}
        style={{ minHeight: '100px' }}
      >
        <Calendar
          diet={selectedDietAdditionalCalendar}
          selectDietDay={selectDietDay}
          disabledDays={disabledDays}
          saturdayInclude={saturdayInclude}
          sundayInclude={sundayInclude}
          month={month}
          secondMonth={secondMonth}
        />
      </DialogContent>
      <DialogActions
        className={classes.modalFooter}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Button onClick={() => onClose(false)}>{t('common.no', 'Nie')}</Button>
        <Button
          onClick={() => {
            const dateWithAmount =
              selectedDietAdditionalCalendar.selectedDays.map(el => {
                return {
                  date: el,
                  amount: 1,
                };
              });
            selectedDietAdditionalCalendar.selectedAdditionalMealTypes.forEach(
              el => {
                if (el.value === editAdditionalMealTypes) {
                  el.date = selectedDietAdditionalCalendar.selectedDays;
                  el.dateWithAmount = dateWithAmount;
                }
                return el;
              }
            );

            updateDiet({
              ...diet,
              selectedDietAdditionalCalendar,
            });
            onClose(false);
          }}
          color="success"
        >
          {t('common.yes', 'Tak')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const combinedStyles = combineStyles(notificationsStyle, buttonsStyle);
const enhance = compose(withStyles(combinedStyles));
export default enhance(AdditionalMealTypeModalCalendar);
