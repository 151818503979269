import React from 'react';
import Button from 'components/CustomButtons/Button';
import { applyMenuTemplate } from 'actions/MenuTemplates';
import { connect } from 'react-redux';
import { CheckCircle } from '@material-ui/icons';
import { DialogLoader } from 'components/DialogLoader';
import SweetAlert from 'react-bootstrap-sweetalert';
import withStyles from '@material-ui/core/styles/withStyles';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle';
import { withTranslation } from 'react-i18next';

class ApplyMenuTemplate extends React.Component {
  state = {
    alert: null,
    loading: false,
  };

  setLoading = value =>
    this.setState(prevState => ({ ...prevState, loading: value }));

  applyTemplate = () => {
    this.setLoading(true);
    this.props
      .applyMenuTemplate(this.props.id, this.props.date)
      .then(async res => {
        await this.props.onFinishAssign({ success: true, result: res });
        this.setLoading(false);
      })
      .catch(async err => {
        await this.props.onFinishAssign({ success: false, result: err });
        this.setLoading(false);
      });
  };

  confirmModal = () => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          title={this.props.t('menuPlanner.action.template.useAlertTitle')}
          onConfirm={() => {
            this.applyTemplate();
            this.hideAlert();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.danger
          }
          confirmBtnText={this.props.t('common.shared.yes')}
          cancelBtnText={this.props.t('common.shared.no')}
          showCancel
        />
      ),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  render() {
    return (
      <>
        <Button
          color="success"
          justIcon
          simple
          disabled={this.state.loading}
          onClick={this.confirmModal}
        >
          <CheckCircle />
          <DialogLoader
            loaderState={this.state.loading}
            text={this.props.t('menuPlanner.action.template.copping')}
          />
        </Button>
        {this.state.alert}
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  applyMenuTemplate: (id, date) => dispatch(applyMenuTemplate(id, date)),
});

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(withStyles(sweetAlertStyle)(ApplyMenuTemplate))
);
