import PayUInfoWithTooltip from 'views/BrandConfiguration/PayUInfoWithTooltip';
import PaymentRow from './PaymentRow';
import { TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const PaymentMethodManagementSection = ({
  classes,
  modules,
  paymentTypes,
  setModules,
  validateChangeStatusPaymentType,
  handleChangePaymentMethod,
}) => {
  const { t } = useTranslation();

  const paymentRowSharedProps = {
    modules,
    paymentTypes,
    setModules,
    validateChangeStatusPaymentType,
    handleChangePaymentMethod,
  };

  return (
    <>
      <TableRow>
        <TableCell rowSpan={6}>
          <div className={classes.payUTitle}>
            PayU
            <PayUInfoWithTooltip />
          </div>
        </TableCell>
        <PaymentRow
          value={{
            title: 'PayU',
            paymentTypeStandard: 'PAYU_CARD',
            paymentTypeSubscription: 'PAYU_CARD',
            option: 'cardPaymentsEnabled',
            method: t('paymentType.payUCard'),
            tooltip: t('paymentType.payUCard.tooltip'),
            designation: 'RGB(0,172,193)',
          }}
          {...paymentRowSharedProps}
        />
      </TableRow>
      <TableRow>
        <PaymentRow
          value={{
            title: 'PayU',
            paymentTypeStandard: 'PAYU_PAYPO',
            option: 'payPo',
            method: t('paymentType.payUPayPo'),
            designation: 'RGB(0,172,193)',
          }}
          {...paymentRowSharedProps}
        />
      </TableRow>
      <TableRow>
        <PaymentRow
          value={{
            title: 'PayU',
            paymentTypeStandard: 'PAYU_GOOGLE_PAY',
            option: 'googlePayEnabled',
            method: t('paymentType.payUGooglePay'),
            designation: 'RGB(0,172,193)',
          }}
          {...paymentRowSharedProps}
        />
      </TableRow>
      <TableRow>
        <PaymentRow
          value={{
            title: 'PayU',
            paymentTypeStandard: 'PAYU',
            method: t('paymentType.payU'),
            designation: 'RGB(0,172,193)',
          }}
          {...paymentRowSharedProps}
        />
      </TableRow>
      <TableRow>
        <PaymentRow
          value={{
            title: 'PayU',
            paymentTypeStandard: 'PAYU_BLIK',
            option: 'blik',
            method: t('paymentType.payUBlik'),
            designation: 'RGB(0,172,193)',
          }}
          {...paymentRowSharedProps}
        />
      </TableRow>
      <TableRow>
        <PaymentRow
          value={{
            title: 'PayU',
            paymentTypeStandard: 'PAYU_SANDBOX',
            option: 'sandbox',
            method: t('paymentType.payUSandbox'),
            designation: 'RGB(0,172,193)',
          }}
          {...paymentRowSharedProps}
        />
      </TableRow>
      <TableRow>
        <TableCell rowSpan={2}>Stripe</TableCell>
        <PaymentRow
          value={{
            title: 'Stripe',
            paymentTypeStandard: 'STRIPE_CARD',
            paymentTypeSubscription: 'STRIPE_CARD',
            option: 'cardPayments',
            method: t('paymentType.stripeCard'),
            designation: 'RGB(76,175,80)',
          }}
          {...paymentRowSharedProps}
        />
      </TableRow>
      <TableRow>
        <PaymentRow
          value={{
            title: 'Stripe',
            paymentTypeStandard: 'STRIPE_LINK',
            method: t('paymentType.stripeLink'),
            designation: 'RGB(76,175,80)',
          }}
          {...paymentRowSharedProps}
        />
      </TableRow>
      <TableRow>
        <TableCell>PayPo</TableCell>
        <PaymentRow
          value={{
            title: 'PayPo',
            paymentTypeStandard: 'PAYPO',
            method: t('paymentType.payPo'),
            designation: 'RGB(204,0,0)',
          }}
          {...paymentRowSharedProps}
        />
      </TableRow>
      <TableRow>
        <TableCell rowSpan={2}>PayNow</TableCell>
        <PaymentRow
          value={{
            title: 'PayNow',
            paymentTypeStandard: 'PAYNOW',
            method: t('paymentType.payNow'),
            designation: 'RGB(255,152,0)',
          }}
          {...paymentRowSharedProps}
        />
      </TableRow>
      <TableRow>
        <PaymentRow
          value={{
            title: 'PayNow',
            paymentTypeStandard: 'PAYNOW_SANDBOX',
            option: 'sandbox',
            method: t('paymentType.payNowSandbox'),
            designation: 'RGB(255,152,0)',
          }}
          {...paymentRowSharedProps}
        />
      </TableRow>
    </>
  );
};

export default PaymentMethodManagementSection;
