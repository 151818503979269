import { combineStyles } from '../../helpers/helpers';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  fetchBrand,
  fetchBrandConfigModulesPack,
  updateBrandConfigModulesPack,
} from '../../actions/Brands';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import { Divider, Tooltip } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import SelectInput from '../../components/FormSelect/SelectInput';
import FormTextInputNoGrid from '../../components/FormTextInput/FormTextInputNoGrid';
import { Info } from '@material-ui/icons';
import FormControlButtons from '../../components/FormControlButtons/FormControlButtons';
import { useState, useEffect } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import { put } from '../../helpers/apiHelpers';
import { DialogLoader } from '../../components/DialogLoader';
import CardWrapper from 'components/Card/CardWrapper';
import { toast } from 'react-toastify';

const encryptionOptions = [
  { label: 'Automatyczne', value: 'auto' },
  { label: 'SSL', value: 'ssl' },
  { label: 'TLS', value: 'tls' },
];

const MailAndSms = ({
  t,
  classes,
  fetchBrand,
  selectedBrand,
  updateBrandConfigModulesPack,
}) => {
  const [modules, setModules] = useState({});
  const [mailOptions, setMailOptions] = useState({
    mailerEncryption: null,
    mailerHost: '',
    mailerFrom: '',
    mailerName: '',
    mailerPort: '',
    mailerUsername: '',
    mailerPassword: '',
  });

  const [smsOptions, setSmsOptions] = useState({
    smsApiEnabled: false,
    smsApiToken: '',
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const brandConfig = await fetchBrand(selectedBrand);

      const newMailOptions = {
        mailerEncryption:
          brandConfig.mailerEncryption === null
            ? 'auto'
            : brandConfig.mailerEncryption,
        mailerFrom: brandConfig.mailerFrom || '',
        mailerHost: brandConfig.mailerHost,
        mailerName: brandConfig.mailerName,
        mailerPort: brandConfig.mailerPort,
        mailerUsername: brandConfig.mailerUsername,
      };

      const newSmsOptions = {
        smsApiEnabled: brandConfig.smsApiEnabled,
        smsApiToken: brandConfig.smsApiToken,
      };

      setMailOptions(prevState => ({ ...prevState, ...newMailOptions }));
      setSmsOptions(prevState => ({ ...prevState, ...newSmsOptions }));
      setIsLoading(false);
    })();
  }, []);

  const validateMailerFrom = () => {
    const pattern = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+.)+[a-zA-Z]{2,}))$/
    );

    return pattern.test(mailOptions.mailerFrom);
  };

  const handleEmailStateChange = e => {
    setMailOptions(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSmsStateChange = e => {
    setSmsOptions(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    const data = {
      mailerEncryption:
        mailOptions.mailerEncryption === 'auto'
          ? null
          : mailOptions.mailerEncryption,
      mailerFrom: mailOptions.mailerFrom,
      mailerHost: mailOptions.mailerHost,
      mailerName: mailOptions.mailerName,
      mailerPassword: mailOptions.mailerPassword,
      mailerPort: parseInt(mailOptions.mailerPort),
      mailerUsername: mailOptions.mailerUsername,
      smsApiEnabled: smsOptions.smsApiEnabled,
      smsApiToken: smsOptions.smsApiToken,
    };

    try {
      await put(`/brands/${selectedBrand}`, data);
      await updateBrandConfigModulesPack(selectedBrand, modules);

      toast.success(t('success.changesSaved'));
    } catch (e) {
      toast.error(t('notify.cannotSave'));
    }
  };

  return (
    <>
      <DialogLoader
        loaderState={isLoading}
        text={t('common.shared.isLoading')}
      />

      {!isLoading && (
        <>
          <CardWrapper
            title={t('brands.newBrandForm.accSettings', 'Ustawienia konta')}
          >
            <CardBody>
              <GridContainer>
                <GridItem md={12}>
                  <Divider style={{ marginBottom: '20px' }} />
                  <GridContainer>
                    <GridItem md={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        {t('brands.newBrandForm.encryption', 'Szyfrowanie ')}
                      </FormLabel>
                      <SelectInput
                        style={{ padding: 0 }}
                        noGrid
                        classes={classes}
                        mapBy="label"
                        trackBy="value"
                        name={'mailerEncryption'}
                        value={mailOptions.mailerEncryption}
                        options={encryptionOptions}
                        handleChange={e => handleEmailStateChange(e)}
                      />
                    </GridItem>
                    <GridItem md={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        {t('brands.newBrandForm.host', 'Host ')}
                      </FormLabel>
                      <FormTextInputNoGrid
                        classes={classes}
                        name="mailerHost"
                        value={mailOptions.mailerHost}
                        maxLength={64}
                        handleChange={handleEmailStateChange}
                      />
                    </GridItem>
                    <GridItem md={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        {t('brands.newBrandForm.sender', 'Email nadawcy ')}
                      </FormLabel>
                      <FormTextInputNoGrid
                        classes={classes}
                        name="mailerFrom"
                        value={mailOptions.mailerFrom}
                        maxLength={64}
                        success={validateMailerFrom()}
                        error={
                          !validateMailerFrom() &&
                          mailOptions.mailerFrom.length > 0
                        }
                        helpText={
                          mailOptions.mailerFrom.length > 0
                            ? validateMailerFrom()
                              ? t(
                                  'brands.newBrandForm.helpText.correct',
                                  'Prawidłowy adres email'
                                )
                              : t(
                                  'brands.newBrandForm.helpText.incorrect',
                                  'Nieprawidłowy adres email'
                                )
                            : t(
                                'brands.newBrandForm.helpText.enter',
                                'Wpisz prawidłowy adres email'
                              )
                        }
                        handleChange={handleEmailStateChange}
                      />
                    </GridItem>
                    <GridItem md={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        {t('brands.newBrandForm.name', 'Nazwa nadawcy ')}
                      </FormLabel>
                      <FormTextInputNoGrid
                        classes={classes}
                        name="mailerName"
                        value={mailOptions.mailerName}
                        maxLength={64}
                        handleChange={handleEmailStateChange}
                      />
                    </GridItem>
                    <GridItem md={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        {t('brands.newBrandForm.port', 'Port ')}
                      </FormLabel>
                      <FormTextInputNoGrid
                        classes={classes}
                        name="mailerPort"
                        value={mailOptions.mailerPort}
                        maxLength={64}
                        handleChange={e => {
                          // accept only positive integer
                          if (/^\d*$/.test(e.target.value)) {
                            return handleEmailStateChange(e);
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem md={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        {t(
                          'brands.newBrandForm.username',
                          'Nazwa użytkownika '
                        )}
                      </FormLabel>
                      <FormTextInputNoGrid
                        classes={classes}
                        name="mailerUsername"
                        value={mailOptions.mailerUsername}
                        maxLength={64}
                        handleChange={handleEmailStateChange}
                      />
                    </GridItem>
                    <GridItem md={3}>
                      {/*<FormLabel className={classes.labelHorizontal}>*/}
                      {/*  {t('brands.newBrandForm.password', 'Hasło ')}*/}
                      {/*</FormLabel>*/}
                      <>
                        <FormLabel className={classes.labelHorizontal}>
                          {t('brands.newBrandForm.password', 'Hasło ')}
                        </FormLabel>{' '}
                        <Tooltip
                          title={
                            <h4>{t('brands.newBrandForm.passwordTooltip')}</h4>
                          }
                        >
                          <Info
                            style={{
                              fontSize: '16px',
                              color: 'grey',
                              marginLeft: '4px',
                              marginBottom: '-3px',
                            }}
                          />
                        </Tooltip>
                      </>
                      <FormTextInputNoGrid
                        classes={classes}
                        name="mailerPassword"
                        value={mailOptions.mailerPassword}
                        type={'password'}
                        noAutoComplete={true}
                        maxLength={64}
                        handleChange={handleEmailStateChange}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </CardWrapper>

          <Card>
            <CardBody>
              <GridContainer>
                <GridItem md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={smsOptions.smsApiEnabled}
                        onChange={() => {
                          setSmsOptions(prevState => ({
                            ...prevState,
                            smsApiEnabled: !smsOptions.smsApiEnabled,
                          }));
                        }}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={t('brands.newBrandForm.SMSgate', 'Bramka SMS')}
                  />
                </GridItem>
                {smsOptions.smsApiEnabled && (
                  <GridItem md={12}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('brands.newBrandForm.smsApiToken', 'SMS API token ')}
                    </FormLabel>
                    <FormTextInputNoGrid
                      classes={classes}
                      name="smsApiToken"
                      value={smsOptions.smsApiToken}
                      maxLength={64}
                      handleChange={handleSmsStateChange}
                    />
                  </GridItem>
                )}
              </GridContainer>
            </CardBody>
          </Card>

          <FormControlButtons
            classes={classes}
            submitText={t('common.shared.save', 'Zapisz')}
            handleSubmit={handleSubmit}
          />
        </>
      )}
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withTranslation(),
  connect(
    ({ Auth: { selectedBrand } }) => ({
      selectedBrand,
    }),
    { fetchBrand, fetchBrandConfigModulesPack, updateBrandConfigModulesPack }
  ),
  withStyles(combinedStyles)
);

export default enhance(MailAndSms);
